import { difference, isEmpty, keys, pick } from 'lodash';
import { Apollo } from '@/core/gql/apollo';
import { ProductType } from '@/interfaces/brokrete';
import { buildProductTypeQuery } from './productTypeQueryBuilder';
import type { ProductTypeFormInitialProps } from '@components/forms/settings/ProductTypeForm/types';

type FuncArgs = {
  productTypeId: string;
  initialProps?: Partial<ProductTypeFormInitialProps>;
};

export const REQUIRED_FIELDS_TO_CLONE = [
  'name',
  'serviceName',
  'group',
  'description',
  'shortDescription',
  'descriptionImages',
  'descriptionResources'
];

const prefetchInitialProps = async ({ productTypeId, initialProps }: FuncArgs) => {
  const formProps = pick(initialProps || {}, REQUIRED_FIELDS_TO_CLONE);
  const diff = difference(REQUIRED_FIELDS_TO_CLONE, keys(formProps));

  if (!isEmpty(diff)) {
    const { data } = await Apollo.client.query<{ productType: ProductType; success: boolean }>({
      query: buildProductTypeQuery(diff),
      variables: { id: productTypeId }
    });

    if (data) {
      const fetchedFields = pick(data.productType, diff);
      Object.assign(formProps, fetchedFields);
    }
  }

  return formProps;
};

export { prefetchInitialProps };
