import gql from 'graphql-tag';

export const productTypePriceCreate = gql`
  mutation productTypePriceCreate($input: ProductTypePriceCreateInput!) {
    productTypePriceCreate(input: $input) {
      productTypePrice {
        id
        price {
          content
          currency
          id
          priority
          value
        }
      }
      success
    }
  }
`;
