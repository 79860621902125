import qs from 'qs';

interface Props {
  decodeURIComponent?: boolean;
}

export function currentQuery(props?: Props): { [key: string]: any } {
  const search = props?.decodeURIComponent ? decodeURIComponent(window.location.search) : window.location.search;
  return qs.parse(search.slice(1));
}

export function toQuery(queryObject: { [key: string]: any }): string {
  return qs.stringify(queryObject);
}
