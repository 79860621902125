import gql from 'graphql-tag';
import { address, city } from '@/core/gql/fragments';

export const plantsDataTable = gql`
  query Query($limit: Int, $skip: Int, $category: [IdInput!], $name: String, $city: [IdInput!], $supplier: [IdInput!]) {
    plants(limit: $limit, skip: $skip, category: $category, name: $name, city: $city, supplier: $supplier) {
      count
      values {
        id
        name
        color
        deliveryRadius
        deliveryRadiusUnit
        supplier {
          id
          name
        }
        address {
          ...address
        }
        city {
          ...city
        }
      }
    }
  }
  ${address}
  ${city}
`;
