import ICONS from '@/assets/images/icons';
import { colors } from '@/defaultStyles';
import React, { useRef } from 'react';
import styled from 'styled-components';

type Props = {
  multiple?: boolean;
  addFiles: (file: File[]) => void;
};

const FileInput = styled.input`
  display: none;
`;

const UploadButton = styled.div`
  height: 90px;
  width: 90px;
  border-radius: 10px;
  border: 2px dashed ${colors.LIGHT_PERIWINKLE};
  background-color: ${colors.EGG_WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AddButton = styled.img`
  height: 60px;
  width: 60px;
  filter: brightness(0.5);
`;

const FileUpload: React.FunctionComponent<Props> = ({ addFiles, multiple = true }) => {
  const fileUpload = useRef<HTMLInputElement | null>(null);

  const handleFileUpload = (fileList: FileList) => {
    const files = Array.from(fileList);
    addFiles(files);
  };

  return (
    <>
      <FileInput
        type="file"
        accept="image/png, image/jpeg, .pdf, .txt, .docx, .doc"
        onChange={e => e.target.files && handleFileUpload(e.target.files)}
        multiple={multiple}
        ref={fileUpload}
      />
      <UploadButton onClick={() => fileUpload.current && fileUpload.current!.click()}>
        <AddButton src={ICONS.icAdd} />
      </UploadButton>
    </>
  );
};

export default FileUpload;
