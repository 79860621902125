import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFileViewerContext } from '@/shared/components/dataDisplay/FileVIewer/FileViewerContext';
import { last } from 'lodash';
import { Tooltip } from 'antd';
import { Resource } from '@/interfaces/brokrete';
import { OptionsPanel } from '@/shared/components/dataDisplay/FileVIewer/items/FileItem/OptionsPanel';
import { TooltipView } from '@/shared/components/dataDisplay/FileVIewer/items/FileItem/TooltipView';
import { FileIcon } from '@/shared/components/dataDisplay/FileVIewer/items/FileIcon';

const Container = styled.div<{ height: number }>`
  height: ${props => props.height}px;
  width: ${props => props.height}px;
  position: relative;
  border: 1px solid #d0d0d0;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;

  .preview {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(${props => props.height / 86} * 48px);
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .options {
    position: absolute;
    left: 0;
    top: -30px;
    width: 100%;
    z-index: 3;
  }

  :hover {
    .options {
      top: 0;
      transition: top 0.3s;
    }
  }
`;

type FileItemProps = Omit<Resource, 'fileUrl'> & { createdAt: string };

/**
 * Item with options panel.
 * @param source
 * @param mimeType
 * @param id
 * @param createdAt
 * @constructor
 */
export const FileItem: React.FC<FileItemProps> = ({ source, mimeType, id, createdAt }) => {
  const [state, dispatch] = useFileViewerContext();
  const name = last(source.split('/')) ?? '';
  const type = mimeType?.includes('image') ? 'image' : 'file';
  useEffect(() => {
    dispatch({ type: 'addItem', item: { id, name, type, source, createdAt, mimeType } });
    return () => {
      dispatch({ type: 'removeItem', id });
    };
  }, [id, source, createdAt, mimeType]);

  return (
    <Tooltip placement="bottom" title={<TooltipView name={name} date={createdAt} />}>
      <Container height={state.height}>
        {!!state.controlsConfig && (
          <div className="options">
            <OptionsPanel id={id} />
          </div>
        )}
        {type === 'image' ? <img src={source} alt="image" /> : <FileIcon fileName={source} />}
      </Container>
    </Tooltip>
  );
};
