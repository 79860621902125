const flags = {
  AD: require('./AD.svg'),
  AE: require('./AE.svg'),
  AF: require('./AF.svg'),
  AG: require('./AG.svg'),
  AI: require('./AI.svg'),
  AL: require('./AL.svg'),
  AM: require('./AM.svg'),
  AN: require('./AN.svg'),
  AO: require('./AO.svg'),
  AR: require('./AR.svg'),
  AT: require('./AT.svg'),
  AU: require('./AU.svg'),
  AW: require('./AW.svg'),
  AX: require('./AX.svg'),
  AZ: require('./AZ.svg'),
  BA: require('./BA.svg'),
  BB: require('./BB.svg'),
  BD: require('./BD.svg'),
  BE: require('./BE.svg'),
  BF: require('./BF.svg'),
  BG: require('./BG.svg'),
  BH: require('./BH.svg'),
  BI: require('./BI.svg'),
  BJ: require('./BJ.svg'),
  BM: require('./BM.svg'),
  BN: require('./BN.svg'),
  BO: require('./BO.svg'),
  BR: require('./BR.svg'),
  BS: require('./BS.svg'),
  BT: require('./BT.svg'),
  BW: require('./BW.svg'),
  BY: require('./BY.svg'),
  BZ: require('./BZ.svg'),
  CA: require('./CA.svg'),
  CAF: require('./CAF.svg'),
  CAS: require('./CAS.svg'),
  CD: require('./CD.svg'),
  CEU: require('./CEU.svg'),
  CF: require('./CF.svg'),
  CG: require('./CG.svg'),
  CH: require('./CH.svg'),
  CI: require('./CI.svg'),
  CL: require('./CL.svg'),
  CM: require('./CM.svg'),
  CN: require('./CN.svg'),
  CNA: require('./CNA.svg'),
  CO: require('./CO.svg'),
  COC: require('./COC.svg'),
  CR: require('./CR.svg'),
  CSA: require('./CSA.svg'),
  CU: require('./CU.svg'),
  CV: require('./CV.svg'),
  CY: require('./CY.svg'),
  CZ: require('./CZ.svg'),
  DE: require('./DE.svg'),
  DJ: require('./DJ.svg'),
  DK: require('./DK.svg'),
  DM: require('./DM.svg'),
  DO: require('./DO.svg'),
  DZ: require('./DZ.svg'),
  EC: require('./EC.svg'),
  EE: require('./EE.svg'),
  EG: require('./EG.svg'),
  ER: require('./ER.svg'),
  ES: require('./ES-new.svg'),
  ET: require('./ET.svg'),
  EU: require('./EU.svg'),
  FI: require('./FI.svg'),
  FJ: require('./FJ.svg'),
  FK: require('./FK.svg'),
  FM: require('./FM.svg'),
  FR: require('./FR-new.svg'),
  GA: require('./GA.svg'),
  GB: require('./GB.svg'),
  GD: require('./GD.svg'),
  GE: require('./GE.svg'),
  GG: require('./GG.svg'),
  GH: require('./GH.svg'),
  GI: require('./GI.svg'),
  GM: require('./GM.svg'),
  GN: require('./GN.svg'),
  GQ: require('./GQ.svg'),
  GR: require('./GR.svg'),
  GT: require('./GT.svg'),
  GW: require('./GW.svg'),
  GY: require('./GY.svg'),
  HK: require('./HK.svg'),
  HN: require('./HN.svg'),
  HR: require('./HR.svg'),
  HT: require('./HT.svg'),
  HU: require('./HU.svg'),
  ID: require('./ID.svg'),
  IE: require('./IE.svg'),
  IL: require('./IL.svg'),
  IM: require('./IM.svg'),
  IN: require('./IN.svg'),
  IQ: require('./IQ.svg'),
  IR: require('./IR.svg'),
  IS: require('./IS.svg'),
  IT: require('./IT.svg'),
  JE: require('./JE.svg'),
  JM: require('./JM.svg'),
  JO: require('./JO.svg'),
  JP: require('./JP.svg'),
  KE: require('./KE.svg'),
  KG: require('./KG.svg'),
  KH: require('./KH.svg'),
  KM: require('./KM.svg'),
  KN: require('./KN.svg'),
  KP: require('./KP.svg'),
  KR: require('./KR.svg'),
  KW: require('./KW.svg'),
  KY: require('./KY.svg'),
  KZ: require('./KZ.svg'),
  LA: require('./LA.svg'),
  LB: require('./LB.svg'),
  LC: require('./LC.svg'),
  LI: require('./LI.svg'),
  LK: require('./LK.svg'),
  LR: require('./LR.svg'),
  LS: require('./LS.svg'),
  LT: require('./LT.svg'),
  LU: require('./LU.svg'),
  LV: require('./LV.svg'),
  LY: require('./LY.svg'),
  MA: require('./MA.svg'),
  MC: require('./MC.svg'),
  MD: require('./MD.svg'),
  ME: require('./ME.svg'),
  MG: require('./MG.svg'),
  MK: require('./MK.svg'),
  ML: require('./ML.svg'),
  MM: require('./MM.svg'),
  MN: require('./MN.svg'),
  MO: require('./MO.svg'),
  MR: require('./MR.svg'),
  MS: require('./MS.svg'),
  MT: require('./MT.svg'),
  MU: require('./MU.svg'),
  MV: require('./MV.svg'),
  MW: require('./MW.svg'),
  MX: require('./MX.svg'),
  MY: require('./MY.svg'),
  MZ: require('./MZ.svg'),
  NA: require('./NA.svg'),
  NE: require('./NE.svg'),
  NG: require('./NG.svg'),
  NI: require('./NI.svg'),
  NL: require('./NL.svg'),
  NO: require('./NO.svg'),
  NP: require('./NP.svg'),
  NZ: require('./NZ.svg'),
  OM: require('./OM.svg'),
  PA: require('./PA.svg'),
  PE: require('./PE.svg'),
  PF: require('./PF.svg'),
  PG: require('./PG.svg'),
  PH: require('./PH.svg'),
  PK: require('./PK.svg'),
  PL: require('./PL.svg'),
  PR: require('./PR.svg'),
  PT: require('./PT.svg'),
  PW: require('./PW.svg'),
  PY: require('./PY.svg'),
  QA: require('./QA.svg'),
  RO: require('./RO.svg'),
  RS: require('./RS.svg'),
  RU: require('./RU.svg'),
  RW: require('./RW.svg'),
  SA: require('./SA.svg'),
  SB: require('./SB.svg'),
  SC: require('./SC.svg'),
  SD: require('./SD.svg'),
  SE: require('./SE.svg'),
  SG: require('./SG.svg'),
  SH: require('./SH.svg'),
  SI: require('./SI.svg'),
  SK: require('./SK.svg'),
  SL: require('./SL.svg'),
  SM: require('./SM.svg'),
  SN: require('./SN.svg'),
  SO: require('./SO.svg'),
  SR: require('./SR.svg'),
  ST: require('./ST.svg'),
  SV: require('./SV.svg'),
  SY: require('./SY.svg'),
  SZ: require('./SZ.svg'),
  TC: require('./TC.svg'),
  TD: require('./TD.svg'),
  TG: require('./TG.svg'),
  TH: require('./TH.svg'),
  TJ: require('./TJ.svg'),
  TL: require('./TL.svg'),
  TM: require('./TM.svg'),
  TN: require('./TN.svg'),
  TO: require('./TO.svg'),
  TR: require('./TR.svg'),
  TT: require('./TT.svg'),
  TW: require('./TW.svg'),
  TZ: require('./TZ.svg'),
  UA: require('./UA-new.svg'),
  UG: require('./UG.svg'),
  US: require('./US-new.svg'),
  UY: require('./UY.svg'),
  UZ: require('./UZ.svg'),
  VC: require('./VC.svg'),
  VE: require('./VE.svg'),
  VG: require('./VG.svg'),
  VN: require('./VN.svg'),
  VU: require('./VU.svg'),
  WS: require('./WS.svg'),
  WW: require('./WW.svg'),
  YE: require('./YE.svg'),
  ZA: require('./ZA.svg'),
  ZM: require('./ZM.svg'),
  ZW: require('./ZW.svg')
};

export default flags;
