import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IdInput, ProductTypeModificationGroup, ProductTypeModifications, ProductTypeOptionValue } from '@/interfaces/brokrete';

import { colors } from '@/defaultStyles';
import { SVGPencilGreen, SVGDelete } from '@components/elements/icons';

import ProductModificationTag from './ProductModificationModal/ProductModificationTag';
import ProductModificationModal from './ProductModificationModal';
import { buildIdInput } from '@/shared/api/brokrete/helper';
import { productTypeModificationsUpdate } from '@components/forms/settings/ProductTypeForm/components/ProductModificationModal/gql';
import ConfirmationModal from '../../../../modals/ConfirmationModal';
import { i18n } from '@/core';
import { isEmpty } from '@/libs';

type ProductModificationSectionProps = {
  getModificationTagStyles?: (modification: ProductTypeModifications) => any;
  productId: string;
  editable?: boolean;
  modification: ProductTypeModificationGroup;
  optionCombinations?: ProductTypeOptionValue[] | null;
  onModificationTagClick?: (modification: ProductTypeModifications, index: number) => void;
  onChanged?: (values: {
    productTypeModificationGroup: ProductTypeModificationGroup | null;
    modifications: ProductTypeModifications[];
  }) => void;
};

const Root = styled.div``;

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const Label = styled.div`
  margin-right: 20px;
  font-family: Catamaran;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: 1px;
  color: ${colors.BATTLESHIP_GREY};
  text-transform: uppercase;
`;

const Divider = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${colors.OFF_WHITE};
`;

const Buttons = styled.div`
  margin-left: 20px;
`;

const EditIcon = styled(SVGPencilGreen)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 15px;

  #ic_pancil {
    fill: ${colors.BATTLESHIP_GREY};
  }

  :hover {
    #ic_pancil {
      fill: ${colors.ALGAE_GREEN};
    }
  }
`;

const DeleteIcon = styled(SVGDelete)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 15px;

  #ic_trash_box,
  #ic_trash {
    fill: ${colors.BATTLESHIP_GREY};
  }

  :hover {
    #ic_trash_box,
    #ic_trash {
      fill: ${colors.ALGAE_GREEN};
    }
  }
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    margin-bottom: 10px;
  }
`;

const Image = styled.img`
  margin-right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const Color = styled.div<{ color: string }>`
  margin-right: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid ${colors.LIGHT_PERIWINKLE};
  border-radius: 50%;

  background-color: ${({ color }) => color};
`;

const ProductModificationSection: React.FC<ProductModificationSectionProps> = ({
  productId,
  editable = true,
  modification,
  onModificationTagClick,
  getModificationTagStyles,
  optionCombinations,
  onChanged
}) => {
  const [state, setState] = useState(modification);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      setState(modification);
    }
  }, [isModalOpen, modification]);

  const canRemoveModifications = (modifications: IdInput[]) => {
    if (optionCombinations === undefined) {
      return false;
    }

    if (isEmpty(optionCombinations)) {
      return true;
    }

    const modificationIds = modifications.map(({ id }) => id);

    const isInUse = optionCombinations.some(optionCombination => {
      return optionCombination.modifications.some(modification => modificationIds.includes(modification.id));
    });

    return !isInUse;
  };

  const onRemoveModifications = async (modifications: IdInput[]) => {
    const result = await productTypeModificationsUpdate({
      id: productId,
      modifications: { remove: modifications }
    });

    return result;
  };

  const onRemoveModification = async (modificationIndex: number) => {
    const values = [...state.values];

    const deletedModification = values.splice(modificationIndex, 1);

    const canRemove = canRemoveModifications([buildIdInput(deletedModification[0])]);

    if (canRemove) {
      const newState = { ...state, values };

      const result = await onRemoveModifications([buildIdInput(deletedModification[0])]);

      setState(newState);

      if (onChanged) {
        onChanged({
          modifications: result?.productTypeUpdate?.productType?.modifications || [],
          productTypeModificationGroup: newState
        });
      }
    } else {
      setInfoModalOpen(true);
    }
  };

  const removeModificationGroup = async () => {
    const modifications = state.values.map(value => buildIdInput(value));
    const canRemove = canRemoveModifications(modifications);

    if (canRemove) {
      const result = await onRemoveModifications(modifications);
      if (onChanged) {
        onChanged({
          modifications: result?.productTypeUpdate?.productType?.modifications || [],
          productTypeModificationGroup: null
        });
      }
    } else {
      setInfoModalOpen(true);
    }
  };

  return (
    <Root>
      <Heading>
        <Label>{state.label}</Label>
        <Divider />
        {editable && (
          <Buttons>
            <EditIcon onClick={() => setIsModalOpen(true)} />
            <DeleteIcon onClick={() => removeModificationGroup()} />
          </Buttons>
        )}
      </Heading>

      <Options>
        {state.values.map((value, idx) => {
          const ImageIcon = value.image && <Image src={value.image} />;
          const ColorIcon = value.color && <Color color={value.color} />;

          return (
            <ProductModificationTag
              key={value.id}
              editable={editable}
              getModificationTagStyles={getModificationTagStyles}
              Icon={ImageIcon || ColorIcon}
              item={value}
              onClick={modification => onModificationTagClick && onModificationTagClick(modification, idx)}
              onRemove={() => onRemoveModification(idx)}
            />
          );
        })}
      </Options>

      <ProductModificationModal
        productId={productId}
        modification={state}
        isOpened={isModalOpen}
        onClosed={() => setIsModalOpen(false)}
        onSubmit={({ productTypeModificationGroup, modifications }) => {
          setState(productTypeModificationGroup);
          if (onChanged) {
            onChanged({ productTypeModificationGroup, modifications });
          }
          setIsModalOpen(false);
        }}
      />

      <ConfirmationModal
        title="Warning"
        text={i18n.t('labels.modInUse')}
        buttonConfirmTitle="Ok"
        isOpen={isInfoModalOpen}
        onConfirm={() => setInfoModalOpen(false)}
      />
    </Root>
  );
};

export default ProductModificationSection;
