import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { gettext } from '@cranium/i18n';

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const lowerCaseRegExp = /(?=.*[a-z])/;
const upperCaseRegExp = /(?=.*[A-Z])/;
const numberRegExp = /(?=.*[0-9])/;

export const email = (value: string) => {
  return value && value.length > 0 && emailRegExp.test(value) ? undefined : 'Invalid email';
};

export function emailValidator(rule: any, value: string, callBack: (message?: any) => void) {
  const isError = value && email(value);
  callBack(isError ? gettext('Email is incorrect') : undefined);
}

export const phone = (value: string) => {
  try {
    const prefix = value?.[0] === '+' ? '' : '+';
    const phoneNumber = parsePhoneNumberFromString(prefix + value);
    if (!phoneNumber || (phoneNumber && !phoneNumber.isPossible())) {
      return 'Invalid phone number';
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
};

const passwordRegExp = [
  /(?=.{8,})/, // Must contain 8 or more characters
  /(?=.*[a-z])/, // Must contain a lower case character
  /(?=.*[A-Z])/ // Must contain an upper case character
];

export const password = (value: string) => {
  if (!value) {
    return undefined;
  }
  if (!passwordRegExp[0].test(value)) {
    return 'Must contain 8 or more characters';
  }
  if (!passwordRegExp[1].test(value)) {
    return 'Must contain a lower case character';
  }
  if (!passwordRegExp[2].test(value)) {
    return 'Must contain an upper case character';
  }
};

export const match = (parentValue: string) => (value: string) => {
  return value && parentValue === value;
};

export const requireMinLength = (min: number) => (value: string) => {
  if (value) {
    return value.length >= min;
  }

  return min === 0;
};

export const required = (value: any) => {
  return value || typeof value === 'number' ? undefined : 'Required';
};

export const notEmpty = (value: any) => {
  return value && (typeof value !== 'string' || value.trim().length > 0) ? undefined : 'Should not be empty';
};

export const requireLowerCase = (value: string) => {
  try {
    return value && lowerCaseRegExp.test(value);
  } catch (e) {
    return false;
  }
};

export const requireUpperCase = (value: string) => {
  try {
    return value && upperCaseRegExp.test(value);
  } catch (e) {
    return false;
  }
};

export const requireNumber = (value: string) => {
  try {
    return value && numberRegExp.test(value) ? undefined : 'Input must be a number';
  } catch (e) {
    return 'Required';
  }
};

export const notAZero = (value: number) => {
  return value && value > 0 ? undefined : 'Required';
};
