import React, { createContext, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UserTipFragment, useUserTipRequest, UserTipQuery } from './queries/userTip.generated';
import { useDevTools } from '@/shared/devTools';
import { ApolloQueryResult } from '@apollo/client';

type UserTipsProviderProps = {};

type UserTipsContextState = {
  data: { [name: string]: TipItem };
  fetchTip: (name: string, abortControllerRef: MutableRefObject<AbortController>) => Promise<void>;
};

type TipItem = {
  tip: UserTipFragment | undefined | null;
  loading: boolean;
  loaded: boolean;
};

const defaultState: UserTipsContextState = { data: {}, fetchTip: () => Promise.resolve() };

const UserTipsContext = createContext<UserTipsContextState>(defaultState);

const useUserTips = (): UserTipsContextState => {
  return useContext(UserTipsContext);
};
export const useGetUserTip = (name: string): TipItem => {
  const abortController = useRef(new AbortController());
  const { data, fetchTip } = useUserTips();
  const devTools = useDevTools();
  const tipItem = data[name];

  const { loaded, loading } = tipItem || {};

  useEffect(() => {
    if (devTools.refetchUserTip || (!loading && !loaded)) {
      fetchTip(name, abortController);
    }
    return () => {
      abortController.current.abort();
    };
  }, [abortController]);

  return tipItem;
};

export const UserTipsProvider: React.FC<UserTipsProviderProps> = ({ children }) => {
  const fetchTip = useUserTipRequest();

  const fetchTipByName = useCallback(
    async (name: string, abortControllerRef: MutableRefObject<AbortController>) => {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      setUserTipsState(st => ({
        ...st,
        data: {
          ...st.data,
          [name]: {
            tip: undefined,
            loading: true,
            loaded: false
          }
        }
      }));

      fetchTip(
        { name },
        {
          fetchPolicy: 'no-cache',
          context: {
            fetchOptions: {
              signal: abortControllerRef.current.signal,
              queryDeduplication: false
            }
          }
        }
      )
        .then((response: ApolloQueryResult<UserTipQuery>) => {
          const { data } = response;

          setUserTipsState(st => {
            return {
              ...st,
              data: {
                ...st.data,
                [name]: { ...st.data[name], tip: data.clientTip }
              }
            };
          });
        })
        .finally(() => {
          setUserTipsState(st => {
            return {
              ...st,
              data: {
                ...st.data,
                [name]: { ...st.data[name], loading: false, loaded: true }
              }
            };
          });
        });
    },
    [fetchTip]
  );

  const [userTipsState, setUserTipsState] = useState<UserTipsContextState>({
    ...defaultState,
    fetchTip: fetchTipByName
  });

  return <UserTipsContext.Provider value={userTipsState}>{children}</UserTipsContext.Provider>;
};
