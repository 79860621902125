import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, CardSubtitle, Col, Form, Row } from 'reactstrap';

type FormInitialProps = {
  lockToHoldTime: string;
  maxCountToHold: string;
  holdingFeePrice: string;
};

type Props = FormikProps<FormInitialProps>;

export const holdOrderParamsInitialProps: FormInitialProps = {
  lockToHoldTime: '',
  maxCountToHold: '',
  holdingFeePrice: ''
};

const OrderParamsHoldForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid }) => {
  const { gettext } = useGettext();
  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col md={4}>
          <Field
            name={'lockToHoldTime'}
            id={'lockToHoldTime'}
            placeholder={gettext('Time')}
            formText={i18n.t('cards.settings.inMinutes')}
            component={FormInput}
            validate={required}
            type="number"
          />
        </Col>
        <Col>
          <div className="mt-1">
            {i18n.t('cards.settings.orderParams.amountOfTime', {
              status: i18n.t('cards.settings.orderParams.cantHold')
            })}
          </div>
        </Col>
      </Row>
      <Row form>
        <Col md={4}>
          <Field
            name={'maxCountToHold'}
            id={'maxCountToHold'}
            placeholder={gettext('Quantity')}
            component={FormInput}
            validate={required}
            type="number"
          />
        </Col>
        <Col>
          <div className="mt-1">{i18n.t('cards.settings.orderParams.maxQuantityHold')}</div>
        </Col>
      </Row>
      <CardSubtitle className="mt-2">{i18n.t('cards.settings.holdingFee')}</CardSubtitle>
      <Row form>
        <Col md={4}>
          <Field
            name={'holdingFeePrice'}
            id={'holdingFeePrice'}
            placeholder={gettext('Fee')}
            component={FormInput}
            type="number"
          />
        </Col>
        <Col>
          <div className="mt-1">{i18n.t('cards.settings.orderParams.holdingFee')}</div>
        </Col>
      </Row>

      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(OrderParamsHoldForm);
