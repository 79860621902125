import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

type FormInitialProps = {
  clientId: string;
  clientSecret: string;
};

type Props = FormikProps<FormInitialProps>;

export const quickbookSettingsFormInitialProps: FormInitialProps = {
  clientId: '',
  clientSecret: ''
};

const QuickBookSettingsForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  const { gettext } = useGettext();
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Field name="clientId" id="clientId" placeholder={gettext('Clint ID')} component={FormInput} validate={required} />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="clientSecret"
            id="clientSecret"
            placeholder={gettext('Client secret')}
            component={FormInput}
            validate={required}
          />
        </Col>
      </Row>

      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(QuickBookSettingsForm);
