// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActivityIndicator-module__root--PGaXt{white-space:nowrap}.ActivityIndicator-module__root--PGaXt .ActivityIndicator-module__loader--M16wF div{background-color:#78c3fb !important;width:10px;height:10px}", "",{"version":3,"sources":["webpack://./src/view/components/elements/ActivityIndicator.module.scss"],"names":[],"mappings":"AAAA,uCACE,kBAAA,CAGE,oFACE,mCAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".root {\n  white-space: nowrap;\n\n  .loader {\n    div {\n      background-color: #78c3fb !important;\n      width: 10px;\n      height: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ActivityIndicator-module__root--PGaXt",
	"loader": "ActivityIndicator-module__loader--M16wF"
};
export default ___CSS_LOADER_EXPORT___;
