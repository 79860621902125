import gql from 'graphql-tag';
import { address } from '../../../fragments';

export const ordersDataTable = gql`
  query orders(
    $skip: Int
    $limit: Int
    $status: [String!]
    $plant: [IdInput!]
    $supplier: [IdInput!]
    $updatedAtFrom: String
    $from: String
    $to: String
    $product: [IdInput!]
  ) {
    orders(
      skip: $skip
      limit: $limit
      status: $status
      supplier: $supplier
      plant: $plant
      updatedAtFrom: $updatedAtFrom
      from: $from
      to: $to
      product: $product
    ) {
      count
      values {
        id
        contractor {
          id
          info {
            name
          }
          companyInfo {
            name
          }
        }
        address {
          ...address
        }
        city {
          id
          name
          country {
            name
          }
        }
        product {
          id
          name
          permissions {
            options
          }
        }
        plant {
          id
          name
          address {
            ...address
          }
        }
        options {
          id
          name
        }
        quantity
        totalPrice
        currency
        status(detailed: true)
        trucks
        timeBetweenTrucks
        deliveryTime
        timezone
        units
        adminNotes
        additional
      }
    }
  }
  ${address}
`;
