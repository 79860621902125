import { FieldProps, getIn } from 'formik';
import React from 'react';
import { CustomInput, FormGroup, FormText } from 'reactstrap';

type InputProps = {
  label: string;
  formGroupClassNames?: string;
  formText?: string;
};

const FormInputSwitch: React.FC<FieldProps<any, any> & InputProps> = (props): JSX.Element => {
  const {
    field,
    label,
    form: { errors: formErrors, touched: formTouched },
    formText,
    formGroupClassNames,
    ...restProps
  } = props;

  const { name } = field;
  const error = getIn(formErrors, name);
  const touched = getIn(formTouched, name);

  const invalid: boolean = !!(error && touched);

  return (
    <FormGroup className={formGroupClassNames}>
      <CustomInput
        id={'form-input-switch-' + field.name}
        checked={field.value}
        invalid={invalid}
        {...field}
        {...restProps}
        type="switch"
        label={label}
      />
      {formText && (
        <FormText style={{ userSelect: 'none' }} color="muted">
          {formText}
        </FormText>
      )}
      {/* {invalid && <FormFeedback>{String(errors[name])}</FormFeedback>} */}
      {/* //TODO return when fixed validators */}
    </FormGroup>
  );
};

export default FormInputSwitch;
