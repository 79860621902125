import * as Types from '../../../../gql/types.generated';

import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeMemberQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeMemberQuery = { __typename?: 'Query', me: { __typename?: 'MeInfo', partnerMember?: { __typename?: 'PartnerMember', id: string, name: string, role: string, avatar?: string | null, identities: Array<{ __typename?: 'Identity', provider: string, uid: string }>, identity?: { __typename?: 'Identity', provider: string, uid: string } | null, partner: { __typename?: 'Partner', name?: string | null } } | null, supplierMember?: { __typename?: 'SupplierMember', id: string, name: string, role: string, avatar?: string | null, identities: Array<{ __typename?: 'Identity', provider: string, uid: string }>, identity?: { __typename?: 'Identity', provider: string, uid: string } | null, supplier: { __typename?: 'Supplier', name: string } } | null } };


export const MeMemberDocument = gql`
    query meMember {
  me {
    partnerMember {
      id
      name
      role
      avatar
      identities {
        provider
        uid
      }
      identity {
        provider
        uid
      }
      partner {
        name
      }
    }
    supplierMember {
      id
      name
      role
      avatar
      identities {
        provider
        uid
      }
      identity {
        provider
        uid
      }
      supplier {
        name
      }
    }
  }
}
    `;

/**
 * __useMeMemberQuery__
 *
 * To run a query within a React component, call `useMeMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeMemberQuery(baseOptions?: Apollo.QueryHookOptions<MeMemberQuery, MeMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeMemberQuery, MeMemberQueryVariables>(MeMemberDocument, options);
      }
export function useMeMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeMemberQuery, MeMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeMemberQuery, MeMemberQueryVariables>(MeMemberDocument, options);
        }
export type MeMemberQueryHookResult = ReturnType<typeof useMeMemberQuery>;
export type MeMemberLazyQueryHookResult = ReturnType<typeof useMeMemberLazyQuery>;
export type MeMemberQueryResult = Apollo.QueryResult<MeMemberQuery, MeMemberQueryVariables>;


export function useMeMemberRequest() {
    const client = useApolloClient();
    return useCallback<(variables: MeMemberQueryVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<MeMemberQuery>>>( (variables, options) => {
        return client.query<MeMemberQuery>({
            fetchPolicy: 'no-cache',
          ...options,
          query: MeMemberDocument,
          variables
        })
    }, []);
}

