import flags from './flags';
import * as brokreteIcons from './brokrete';

const IMAGES = {
  FLAGS: flags,
  ICONS: {
    ...brokreteIcons
  }
};

export default IMAGES;
