import gql from 'graphql-tag';

export const productDecorates = gql`
  query productTypes($contractor: IdInput, $input: OrderPreparatoryConfigInput, $productTypePrice: IdInput, $currency: String) {
    orderPreparatoryConfig(contractor: $contractor, input: $input) {
      productDecorates(currency: $currency, productTypePrice: $productTypePrice) {
        price {
          id
          currency
          value
          content
        }
        productDecorate {
          id
          name
          image
          color
        }
      }
    }
  }
`;
