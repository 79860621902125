import { FC } from 'react';
import { SVGProps } from './types';
export const SVGPencilGreen: FC<SVGProps> = props => {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g id="UI-Design" stroke="none" strokeWidth="1" fill="" fillRule="evenodd">
        <g id="3.0.6.-Active-Order-Details" transform="translate(-126.000000, -333.000000)" fill="#BABBC0" fillRule="nonzero">
          <g id="Group-14" transform="translate(20.000000, 332.000000)">
            <path
              fill="#50C468"
              d="M106.043613,12.2953811 L106.500407,10.9983732 L107.249797,11.7502034 L106.854048,12.1459522 C106.854048,12.1459522 106.854048,12.1459522 106.854048,12.1459522 L107.249797,11.7502034 L108.001627,12.4995933 L106.704619,12.9563869 C106.642222,12.9844051 106.573094,13 106.500407,13 C106.224572,13 106,12.7754278 106,12.4995933 C106,12.4269057 106.015595,12.3577778 106.043613,12.2953811 Z M116.49878,5.00081341 L113.999187,2.50122011 L115.207486,1.29292109 C115.598047,0.902359637 116.230269,0.902359637 116.62083,1.29292109 L117.707079,2.37916997 C118.09764,2.76973142 118.09764,3.40195293 117.707079,3.79251438 L116.49878,5.00081341 Z M113.000813,3.4995933 L115.500407,5.99918659 L109.62978,11.8698133 C109.62978,11.8698133 109.641985,11.37917 109.480879,11.2205045 C109.322213,11.0618391 108.211554,11.2082996 108.001627,10.9983732 C107.7917,10.7884461 107.930838,9.67046424 107.759967,9.4995933 C107.589096,9.32872235 107.130187,9.37021997 107.130187,9.37021997 L113.000813,3.4995933 Z"
              id="ic_pancil"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
