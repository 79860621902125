import gql from 'graphql-tag';
import { contractor, driver, partner, superAdmin, supplier } from '@/core/gql/fragments';

export const signIn = gql`
  mutation signIn($email: String!, $password: String!, $type: IdentityTypeEnum!) {
    authSignIn(email: { email: $email, password: $password }, type: $type) {
      success
      driver {
        ...driver
      }
      superAdmin {
        ...superAdmin
      }
      contractor {
        ...contractor
      }
      partner {
        ...partner
      }
      supplier {
        ...supplier
      }
      type
      token
      refreshToken
    }
  }
  ${contractor}
  ${driver}
  ${partner}
  ${supplier}
  ${superAdmin}
`;
