import gql from 'graphql-tag';
import { productType } from '@/core/gql/fragments';

export const productTypes = gql`
  query productTypes($limit: Int, $skip: Int, $product: IdInput, $supplier: IdInput, $root: Boolean) {
    productTypes(limit: $limit, skip: $skip, product: $product, supplier: $supplier, root: $root) {
      count
      values {
        ...productType
      }
    }
  }
  ${productType}
`;
