import { FC } from 'react';
import { TranslateProvider } from '@cranium/i18n';
import otaClient from '@crowdin/ota-client';
import locales from '@/assets/locales';
import get from 'lodash/get';
import { mergeDeep } from '@/view/utils';
import type { Language } from '@crowdin/ota-client/out/internal/util/exportPattern';
export const client = new otaClient(process.env.CROWDIN_DISTRIBUTON_HASH || '02cc3163eccdc0b77832b299kvf');

function getTranslation(lang?: string) {
  const staticTranslation = lang ? get(locales, lang, get(locales, 'en')) : locales;
  //@ts-ignore
  if (window.Cypress) {
    return staticTranslation;
  }
  return client
    .listFiles()
    .then(data => {
      const ordered = ['translations_', 'trans_', 'customstrings_'].map((name, index) => {
        return data.find(item => item.includes(name));
      });
      const unknownFiles = data.filter(name => !ordered.includes(name));
      return Promise.all(
        [...unknownFiles, ...ordered].map(name => {
          return client.getStringsByLocale(name, lang).catch(() => staticTranslation);
        })
      );
    })
    .then(data => {
      //@ts-ignore
      if (window.Cypress) {
        return locales.en;
      }
      return data.reduce((res, item) => {
        return mergeDeep(res, item);
      }, staticTranslation);
    })
    .catch(err => {
      return staticTranslation;
    });
}

const defaultLanguage =
  window?.navigator?.language && ['en', 'uk', 'fr', 'es', 'ach'].includes(window.navigator.language.split('-')[0]?.toLowerCase())
    ? window.navigator.language
    : 'en';

export const TranslationsProvider: FC = ({ children }) => {
  return (
    <TranslateProvider
      storage={localStorage}
      langKey="lang"
      translationsKey="translations"
      getTranslation={getTranslation}
      defaultLanguage={defaultLanguage}
      monoLanguageJSON
      getLanguages={() => client.getLanguageObjects().then(langs => langs.map(item => (item as Language)?.id))}
      defaultTranslations={get(locales, window.navigator.language, get(locales, 'en'))}
    >
      {children}
    </TranslateProvider>
  );
};

//init in-app translations
if (window.localStorage.getItem('lang') === 'ach') {
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', '//cdn.crowdin.com/jipt/jipt.js');
  //@ts-ignore sd
  window._jipt = [];
  //@ts-ignore sd
  window._jipt.push(['project', 'brokrete-dashboard']);
  document.head.appendChild(script);
}
