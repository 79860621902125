import gql from 'graphql-tag';

export const productTypePrices = gql`
  query productTypePrices($productTypes: [IdInput!]) {
    productTypePrices(productTypes: $productTypes) {
      count
      values {
        id
        price {
          id
          value
        }
        productType {
          id
        }
      }
    }
  }
`;
