import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Image } from 'antd';
import { Modal, ModalBody } from 'reactstrap';
import { ControlsConfig, Item, useFileViewerContext } from '@/shared/components/dataDisplay/FileVIewer/FileViewerContext';

const IframeModalContainer = styled.div`
  height: calc(100vh - 3.5rem - 35px);

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const onDownload = async (item: Item) => {
  const res = await fetch(item.source);
  const blob = (await res.blob()) as File;
  const downloadLink = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadLink;
  link.setAttribute('download', `${item.name}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode && link.parentNode.removeChild(link);
};

export const FilePreview: React.FC<{ controlsConfig?: ControlsConfig }> = ({ controlsConfig }) => {
  const [state, dispatch] = useFileViewerContext();
  const [preview, setPreview] = useState<{ image: boolean; visible: boolean; currentIndex: number; currentItem: Item | null }>({
    image: true,
    visible: false,
    currentIndex: 0,
    currentItem: null
  });

  const imageItems = useMemo(() => state.items.filter(item => item.type === 'image'), [state.items]);

  useEffect(() => {
    const onOpen = (item: Item) => {
      if (item.type !== 'image') {
        setPreview(value => ({ ...value, visible: true, image: false, currentItem: item }));
      } else {
        const currentIndex = imageItems.findIndex(value => value.id === item.id);
        setPreview(value => ({ ...value, visible: true, image: true, currentIndex }));
      }
    };
    dispatch({ type: 'setPartialState', state: { controlsConfig: { onDownload, onOpen, ...controlsConfig } } });
  }, [imageItems]);

  return (
    <>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            current: preview.currentIndex,
            visible: preview.visible && preview.image,
            onVisibleChange: visible => setPreview(v => ({ ...v, visible }))
          }}
        >
          {imageItems.map(item => (
            <Image key={item.source} src={item.source} />
          ))}
        </Image.PreviewGroup>
      </div>
      <Modal
        isOpen={preview.visible && !preview.image}
        toggle={() => {
          setPreview(v => ({ ...v, visible: false }));
        }}
        size="lg"
      >
        <ModalBody>
          <IframeModalContainer>
            <iframe width="100%" height="100%" src={preview.currentItem?.source} />
          </IframeModalContainer>
        </ModalBody>
      </Modal>
    </>
  );
};
