import React from 'react';
import styled from 'styled-components';
import { CloseButton } from '@components/elements/buttons';
import { ProductTypeModifications, ProductTypeOptionValue } from '@/interfaces/brokrete';
import { colors } from '@/defaultStyles';

type Props = {
  className?: string;
  editable?: boolean;
  Icon?: any;
  getModificationTagStyles?: (modification: ProductTypeModifications) => void;
  onClick?: (item: ProductTypeModifications) => void;
  item: { deleted?: boolean; selected?: boolean } & ProductTypeModifications;
  onRemove?: () => void;
};

const Root = styled.div<{ leftPadding: number; styles: any }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin: 0 10px 2px 0;
  padding: 7px 12px 7px ${({ leftPadding }) => `${leftPadding}px`};
  border-radius: 16px;
  border: solid 1px #dcdcdf;
  font-size: 14px;

  cursor: pointer;

  ${({ styles }) => styles}
`;

const Label = styled.div`
  margin-top: 2px;
`;

const ProductModificationTag: React.FunctionComponent<Props> = (props: Props) => {
  const { editable = true, className, item, onRemove, Icon, onClick, getModificationTagStyles } = props;

  if (item.deleted) {
    return null;
  }

  return (
    <Root
      styles={getModificationTagStyles ? getModificationTagStyles(item) : ''}
      className={className}
      leftPadding={Icon != null ? 5 : 17}
      onClick={() => onClick && onClick(item)}
    >
      {Icon}
      <Label>{item.name}</Label>
      {editable && <CloseButton onClick={onRemove} />}
    </Root>
  );
};

export default ProductModificationTag;
