import React, { Component } from 'react';
import Button, { Type } from '@/shared/components/buttons/Button';
import { i18n } from '@/core';
import BaseModal from '@components/modals/BaseModal';
import styled from 'styled-components';

type Props = {};

type State = {
  title: string;
  isOpen: boolean;
  content: string | React.ReactNode;
  buttonConfirmTitle?: string;
  canCancel?: boolean;
};

const defaultState = {
  isOpen: false,
  buttonConfirmTitle: 'Confirm',
  canCancel: undefined
};

const Container = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 20px;
`;

const Text = styled.div``;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  & > *:first-child {
    margin-right: 10px;
  }
`;

class InlineConfirmationModal extends Component<Props, State> {
  private actionResolver!: (value: { confirmed: boolean }) => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      title: '',
      buttonConfirmTitle: 'Confirm',
      content: <></>
    };
  }

  open = async (params: Omit<State, 'isOpen'>) => {
    return new Promise<{ confirmed: boolean }>(resolve => {
      this.setState({ ...params, isOpen: true }, () => {
        this.actionResolver = resolve;
      });
    });
  };

  onConfirm = async () => {
    this.setState({ ...defaultState }, () => {
      this.actionResolver && this.actionResolver({ confirmed: true });
    });
  };

  onCancel = async () => {
    this.setState({ ...defaultState }, () => {
      this.actionResolver && this.actionResolver({ confirmed: false });
    });
  };

  render() {
    const { isOpen, title, content, canCancel, buttonConfirmTitle } = this.state;

    return (
      <BaseModal isOpen={isOpen} title={title}>
        <Container>{typeof content === 'string' ? <Text>{content}</Text> : content}</Container>
        <ButtonGroup>
          {canCancel && (
            <Button type={Type.SECONDARY} onClick={this.onCancel}>
              {i18n.t('labels.cancel')}
            </Button>
          )}
          <Button type={Type.PRIMARY} onClick={this.onConfirm}>
            {buttonConfirmTitle}
          </Button>
        </ButtonGroup>
      </BaseModal>
    );
  }
}

export default InlineConfirmationModal;
