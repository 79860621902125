import React from 'react';
import styled from 'styled-components';

import { OptionItemBase } from '@/shared/components/dataEntry/Select';
import { colors } from '@/defaultStyles';

const Icon = styled.img`
  margin-right: 8px;
`;

const Label = styled.div`
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.CHARCOAL_GREY};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const OptionItem: React.FC<any> = props => {
  const { innerRef, data, selectOption, isFocused, isDisabled } = props;

  return (
    <OptionItemBase ref={innerRef} isFocused={isFocused} isDisabled={isDisabled} onClick={() => selectOption(data)}>
      <Container>
        <Icon src={data.icon} />
        <Label>{data.label}</Label>
      </Container>
    </OptionItemBase>
  );
};

export default OptionItem;
