import { isArray, mergeWith } from 'lodash';
import { PartialDeep } from '@/interfaces/brokrete';
import { Maybe } from '@/gql/types.generated';

export * from './financeUtils';

/**
 * Deep merge source with source2 and return a new object, arrays do not merge and will be applied from source2
 * @param source
 * @param source2
 */
export function mergeDeep<TSource extends object>(source: PartialDeep<TSource>, source2: PartialDeep<TSource>): TSource {
  return mergeWith({}, source, source2, (destination, source) => {
    if (isArray(source)) return source;
  }) as TSource;
}

/**
 * Return IdInput object {[name]:{id}} or empty object if !id
 * @param name
 * @param id
 */
export function makeIdInput<K extends string, T extends string>(name: K, id?: T | null): { [P in K]: { id: T } };
export function makeIdInput<K extends string>(name: K): {};
export function makeIdInput(name: string, id?: Maybe<string> | null) {
  return id ? { [name]: { id } } : {};
}

/**
 * This type recursively transform all fields of current type T to NotNullable.
 * It supports arrays and objects.
 */
export type DeepRequired<T> = {
  [K in keyof T]-?: T[K] extends Array<infer U> ? Array<DeepRequired<NonNullable<U>>> : DeepRequired<NonNullable<T[K]>>;
};
