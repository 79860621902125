import React, { useCallback, useMemo } from 'react';
import { FormText } from 'reactstrap';
import styled from 'styled-components';
import cx from 'classnames';
import { getIn } from 'formik';
import PhoneInput from 'react-phone-input-2';
import { FormLabel } from '@components/forms/controls/FormLabel';
import { get } from 'lodash';
import FormGroup from '@/shared/components/formik/fields/FormGroup';

const FormGroupStyled = styled(FormGroup)`
  input[disabled] {
    background-color: #f5f5f5 !important;
  }
`;

const FormInputPhone: React.FC<InputProps> = (props): JSX.Element => {
  const {
    field,
    label,
    form: { errors: formErrors, /*touched: formTouched,*/ setFieldValue, values },
    formText,
    formGroupClassNames,
    linkedFieldName,
    onChange,
    disabled,
    ...restProps
  } = props;

  const { name } = field;

  const { id } = restProps;

  const error = getIn(formErrors, name);
  // const touched = getIn(formTouched, name);

  const invalid: boolean = !!error;
  /*   console.info({ values }, { invalid }, { error }, { touched }); */

  const displayLabel = label && !restProps.hidden;

  const nameValue = get(values, name);

  const initialCountry = useMemo(() => {
    if (linkedFieldName) {
      const linkedFieldValue = values[linkedFieldName];
      if (typeof linkedFieldValue === 'object') {
        return linkedFieldValue['code'] ? String(linkedFieldValue.code).toLocaleLowerCase() : DEFAULT_COUNTRY;
      } else if (typeof linkedFieldValue === 'string') {
        const fieldValueInLowerCase = String(linkedFieldValue).toLocaleLowerCase();
        return SUPPORTED_COUNTRIES.includes(fieldValueInLowerCase) ? fieldValueInLowerCase : DEFAULT_COUNTRY;
      }
      return DEFAULT_COUNTRY;
    } else {
      return DEFAULT_COUNTRY;
    }
  }, [linkedFieldName, values]);

  const onChangeHandler = useCallback(
    (phone: string) => {
      try {
        onChange && onChange(phone);
        setFieldValue(name, phone);
      } catch (e) {
        // setFieldError(name, 'Wrong phone number');
      }
    },
    [name, setFieldValue, onChange]
  );

  return (
    <FormGroupStyled className={formGroupClassNames}>
      {displayLabel && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <PhoneInput
        disabled={disabled}
        onlyCountries={SUPPORTED_COUNTRIES}
        country={initialCountry}
        onChange={onChangeHandler}
        value={nameValue ? nameValue : undefined}
        inputClass={cx('w-100', { 'border-danger': invalid })}
      />
      {formText && <FormText color="muted">{formText}</FormText>}
    </FormGroupStyled>
  );
};

export default FormInputPhone;

/* constants */
const DEFAULT_COUNTRY = 'ca';
const SUPPORTED_COUNTRIES = ['us', 'ca', 'au'];

/* types */
type InputProps = {
  [key: string]: any;
  id?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  formGroupClassNames?: string;
  formText?: string;
  linkedFieldName?: string;
};
