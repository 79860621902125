import { FC, useCallback, useState, useEffect, useMemo } from 'react';
import { usePGettext, useTranslations } from '@cranium/i18n';
import { Select, Typography } from 'antd';
import Flags from '@/assets/images/flags';
import styled from 'styled-components';
import get from 'lodash/get';
import { client } from '@/shared/i18n/TranslationsProvider';
import { useHasPermissions, usePartnerData } from '@/shared/access';

const Flag = styled.img`
  width: 28px;
  border-radius: 2px;
`;

const FlagSmall = styled.img`
  width: 22px;
  margin-right: 8px;
  border-radius: 2px;
`;

const InApp = styled.span`
  width: 22px;
  margin-right: 8px;
  height: 22.5px;
  text-align: center;
  display: inline-block;
  &:before {
    display: inline;
    line-height: 22.5px;
  }
`;

const DropDown = styled(Select)`
  .ant-select-selector {
    padding: 0 !important;
    height: auto !important;
    line-height: 0 !important;
    &:after {
      display: none !important;
    }
    .ant-select-selection-search {
      display: none !important;
    }
    .ant-select-selection-item {
      line-height: 0 !important;
    }
  }
  img,
  .icon-edit {
    margin-right: 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-left: 1px solid var(--gray-1);
  border-right: 1px solid var(--gray-1);
`;

const envLangs = new Set((process.env.REACT_APP_LANGUAGES || 'en').split(','));

export const ChangeLanguage: FC = () => {
  const { language, setLanguage } = useTranslations();
  const [langs, setLang] = useState<Set<string>>(envLangs);
  useEffect(() => {
    client.getLanguageObjects().then(data => {
      const set = new Set(data.map(lang => get(lang, 'id', '').split('-')[0].toLowerCase()).filter(lang => envLangs.has(lang)));
      if (!set.has(language)) {
        setLanguage('en');
      }
      setLang(set);
    });
  }, []);
  const canShowPartner = useHasPermissions('permissions.finances.partner.show');
  const partnerData = usePartnerData();
  const { pgettext } = usePGettext();

  const handleChange = useCallback(
    value => {
      setLanguage(value);
      if (value === 'ach' || language === 'ach') {
        window.location.reload();
      }
    },
    [setLanguage, language]
  );
  const canShowSelect = localStorage.getItem('main-token') || canShowPartner;

  const enFlag = (country: string | undefined) => {
    if (country === 'United States') return Flags.US;
    if (country === 'Australia') return Flags.AU;
    return Flags.CA;
  };

  const options = useMemo(() => {
    const list = [
      {
        value: 'en',
        flag: {
          src: enFlag(partnerData?.address?.country),
          alt: pgettext('english', 'lang_alt_en')
        },
        text: pgettext('English', 'lang_label_en')
      },
      canShowSelect && {
        value: 'uk',
        flag: {
          src: Flags.UA,
          alt: pgettext('ukrainian', 'lang_alt_ua')
        },
        text: pgettext('Ukrainian', 'lang_label_ua')
      },
      {
        value: 'fr',
        flag: {
          src: Flags.FR,
          alt: pgettext('french', 'lang_alt_fr')
        },
        text: pgettext('French', 'lang_label_fr')
      },
      {
        value: 'es',
        flag: {
          src: Flags.ES,
          alt: pgettext('spanish', 'lang_alt_es')
        },
        text: pgettext('Spanish', 'lang_label_es')
      },
      canShowSelect && {
        value: 'ach',
        icon: <InApp className="icon-edit" />,
        text: pgettext('In app translate', 'lang_in_app')
      }
    ];
    return list
      .filter(option => option && langs.has(option.value))
      .map(option =>
        typeof option === 'object' ? (
          <Select.Option key={option.value} value={option.value} label={option.flag ? <Flag {...option.flag} /> : option.icon}>
            {option.flag ? <FlagSmall {...option.flag} /> : option.icon}
            <Typography.Text className="regular-14 gray-9">{option.text}</Typography.Text>
          </Select.Option>
        ) : null
      );
  }, [canShowSelect, langs, partnerData]);

  return (
    <Container>
      <DropDown
        value={language}
        defaultValue={language}
        onChange={handleChange}
        optionLabelProp="label"
        showArrow={false}
        dropdownMatchSelectWidth={false}
      >
        {options}
      </DropDown>
    </Container>
  );
};
