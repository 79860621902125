import React, { useCallback } from 'react';
import { FormProps as ReactstrapFormProps } from 'reactstrap';
import { Formik, FormikErrors, FormikHelpers, FormikProps, FormikValues, FormikConfig } from 'formik';

type FormProps<FormikValues> = {
  className?: string;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>,
    params: { [key: string]: any }
  ) => void | Promise<any>;
  prepareSubmitValues?: (values: any) => any;
};

type InitialFormProps<Values> = {
  initialValues: Values;
  initialErrors?: FormikErrors<Values>;
};

export type NumberFieldValue = number | string;

const FormikForm = <T, P>(
  Component: React.ComponentType<ReactstrapFormProps & FormikProps<P>>,
  initialProps: InitialFormProps<P>
): React.FC<FormProps<P>> => {
  // @ts-ignore
  const { initialValues, initialErrors } = initialProps;
  return (props: React.PropsWithChildren<FormProps<P>>) => {
    const { onSubmit, className } = props;
    // eslint-disable-next-line
    const onSubmitWrapper = useCallback(
      (values, formikActions) => {
        if (onSubmit) {
          const submitResult = onSubmit(values, formikActions, { initialValues });
          if (submitResult instanceof Promise) {
            return submitResult.catch(error => {
              console.error(error);
            });
          }
        }

        return undefined;
      },
      [onSubmit]
    );

    return (
      <Formik initialValues={initialValues} initialErrors={initialErrors} onSubmit={onSubmitWrapper}>
        {renderProps => {
          // @ts-ignore
          return <Component className={className} {...renderProps} {...props} />;
        }}
      </Formik>
    );
  };
};

FormikForm.defaultProps = {
  enableReinitialize: false,
  initialValues: {},
  onSubmit: () => {}
};

export default FormikForm;

/* Make Formik HOC */
type Props<Values extends FormikValues = FormikValues> = FormikConfig<Values>;

const makeFormik =
  <P extends object, T = {}>(Component: React.FC<FormikProps<P> & T>) =>
  ({
    onSubmit,
    // @ts-ignore
    prepareSubmitValues,
    ...props
  }: Props<P> & T) => {
    const onSubmitWrapper = useCallback(
      (values, formikActions) => {
        if (onSubmit) {
          const preparedValues = prepareSubmitValues ? prepareSubmitValues(values, props.initialValues) : values;

          const submitResult = onSubmit(preparedValues, formikActions);

          if (submitResult instanceof Promise) {
            return submitResult.catch(error => {
              console.error(error);
            });
          }
        }

        return undefined;
      },
      [onSubmit, prepareSubmitValues, props.initialValues]
    );

    return (
      <>
        {/*//@ts-ignore*/}
        <Formik {...props} onSubmit={onSubmitWrapper}>
          {(renderProps: FormikProps<P> & T) => <Component {...props} {...renderProps} />}
        </Formik>
      </>
    );
  };

export { makeFormik };
