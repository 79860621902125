import { i18n } from '@/core';
import { IdInput } from '@/interfaces/brokrete';
import { same } from '@/libs/array';
import { required } from '@/libs/validators';
import { checkIfFeeIsEmpty } from '@components/cards/GeneralFeeSettings/helpers';
import { FieldArrayControlButtonsOld, FormInput, FormInputGroup, FormInputSelect } from '@components/forms/controls';
import { FieldArrayControlProps } from '@components/forms/controls/FieldArrayControlButtonsOld';
import { makeFormik } from '@components/forms/FormikForm';
import { Field, FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { useFormContentOptions } from './options';
import { useGettext } from '@cranium/i18n';

function validateGroupIsEmptyOtherwiseRequired(allIsEmpty: boolean): (v: any) => undefined | string {
  return (value: any): undefined | string => {
    return allIsEmpty ? undefined : required(value);
  };
}

const DeliveryForm: React.FC<FormProps> = ({ handleSubmit, initialValues, values, isSubmitting, isValid, dirty }) => {
  const canSubmitEmpty = useMemo(() => {
    const isEmpty = checkIfFeeIsEmpty(values.values);
    const theSame = same(initialValues.values, values.values);

    return isEmpty && !theSame;
  }, [initialValues.values, values.values]);

  return (
    <Form onSubmit={handleSubmit}>
      <Field className="m-0" hidden name="category.id" id="category.id" component={FormInput} validate={required} />
      <FieldArray
        validateOnChange
        name="values"
        render={({ form, remove, push }: FieldArrayRenderProps) => {
          const { values } = form.values;

          return values.map((value: PlantFeesDeliveryItemInput, index: number) => {
            return (
              <DeliveryRow
                key={`values[${index}]`}
                data={values}
                index={index}
                push={push}
                remove={remove}
                initialValue={deliveryFormEmptyValue}
                namePrefix={`values[${index}]`}
                canSubmitEmpty={canSubmitEmpty}
              />
            );
          });
        }}
      />
      <div className="float-left">
        <Button
          type="submit"
          size="lg"
          disabled={!canSubmitEmpty && (isSubmitting || !isValid || !dirty)}
          className="btn-success mt-3"
        >
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<DeliveryFormInitialProps>(DeliveryForm);

/* row component */
const DeliveryRow: React.FC<DeliveryRowProps & FieldArrayControlProps> = (props): JSX.Element => {
  const formContentOptions = useFormContentOptions();
  const { gettext } = useGettext();
  const { namePrefix, canSubmitEmpty, ...restProps } = props;

  const validationFunc = useMemo(() => {
    return validateGroupIsEmptyOtherwiseRequired(canSubmitEmpty);
  }, [canSubmitEmpty]);

  return (
    <Row form>
      <Col md={4} sm={4} lg={4} style={{ minWidth: '72px' }}>
        <Field
          name={`${namePrefix}.content`}
          id={`${namePrefix}.content`}
          placeholder={gettext('Type')}
          formText={i18n.t('cards.settings.fixFee')}
          component={FormInputSelect}
          options={formContentOptions}
          autoComplete={`minDeliveryRow.content`}
          validate={validationFunc}
        />
      </Col>
      <Col>
        <Field
          name={`${namePrefix}.value`}
          id={`${namePrefix}.value`}
          component={FormInputGroup}
          addonType="append"
          type="number"
          formText={i18n.t('cards.settings.enterAmountFormText')}
          inputGroupText={i18n.t('common.priceSymbol')}
          formGroupClassNames="m-0"
          validate={validationFunc}
          placeholder={'00.0'}
          inputGroupClassName="p-0 pr-2 pl-2"
        />
      </Col>
      <Col>
        <Field
          name={`${namePrefix}.from`}
          id={`${namePrefix}.from`}
          placeholder={gettext('From')}
          formText={i18n.t('cards.settings.firstQuantity')}
          component={FormInput}
          autoComplete={`minDeliveryRow.betweenStart`}
          validate={validationFunc}
          type="number"
        />
      </Col>
      <Col>
        <Field
          name={`${namePrefix}.to`}
          id={`${namePrefix}.to`}
          placeholder={gettext('To')}
          formText={i18n.t('cards.settings.secondQuantity')}
          component={FormInput}
          autoComplete={`minDeliveryRow.betweenEnd`}
          validate={validationFunc}
          type="number"
        />
      </Col>
      <Col md={1} sm={1} lg={1} className="pt-1" style={{ minWidth: '72px' }}>
        <FieldArrayControlButtonsOld {...restProps} />
      </Col>
    </Row>
  );
};

/*  props  */

export const deliveryFormEmptyValue: PlantFeesDeliveryItemInput = {
  content: '',
  from: '',
  to: '',
  value: ''
};

export const deliveryFormInitialValues: DeliveryFormInitialProps = {
  category: { id: '' },
  values: [{ ...deliveryFormEmptyValue }]
};

/* types */

type InputOptionType = {
  label: string;
  value: string | number;
};

export type PlantFeesDeliveryItemInput = {
  content: InputOptionType | string;
  from: number | string;
  to: number | string;
  value: number | string;
};

export type DeliveryFormInitialProps = {
  category: IdInput;
  values: PlantFeesDeliveryItemInput[];
};

type FormProps = FormikProps<DeliveryFormInitialProps>;

type DeliveryRowProps = {
  namePrefix: string;
  canSubmitEmpty: boolean;
};
