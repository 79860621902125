import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

const HalfLoadForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  const { gettext } = useGettext();
  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col>
          <Field
            name="maxQuantity"
            id="maxQuantity"
            placeholder={gettext('Maximum quantity per truck')}
            formText={i18n.t('cards.settings.enterAmountFormText')}
            component={FormInput}
            autoComplete={`halfLoadModality.maxQuantity`}
            validate={required}
            type="number"
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="extraFee"
            id="extraFee"
            placeholder={gettext('Extra half-load fee')}
            formText={i18n.t('cards.settings.enterAmountFormText')}
            component={FormInput}
            autoComplete={`halfLoadModality.extraFee`}
            validate={required}
            numeric
            type="number"
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="startDate"
            id="startDate"
            placeholder={gettext('Start Date')}
            formText={i18n.t('cards.settings.from')}
            component={FormInput}
            autoComplete={`halfLoadModality.startDate`}
            validate={required}
            type="date"
          />
        </Col>
        <Col>
          <Field
            name="endDate"
            id="endDate"
            placeholder={gettext('End Date')}
            formText={i18n.t('cards.settings.to')}
            component={FormInput}
            autoComplete={`halfLoadModality.endDate`}
            validate={required}
            type="date"
          />
        </Col>
      </Row>

      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<HalfLoadFormInitialProps>(HalfLoadForm);

export const halfLoadFormInitialValues: HalfLoadFormInitialProps = {
  category: { id: '' },
  values: {
    content: '',
    from: '',
    to: '',
    value: ''
  }
};

export type HalfLoadFormInitialProps = {
  category: { id: string };
  values: {
    content: string;
    from: number | string;
    to: number | string;
    value: number | string;
  };
};

type Props = FormikProps<HalfLoadFormInitialProps>;
