import { ConfigurationType, IConfiguration } from '@/typings';

class Configuration implements IConfiguration {
  private readonly _apiURL: string;
  private readonly _googleAPIKey: string;
  private readonly _toastErrors: boolean = false;

  constructor() {
    // Brokrete Server API URL
    this._apiURL = process.env.REACT_APP_API as string;
    // Google API Key to access Places API and other staff related to geo data
    this._googleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY as string;

    this._toastErrors =
      ['1', 'true'].includes(process.env.REACT_APP_TOAST_ERRORS as string) || process.env.NODE_ENV === 'development';
  }

  get apiUrl() {
    return this._apiURL;
  }

  get googleAPIKey() {
    return this._googleAPIKey;
  }

  get toastErrors() {
    return this._toastErrors;
  }
}

export default new Configuration();
