import { address, city, plant, supplier } from '@/core/gql/fragments';
import { Plant } from '@/interfaces/brokrete';
import gql from 'graphql-tag';
import { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';

export const usePlant = (id?: string, light: boolean = false): [Plant | undefined, boolean, () => Promise<void>] => {
  const [plant, setPlant] = useState<Plant | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const client = useApolloClient();

  const fetchPlant = useCallback(async () => {
    if (id) {
      setLoading(true);
      const { data } = await client.query({
        query: light ? PLANT_QUERY_LIGHT : PLANT_QUERY,
        variables: {
          id
        },
        fetchPolicy: 'no-cache'
      });

      setLoading(false);

      if (data) {
        setPlant({ ...data.plant });
      }
    }
  }, [id, light, client, setPlant, setLoading]);

  useEffect(() => {
    fetchPlant();
  }, [fetchPlant]);

  return [plant, loading, fetchPlant];
};

export const PLANT_QUERY = gql`
  query plant($id: ID!) {
    plant(id: $id) {
      ...plant
    }
  }
  ${plant}
`;

export const PLANT_QUERY_LIGHT = gql`
  query plant($id: ID!) {
    plant(id: $id) {
      id
      name
      timezone
      color
      deliveryRadius
      deliveryRadiusUnit
      productCategories {
        id
        image
        name
        industry
        industryColor
      }
      supplier {
        ...supplier
      }
      address {
        ...address
      }
      city {
        ...city
      }
      deliveryRadius
    }
  }
  ${address}
  ${supplier}
  ${city}
`;
