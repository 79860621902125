import * as queries from './queries';
import * as mutations from './mutations';

export const orders = {
  mutations: {
    ...mutations
  },
  queries: {
    ...queries
  }
};
