import React, { Component } from 'react';
import { ApplicationContext, ApplicationContextType, Breadcrumb, SidebarProps } from '@components/context/ApplicationContext';
import { mergeDeep } from '@/view/utils';

type Props = {};

type PageProps = {
  icon?: any;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  titleSecondary?: React.ReactNode;
  info?: React.ReactNode;
  locked?: boolean;
  selectParentId?: string;
};

class Application extends Component<Props, ApplicationContextType> {
  constructor(props: Props) {
    super(props);

    this.state = {
      page: {
        icon: null,
        locked: false,
        title: '',
        subtitle: '',
        setPageProps: this.setPageProps,
        setLocked: this.setLocked
      },
      ui: {
        topPanel: {
          isTopPanelVisible: true,
          showUserManagement: this.showUserManagement,
          hideUserManagement: this.hideUserManagement,
          userManagementShown: true,
          setVisibility: this.setVisibility,
          breadcrumb: { type: '', text: '' },
          setBreadcrumb: this.setBreadcrumb,
          clearBreadcrumb: this.clearBreadcrumb
        },
        sidebar: {
          isOpen: true,
          setOpened: this.sidebarToggle,
          props: {},
          setProps: this.setSidebarProps,
          minimized: false,
          setMinimized: this.setSidebarMinimized
        },
        rootContainer: null,
        setRootContainer: this.setRootContainer
      }
    };
  }

  setVisibility = (visible: boolean) => {
    this.setState(state => ({
      ...state,
      ui: {
        ...state.ui,
        topPanel: {
          ...state.ui.topPanel,
          isTopPanelVisible: visible
        }
      }
    }));
  };

  toggleUserManagement = (visible: boolean) => {
    this.setState(state => ({
      ...state,
      ui: {
        ...state.ui,
        topPanel: {
          ...state.ui.topPanel,
          userManagementShown: visible
        }
      }
    }));
  };

  showUserManagement = () => {
    this.toggleUserManagement(true);
  };

  hideUserManagement = () => {
    this.toggleUserManagement(false);
  };

  sidebarToggle = (opened: boolean): void => {
    this.setState(state => ({
      ...state,
      ui: {
        ...state.ui,
        sidebar: {
          ...this.state.ui.sidebar,
          isOpen: opened
        }
      }
    }));
  };

  setPageProps = (props: PageProps) => {
    this.setState(({ page }) => {
      return {
        page: {
          ...page,
          ...props
        }
      };
    });
  };

  setSidebarProps = (props: Partial<SidebarProps>) => {
    this.setState(state => {
      return {
        ...state,
        ui: {
          ...state.ui,
          sidebar: {
            ...state.ui.sidebar,
            props: { ...state.ui.sidebar.props, ...props }
          }
        }
      };
    });
  };

  setLocked = (locked: boolean) => {
    this.setState(({ page }) => {
      return {
        page: {
          ...page,
          locked: locked
        }
      };
    });
  };

  setBreadcrumb = (breadcrumb: Breadcrumb) => {
    this.setState(state => mergeDeep(state, { ui: { topPanel: { breadcrumb } } }));
  };

  clearBreadcrumb = () => {
    this.setState(state => mergeDeep(state, { ui: { topPanel: { breadcrumb: { type: '', text: '' } } } }));
  };

  setRootContainer = (element: HTMLDivElement | null) => {
    this.setState(state => mergeDeep(state, { ui: { rootContainer: element } }));
  };

  setSidebarMinimized = (minimized: boolean) => {
    this.setState(state => mergeDeep(state, { ui: { sidebar: { minimized } } }));
  };

  render(): React.ReactNode {
    return <ApplicationContext.Provider value={this.state}>{this.props.children}</ApplicationContext.Provider>;
  }
}

export default Application;
