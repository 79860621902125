import { FC, useContext, useMemo } from 'react';
import { Redirect, Route, RouteProps, useParams } from 'react-router-dom';
import { PermissionsContext } from '@/shared/access/Access';

export const PartnerRoute: FC<RouteProps> = props => {
  const Component = props.component;
  const component = useMemo(() => {
    return () => <PartnerIdRoute Component={Component} path={props.location?.pathname} />;
  }, [Component]);

  return <Route {...props} component={component} />;
};

export const PartnerIdRoute = ({ Component, path }: any) => {
  const param = useParams<{ partnerId?: string }>();
  const { partner } = useContext(PermissionsContext);
  if (param?.partnerId) {
    //@ts-ignore
    return <Component />;
  }
  if (partner?.id) {
    return <Redirect to={`/p-${partner?.id}${path}`} />;
  }
  return null;
};
