import { setUser, createReduxEnhancer, captureException, User, setContext, setTag } from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';

export function setSentryUser(user: User) {
  if (!process.env.REACT_APP_SENTRY_AUTH_TOKEN) {
    return;
  }
  setUser(user || null);
}

export function setSentryTag(key: string, value?: string | null) {
  if (!process.env.REACT_APP_SENTRY_AUTH_TOKEN || !value) {
    return;
  }
  setTag(key, value);
}

export function setSentryContext(key: string, value?: Record<string, unknown> | null) {
  if (!process.env.REACT_APP_SENTRY_AUTH_TOKEN) {
    return;
  }
  setContext(key, value || null);
}

export const sentryReduxEnhancer = !process.env.REACT_APP_SENTRY_AUTH_TOKEN ? undefined : createReduxEnhancer({});

export function sendError(error: any) {
  if (!error) {
    return;
  }
  if (process.env.REACT_APP_SENTRY_AUTH_TOKEN) {
    captureException({ error });
  } else {
    console.error(error);
  }
}

export const sentryLink = !process.env.REACT_APP_SENTRY_AUTH_TOKEN
  ? undefined
  : new SentryLink({
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeError: true
      }
    });
