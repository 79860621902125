import { Actions } from './actions';

import reducer, { SessionState } from './reducers';

export const SessionActions = {
  session: Actions
};

export const sessionReducer = {
  session: reducer
};

export interface SessionStoreState {
  session: SessionState;
}
