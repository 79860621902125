import { FC, useEffect, useState } from 'react';
import { initHubSpot } from './utils';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { Dropdown, Badge, Button } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { MeType } from './types';

const Container = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
`;

const GlobalStyle = createGlobalStyle`
    #hubspot-conversations-inline-parent,
    #hubspot-conversations-inline-parent > iframe {
        height: 100%;
        width: 100%;
        border: 0;
    }
`;

const HubSpotOverlay = styled.div`
  height: 500px;
  width: 300px;
  margin-top: 10px;
  border-radius: 2px;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

const HubSpotButton = styled(Button)`
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 2vh solid transparent;
    border-right: 2vh solid transparent;
    border-top: 1vh solid var(--green-6);
    position: absolute;
    top: 25px;
    right: -10px;
    transform: rotate(-140deg);
    position: absolute;
    border-top-left-radius: 8px;
    transition: border-top-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:hover::after {
    border-top-color: #41c459;
  }
  .icon-ic-help-desc {
    font-size: 21px;
  }
`;

const HubSpotIcon = styled.span`
  z-index: 3;
  position: relative;
`;

declare global {
  interface Window {
    _hsq?: unknown[];
    hsConversationsSettings?: {
      loadImmediately?: boolean;
      inlineEmbedSelector?: string;
      enableWidgetCookieBanner?: boolean;
      identificationEmail?: string;
      identificationToken?: string;
      [key: string]: any;
    };
    hsConversationsOnReady?: [() => void];
    HubSpotConversations: {
      on: (event: string, callback: (resp: { unreadCount: number }) => void) => void;
      off: (event: string, callback: (resp: { unreadCount: number }) => void) => void;
      widget: {
        load: () => void;
        open: () => void;
        close: () => void;
        remove: () => void;
        refresh: () => void;
      };
    };
  }
}

export const getMePartner = gql`
  query me {
    me {
      service {
        hubspotToken
      }
      partnerMember {
        email
      }
    }
  }
`;

export const HubSpot: FC = () => {
  const [isReady, setReady] = useState(false);
  const [count, setCount] = useState(0);
  const [fetch] = useLazyQuery(getMePartner, { fetchPolicy: 'no-cache' });
  useEffect(() => {
    function handleCountChange(payload: { unreadCount: number }) {
      setCount(payload.unreadCount);
    }
    fetch()
      .then(({ data }: { data?: MeType }) => initHubSpot(data))
      .catch(() => initHubSpot())
      .then(() => {
        setReady(true);
        window.HubSpotConversations.on('unreadConversationCountChanged', handleCountChange);
      });

    return () => {
      if (typeof window?.HubSpotConversations?.off === 'function') {
        window.HubSpotConversations?.off('unreadConversationCountChanged', handleCountChange);
      }
      if (typeof window?.HubSpotConversations?.widget?.remove === 'function') {
        window.HubSpotConversations?.widget?.remove();
      }
    };
  }, []);

  return (
    <Container>
      <Dropdown overlay={<HubSpotOverlay id="hubspot" />} trigger={['click']} disabled={!isReady} forceRender>
        <Badge count={count}>
          <HubSpotButton
            type="primary"
            shape="circle"
            icon={<HubSpotIcon className={'icon-ic-help-desc'} />}
            loading={!isReady}
          />
        </Badge>
      </Dropdown>
      <GlobalStyle />
    </Container>
  );
};
