import { FieldProps } from 'formik';
import React from 'react';
import { CustomInput, FormGroup, FormText } from 'reactstrap';
import cx from 'classnames';

type RadioEntity = {
  value: string;
  label: string;
};

type InputProps = {
  [key: string]: any;
  label: string;
  formGroupClassNames?: string;
  formText?: string;
  radios: RadioEntity[];
  orientation: 'horizontal' | 'vertical';
};

const FormInputRadios: React.FC<InputProps & FieldProps<any, any>> = (props): JSX.Element => {
  const {
    field,
    form: { values },
    formText,
    formGroupClassNames,
    radios,
    orientation,
    ...restProps
  } = props;

  const { name } = field;
  const alignItems = orientation || 'horizontal';

  return (
    <FormGroup className={formGroupClassNames}>
      {/* {label && <Label for={}>{label}</Label>} */}
      <div
        className={cx(
          'd-flex justify-content-start',
          {
            'align-items-center flex-row': alignItems === 'horizontal'
          },
          {
            'align-items-start flex-column': alignItems === 'vertical'
          }
        )}
      >
        {radios.map(({ value, label }) => (
          <div key={value} className={cx({ 'mr-3': alignItems === 'horizontal' }, { 'mb-3': alignItems === 'vertical' })}>
            <CustomInput
              {...field}
              {...restProps}
              type="radio"
              name={name}
              value={value}
              id={value}
              label={label}
              checked={values[name] === value}
            />
          </div>
        ))}
      </div>

      {formText && <FormText color="muted">{formText}</FormText>}
      {/* {invalid && <FormFeedback>{String(errors[name])}</FormFeedback>} */}
      {/* //TODO return when fixed validators */}
    </FormGroup>
  );
};

export default FormInputRadios;
