import { showModal } from './showModal';
import ActivityIndicator from '../elements/ActivityIndicator';
import React from 'react';

export const showLoaderModal = (allowOutsideClick: boolean = false) =>
  showModal({
    allowOutsideClick,
    showCloseButton: false,
    background: 'none',
    position: 'center',
    html: <ActivityIndicator />
  });
