import React from 'react';
import styled from 'styled-components';
import { Skeleton, Spin, Typography, Row, Col } from 'antd';
import { converter } from '../converter';

import { useGetUserTip } from '../UserTipsProvider';
import { useGettext } from '@cranium/i18n';
import { useDevTools } from '@/shared/devTools';
import sanitize from '@/libs/sanitize';

type TipItemProps = {
  name: string;
};

const Container = styled.div<{ width?: string; height?: string }>`
  display: grid;
  min-height: 100px;
  width: ${({ width = 400 }) => `${width}px`};
  max-height: ${({ height = 600 }) => `${height}px`};
  overflow-y: scroll;

  .margin-auto {
    margin: auto;
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;

    video,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .tip-content {
    overflow: hidden;
  }
`;

const FlexContainer = styled.div``;

export const TipItem: React.FC<TipItemProps> = ({ name }) => {
  const tipItem = useGetUserTip(name);
  const devTools = useDevTools();
  const { gettext } = useGettext();

  const notAvailableTextContent = devTools.displayClientTipsKeys ? (
    <Row justify="center" align="middle">
      <div>
        <Row justify="center">
          <Typography.Text className="semibold-14 margin-auto">
            {gettext('Tip "%(name)" is not available', { name })}
          </Typography.Text>
        </Row>
        {process.env.REACT_APP_SERVER_ADMIN && (
          <Typography.Text className="semibold-14 margin-auto">
            {gettext('Navigate to ')}
            <a href={`${process.env.REACT_APP_SERVER_ADMIN}/client_tip`} target="_blank">
              {gettext('Server Admin')}
            </a>
            {gettext(' and add missing tip.')}
          </Typography.Text>
        )}
      </div>
    </Row>
  ) : (
    <Typography.Text className="semibold-14 margin-auto">{gettext('Tip is not available')}</Typography.Text>
  );

  if (!tipItem) {
    return <Container>{notAvailableTextContent}</Container>;
  }

  const { tip, loading, loaded } = tipItem;

  return (
    <Container width={tip?.details.width}>
      {!loaded && loading && (
        <Spin spinning>
          <Skeleton />
        </Spin>
      )}

      {loaded && !loading && !tip?.value && notAvailableTextContent}

      {loaded && !loading && tip?.value && (
        <div className="tip-content" dangerouslySetInnerHTML={sanitize(converter.makeHtml(tip?.value))} />
      )}
    </Container>
  );
};
