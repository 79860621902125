import { i18n } from '@/core';
import { makeFormik } from '@components/forms/FormikForm';
import { ArrayHelpers, FieldArray, FormikProps, FormikHelpers } from 'formik';
import React from 'react';
import { Button, Table } from 'reactstrap';
import { PricesFormRow } from './components/PricesFormRow';
import styles from './PricesForm.module.scss';
import InfoContainer from '../../../elements/InfoContainer';
import styled from 'styled-components';
import type { PricesFormInitialProps, PricesFormRowProps } from './types';

type Props = FormikProps<PricesFormInitialProps>;

type ArrayHelperProps<Values> = ArrayHelpers & {
  form: FormikProps<Values>;
  name: string;
};

export type PricesFormSubmit = (
  values: PricesFormInitialProps,
  formikHelpers: FormikHelpers<PricesFormInitialProps>
) => void | Promise<any>;

const Form = styled.form`
  width: 100%;
`;

const PricesForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <FieldArray
        name="prices"
        render={({ form }: ArrayHelperProps<PricesFormInitialProps>) => {
          const { prices } = form.values;

          return (
            <div className={styles.wrapper}>
              {prices.length > 0 ? (
                <Table hover>
                  <thead>
                    <tr>
                      <th>{i18n.t('labels.name')}</th>
                      <th>{i18n.t('labels.price')}</th>
                      <th>{i18n.t('labels.specialPrice')}</th>
                      <th>{i18n.t('labels.specialPrice')}</th>
                      <th>{i18n.t('labels.specialPrice')}</th>
                      <th>{i18n.t('labels.specialPrice')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prices.map((value: PricesFormRowProps, idx: number) => (
                      <PricesFormRow key={`${value.id}_${idx}`} namePrefix={`prices[${idx}]`} />
                    ))}
                  </tbody>
                </Table>
              ) : (
                <InfoContainer>
                  <h5>{i18n.t('common.emptyPrices')}</h5>
                </InfoContainer>
              )}
              {prices.length > 0 ? (
                <div className="float-left">
                  <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
                    {i18n.t('labels.save')}
                  </Button>
                </div>
              ) : null}
            </div>
          );
        }}
      />
    </Form>
  );
};

export default makeFormik<PricesFormInitialProps>(PricesForm);

export const pricesFormInitialValues: PricesFormInitialProps = {
  prices: []
};
