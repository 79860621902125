import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Sidebar as SidebarComponent } from './Sidebar';
import Powered from './Powered';
import HeaderLogo from './HeaderLogo';
import { useUIContext } from '@components/context/ApplicationContext';
import { colors } from '@/defaultStyles';
import { debounce } from 'lodash';
import { useIsMounted } from '@/view/hooks/useIsMounted';
import { Navbar } from '@/view/components/Navbar';
import { BackgroundOrdersJobService } from '@/core/gql/apollo';
import { useSidebarSize } from '@components/layout/useSidebarSize';
import { LocalStorage } from '@/core/Storage';

type MainLayoutProps = {} & React.HTMLAttributes<HTMLDivElement>;

const MainLayout: React.FC<MainLayoutProps> = props => {
  const {
    sidebar: { isOpen: isSidebarOpen, minimized, setMinimized },
    topPanel: { isTopPanelVisible },
    setRootContainer
  } = useUIContext();
  useAutoLogout();
  const smallScreen = useSidebarSize();

  return (
    <Root className={props.className} ref={setRootContainer}>
      <Navbar visible={isSidebarOpen} />

      <Sidebar data-testid="Sidebar" opened={isSidebarOpen} minimized={minimized} data-main-layout-sidebar>
        <HeaderLogo minimized={minimized} />

        <SidebarComponent />

        {smallScreen && (
          <SidebarExpandIcon className="icon-chevron-right" minimized={minimized} onClick={() => setMinimized(!minimized)} />
        )}

        {!minimized && !smallScreen && (
          <SidebarFooter>
            <Powered />
          </SidebarFooter>
        )}
      </Sidebar>

      <Main withLeftPanel={isSidebarOpen} withTopPanel={isTopPanelVisible} minimized={minimized || smallScreen}>
        <Content className="Content">{props.children}</Content>
      </Main>
    </Root>
  );
};

type SidebarProps = {
  opened: boolean;
  minimized: boolean;
};

const Sidebar = styled.div`
  display: grid;
  grid-template-rows: 112px 1fr auto;
  width: ${({ opened, minimized }: SidebarProps) => (opened ? (minimized ? '50px' : '240px') : '0px')};
  max-height: 100vh;
  height: 100%;
  z-index: 120;

  overflow: hidden;
  position: fixed;

  background-color: ${colors.WHITE};
  box-shadow: 0 0 5px 1px rgba(38, 42, 54, 0.08);
  transition: width 0.1s ease;
`;

const SidebarFooter = styled.div`
  display: grid;
`;

const SidebarExpandIcon = styled.span<{ minimized: boolean }>`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transform: rotate(${props => (props.minimized ? 0 : '180deg')});
  cursor: pointer;
`;

const Root = styled.div`
  display: flex;
  margin: 0;
  min-height: 100vh;
  flex-direction: column;
`;

const Main = styled.div<{ withTopPanel?: boolean; withLeftPanel: boolean; minimized: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: ${props => (props.withLeftPanel ? '48px' : '0')};
  margin-left: ${props => (props.withLeftPanel ? (props.minimized ? '50px' : '240px') : '0')};

  background-color: var(--gray-1);

  transition: all 0.2s;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
`;

export default MainLayout;

const useAutoLogout = () => {
  const isMounted = useIsMounted();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const startInterval = () => {
      timeout && clearTimeout(timeout);
      if (isMounted.current) {
        const ms = process.env.REACT_APP_LOGOUT_TIMEOUT;
        timeout = setTimeout(
          () => {
            removeListeners();
            LocalStorage.clearAll();
            const locationBeforeLogin = [window.location.pathname, window.location.search].filter(Boolean).join('');
            if (!locationBeforeLogin.includes('login')) {
              LocalStorage.setItem('location.beforeLogin', locationBeforeLogin);
            }
            BackgroundOrdersJobService.stopWatch();
          },
          ms === undefined ? 300000 : +ms
        );
      }
    };
    startInterval();
    const onActiveEvent = debounce(
      () => {
        startInterval();
      },
      1000,
      { maxWait: 2000 }
    );

    window.addEventListener('focus', onActiveEvent);
    window.addEventListener('keydown', onActiveEvent);
    window.addEventListener('mousemove', onActiveEvent);

    const removeListeners = () => {
      window.removeEventListener('focus', onActiveEvent);
      window.removeEventListener('keydown', onActiveEvent);
      window.removeEventListener('mousemove', onActiveEvent);
    };
    return () => {
      removeListeners();
      clearTimeout(timeout);
    };
  }, []);
};
