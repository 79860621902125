import styled, { css } from 'styled-components';
import { colors } from '@/defaultStyles';

const Input = styled.input<{ error: boolean; disabled?: boolean; largeWidth?: boolean }>`
  height: ${props => (props.largeWidth ? '50px' : '40px')};
  width: 100%;
  padding: 10px 12px;
  border-radius: 4px;
  border: ${props => `solid 1px ${props.error ? colors.PINKISH_RED : colors.LIGHT_PERIWINKLE}`};
  &:focus {
    border: solid 1px ${colors.ALGAE_GREEN};
  }
  background-color: ${colors.WHITE};
  font-size: 14px;
  line-height: 1.43;
  color: ${colors.CHARCOAL_GREY};
  outline: none;
  ${({ disabled }) =>
    disabled
      ? css`
          color: #ccc;
          background: #f7f7f7;
        `
      : ''}
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: Catamaran;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.5px;
    color: ${colors.BATTLESHIP_GREY};
  }
  :-ms-input-placeholder {
    font-family: Catamaran;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.5px;
    color: ${colors.BATTLESHIP_GREY};
  }
`;

export default Input;
