import React from 'react';
import styled from 'styled-components';
import { Space } from 'antd';
import AccountSwitcher from '@components/layout/AccountSwitcher';
import Breadcrumbs from '@components/Navbar/Breadcrumbs';
import MemberInfoMenu from '@components/Navbar/MemberInfoMenu';
import { ChangeLanguage } from './components/ChangeLanguage';
import { useUIContext } from '@components/context/ApplicationContext';

export const NAVBAR_HEIGHT = '48px';

const NavContainer = styled.div<{ visible: boolean; minimized: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - ${props => (props.minimized ? '50px' : '242px')});
  height: ${NAVBAR_HEIGHT};
  padding: 0 24px;
  display: ${props => (props.visible ? 'grid' : 'none')};
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-content: center;
  z-index: 100;
  background-color: #fff;
`;

interface Props {
  visible: boolean;
}

export const Navbar: React.FunctionComponent<Props> = ({ visible }) => {
  const {
    sidebar: { minimized }
  } = useUIContext();

  return (
    <NavContainer visible={visible} data-main-layout-navbar minimized={minimized}>
      <Breadcrumbs />
      <Space size={24}>
        <ChangeLanguage />
        <AccountSwitcher />
        <MemberInfoMenu />
      </Space>
    </NavContainer>
  );
};
