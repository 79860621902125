import { OptionPrice, Order, OrderPreparatoryConfig, OrderUpdateInputType, Price } from '@/interfaces/brokrete';
import { OrderInvoiceFormInitialProps, OrderProductTypePrice } from '@/view/screens/OrderInvoiceScreen/components/types';
import { SelectedOptionType } from '@/interfaces/common';
import { toNumber } from '@/libs/string';
import { buildIdInput } from '@/shared/api/brokrete/helper';
import { order as orderHelper } from '@/libs';

function prepareOrderInputPayload(order: Order, config: OrderPreparatoryConfig, formValues: OrderInvoiceFormInitialProps) {
  const { options, productTypes, taxFees, trucks, commonFees } = formValues;
  const isServiceIndustry = order.product.industry === 'service' || order.product.industry === 'concrete_pumping';

  const orderUpdateInputPayload: OrderUpdateInputType = {
    id: order.id,
    product: {
      id: order.product.id,
      prices: productTypes.map((productType: SelectedOptionType<OrderProductTypePrice>) => {
        return {
          ...(!order.product.permissions.trucks && {
            quantity: toNumber(productType.data.quantity)
          }),
          type: buildIdInput(productType.data.type),
          ...(productType.data.typePriceValue
            ? {
                typePriceValue: toNumber(productType.data.typePriceValue)
              }
            : {
                typePrice: buildIdInput(productType.data.typePrice)
              }),
          ...(productType.data.decorate && {
            decorate: buildIdInput(productType.data.decorate),
            ...(productType.data.decoratePriceValue
              ? {
                  decoratePriceValue: toNumber(productType.data.decoratePriceValue)
                }
              : {
                  decoratePrice: buildIdInput(productType.data.decoratePrice as Price)
                })
          })
        };
      })
    },
    options: options.map((option: SelectedOptionType<OptionPrice>) => {
      return {
        id: option.id,
        ...(option.data.optionPriceValue
          ? {
              value: toNumber(option.data.optionPriceValue)
            }
          : {
              price: buildIdInput(option.data.price)
            }),
        content: option.data.price.content,
        quantity: option.data.quantity
      };
    }),
    fees: [...taxFees, ...commonFees].map(fee => {
      return {
        id: fee.id,
        ...(fee.data.feePriceValue
          ? {
              value: toNumber(fee.data.feePriceValue)
            }
          : {
              price: buildIdInput(fee.data.price)
            }),
        content: fee.data.price.content
      };
    }),
    trucks:
      order.product.industry === 'related_products'
        ? [
            productTypes.reduce((memo, productType) => {
              memo += toNumber(productType.data.quantity);
              return memo;
            }, 0)
          ]
        : isServiceIndustry
        ? [toNumber(productTypes[0].data.quantity)]
        : trucks,
    ...(isServiceIndustry && {
      trucksSecondary: [toNumber(productTypes[1].data.quantity)]
    }),
    ...(formValues.paymentMode && {
      paymentMode: formValues.paymentMode
    }),
    ...(formValues.priceMode && {
      priceMode: formValues.priceMode
    })
  };

  return orderUpdateInputPayload;
}

function prepareConfigPayload(order: Order, orderUpdateInputPayload: OrderUpdateInputType) {
  return {
    address: order.address,
    category: order.product,
    deliveryTime: order.deliveryTime,
    plant: order.plant,
    productPrices: orderUpdateInputPayload.product?.prices.map(productTypePrice => {
      const typePrice = {
        ...productTypePrice
      };

      if (typePrice.typePriceValue) {
        delete typePrice['typePrice'];
      }

      if (typePrice.decoratePriceValue) {
        delete typePrice['decoratePrice'];
      }

      return typePrice;
    }),
    fees: orderUpdateInputPayload.fees,
    options: orderUpdateInputPayload.options,
    trucks: orderUpdateInputPayload.trucks,
    trucksSecondary: orderUpdateInputPayload.trucksSecondary,
    deliveryMethod: order.deliveryMethod.name
  };
}

function mapProductTypePricesOptions(order: Order, config?: OrderPreparatoryConfig) {
  const productPrices = orderHelper.extractProductPrices(order);
  const industry = order.product.industry;

  return productPrices.map((productPrice, index) => {
    const totalTypePrice =
      config &&
      config.totalPrice.components.find(priceComponent => {
        return priceComponent.productType && priceComponent.productType.id === productPrice.productTypePrice.productType.id;
      });

    const totalDecoratePrice =
      config &&
      config.totalPrice.components.find(priceComponent => {
        return (
          priceComponent.productDecorate &&
          priceComponent.productDecorate.id === productPrice.productDecoratePrice?.productDecorate.id
        );
      });

    return {
      data: {
        type: productPrice.productTypePrice.productType,
        typePrice: orderHelper.extractProductPrice(order, productPrice),
        typePriceValue: undefined,
        typePriceTotal: totalTypePrice && totalTypePrice.value,
        ...(productPrice.productDecoratePrice?.productDecorate && {
          decorate: {
            data: productPrice.productDecoratePrice.productDecorate,
            id: productPrice.productDecoratePrice.productDecorate.id,
            value: productPrice.productDecoratePrice.productDecorate.id,
            label: productPrice.productDecoratePrice.productDecorate.name
          },
          decoratePrice: productPrice.productDecoratePrice?.price,
          decoratePriceValue: undefined,
          decoratePriceTotal: totalDecoratePrice && totalDecoratePrice.value
        }),
        quantity: productPrice.quantity as number
      },
      id: productPrice.productTypePrice.productType.id,
      value: productPrice.productTypePrice.productType.id,
      label: productPrice.productTypePrice.productType.name
    };
  });
}

function mapPreparatoryConfigOptions(config: OrderPreparatoryConfig) {
  return config.options.map(option => {
    const optionTotalPrice = config.totalPrice.components
      .filter(component => {
        return component.option != null;
      })
      .find(component => {
        // @ts-ignore
        return component.option.id === option.option.id;
      });

    return {
      data: {
        ...option,
        totalPrice: (optionTotalPrice ? optionTotalPrice.value : null) as number
      },
      id: option.option.id,
      value: option.option.id,
      label: option.option.name
    };
  });
}

function mapPreparatoryConfigProductDecorates(config: OrderPreparatoryConfig) {
  if (!config.productDecorates) {
    return [];
  }

  return config.productDecorates.map(productDecorate => {
    return {
      data: productDecorate,
      id: productDecorate.productDecorate.id,
      value: productDecorate.productDecorate.id,
      label: productDecorate.productDecorate.name
    };
  });
}

function mapPreparatoryConfigProductTypes(config: OrderPreparatoryConfig) {
  if (!config.productTypes) {
    return [];
  }

  return config.productTypes.map(productType => {
    return {
      data: {
        type: productType.productType,
        typePrice: productType.price,
        typePriceValue: null,
        decorate: null,
        decoratePrice: null,
        decoratePriceValue: null,
        quantity: null
      } as unknown as OrderProductTypePrice,
      id: productType.productType.id,
      value: productType.productType.id,
      label: productType.productType.name
    };
  });
}

function mapPrepratoryConfigFees(config: OrderPreparatoryConfig) {
  return config.fees.map(fee => {
    const totalFeePrice = config.totalPrice.components.find(priceComponent => {
      return priceComponent.fee && priceComponent.fee.id === fee.fee.id;
    });

    return {
      data: {
        ...fee,
        totalPrice: totalFeePrice && totalFeePrice.value
      },
      id: fee.fee.id,
      value: fee.fee.id,
      label: fee.fee.name
    };
  });
}

function mapOrderOptions(options: OptionPrice[]) {
  return options.map(option => {
    return {
      data: option,
      id: option.option.id,
      value: option.option.id,
      label: option.option.name
    };
  });
}

export {
  prepareOrderInputPayload,
  prepareConfigPayload,
  mapProductTypePricesOptions,
  mapPreparatoryConfigOptions,
  mapPreparatoryConfigProductDecorates,
  mapPreparatoryConfigProductTypes,
  mapPrepratoryConfigFees,
  mapOrderOptions
};
