import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput, FormInputCheckBox } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, CardSubtitle, Col, Form, Row } from 'reactstrap';

type FormInitialProps = {
  amount: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

type Props = FormikProps<FormInitialProps>;

export const dayFeeFormInitialProps: FormInitialProps = {
  amount: '',
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false
};

const DayFeeForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  const { gettext } = useGettext();
  return (
    <Form onSubmit={handleSubmit}>
      <CardSubtitle>{i18n.t('cards.settings.dayFee.subtitle')}</CardSubtitle>
      <Row form className="mt-4">
        <Col md={3}>
          <Field name={'amount'} id={'amount'} placeholder={gettext('Amount')} component={FormInput} validate={required} />
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row form className="mb-3">
        <Col>
          <Field name={`monday`} label={i18n.t('labels.weekday.monday')} component={FormInputCheckBox} />
        </Col>
        <Col>
          <Field name={`tuesday`} label={i18n.t('labels.weekday.tuesday')} component={FormInputCheckBox} />
        </Col>
        <Col>
          <Field name={`wednesday`} label={i18n.t('labels.weekday.wednesday')} component={FormInputCheckBox} />
        </Col>
      </Row>
      <Row form className="mb-3">
        <Col>
          <Field name={`thursday`} label={i18n.t('labels.weekday.thursday')} component={FormInputCheckBox} />
        </Col>
        <Col>
          <Field name={`friday`} label={i18n.t('labels.weekday.friday')} component={FormInputCheckBox} />
        </Col>
        <Col>
          <Field name={`saturday`} label={i18n.t('labels.weekday.saturday')} component={FormInputCheckBox} />
        </Col>
      </Row>
      <Row form className="mb-3">
        <Col>
          <Field name={`sunday`} label={i18n.t('labels.weekday.sunday')} component={FormInputCheckBox} />
        </Col>
      </Row>
      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(DayFeeForm);
