import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput, FormInputFile, FormInputSwitch } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

type FormInitialProps = {
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  phone: string;
  address: string;
  file: string;
  isActive: boolean;
};

type Props = FormikProps<FormInitialProps>;

export const driverFormInitialProps: FormInitialProps = {
  firstName: '',
  lastName: '',
  gender: '',
  email: '',
  phone: '',
  address: '',
  file: '',
  isActive: false
};

const DriverForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  const { gettext } = useGettext();
  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col>
          <Field
            name={'firstName'}
            id={'firstName'}
            placeholder={gettext('First Name')}
            component={FormInput}
            validate={required}
          />
        </Col>
        <Col>
          <Field name={'lastName'} id={'lastName'} placeholder={gettext('Last Name')} component={FormInput} validate={required} />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field name={'gender'} id={'gender'} placeholder={gettext('Gender')} component={FormInput} validate={required} />
        </Col>
        <Col>
          <Field name={'email'} id={'email'} placeholder={gettext('Email')} component={FormInput} validate={required} />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field name={'phone'} id={'phone'} placeholder={gettext('Phone')} component={FormInput} validate={required} />
        </Col>
        <Col>
          <Field name={'address'} id={'address'} placeholder={gettext('Address')} component={FormInput} validate={required} />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name={'file'}
            id={'file'}
            // label={i18n.t('labels.file')}
            component={FormInputFile}
            validate={required}
          />
        </Col>
        <Col>
          <Field
            className="mt-2"
            name={'isActive'}
            id={'isActive'}
            label={i18n.t('labels.isActive')}
            component={FormInputSwitch}
            validate={required}
          />
        </Col>
      </Row>

      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(DriverForm);
