import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { IdInput } from '@/interfaces/brokrete';

const OrderSizeForm: React.FC<FormProps> = ({ handleSubmit, isSubmitting, isValid, dirty, values }) => {
  const { trucks } = values;
  return (
    <Form onSubmit={handleSubmit}>
      <Field className="m-0" hidden name="category.id" id="category.id" component={FormInput} validate={required} />
      <Row form>
        <Col>
          <Field
            name="minOrderQuantity"
            id="minOrderQuantity"
            label={i18n.t('cards.settings.minimum')}
            formText={i18n.t('cards.settings.minAndMaxOrderSize.minimumFormText')}
            component={FormInput}
            autoComplete={`orderSize.minimum`}
            validate={required}
            type="number"
            disabled={isSubmitting}
          />
        </Col>
      </Row>
      {trucks ? (
        <>
          <Row form>
            <Col>
              <Field
                name="maxTrucksQuantity"
                id="maxTrucksQuantity"
                label={i18n.t('cards.settings.maximum')}
                formText={i18n.t('cards.settings.minAndMaxOrderSize.maximumFormText')}
                component={FormInput}
                autoComplete={`orderSize.maximum`}
                validate={required}
                type="number"
                disabled={isSubmitting}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <Field
                name="truckCapacity"
                id="truckCapacity"
                label={i18n.t('cards.settings.truckCapacity')}
                component={FormInput}
                autoComplete={`quantity.truckCapacity`}
                validate={required}
                type="number"
                disabled={isSubmitting}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row form>
            <Col>
              <Field
                name="truckCapacity"
                id="truckCapacity"
                label={i18n.t('cards.settings.maximum')}
                formText={i18n.t('cards.settings.minAndMaxOrderSize.maximumFormText')}
                component={FormInput}
                autoComplete={`orderSize.maximum`}
                validate={required}
                type="number"
                disabled={isSubmitting}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <Field
                hidden
                value={1}
                name="maxTrucksQuantity"
                id="maxTrucksQuantity"
                label={i18n.t('cards.settings.truckCapacity')}
                component={FormInput}
                autoComplete={`quantity.truckCapacity`}
                validate={required}
                type="number"
                disabled={isSubmitting}
              />
            </Col>
          </Row>
        </>
      )}

      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(OrderSizeForm);

export const orderSizeFormInitialValues: FormInitialProps = {
  category: { id: '' },
  maxTrucksQuantity: '',
  minOrderQuantity: '',
  truckCapacity: '',
  trucks: false
};

type FormInitialProps = {
  category: IdInput;
  maxTrucksQuantity: number | string;
  minOrderQuantity: number | string;
  truckCapacity: number | string;
  trucks: boolean;
};

type FormProps = FormikProps<FormInitialProps>;
