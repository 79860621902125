import { Place, Price } from '@/interfaces/brokrete';
import { Apollo } from '@/core/gql/apollo';
import { GQL } from '@/core/gql';
import { buildAddressInput, buildIdInput } from '@/shared/api/brokrete/helper';
import { OrderPreparatoryConfigPayload, OrderPreparatoryConfigType } from './config';
import { toNumber } from '@/libs/string';

const fetchTotalPrices = async ({
  contractor,
  input,
  order,
  partner
}: OrderPreparatoryConfigType): Promise<OrderPreparatoryConfigPayload | null> => {
  // FIXME: Refacyoring is needed. Probably Lodash has some methods to remove nulls from objects
  const result = await Apollo.client.query<OrderPreparatoryConfigPayload, OrderPreparatoryConfigType>({
    query: GQL.orderPreparatoryConfig.queries.totalPrices,
    // @ts-ignore
    variables: {
      ...(partner && {
        currency: partner.account.currency
      }),
      ...(order && {
        order: buildIdInput(order)
      }),
      ...(contractor && {
        contractor: buildIdInput(contractor)
      }),
      ...(input && {
        input: {
          pickup: input.pickup,
          ...(input.address && {
            address: buildAddressInput(input.address as Place)
          }),
          ...(input.options && {
            options: input.options
          }),
          ...(input.category && {
            category: buildIdInput(input.category)
          }),
          ...(input.deliveryTime && {
            deliveryTime: input.deliveryTime
          }),
          ...(input.fees && {
            fees: input.fees
          }),
          ...(input.plant && {
            plant: buildIdInput(input.plant)
          }),
          ...(input.project && {
            project: buildIdInput(input.project)
          }),
          ...(input.trucks && {
            trucks: input.trucks
          }),
          ...(input.productPrices && {
            productPrices: input.productPrices.map(productPrice => {
              return {
                ...(productPrice.decorate && {
                  decorate: buildIdInput(productPrice.decorate),
                  ...(productPrice.decoratePriceValue
                    ? {
                        decoratePriceValue: productPrice.decoratePriceValue
                      }
                    : {
                        decoratePrice: buildIdInput(productPrice.decoratePrice as Price)
                      })
                }),
                quantity: toNumber(productPrice.quantity),
                ...(productPrice.type && {
                  type: buildIdInput(productPrice.type)
                }),
                ...(productPrice.typePriceValue
                  ? {
                      typePriceValue: productPrice.typePriceValue
                    }
                  : {
                      typePrice: buildIdInput(productPrice.typePrice as Price)
                    })
              };
            })
          }),
          ...(input.deliveryMethod && {
            deliveryMethod: input.deliveryMethod
          })
        }
      })
    }
  });

  return result.data;
};

export { fetchTotalPrices };
