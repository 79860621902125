import gql from 'graphql-tag';

export const partnerContractors = gql`
  query partnerContractors(
    $contractors: [IdInput!]
    $from: String
    $limit: Int
    $partners: [IdInput!]
    $search: String
    $skip: Int
    $source: [PartnerContractorSourceEnum!]
    $status: [PartnerContractorStatusEnum!]
    $accountStatus: [PartnerContractorAccountStatusEnum!]
    $priceStatus: [PartnerContractorPriceStatusEnum!]
    $to: String
  ) {
    partnerContractors(
      contractors: $contractors
      from: $from
      limit: $limit
      partners: $partners
      search: $search
      skip: $skip
      source: $source
      status: $status
      accountStatus: $accountStatus
      priceStatus: $priceStatus
      to: $to
      markAsRead: true
    ) {
      count
      values {
        id
        statusChangedAt
        locked
        owner
        priceAdded
        selected
        source
        status
        priceStatus
        accountInfo
        priceDetails {
          groups
          personal
        }
        contractor {
          id
          info {
            name
            type
            additional
          }
          companyInfo {
            name
            address
          }
          owner {
            phone
            email
          }
        }
      }
    }
  }
`;

export const partnerContractorsAll = gql`
  query partnerContractorsAll {
    priceRequest: partnerContractors(onlyNotRead: true, priceStatus: [pending, done]) {
      count
    }
    invite: partnerContractors(onlyNotRead: true, source: [invite]) {
      count
    }
    disconnected: partnerContractors(onlyNotRead: true, status: [disabled]) {
      count
    }
  }
`;
