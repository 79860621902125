import { useCallback, useEffect, useRef, useState } from 'react';
import { useIsMounted } from '@/view/hooks/useIsMounted';
import { Apollo } from '@/core/gql/apollo';
import { partnerContractorsAll } from '@pages/RequestsPage/query';

export type BackgroundFetcher = [{ all: number; priceRequest: number; invite: number; disconnected: number }, () => void];

export const useBackgroundFetcher: (timeout?: number | 20) => BackgroundFetcher = (timeout = 20) => {
  const [state, setState] = useState({ priceRequest: 0, invite: 0, disconnected: 0, all: 0 });
  const tickRef = useRef(timeout);
  const isMounted = useIsMounted();

  const reFetch = useCallback(() => {
    tickRef.current = 0;
  }, []);

  useEffect(() => {
    let loading = false;

    const interval = setInterval(() => {
      if (loading) return;
      tickRef.current--;
      if (!loading && tickRef.current <= 0) {
        loading = true;
        Apollo.client
          .query({
            query: partnerContractorsAll,
            fetchPolicy: 'no-cache'
          })
          .then(res => {
            if (isMounted.current) {
              const priceRequest = res.data?.priceRequest.count ?? 0;
              const invite = res.data?.invite.count ?? 0;
              const disconnected = res.data?.disconnected.count ?? 0;
              const all = priceRequest + invite + disconnected;
              setState({ priceRequest, invite, disconnected, all });
              tickRef.current = timeout;
              loading = false;
            }
          });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return [state, reFetch];
};
export default useBackgroundFetcher;
