import { IdInput, ModificationTypeEnum, ProductType, ProductTypeModifications } from '@/interfaces/brokrete';
import { Apollo } from '@/core/gql/apollo';
import { gql } from 'graphql-tag';

export type ProductTypeModificationsInput = {
  add?: {
    label: string;
    type: ModificationTypeEnum;
    values: ProductTypeModifications[];
  };

  change?: {
    label: string;
    type: ModificationTypeEnum;
    values: ProductTypeModifications[];
  };

  remove?: IdInput[];
};

const productTypeUpdateMutation = gql`
  mutation productTypeUpdate($input: ProductTypeUpdateInput!) {
    productTypeUpdate(input: $input) {
      success
      productType {
        modifications {
          id
          name
          color
          image
          label
          type
        }
      }
    }
  }
`;

type ProductTypeUpdateInput = {
  id: string;
  modifications: ProductTypeModificationsInput;
};

type MutationResult = {
  productTypeUpdate: {
    success: boolean;
    productType: Pick<ProductType, 'modifications'>;
  };
};

const productTypeModificationsUpdate = async (input: ProductTypeUpdateInput): Promise<MutationResult | null | undefined> => {
  const result = await Apollo.client.mutate<MutationResult, { input: ProductTypeUpdateInput }>({
    mutation: productTypeUpdateMutation,
    variables: { input }
  });

  return result.data;
};

export default productTypeModificationsUpdate;
