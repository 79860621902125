import gql from 'graphql-tag';

export const orderClusters = gql`
  query orders($precision: Float!, $status: [String!], $supplier: [IdInput!], $from: String, $to: String, $region: RegionInput) {
    orders(status: $status, supplier: $supplier, from: $from, to: $to, region: $region) {
      clusters(precision: $precision) {
        count
        location {
          latitude
          longitude
        }
        value {
          id
          contractor {
            id
            info {
              name
            }
            owner {
              identities {
                provider
                uid
              }
            }
          }
          address {
            display
          }
          city {
            id
            name
            country {
              name
            }
          }
          product {
            id
            name
          }
          plant {
            id
            name
          }
          quantity
          totalPrice
          currency
          status(detailed: true)
          trucks
          timeBetweenTrucks
          deliveryTime
        }
        values
      }
    }
  }
`;
