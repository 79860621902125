import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Card, ListGroup, ListGroupItem } from 'reactstrap';
import React from 'react';
import styles from './ContractorInfo.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import type { ContractorsSearchOption } from '../types';
import cx from 'classnames';

const ContractorInfo: React.FC<ContractorsSearchOption> = (props): JSX.Element => {
  const formattedPhoneNumber = parsePhoneNumberFromString(props.phone);

  return (
    <Card className="mt-5 shadow-none">
      <ListGroup className={cx(styles.contractorInfo)}>
        <ListGroupItem className="d-flex justify-content-between align-items-center pl-1 pr-1">
          <span>{props.name}</span>
          <FontAwesomeIcon icon={faUser} />
        </ListGroupItem>
        {formattedPhoneNumber && (
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <span>{formattedPhoneNumber.formatInternational()}</span>
            <FontAwesomeIcon icon={faPhone} />
          </ListGroupItem>
        )}
        <ListGroupItem className="d-flex justify-content-between align-items-center">
          <span>{props.email}</span>
          <FontAwesomeIcon icon={faEnvelope} />
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default ContractorInfo;
