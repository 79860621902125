import { applyMiddleware, combineReducers, createStore, PreloadedState, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { History } from 'history';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  authorizationReducer,
  AuthorizationStoreState,
  settingsReducer,
  SettingsStoreState,
  sessionReducer,
  SessionStoreState,
  ThemeStoreState,
  themeReducer
} from './reducers';

const createRootReducer = (history: History) =>
  combineReducers({
    ...authorizationReducer,
    ...themeReducer,
    ...settingsReducer,
    ...sessionReducer,
    router: connectRouter(history)
  });

function createEnhancer(middlewares: any) {
  const storeEnhancer = applyMiddleware(...middlewares);
  const composeArgs = [storeEnhancer].filter(Boolean);

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(...composeArgs);
  }

  return compose(...composeArgs);
}

export default function configureStore(initialState: PreloadedState<any> | undefined, history: History) {
  const reducer = createRootReducer(history);
  const middlewares = [thunk, routerMiddleware(history)];

  const persistConfig = {
    key: 'root',
    storage
  };

  const persistedReducer = persistReducer(persistConfig, reducer);

  const enhancer = createEnhancer(middlewares);
  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);

  return {
    store,
    persistor
  };
}

export interface RootStoreState extends AuthorizationStoreState, SettingsStoreState, SessionStoreState, ThemeStoreState {}
