import React, { useCallback, useMemo, useState } from 'react';
import { IdInput } from '@/interfaces/brokrete';
import { required } from '@/libs/validators';
import ActivityIndicator from '@/view/components/elements/ActivityIndicator';
import BaseModalView, { Body, Footer, Navigation } from '@/shared/components/modal/BaseModalView';
import Button, { Type } from '@/shared/components/buttons/Button';
import { i18n } from '@/core';
import styled from 'styled-components';
import { FormInput, FormInputSelect } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { withApolloContext } from '@components/withApolloContext';
import { Field, FormikProps } from 'formik';
import { FormikTextField } from '@/shared/components/formik/fields';
import type { FormMode } from './ContractorEditForm/types';
import { hideModal } from '@components/modals';
import { colors } from '@/defaultStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import ICONS from '@/assets/images/icons';
import { useGettext } from '@cranium/i18n';

const OptionContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-column-gap: 50px;
`;

const ActiveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextField = styled(FormikTextField)<{ shallowMargin?: boolean }>`
  margin: ${props => (props.shallowMargin ? '7px 0 14px 0' : '15px 0')};
`;

const StyledField = styled(Field)`
  margin-bottom: 15px;
`;
const UploadImageContainer = styled.div<{ isFilled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-top: 16px;
  & label {
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.23px;
    margin-bottom: 0;
    font-weight: normal;
    text-transform: initial;
  }
  & .product-catgeory-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin-left: 20px;
    background-color: ${colors.LIGHT};
    color: ${colors.BATTLESHIP_GREY};
    border: dashed 2px ${colors.LIGHT_PERIWINKLE};
    border-radius: 10px;
    opacity: ${props => (props.isFilled ? 0.5 : 1)};
    & img {
      filter: invert(51%) sepia(2%) saturate(1994%) hue-rotate(191deg) brightness(88%) contrast(90%);
    }
    & input[type='file'] {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  /* &.decorate-color-input-class {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    border-radius: 10px;
    opacity: ${props => (props.isFilled ? 0.5 : 1)};
    & input {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      background-color: transparent;
    }
    & input[type='color'] {
      display: none;
      /* -webkit-appearance: none;
      border: none;
      ::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      ::-webkit-color-swatch {
        border: none;
      }
    }
  } */
`;

const PreviewImageContainer = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  margin-left: 29px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: solid 1px ${colors.EGG_WHITE};
  background-color: ${colors.WHITE};
`;

const DeleteLogoButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: none;
  background-color: black;
  opacity: 40%;
  color: ${colors.WHITE};
  cursor: pointer;
  & svg {
    transform: rotate(45deg);
  }
`;

const Title = styled.div`
  font-size: 14px;
  min-height: 36px;
  line-height: 1.29;
  letter-spacing: 0.23px;
  white-space: pre-line;
  color: ${colors.BATTLESHIP_GREY};
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  white-space: pre-line;
  color: ${colors.BATTLESHIP_GREY};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button<{ newGroup: boolean }>`
  background-color: ${props => (props.newGroup ? colors.BATTLESHIP_GREY : colors.ALGAE_GREEN)};
  border-radius: 4px;
  border: none;
  box-shadow: none;
  margin-top: 13px;
  height: 35px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled.div`
  width: 90%;
  margin-right: 10px;
`;

const StyledSelcet = styled(FormInputSelect)`
  font-size: 14.5px;
  padding-top: 0px;
`;

const ProductOptionForm: React.FunctionComponent<Props> = ({
  handleSubmit,
  initialValues,
  isSubmitting,
  isValid,
  values,
  mode,
  dirty,
  setFieldValue,
  availableGroups
}) => {
  const { gettext } = useGettext();
  const [newGroup, setNewGroup] = useState<boolean>(false);
  const convertImgUrl = useCallback((image: string | Blob) => {
    return typeof image !== 'string' ? URL.createObjectURL(image) : image;
  }, []);

  return (
    <BaseModalView
      title={initialValues.name ? initialValues.name : i18n.t('modals.newOption.title')}
      css="padding: 0px 10px 10px 10px;"
    >
      <Body>
        <OptionContainer>
          <Column>
            <TextField
              name="exportCode"
              label={gettext(`Export Code`)}
              placeholder={gettext(`Export Code`)}
              component={FormInput}
              validate={required}
              disabled={isSubmitting}
            />
            <TextField
              name="name"
              label={gettext(`Option Name`)}
              placeholder="Special Concrete Mix"
              component={FormInput}
              validate={required}
              disabled={isSubmitting}
            />
            <TextField
              name="serviceName"
              label={gettext(`Service Name`)}
              placeholder="Special Concrete Mix"
              component={FormInput}
              validate={required}
              disabled={isSubmitting}
            />
            <Row>
              <InputContainer>
                {newGroup ? (
                  <TextField
                    name="group"
                    shallowMargin
                    label={i18n.t(`forms.optionsForm.fields.group`)}
                    placeholder="No Air Mix"
                    component={FormInput}
                    disabled={isSubmitting}
                  />
                ) : (
                  <StyledField
                    name="group"
                    label={i18n.t(`forms.optionsForm.fields.group`)}
                    placeholder="No Air Mix"
                    component={StyledSelcet}
                    options={availableGroups.map(group => {
                      return group === 'No group' ? { label: group, value: '' } : { label: group, value: group };
                    })}
                    disabled={isSubmitting}
                  />
                )}
              </InputContainer>
              <StyledButton
                newGroup={newGroup}
                onClick={() => {
                  setFieldValue('group', '');
                  setNewGroup(!newGroup);
                }}
              >
                <FontAwesomeIcon icon={!newGroup ? faPlus : faUndo} color={colors.WHITE} />
              </StyledButton>
            </Row>
          </Column>
          <Column>
            <UploadImageContainer isFilled={values.image !== null}>
              <Title>{i18n.t('labels.uploadSample')}</Title>
              {values.image ? (
                <PreviewImageContainer>
                  <Image src={convertImgUrl(values.image)} alt="profile-logo" />
                  <DeleteLogoButton type="button" onClick={() => setFieldValue(`image`, null, false)}>
                    <FontAwesomeIcon icon={faPlus} size="xs" />
                  </DeleteLogoButton>
                </PreviewImageContainer>
              ) : (
                <Label htmlFor="product-catgeory-image-input" className="product-catgeory-image">
                  <input
                    id="product-catgeory-image-input"
                    name={`image`}
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(event: React.ChangeEvent<any>) => {
                      const file: File = event.currentTarget.files[0];
                      setFieldValue('image', file, false);
                    }}
                  />
                  <img src={ICONS.icAdd} width="60" height="60" alt="Add new product" />
                </Label>
              )}
            </UploadImageContainer>
          </Column>
        </OptionContainer>
        {isSubmitting ? (
          <ActiveContainer>
            <ActivityIndicator />
          </ActiveContainer>
        ) : (
          <Footer navigation={Navigation.CUSTOM} css="padding: 0; display: flex; justify-content: flex-end;">
            <Button type={Type.TEXT} onClick={() => hideModal()}>
              {i18n.t('labels.cancel')}
            </Button>
            <Button
              disabled={!isValid || isSubmitting || !dirty}
              onClick={() => handleSubmit()}
              htmlType="submit"
              css="width: 101px;"
            >
              {mode === 'create' ? i18n.t('labels.add') : i18n.t('labels.save')}
            </Button>
          </Footer>
        )}
      </Body>
    </BaseModalView>
  );
};

const ProductOptionFormWithFormik = makeFormik<ProductOptionFormInitialProps, FormProps>(ProductOptionForm);
const ProductOptionFormWithApollo = withApolloContext(ProductOptionFormWithFormik);

export default ProductOptionFormWithFormik;

export { ProductOptionFormWithApollo };

export type ProductOptionFormInitialProps = {
  category: IdInput;
  name: string;
  image: any;
  exportCode?: string;
  serviceName: string;
  group: any;
};

type FormProps = {
  mode: FormMode;
  availableGroups: string[];
};

type Props = FormikProps<ProductOptionFormInitialProps> & FormProps;

export const productOptionFormInitialProps: ProductOptionFormInitialProps = {
  category: { id: '' },
  exportCode: '',
  name: '',
  image: null,
  serviceName: '',
  group: ''
};
