import React, { useCallback } from 'react';
import { i18n } from '@/core';
import { required } from '@/libs/validators';
import ActivityIndicator from '@/view/components/elements/ActivityIndicator';
import { FormInput } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { withApolloContext } from '@components/withApolloContext';
import { Field, FormikProps, FieldArray } from 'formik';
import type { FormMode } from './ContractorEditForm/types';
import { IdInput } from '@/interfaces/brokrete';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ICONS from '@/assets/images/icons';
import { colors } from '@/defaultStyles';
import { hideModal } from '@components/modals';
import { FormikTextField } from '@/shared/components/formik/fields';
import BaseModalView, { Body, Footer, Navigation } from '@/shared/components/modal/BaseModalView';
import Button, { Type } from '@/shared/components/buttons/Button';

const ProductDecorateForm: React.FunctionComponent<Props> = ({
  handleSubmit,
  setFieldValue,
  isSubmitting,
  values,
  isValid,
  mode,
  dirty
}) => {
  const convertImgUrl = useCallback((image: string | Blob) => {
    return typeof image !== 'string' ? URL.createObjectURL(image) : image;
  }, []);

  return (
    <BaseModalView title={i18n.t('modals.newDecorates.title')} css="padding: 0px 10px 10px 10px;">
      <Body>
        <Form onSubmit={handleSubmit}>
          {mode === 'create' && <></>}
          <Field hidden name="category.id" id="category.id" component={FormInput} validate={required} />
          <FieldArray
            name="decorates"
            render={({ push, form: { isSubmitting } }) => {
              return (
                <FieldsContainer>
                  <ScrollBoundary>
                    {values.decorates?.length > 0 &&
                      values.decorates?.map((input: any, index: number) => {
                        const isColorFilled = values.decorates[index].color !== '';
                        const isImageFilled = values.decorates[index].image !== null;
                        return (
                          <InputFieldsWrapper key={index}>
                            <InputContainer>
                              <NameInputContainer>
                                <FormikTextField
                                  name={`decorates[${index}].name`}
                                  className="product_decorate_name_input"
                                  label="Texture Name"
                                  placeholder="e.g. “Sand Cayon”"
                                  component={FormInput}
                                  validate={required}
                                  disabled={isSubmitting}
                                />
                              </NameInputContainer>
                              <UploadImageContainer isFilled={isColorFilled}>
                                <Title>{i18n.t('labels.downloadSample')}</Title>
                                {values.decorates[index].image ? (
                                  <PreviewImageContainer>
                                    <Image src={convertImgUrl(values.decorates[index].image)} alt="profile-logo" />
                                    <DeleteLogoButton
                                      type="button"
                                      onClick={() => setFieldValue(`decorates[${index}].image`, null, false)}
                                    >
                                      <FontAwesomeIcon icon={faPlus} size="xs" />
                                    </DeleteLogoButton>
                                  </PreviewImageContainer>
                                ) : (
                                  <Label htmlFor="product-catgeory-image-input" className="product-catgeory-image">
                                    <input
                                      id="product-catgeory-image-input"
                                      name={`decorates[${index}].image`}
                                      type="file"
                                      accept="image/png, image/jpeg"
                                      onChange={(event: React.ChangeEvent<any>) => {
                                        const file: File = event.currentTarget.files[0];
                                        setFieldValue(`decorates[${index}].image`, file, false);
                                      }}
                                    />
                                    <img src={ICONS.icAdd} width="60" height="60" alt="Add new product" />
                                  </Label>
                                )}
                              </UploadImageContainer>
                              <UploadImageContainer isFilled={isImageFilled}>
                                <Title>{i18n.t('labels.addColor')}</Title>
                                {isColorFilled ? (
                                  <PreviewColor color={values.decorates[index].color}>
                                    <DeleteLogoButton
                                      type="button"
                                      onClick={() => setFieldValue(`decorates[${index}].color`, '', false)}
                                    >
                                      <FontAwesomeIcon icon={faPlus} size="xs" />
                                    </DeleteLogoButton>
                                  </PreviewColor>
                                ) : (
                                  <ColorLabel htmlFor="decorate-color-input" isFilled={isImageFilled}>
                                    <Field
                                      id="decorate-color-input"
                                      name={`decorates[${index}].color`}
                                      component={FormInput}
                                      type="color"
                                      disabled={isColorFilled || isSubmitting}
                                      formGroupClassNames="upload_color"
                                    />
                                    <img src={ICONS.icColor} width="60px" height="60px" alt="Add new color" />
                                  </ColorLabel>
                                )}
                              </UploadImageContainer>
                            </InputContainer>
                          </InputFieldsWrapper>
                        );
                      })}
                  </ScrollBoundary>
                  <CreateNewButton onClick={() => push({ color: '' })}>
                    <Add>
                      <img src={ICONS.icAdd} width="20" height="20" alt="Add new decorate" />
                    </Add>
                    <span>{i18n.t('labels.addNew')}</span>
                  </CreateNewButton>
                </FieldsContainer>
              );
            }}
          />

          {isSubmitting ? (
            <ActiveContainer>
              <ActivityIndicator />
            </ActiveContainer>
          ) : (
            <Footer navigation={Navigation.CUSTOM} css="padding: 0; display: flex; justify-content: flex-end;">
              <Button type={Type.TEXT} onClick={() => hideModal()}>
                {i18n.t('labels.cancel')}
              </Button>
              <Button
                disabled={!isValid || isSubmitting || !dirty}
                onClick={() => handleSubmit()}
                htmlType="submit"
                css="width: 101px;"
              >
                {i18n.t('labels.save')}
              </Button>
            </Footer>
          )}
        </Form>
      </Body>
    </BaseModalView>
  );
};

const FieldsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ScrollBoundary = styled.div`
  min-height: 315px;
  max-height: 315px;
  margin-top: 30px;
  width: 100%;
  overflow-y: auto;
  border-radius: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const InputFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  /* height: 60px; */
  margin-bottom: 43px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > p {
    margin-left: 0;
  }
`;

const CreateNewButton = styled.button`
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 40px;
  color: ${colors.ALGAE_GREEN};
  background-color: transparent;
  border: none;
  > span {
    margin-left: 10px;
    height: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
`;

const Add = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.ALGAE_GREEN};
  width: 20px;
  height: 20px;
  border-radius: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  white-space: pre-line;
  color: ${colors.BATTLESHIP_GREY};
`;

const Title = styled.div`
  font-size: 14px;
  min-height: 36px;
  line-height: 1.29;
  letter-spacing: 0.23px;
  white-space: pre-line;
  color: ${colors.BATTLESHIP_GREY};
`;

const PreviewColor = styled.div<{ color: string }>`
  width: 60px;
  height: 60px;
  margin-left: 20px;
  border-radius: 10px;
  background-color: ${props => (props.color ? props.color : 'transparent')};
`;

const NameInputContainer = styled.div`
  width: 230px;
  .product_decorate_name_input {
    > input {
      ::-webkit-input-placeholder {
        opacity: 0.5;
      }
      ::-moz-placeholder {
        opacity: 0.3;
      }
      :-ms-input-placeholder {
        opacity: 0.5;
      }
      :-moz-placeholder {
        opacity: 0.3;
      }
    }
  }
`;

const ColorLabel = styled.label<{ isFilled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-left: 20px;
  border-radius: 10px;
  cursor: pointer;
  & img {
    position: absolute;
    top: 0;
    right: 0;
    opacity: ${props => (props.isFilled ? 0.5 : 1)};
  }
  & input[type='color'] {
    visibility: hidden;
  }
`;

const UploadImageContainer = styled.div<{ isFilled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-top: 16px;
  & label {
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.23px;
    margin-bottom: 0;
    font-weight: normal;
    text-transform: initial;
  }
  & .product-catgeory-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin-left: 20px;
    background-color: ${colors.LIGHT};
    color: ${colors.BATTLESHIP_GREY};
    border: dashed 2px ${colors.LIGHT_PERIWINKLE};
    border-radius: 10px;
    opacity: ${props => (props.isFilled ? 0.5 : 1)};
    & img {
      filter: invert(51%) sepia(2%) saturate(1994%) hue-rotate(191deg) brightness(88%) contrast(90%);
    }
    & input[type='file'] {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  /* &.decorate-color-input-class {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    border-radius: 10px;
    opacity: ${props => (props.isFilled ? 0.5 : 1)};
    & input {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      background-color: transparent;
    }
    & input[type='color'] {
      display: none;
      /* -webkit-appearance: none;
      border: none;
      ::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      ::-webkit-color-swatch {
        border: none;
      }
    }
  } */
`;

const PreviewImageContainer = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  margin-left: 29px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: solid 1px ${colors.EGG_WHITE};
  background-color: ${colors.WHITE};
`;

const DeleteLogoButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: none;
  background-color: black;
  opacity: 40%;
  color: ${colors.WHITE};
  cursor: pointer;
  & svg {
    transform: rotate(45deg);
  }
`;

const ActiveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DecorateFormWithFormik = makeFormik<ProductDecorateFormInitialProps, FormProps>(ProductDecorateForm);
const DecorateFormWithApollo = withApolloContext(DecorateFormWithFormik);

export default DecorateFormWithFormik;

export { DecorateFormWithApollo };

export type ProductDecorateFormInitialProps = {
  category: IdInput;
  decorates: Decorate[];
};

type FormProps = {
  mode: FormMode;
};

type Decorate = {
  color: string;
  image: any;
  name: string;
};

type Props = FormikProps<ProductDecorateFormInitialProps> & FormProps;

export const productDecorateFormInitialProps: ProductDecorateFormInitialProps = {
  category: { id: '' },
  decorates: [
    {
      color: '',
      image: null,
      name: ''
    }
  ]
};
