import { Actions } from './actions';

import reducer, { SettingsState } from './reducers';

export const SettingsActions = {
  settings: Actions
};

export const settingsReducer = {
  settings: reducer
};

export interface SettingsStoreState {
  settings: SettingsState;
}
