import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { colors } from '@/defaultStyles';
import styled from 'styled-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PhotoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 115px;
  height: 115px;
  margin-right: 14px;
  user-select: none;
  cursor: grab;
  z-index: 10;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;

const PreviewImageContainer = styled.div`
  position: relative;
  width: 115px;
  height: 115px;
`;

const Image = styled.img<{ isMainPhoto: boolean }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: solid 2px ${({ isMainPhoto }) => (isMainPhoto ? `${colors.ALGAE_GREEN}` : `${colors.LIGHT_PERIWINKLE}`)};
  background-color: ${colors.WHITE};
`;

const DeleteLogoButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: none;
  background-color: black;
  opacity: 40%;
  color: ${colors.WHITE};
  cursor: pointer;
  z-index: 5;
  & svg {
    transform: rotate(45deg);
    pointer-events: none;
  }
`;

const SortableItem = SortableElement(({ photo, productIndex, remove }: SortableItemProps) => (
  <PhotoContainer className="product-preview-photo">
    <PreviewImageContainer>
      <Image src={photo} isMainPhoto={productIndex === 0} alt="profile-logo" />
      <DeleteLogoButton type="button" onClick={() => remove(productIndex)}>
        <FontAwesomeIcon icon={faPlus} size="xs" />
      </DeleteLogoButton>
    </PreviewImageContainer>
  </PhotoContainer>
));

type SortableItemProps = {
  photo: string;
  productIndex: number;
  [key: string]: any;
};

export default SortableItem;
