// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CountryOption-module__countryOption--SwjDL{display:flex;flex-direction:row;justify-content:flex-start;align-items:center;padding:.25rem .6rem}.CountryOption-module__countryOption--SwjDL:hover{background:rgba(197,197,197,.125)}.CountryOption-module__countryOption--SwjDL :first-child{flex-grow:1;margin:0 .25rem}", "",{"version":3,"sources":["webpack://./src/view/components/forms/settings/ContractorCreateForm/components/CountryOption.module.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBAAA,CACA,oBAAA,CAEA,kDACE,iCAAA,CAGF,yDACE,WAAA,CACA,eAAA","sourcesContent":[".countryOption {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 0.25rem 0.6rem;\n\n  &:hover {\n    background: rgba(197, 197, 197, 0.125);\n  }\n\n  :first-child {\n    flex-grow: 1;\n    margin: 0 0.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countryOption": "CountryOption-module__countryOption--SwjDL"
};
export default ___CSS_LOADER_EXPORT___;
