import gql from 'graphql-tag';

export const orders = gql`
  query contractor($id: ID!, $limit: Int, $skip: Int, $status: [String!]) {
    contractor(id: $id) {
      ordersP(limit: $limit, skip: $skip, status: $status) {
        count
        values {
          id
          adminNotes
          additional
          address {
            display
          }
          city {
            id
            name
            country {
              name
            }
          }
          product {
            id
            name
            permissions {
              options
            }
          }
          plant {
            id
            name
          }
          options {
            id
            name
          }
          prices {
            productType {
              name
            }
            productDecorate {
              name
            }
            option {
              name
            }
            quantity
          }
          contractor {
            id
            owner {
              info {
                avatar
                name
              }
            }
            companyInfo {
              name
            }
          }
          trucksDrivers {
            status
            deliveryTime
            quantity
          }
          quantity
          totalPrice
          currency
          status(detailed: true)
          trucks
          timeBetweenTrucks
          deliveryTime
          timezone
          units
        }
      }
    }
  }
`;
