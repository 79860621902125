import * as Types from '../../../gql/types.generated';

import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserTipQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type UserTipQuery = { __typename?: 'Query', clientTip?: { __typename?: 'PartnerClientTipInstance', id: string, name: string, value?: string | null, details: { __typename?: 'PartnerClientTipDetails', width: string, height: string } } | null };

export type UserTipFragment = { __typename?: 'PartnerClientTipInstance', id: string, name: string, value?: string | null, details: { __typename?: 'PartnerClientTipDetails', width: string, height: string } };

export const UserTipFragmentDoc = gql`
    fragment UserTip on PartnerClientTipInstance {
  id
  name
  value
  details {
    width
    height
  }
}
    `;
export const UserTipDocument = gql`
    query userTip($name: String!) {
  clientTip(name: $name) {
    ...UserTip
  }
}
    ${UserTipFragmentDoc}`;

/**
 * __useUserTipQuery__
 *
 * To run a query within a React component, call `useUserTipQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTipQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUserTipQuery(baseOptions: Apollo.QueryHookOptions<UserTipQuery, UserTipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTipQuery, UserTipQueryVariables>(UserTipDocument, options);
      }
export function useUserTipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTipQuery, UserTipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTipQuery, UserTipQueryVariables>(UserTipDocument, options);
        }
export type UserTipQueryHookResult = ReturnType<typeof useUserTipQuery>;
export type UserTipLazyQueryHookResult = ReturnType<typeof useUserTipLazyQuery>;
export type UserTipQueryResult = Apollo.QueryResult<UserTipQuery, UserTipQueryVariables>;


export function useUserTipRequest() {
    const client = useApolloClient();
    return useCallback<(variables: UserTipQueryVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<UserTipQuery>>>( (variables, options) => {
        return client.query<UserTipQuery>({
            fetchPolicy: 'no-cache',
          ...options,
          query: UserTipDocument,
          variables
        })
    }, []);
}

