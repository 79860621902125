import React, { useCallback, useMemo } from 'react';
import { ProductIndustry } from '@/interfaces/brokrete';
import moment from 'moment';
import { gettext, useGettext, ngettext } from '@cranium/i18n';
import { safeExecute } from '@/libs';
import { useGetClientSettings } from '@/shared/access';

type UseTimeBetweenTrucksOptionsProps = {
  industry: ProductIndustry | undefined | null;
  timeStep?: number;
};

type TextDescription = 'full' | 'short';

/**
 * @deprecated please use useTimeBetweenTrucksOptionsBuilder
 * @param timeBetweenTruck
 * @param textDescription
 */
export const buildTimeBetweenTrucksOptions = (timeBetweenTruck: number[], textDescription: TextDescription = 'full') => {
  return timeBetweenTruck.map(value => {
    const getTime = (value: number) => {
      const _hours = Math.floor(value / 60);
      const _mins = value % 60;
      const _hoursText = ngettext('%(h) hour', '%(h) hours', _hours, { h: _hours });
      const _minsText = ngettext('%(m) min', '%(m) mins', _mins, { m: _mins });
      const text = (
        <>
          {_hours ? _hoursText : null} {_mins ? _minsText : null}
        </>
      );
      return text;
    };
    const label = value ? getTime(value) : gettext('All trucks in the same time');
    return { label, value: value };
  });
};

export const useTimeBetweenTrucksOptionsBuilder = () => {
  const { gettext } = useGettext();
  return useCallback((timeBetweenTruck: number[], isFull: boolean) => {
    return timeBetweenTruck.map(value => {
      const getTime = (value: number) => {
        const time = moment().startOf('day').add(value, 'minutes');
        const h = time.hours();
        const min = time.minutes();
        const hours = h ? gettext('%(value) %(h)%(s)', { value: h, h: isFull ? 'hour' : 'h', s: h > 1 ? 's' : '' }) : '';
        const minutes = min ? gettext('%(value) min', { value: min }) : '';
        return hours + minutes;
      };
      const label = value ? getTime(value) : isFull ? gettext('All trucks at the same time') : gettext('Same');
      return { label, value: value };
    });
  }, []);
};

export const ALL_TRUCKS_AT_THE_SAME_TIME_OPTION = buildTimeBetweenTrucksOptions([0])[0];

const useTimeBetweenTrucksOptions = ({ industry }: UseTimeBetweenTrucksOptionsProps) => {
  const timeBetweenTrucksConfig = useGetClientSettings('orders.trucks.time_between_trucks');
  const timeBetweenTrucksOptions = useMemo(() => {
    if (!industry) {
      return [];
    }

    let values = safeExecute(() => JSON.parse(timeBetweenTrucksConfig[industry]), []);

    return buildTimeBetweenTrucksOptions(values);
  }, [timeBetweenTrucksConfig, industry]);

  return { options: timeBetweenTrucksOptions };
};

export default useTimeBetweenTrucksOptions;
