import { Actions } from './actions';

import reducer, { AuthorizationState } from './reducers';

export const AuthorizationActions = {
  authorization: Actions
};

export const authorizationReducer = {
  authorization: reducer
};

export interface AuthorizationStoreState {
  authorization: AuthorizationState;
}
