import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

type FormInitialProps = {
  lockToModifyTime: string;
};

type Props = FormikProps<FormInitialProps>;

export const activeOrderParamsInitialProps: FormInitialProps = {
  lockToModifyTime: ''
};

const OrderParamsActiveForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  const { gettext } = useGettext();
  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col md={4}>
          <Field
            name={'lockToModifyTime'}
            id={'lockToModifyTime'}
            placeholder={gettext('Time')}
            formText={i18n.t('cards.settings.inMinutes')}
            component={FormInput}
            validate={required}
            type="number"
          />
        </Col>
        <Col>
          <div className="mt-1">
            {i18n.t('cards.settings.orderParams.amountOfTime', {
              status: i18n.t('cards.settings.orderParams.cantModify')
            })}
          </div>
        </Col>
      </Row>
      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(OrderParamsActiveForm);
