import { i18n } from '@/core';
import { FormInputCheckBox } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { Field, FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import { Button, Form } from 'reactstrap';

type FormInitialProps = {
  deliveryTimes: boolean[];
};

type Props = FormikProps<FormInitialProps>;

const deliveryTimesList = ((startTime: string = '06:00', durationInMinutes: number = 30, itemsCount: number = 31) => {
  const startMoment = moment(startTime, 'HH:mm');
  const list = new Map<string, boolean>();

  for (let i = 1; i <= itemsCount; i++) {
    list.set(startMoment.format('H:mm'), false);
    startMoment.add(durationInMinutes, 'minutes');
  }
  return { values: Array.from(list.values()), keys: Array.from(list.keys()) };
})();

export const deliveryTimesFormInitialProps: FormInitialProps = {
  deliveryTimes: deliveryTimesList.values
};

const DeliveryTimesForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="d-flex flex-row justify-content-start align-items-start flex-wrap">
        {deliveryTimesList.keys.map((value, index) => {
          return (
            <div key={value} className="mr-3 w-25 mb-3">
              <Field name={`deliveryTimes[${index}]`} label={value} component={FormInputCheckBox} />
            </div>
          );
        })}
      </div>
      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(DeliveryTimesForm);
