import type { ProductTypeFormInitialProps } from './types';
export const productTypeFormInitialProps: ProductTypeFormInitialProps = {
  product: { id: '', industry: '' },
  name: '',
  exportCode: '',
  serviceName: '',
  group: '',
  groupOptions: null,
  tags: [],
  description: '',
  shortDescription: '',
  settings: [{ name: '', value: '' }],
  image: '',
  descriptionResources: [],
  descriptionImages: [''],
  sortOrder: 0,
  submitAndContinue: false,
  withModification: false,
  modifications: [],
  optionCombinations: [],
  options: {
    label: '',
    values: []
  },
  possibleOptionsValues: [],
  colors: []
};
