import React, { useCallback, useRef, useState, useEffect } from 'react';
import { AsyncSelect, AsyncSelectProps } from '@/shared/components/dataEntry/AsyncSelect';
import { noop } from 'lodash';
import { QueryOptions, useApolloClient } from '@apollo/client';
import { useIsMounted } from '@/view/hooks/useIsMounted';
import useUpdate from '@/view/hooks/useUpdate';
import { gettext } from '@cranium/i18n';
import styled, { css } from 'styled-components';

const StyledSelect = styled(AsyncSelect)`
  ${({ mode }) =>
    mode === 'multiple' &&
    css`
      .ant-select-selector {
        :focus-within {
          .ant-select-selection-placeholder,
          .ant-select-selection-overflow-item label {
            display: none;
          }
        }
        .ant-select-selection-overflow-item + .ant-select-selection-overflow-item:not(:last-of-type) {
          display: none;
        }
      }
    `}

  .ant-select-selection-placeholder {
    color: var(--gray-9) !important;
  }
`;

interface Value {
  label: string;
  value: string;
}
type FetchOptions = (queryResult: any) => Array<Value> | undefined;
interface Props {
  query: (search: string) => QueryOptions<any>;
  fetchOptions: FetchOptions;
  skipUpdateOnInit?: boolean;
  /**
   * Automatically select first option if it's exist.
   * 'always' - always select first option
   * 'singleOptionOnly' - select first option if options length === 1
   */
  selectFirst?: 'always' | 'singleOptionOnly';
  /**
   * Clears selected value if it's missing in fetched options using onChange.
   * This runs only once when component renders (e.g. key update)
   * and only when value is already selected
   */
  clearMissingValue?: boolean;
}

export type AsyncQuerySelectProps = Props & Omit<AsyncSelectProps, 'fetchOptions'>;

export const AsyncQuerySelect: React.FunctionComponent<AsyncQuerySelectProps> = ({
  value,
  onChange = noop,
  query,
  fetchOptions,
  skipUpdateOnInit,
  clearMissingValue,
  ...props
}) => {
  const stateRef = useRef({ value, onChange, fetchOptions, query });
  const isMounted = useIsMounted();
  const client = useApolloClient();
  const { update } = useUpdate();
  Object.assign(stateRef.current, { value, onChange, fetchOptions, query });

  const fetch = useCallback(
    (search: string) => {
      return client.query<any>(stateRef.current.query(search)).then(({ data }) => {
        if (!isMounted.current) return;
        return stateRef.current.fetchOptions(data) ?? [];
      }) as Promise<Array<Value>>;
    },
    [query]
  );

  const clearValueIfNotPresent = async (value: { label: string; value: string }) => {
    if (value) {
      const items = await fetch(value?.label);
      const foundValue = items?.find(v => v?.value === value?.value);
      if (!foundValue) {
        stateRef.current.value = undefined;
        update();
        stateRef.current.onChange(undefined);
      } else {
        if (!skipUpdateOnInit) {
          stateRef.current.onChange(foundValue);
        }
      }
    }
  };

  useEffect(() => {
    if (clearMissingValue && value?.value) {
      clearValueIfNotPresent(value);
    }
  }, []);

  return (
    <StyledSelect
      optionLabelProp="label"
      showSearch
      value={value}
      placeholder={gettext('Select...')}
      fetchOptions={fetch}
      onChange={(newValue, fullInfo) => {
        stateRef.current.value = newValue;
        update();
        stateRef.current.onChange(newValue, fullInfo);
      }}
      tagRender={props.mode === 'multiple' ? () => <Tag>{props.placeholder || gettext('Select...')}</Tag> : props.tagRender}
      {...props}
    />
  );
};

export const StyledSelectFilter = styled(AsyncQuerySelect)`
  min-width: 60px;

  .ant-select-selector {
    border: 0 !important;
    padding: 0 10px !important;
    transition: background-color 0.3s;
    height: 32px;
    :hover:not(:focus-within) {
      background-color: var(--gray-1) !important;
    }
    :focus-within {
      .ant-select-selection-placeholder,
      .ant-select-selection-overflow-item label {
        display: none;
      }
    }
    .ant-select-selection-overflow-item + .ant-select-selection-overflow-item:not(:last-of-type) {
      display: none;
    }
  }

  .ant-select-selection-placeholder {
    color: var(--gray-9) !important;
  }
`;

const Tag = styled.label`
  font: var(--font-regular-14);
  color: var(--gray-9);
  margin-bottom: 0;
`;

function transformValue(value?: AsyncQuerySelectProps['value'], placeholder?: AsyncQuerySelectProps['placeholder']) {
  if (!value || Array.isArray(value)) return value;
  return { ...value, label: placeholder || gettext('Select...') };
}

type AsyncQuerySelectFilter = Omit<AsyncQuerySelectProps, 'value'> & { width?: number };

export const AsyncQuerySelectFilter: React.FunctionComponent<AsyncQuerySelectFilter> = ({
  width,
  defaultValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(transformValue(defaultValue, props.placeholder));
  useEffect(() => {
    setValue(transformValue(defaultValue, props.placeholder));
  }, [defaultValue]);
  const handleChange = useCallback(
    value => {
      if (onChange) {
        onChange(value, value);
      }
      setValue(transformValue(value, props.placeholder));
    },
    [onChange]
  );
  return (
    <StyledSelectFilter
      style={width ? { width: `${width}px` } : undefined}
      {...props}
      value={value}
      onChange={handleChange}
      showArrow
      tagRender={props.mode ? () => <Tag>{props.placeholder || gettext('Select...')}</Tag> : props.tagRender}
    />
  );
};
