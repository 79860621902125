import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';
import { useIsMounted } from '@/view/hooks/useIsMounted';

export interface AsyncSelectProps<ValueType = any> extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  defaultOptions?: ValueType[];
  debounceTimeout?: number;
  /**
   * Fetch options, by default 'onStart'.
   * 'onStart' - Fetches data on component mount.
   * 'onFocus' - Fetches data when clicked on Select component.
   * 'none' - Fetches data after typing in search
   */
  autoFetch?: 'onStart' | 'onFocus' | 'none';
  /**
   * Automatically select first option if it's exist.
   * 'always' - always select first option
   * 'singleOptionOnly' - select first option if options length === 1
   */
  selectFirst?: 'always' | 'singleOptionOnly';
  /**
   * HTML name attribute
   */
  name?: string;
}

/**
 * Antd Async Select
 * @param fetchOptions
 * @param debounceTimeout
 * @param defaultOptions - to have ability show custom label if value (as a plain type) exist but options still not loaded
 * @param props
 * @param autoFetch
 * @param selectFirst
 * @constructor
 */
export function AsyncSelect<ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any>({
  fetchOptions,
  debounceTimeout = 800,
  autoFetch = 'onStart',
  selectFirst,
  defaultOptions,
  ...props
}: AsyncSelectProps) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[] | undefined>();
  const isMounted = useIsMounted();
  const fetchRef = useRef(0);
  const isOpenedRef = useRef(false);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (!isMounted.current) return;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then(newOptions => {
        if (!isMounted.current || fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return (value: string, timeout: number = debounceTimeout) => {
      debounce(loadOptions, timeout)(value);
    };
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    if (autoFetch === 'onStart') debounceFetcher('');
  }, []);

  useEffect(() => {
    if (
      [
        fetchRef.current === 1,
        autoFetch === 'onStart',
        !!selectFirst,
        !isOpenedRef.current,
        props.onChange,
        !!options?.length
      ].some(v => !v)
    )
      return;
    if (options!.length === 1 || selectFirst === 'always') {
      const option = options![0];
      const value = props.labelInValue ? option : option?.value;
      props.onChange!(value, option);
    }
  }, [options]);

  const handleFocusFetch = () => {
    if (autoFetch === 'onFocus' && !options?.length) {
      debounceFetcher('', 100);
    }
  };

  return (
    <Select<ValueType>
      aria-autocomplete="none"
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      onFocus={handleFocusFetch}
      {...props}
      options={options ?? defaultOptions}
      loading={fetching}
      onDropdownVisibleChange={open => {
        isOpenedRef.current = open;
        props.onDropdownVisibleChange && props.onDropdownVisibleChange(open);
      }}
    />
  );
}
