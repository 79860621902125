import { createContext, Dispatch, useContext } from 'react';
import { noop, omit } from 'lodash';
import { Resource } from '@/interfaces/brokrete';

export const defaultState: State = {
  height: 40,
  controlsConfig: undefined,
  items: []
};

export type Item = Omit<Resource, 'fileUrl'> & { name: string; type: 'image' | 'file'; createdAt: string };
export type Callback = (item: Item) => void;

export type ControlsConfig = Partial<{ onOpen: Callback; onDelete: Callback; onDownload: Callback }>;

interface State {
  height: number;
  controlsConfig?: ControlsConfig;
  items: Array<Item>;
}

export type Action =
  | {
      type: 'setPartialState';
      state: Partial<State>;
    }
  | { type: 'addItem'; item: Item }
  | { type: 'removeItem'; id: string };

export const reducer = (state: State, action: Action): State => {
  if (action.type === 'setPartialState') return { ...state, ...action.state };
  else if (action.type === 'addItem') return { ...state, items: addItem(state.items, action.item) };
  else if (action.type === 'removeItem') return { ...state, items: state.items.filter(item => item.id !== action.id) };
  return { ...state, ...omit(action, 'type') };
};

export const FileViewerContext = createContext<[State, Dispatch<Action>]>([{ ...defaultState }, noop]);

export const useFileViewerContext = (): [State, Dispatch<Action>] => useContext(FileViewerContext);

const addItem = (items: Item[], item: Item) => {
  const newItems = [...items];
  const index = newItems.findIndex(value => value.id === item.id);
  if (index === -1) newItems.push(item);
  else newItems[index] = item;

  return newItems;
};
