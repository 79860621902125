import React, { useCallback } from 'react';
import gql from 'graphql-tag';
import { AsyncQuerySelect } from '@/shared/components/dataEntry/AsyncQuerySelect';
import { Typography } from 'antd';
import styled from 'styled-components';
import { usePrefetchPartnerData, useUserData } from '@/shared/access';
import { useRootUserPermissionsQuery } from './query/rootUserPermissions.generated';
import { LocalStorage } from '@/core/Storage';

const Container = styled.div`
  .accountSwitcher {
    min-width: 150px;
  }
`;

const SelectValue = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  align-items: center;
  gap: 8px;

  img {
    width: 24px;
  }
`;

const AccountSwitcher = () => {
  const { data } = useRootUserPermissionsQuery({
    context: {
      skipTokenSave: true,
      headers: {
        Authorization: LocalStorage.getItem('token')
      }
    }
  });
  const prefetchPartnerData = usePrefetchPartnerData();
  const mePartner = useUserData();

  const onPartnerChange = useCallback(
    ({ value }: { value: string }) => {
      if (value !== mePartner?.partner?.id && mePartner?.type) {
        prefetchPartnerData(value, mePartner?.type).then(() => {
          const path = window.location.pathname.replace(mePartner?.partner?.id || '', value);
          window.location.href = path;
        });
      }
    },
    [mePartner]
  );

  const canShowSelect = Boolean(data?.me?.permissionsNew?.superuser);

  const selectLabel = (logo: string | undefined, name: string | undefined) => (
    <SelectValue>
      <img src={logo} alt="logo" />
      <Typography.Text ellipsis>{name || 'N/A'}</Typography.Text>
    </SelectValue>
  );

  return (
    <Container>
      {canShowSelect ? (
        <AsyncQuerySelect
          className="accountSwitcher"
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ maxWidth: 200 }}
          placement="bottomRight"
          bordered={false}
          labelInValue={true}
          defaultValue={{
            value: mePartner?.partner?.id,
            label: selectLabel(mePartner?.partner?.logo || '', mePartner?.partner?.name || '')
          }}
          query={(search: string) => ({
            query: PARTNERS_QUERY,
            fetchPolicy: 'no-cache',
            variables: { limit: 20, search }
          })}
          fetchOptions={data => {
            const mePartnerIncluded = data?.partners.values.find((p: { id: string }) => p.id === mePartner?.partner?.id);
            const partners = mePartnerIncluded ? data?.partners.values : [...data?.partners.values, data?.me?.partner];
            return partners.map(({ id, name, logo }: any) => ({
              label: selectLabel(logo, name),
              value: id
            }));
          }}
          onChange={onPartnerChange}
        />
      ) : null}
    </Container>
  );
};

export default AccountSwitcher;

const PARTNERS_QUERY = gql`
  query partners($limit: Int, $search: String) {
    partners(limit: $limit, search: $search) {
      count
      values {
        id
        name
        logo
        hasApp
        hasCreditAccount
      }
    }
    me {
      partner {
        id
        name
        logo
        hasApp
        hasCreditAccount
      }
    }
  }
`;
