import { i18n } from '@/core';
import { FormInput, FormInputSwitch } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

type FormInitialProps = {
  first: boolean;
  firstValue: string;
  second: boolean;
  secondValue: string;
};

type Props = FormikProps<FormInitialProps>;

export const notificationUserInitialProps: FormInitialProps = {
  first: false,
  firstValue: '',
  second: false,
  secondValue: ''
};

const NotificationUserForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col md={3} className="mt-2">
          <Field
            name="first"
            id="first"
            label={i18n.t('cards.settings.notificationToUser.firstLabel')}
            component={FormInputSwitch}
          />
        </Col>
        <Col md={1}>
          <Field name={`firstValue`} id={`firstValue`} component={FormInput} />
        </Col>
        <Col className="mt-2">{i18n.t('cards.settings.notificationToUser.firstDescription')}</Col>
      </Row>

      <Row form>
        <Col md={3} className="mt-2">
          <Field
            name="second"
            id="second"
            label={i18n.t('cards.settings.notificationToUser.secondLabel')}
            component={FormInputSwitch}
          />
        </Col>
        <Col md={1}>
          <Field name={`secondValue`} id={`secondValue`} component={FormInput} />
        </Col>
        <Col className="mt-2">{i18n.t('cards.settings.notificationToUser.secondDescription')}</Col>
      </Row>

      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(NotificationUserForm);
