import styled from 'styled-components';
import { colors } from '@/defaultStyles';

const TextArea = styled.textarea<{ error: boolean }>`
  min-height: 40px;
  width: 330px;
  padding: 10px 12px;
  border-radius: 4px;
  border: ${props => `solid 1px ${props.error ? colors.PINKISH_RED : colors.LIGHT_PERIWINKLE}`};
  background-color: ${colors.WHITE};
  font-size: 14px;
  line-height: 1.43;
  color: ${colors.CHARCOAL_GREY};
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px rgb(0, 106, 255);
  }
`;

export default TextArea;
