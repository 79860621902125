import * as Types from '../../../../gql/types.generated';

import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderPreparatoryConfigTotalPriceQueryVariables = Types.Exact<{
  contractor?: Types.InputMaybe<Types.IdInput>;
  input?: Types.InputMaybe<Types.OrderPreparatoryConfigInput>;
  order?: Types.InputMaybe<Types.IdInput>;
  partner?: Types.InputMaybe<Types.IdInput>;
  currency?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type OrderPreparatoryConfigTotalPriceQuery = { __typename?: 'Query', orderPreparatoryConfig: { __typename?: 'OrderPreparatoryConfig', totalPrice: { __typename?: 'OrderPreparatoryConfigTotalPrice', value: number, components: Array<{ __typename?: 'OrderPrice', value: number, fee?: { __typename?: 'Fee', id: string } | null }> } } };

export type OrderPreparatoryConfigDefaultFeePriceQueryVariables = Types.Exact<{
  contractor?: Types.InputMaybe<Types.IdInput>;
  input?: Types.InputMaybe<Types.OrderPreparatoryConfigInput>;
  order?: Types.InputMaybe<Types.IdInput>;
  partner?: Types.InputMaybe<Types.IdInput>;
  currency?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type OrderPreparatoryConfigDefaultFeePriceQuery = { __typename?: 'Query', orderPreparatoryConfig: { __typename?: 'OrderPreparatoryConfig', fees: Array<{ __typename?: 'OrderPreparatoryConfigFeePrice', fee: { __typename?: 'Fee', id: string, name: string, type: string }, price: { __typename?: 'Price', id?: string | null, value: number, currency: string } }> } };

export type OrderFeeOrderQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type OrderFeeOrderQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: string, currency: string, contractor: { __typename?: 'Contractor', id: string }, partner?: { __typename?: 'Partner', id: string } | null, prices: Array<{ __typename?: 'OrderPrice', id?: string | null, value: number, fee?: { __typename?: 'Fee', id: string, name: string, type: string } | null, price: { __typename?: 'Price', id?: string | null } }> } };


export const OrderPreparatoryConfigTotalPriceDocument = gql`
    query orderPreparatoryConfigTotalPrice($contractor: IdInput, $input: OrderPreparatoryConfigInput, $order: IdInput, $partner: IdInput, $currency: String) {
  orderPreparatoryConfig(
    contractor: $contractor
    input: $input
    order: $order
    partner: $partner
  ) {
    totalPrice(currency: $currency) {
      components {
        fee {
          id
        }
        value
      }
      value
    }
  }
}
    `;

/**
 * __useOrderPreparatoryConfigTotalPriceQuery__
 *
 * To run a query within a React component, call `useOrderPreparatoryConfigTotalPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderPreparatoryConfigTotalPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderPreparatoryConfigTotalPriceQuery({
 *   variables: {
 *      contractor: // value for 'contractor'
 *      input: // value for 'input'
 *      order: // value for 'order'
 *      partner: // value for 'partner'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useOrderPreparatoryConfigTotalPriceQuery(baseOptions?: Apollo.QueryHookOptions<OrderPreparatoryConfigTotalPriceQuery, OrderPreparatoryConfigTotalPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderPreparatoryConfigTotalPriceQuery, OrderPreparatoryConfigTotalPriceQueryVariables>(OrderPreparatoryConfigTotalPriceDocument, options);
      }
export function useOrderPreparatoryConfigTotalPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderPreparatoryConfigTotalPriceQuery, OrderPreparatoryConfigTotalPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderPreparatoryConfigTotalPriceQuery, OrderPreparatoryConfigTotalPriceQueryVariables>(OrderPreparatoryConfigTotalPriceDocument, options);
        }
export type OrderPreparatoryConfigTotalPriceQueryHookResult = ReturnType<typeof useOrderPreparatoryConfigTotalPriceQuery>;
export type OrderPreparatoryConfigTotalPriceLazyQueryHookResult = ReturnType<typeof useOrderPreparatoryConfigTotalPriceLazyQuery>;
export type OrderPreparatoryConfigTotalPriceQueryResult = Apollo.QueryResult<OrderPreparatoryConfigTotalPriceQuery, OrderPreparatoryConfigTotalPriceQueryVariables>;
export const OrderPreparatoryConfigDefaultFeePriceDocument = gql`
    query orderPreparatoryConfigDefaultFeePrice($contractor: IdInput, $input: OrderPreparatoryConfigInput, $order: IdInput, $partner: IdInput, $currency: String) {
  orderPreparatoryConfig(
    contractor: $contractor
    input: $input
    order: $order
    partner: $partner
  ) {
    fees(currency: $currency, filter: true, mergeWithExisting: true) {
      fee {
        id
        name
        type
      }
      price {
        id
        value
        currency
      }
    }
  }
}
    `;

/**
 * __useOrderPreparatoryConfigDefaultFeePriceQuery__
 *
 * To run a query within a React component, call `useOrderPreparatoryConfigDefaultFeePriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderPreparatoryConfigDefaultFeePriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderPreparatoryConfigDefaultFeePriceQuery({
 *   variables: {
 *      contractor: // value for 'contractor'
 *      input: // value for 'input'
 *      order: // value for 'order'
 *      partner: // value for 'partner'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useOrderPreparatoryConfigDefaultFeePriceQuery(baseOptions?: Apollo.QueryHookOptions<OrderPreparatoryConfigDefaultFeePriceQuery, OrderPreparatoryConfigDefaultFeePriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderPreparatoryConfigDefaultFeePriceQuery, OrderPreparatoryConfigDefaultFeePriceQueryVariables>(OrderPreparatoryConfigDefaultFeePriceDocument, options);
      }
export function useOrderPreparatoryConfigDefaultFeePriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderPreparatoryConfigDefaultFeePriceQuery, OrderPreparatoryConfigDefaultFeePriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderPreparatoryConfigDefaultFeePriceQuery, OrderPreparatoryConfigDefaultFeePriceQueryVariables>(OrderPreparatoryConfigDefaultFeePriceDocument, options);
        }
export type OrderPreparatoryConfigDefaultFeePriceQueryHookResult = ReturnType<typeof useOrderPreparatoryConfigDefaultFeePriceQuery>;
export type OrderPreparatoryConfigDefaultFeePriceLazyQueryHookResult = ReturnType<typeof useOrderPreparatoryConfigDefaultFeePriceLazyQuery>;
export type OrderPreparatoryConfigDefaultFeePriceQueryResult = Apollo.QueryResult<OrderPreparatoryConfigDefaultFeePriceQuery, OrderPreparatoryConfigDefaultFeePriceQueryVariables>;
export const OrderFeeOrderDocument = gql`
    query orderFeeOrder($id: ID) {
  order(id: $id) {
    id
    contractor {
      id
    }
    partner {
      id
    }
    currency
    prices {
      id
      value
      fee {
        id
        name
        type
      }
      price {
        id
      }
    }
  }
}
    `;

/**
 * __useOrderFeeOrderQuery__
 *
 * To run a query within a React component, call `useOrderFeeOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFeeOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFeeOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderFeeOrderQuery(baseOptions?: Apollo.QueryHookOptions<OrderFeeOrderQuery, OrderFeeOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderFeeOrderQuery, OrderFeeOrderQueryVariables>(OrderFeeOrderDocument, options);
      }
export function useOrderFeeOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderFeeOrderQuery, OrderFeeOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderFeeOrderQuery, OrderFeeOrderQueryVariables>(OrderFeeOrderDocument, options);
        }
export type OrderFeeOrderQueryHookResult = ReturnType<typeof useOrderFeeOrderQuery>;
export type OrderFeeOrderLazyQueryHookResult = ReturnType<typeof useOrderFeeOrderLazyQuery>;
export type OrderFeeOrderQueryResult = Apollo.QueryResult<OrderFeeOrderQuery, OrderFeeOrderQueryVariables>;


export function useOrderPreparatoryConfigTotalPriceRequest() {
    const client = useApolloClient();
    return useCallback<(variables: OrderPreparatoryConfigTotalPriceQueryVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<OrderPreparatoryConfigTotalPriceQuery>>>( (variables, options) => {
        return client.query<OrderPreparatoryConfigTotalPriceQuery>({
            fetchPolicy: 'no-cache',
          ...options,
          query: OrderPreparatoryConfigTotalPriceDocument,
          variables
        })
    }, []);
}


export function useOrderPreparatoryConfigDefaultFeePriceRequest() {
    const client = useApolloClient();
    return useCallback<(variables: OrderPreparatoryConfigDefaultFeePriceQueryVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<OrderPreparatoryConfigDefaultFeePriceQuery>>>( (variables, options) => {
        return client.query<OrderPreparatoryConfigDefaultFeePriceQuery>({
            fetchPolicy: 'no-cache',
          ...options,
          query: OrderPreparatoryConfigDefaultFeePriceDocument,
          variables
        })
    }, []);
}


export function useOrderFeeOrderRequest() {
    const client = useApolloClient();
    return useCallback<(variables: OrderFeeOrderQueryVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<OrderFeeOrderQuery>>>( (variables, options) => {
        return client.query<OrderFeeOrderQuery>({
            fetchPolicy: 'no-cache',
          ...options,
          query: OrderFeeOrderDocument,
          variables
        })
    }, []);
}

