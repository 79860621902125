import React from 'react';
import { useField, FieldConfig } from 'formik';
import styled from 'styled-components';
import { startCase } from 'lodash';

import { colors } from '@/defaultStyles';
import { isEmpty } from '@/libs';

import FormGroup from './FormGroup';
import Input from './Input';
import InputError from './InputError';
import Label from './Label';
import { ApolloError } from '@apollo/client';

const Hint = styled.div`
  font-size: 10px;
  color: ${colors.BATTLESHIP_GREY};
`;

interface Props extends FieldConfig {
  className?: string;
  displayErrorMessage?: boolean;
  label?: React.ReactNode;
  hint?: string;
  placeholder?: string;
  disabled?: boolean;
  largeWidth?: boolean;
  noLabel?: boolean;
  maxLength?: number;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: string) => void;
  apolloError?: ApolloError;
  onFocus?: () => void;
  onBlur?: () => void;
  autoComplete?: 'off' | 'on';
}

const FormikTextField: React.FunctionComponent<Props> = ({
  className,
  label,
  hint,
  placeholder,
  displayErrorMessage = true,
  onChange,
  onFocus,
  onBlur,
  type,
  largeWidth,
  noLabel,
  maxLength,
  inputRef,
  apolloError,
  disabled = false,
  autoComplete,
  ...props
}) => {
  const [field, meta] = useField(props);
  const error = (meta.touched && !!meta.error) || !!apolloError;

  const customEventHandlers = {
    ...(onFocus && { onFocus }),
    ...(onBlur && { onBlur })
  };

  return (
    <FormGroup className={className}>
      {noLabel ? <Label></Label> : <Label>{label || startCase(props.name)}</Label>}
      <Input
        {...field}
        {...customEventHandlers}
        ref={inputRef}
        type={type ?? 'text'}
        largeWidth={largeWidth}
        error={error}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        onChange={e => {
          field.onChange(e);
          onChange && onChange(e, props.name);
        }}
        autoComplete={autoComplete}
      />
      {!isEmpty(hint) && <Hint>{hint}</Hint>}
      {displayErrorMessage && <InputError visible={error}>{meta.error}</InputError>}
    </FormGroup>
  );
};

export default FormikTextField;
