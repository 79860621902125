import { faCheck, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { ComponentType } from 'react';
import { OptionProps } from 'react-select';
import styles from './SupplierFieldOption.module.scss';

const SupplierFieldOption: ComponentType<OptionProps<any, boolean>> = (props): JSX.Element => {
  const { innerProps, data, isSelected } = props;
  const optionData: any = data;
  return (
    <div {...innerProps} className={cx(styles.selectOption)}>
      <div>
        <div className={cx(styles.row)}>
          <FontAwesomeIcon icon={faTruck} />
          <span className="font-weight-bold">{optionData.name}</span>
        </div>
      </div>
      {isSelected && <FontAwesomeIcon icon={faCheck} />}
    </div>
  );
};

export default SupplierFieldOption;
