import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import styled, { css } from 'styled-components';
import { SVGButtonAdd, SVGButtonRemove } from '@components/elements/icons';
import { colors } from '@/defaultStyles';

export type FieldArrayControlProps = {
  remove<T>(index: number): T | undefined;
  push: (obj: any) => void;
  data: any[];
  index: number;
  initialValue: any;
  disabled?: boolean;
  keepEmptyRow?: boolean;
  canAdd?: boolean;
  canRemoveLast?: boolean;
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

type IconButtonProps = typeof SVGButtonAdd & {
  [key: string]: any;
  disabled?: boolean;
};

const DefaultStyles = css`
  ${({ disabled }: IconButtonProps) => {
    return disabled
      ? css`
          cursor: default;
          pointer-events: none;
        `
      : css`
          cursor: pointer;
          pointer-events: all;
        `;
  }};

  #button-group {
    #button-border {
      stroke: ${colors.BATTLESHIP_GREY};
      opacity: 0.3;
    }

    #button-icon {
      fill: ${colors.BATTLESHIP_GREY};
      ${({ disabled }: IconButtonProps) => (disabled ? 'opacity: 0.3;' : '')};
    }
  }

  :hover {
    #button-group {
      #button-border {
        stroke: ${colors.BATTLESHIP_GREY};
        opacity: 1;
      }

      #button-icon {
        fill: ${colors.ALGAE_GREEN};
      }
    }
  }
`;

const ButtonPlus = styled<IconButtonProps>(SVGButtonAdd)`
  ${DefaultStyles};
`;

const ButtonMinus = styled<IconButtonProps>(SVGButtonRemove)`
  ${DefaultStyles};
`;

const FieldArrayControlButtons: React.FC<
  FieldArrayControlProps & {
    className?: string;
  }
> = (props): JSX.Element => {
  const { canAdd, canRemoveLast, className, index, data, initialValue, push, remove, disabled, keepEmptyRow } = props;
  const isDisabled = disabled !== undefined ? disabled : false;
  const { validateForm } = useFormikContext();
  const displayButton = canAdd && index === data.length - 1;

  const addEntityCallback = useCallback(() => {
    push(initialValue);
    setTimeout(async () => {
      await validateForm();
    }, 0);
  }, [validateForm, push, initialValue]);

  const removeEntityCallback = useCallback(() => {
    remove(index);
    /* add empty row */
    index === 0 && data.length === 1 && keepEmptyRow && push(initialValue);
  }, [remove, index, initialValue, push, data, keepEmptyRow]);

  return (
    <Root className={className}>
      {(data.length > 1 || canRemoveLast) && <ButtonMinus disabled={isDisabled} onClick={removeEntityCallback} />}
      {displayButton && <ButtonPlus disabled={isDisabled} onClick={addEntityCallback} />}
    </Root>
  );
};

FieldArrayControlButtons.defaultProps = {
  canAdd: true,
  keepEmptyRow: true,
  canRemoveLast: true
};

export default FieldArrayControlButtons;
