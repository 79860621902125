import React, { useMemo } from 'react';

import { icColor, icSample, icList } from './icons';
import Select from '@/shared/components/dataEntry/Select';
import OptionItem from './OptionItem';
import { i18n } from '@/core';
import { useGettext } from '@cranium/i18n';

type DisplayAsSelectProps = {
  value: OptionType | null;
  onChange: (option: OptionType | null) => void;
};

type OptionType = {
  label: string;
  value: string;
  icon: string;
};

export function useGetSelectOptions() {
  const { gettext } = useGettext();
  return useMemo(
    () => [
      {
        label: gettext('List'),
        value: 'list',
        icon: icList
      },
      {
        label: gettext('Color'),
        value: 'color',
        icon: icColor
      },
      {
        label: gettext('Sample (texture, structure, etc.)'),
        value: 'image',
        icon: icSample
      }
    ],
    [gettext]
  );
}

const DisplayAsSelect: React.FC<DisplayAsSelectProps> = ({ onChange, value }) => {
  const options = useGetSelectOptions();
  return (
    <Select
      value={value}
      options={options}
      // @ts-ignore
      onChange={onChange}
      components={{
        Option: OptionItem
        // ValueContainer: ValueContainer
      }}
    />
  );
};

export default DisplayAsSelect;
