import { FC } from 'react';
import { Form } from 'antd';
import { OrderQuery } from '../queries/orderWithConfigQuery.generated';
type DeliveryItems = OrderQuery['order']['trucksDrivers'][0]['deliveryItems'];
import { DNDProductDeliveryConfirmation } from '@/shared/components/modal/DNDProductDeliveryConfirmation';

export const DNDConfirantion: FC<{ setTouched: (touched: boolean) => void }> = ({ children, setTouched }) => {
  const form = Form.useFormInstance<{
    trucks: {
      deliveryItems?: DeliveryItems;
    }[];
    unisignedProducts?: DeliveryItems;
  }>();

  return (
    <DNDProductDeliveryConfirmation
      onChange={value => {
        form.setFields([
          {
            name: value.from.key,
            value: value.from.value,
            touched: true
          },
          {
            name: value.to.key,
            value: value.to.value,
            touched: true
          }
        ]);

        form.setFieldsValue({});
        setTouched(true);
      }}
      getFieldValue={form.getFieldValue}
    >
      {children}
    </DNDProductDeliveryConfirmation>
  );
};
