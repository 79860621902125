import React from 'react';
import cx from 'classnames';
import styles from './PricesFormRow.module.scss';
import { Field } from 'formik';
import { FormInputGroup } from '@components/forms/controls';
import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { useGettext } from '@cranium/i18n';

export const PricesCells: React.FC<{ namePrefix: string }> = ({ namePrefix }): JSX.Element => {
  const { gettext } = useGettext();
  return (
    <>
      <td className={cx(styles.price)}>
        <Field
          id={`${namePrefix}.price`}
          name={`${namePrefix}.price`}
          component={FormInputGroup}
          addonType="append"
          type="number"
          inputGroupText={i18n.t('common.priceSymbol')}
          formGroupClassNames="m-0"
          validate={required}
          placeholder={gettext('00.0')}
          bsSize="sm"
          inputGroupClassName="p-0 pr-2 pl-2"
        />
      </td>
      <td className={cx(styles.specialPrice)}>
        <Field
          id={`${namePrefix}.groupAPrice`}
          name={`${namePrefix}.groupAPrice`}
          component={FormInputGroup}
          addonType="append"
          type="number"
          inputGroupText={i18n.t('common.priceSymbol')}
          placeholder={gettext('00.0')}
          formGroupClassNames="m-0"
          disabled
          bsSize="sm"
          inputGroupClassName="p-0 pr-2 pl-2"
        />
      </td>
      <td className={cx(styles.specialPrice)}>
        <Field
          id={`${namePrefix}.groupBPrice`}
          name={`${namePrefix}.groupBPrice`}
          component={FormInputGroup}
          addonType="append"
          type="number"
          inputGroupText={i18n.t('common.priceSymbol')}
          placeholder={gettext('00.0')}
          formGroupClassNames="m-0"
          disabled
          bsSize="sm"
          inputGroupClassName="p-0 pr-2 pl-2"
        />
      </td>
      <td className={cx(styles.specialPrice)}>
        <Field
          id={`${namePrefix}.groupCPrice`}
          name={`${namePrefix}.groupCPrice`}
          component={FormInputGroup}
          addonType="append"
          type="number"
          inputGroupText={i18n.t('common.priceSymbol')}
          placeholder={gettext('00.0')}
          formGroupClassNames="m-0"
          disabled
          bsSize="sm"
          inputGroupClassName="p-0 pr-2 pl-2"
        />
      </td>
      <td className={cx(styles.specialPrice)}>
        <Field
          id={`${namePrefix}.groupDPrice`}
          name={`${namePrefix}.groupDPrice`}
          component={FormInputGroup}
          addonType="append"
          type="number"
          inputGroupText={i18n.t('common.priceSymbol')}
          placeholder={'00.0'}
          formGroupClassNames="m-0"
          disabled
          bsSize="sm"
          inputGroupClassName="p-0 pr-2 pl-2"
        />
      </td>
    </>
  );
};
