import { PropsWithChildren, FC, useContext, useMemo } from 'react';
import { PermissionsGateProps, PermissionsContext, useHasPermissions } from './Access';
import { Redirect, Route, RouteProps, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { PartnerIdRoute } from '@/shared/navigation/PartnerRoute';
import { AppSkeleton } from '@/shared/access/AppSkeleton';

export interface PermissionsRouteProps extends Omit<PermissionsGateProps, 'fallback'> {
  redirectTo: string;
}

export const PermissionsRoute: FC<PropsWithChildren<PermissionsRouteProps & RouteProps>> = ({
  permissions,
  operator,
  redirectTo,
  ...props
}) => {
  const Component = props.component;
  const param = useParams<{ partnerId?: string }>();
  const { refreshing, permissions: perm } = useContext(PermissionsContext);
  //if isEmpty permissions => user is not logged in
  const _redirectUrl = isEmpty(perm) ? '/login' : param?.partnerId ? `/p-${param.partnerId}${redirectTo}` : redirectTo;
  const component = useMemo(() => {
    return () => <PartnerIdRoute Component={Component} path={props.location?.pathname} />;
  }, [Component]);
  return useHasPermissions(permissions, operator) ? (
    <Route {...props} component={component} />
  ) : (
    <>{refreshing ? <Route {...props} /> : <Redirect to={_redirectUrl} />}</>
  );
};

export const PrivateRoute: FC<PropsWithChildren<RouteProps>> = props => {
  const { refreshing, partner, loading } = useContext(PermissionsContext);
  if (loading) {
    return <AppSkeleton />;
  }
  return partner?.id ? <Route {...props} /> : <>{refreshing ? <Route {...props} /> : <Redirect to="/login" />}</>;
};
