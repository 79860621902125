import gql from 'graphql-tag';
import { productDecorate } from '@/core/gql/fragments';

export const productDecorates = gql`
  query productDecorates($limit: Int, $skip: Int, $supplier: IdInput, $category: IdInput, $root: Boolean) {
    productDecorates(limit: $limit, skip: $skip, category: $category, supplier: $supplier, root: $root) {
      count
      values {
        ...productDecorate
      }
    }
  }
  ${productDecorate}
`;
