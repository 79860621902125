import gql from 'graphql-tag';

export const productTypes = gql`
  query productTypes($contractor: IdInput, $input: OrderPreparatoryConfigInput, $currency: String) {
    orderPreparatoryConfig(contractor: $contractor, input: $input) {
      productTypes(currency: $currency) {
        price {
          id
          currency
          content
          value
        }
        productType {
          id
          name
          image
          parent {
            id
            name
            image
          }
          category {
            name
            industry
          }
          measuredAs
        }
        units
      }
    }
  }
`;
