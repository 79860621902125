export type DeliveryItem = {
  productType: {
    id: string;
  };
  quantity?: number | null;
};

export function mergeData(curr: DeliveryItem, prev?: DeliveryItem[]): DeliveryItem[] {
  if (!prev || !Array.isArray(prev)) {
    return [curr];
  }
  if (prev.findIndex(item => item.productType.id === curr.productType.id) !== -1) {
    return prev.map(item =>
      item.productType.id === curr.productType.id
        ? {
            ...item,
            ...curr,
            quantity: (item?.quantity || 0) + (curr?.quantity || 0)
          }
        : item
    );
  }
  return [...prev, curr];
}
