import React from 'react';
import { PaymentSystem } from '@/interfaces/types';

type ConfigOrderType = {
  [key: string]: {
    create: {
      steps: Array<{ id: string; key?: string; visible?: boolean }>;
    };
    timeBetweenTrucks: Array<number>;
    additionalInfo?: {
      unloadingMethods?: Array<string>;
      projectTypes?: Array<string>;
      desiredSlump?: {
        min: number;
        max: number;
      };
    };
    options: boolean;
    images: boolean;
    trucks: boolean;
    icons: {
      truckToCalendar?: React.JSXElementConstructor<any>;
      truck?: any;
      truckInOrder?: React.JSXElementConstructor<any>;
      truckAddIcon?: React.JSXElementConstructor<any>;
      calendarToTimeSlots?: React.JSXElementConstructor<any>;
    };
  };
};

const Config = {
  order: {
    ready_mix_concrete: {
      create: {
        steps: [
          {
            id: 'address'
          },
          {
            id: 'type',
            key: 'strength'
          },
          {
            id: 'quantity'
          },
          {
            id: 'schedule'
          },
          {
            id: 'availability'
          },
          {
            id: 'options'
          },
          {
            id: 'checkout'
          }
        ]
      },
      timeBetweenTrucks: [0, 15, 30, 45, 60, 90],
      additionalInfo: {
        unloadingMethods: ['wheelBarrow', 'buggyMachinery', 'directPour', 'linePump', 'boomPump', 'other'],
        projectTypes: [
          'footing',
          'wall',
          'column',
          'support',
          'slab',
          'floor',
          'sidewalk',
          'patio',
          'stairs',
          'driveway',
          'pool'
        ],
        desiredSlump: {
          min: 0,
          max: 10
        }
      },
      options: true,
      images: true,
      trucks: true,
      icons: {
        truckToCalendar: undefined,
        truck: undefined,
        truckInOrder: undefined,
        truckAddIcon: undefined,
        calendarToTimeSlots: undefined
      }
    },
    ready_mix_concrete_us: {
      create: {
        steps: [
          {
            id: 'address'
          },
          {
            id: 'type',
            key: 'strength'
          },
          {
            id: 'quantity'
          },
          {
            id: 'schedule'
          },
          {
            id: 'availability'
          },
          {
            id: 'options'
          },
          {
            id: 'checkout'
          }
        ]
      },
      timeBetweenTrucks: [0, 15, 30, 45, 60, 90],
      additionalInfo: {
        unloadingMethods: ['wheelBarrow', 'buggyMachinery', 'directPour', 'linePump', 'boomPump', 'other'],
        projectTypes: [
          'footing',
          'wall',
          'column',
          'support',
          'slab',
          'floor',
          'sidewalk',
          'patio',
          'stairs',
          'driveway',
          'pool'
        ],
        desiredSlump: {
          min: 0,
          max: 10
        }
      },
      options: true,
      images: true,
      trucks: true,
      icons: {
        truckToCalendar: undefined,
        truck: undefined,
        truckInOrder: undefined,
        truckAddIcon: undefined,
        calendarToTimeSlots: undefined
      }
    },
    aggregate_and_stone: {
      create: {
        steps: [
          {
            id: 'address'
          },
          {
            id: 'type'
          },
          {
            id: 'quantity'
          },
          {
            id: 'schedule'
          },
          {
            id: 'availability'
          },
          {
            id: 'checkout'
          }
        ]
      },
      timeBetweenTrucks: [0, 15, 30, 45, 60, 90],
      additionalInfo: {
        unloadingMethods: ['driveway', 'road', 'other']
      },
      options: false,
      images: true,
      trucks: true,
      icons: {
        truckToCalendar: undefined,
        truck: undefined,
        truckInOrder: undefined,
        truckAddIcon: undefined,
        calendarToTimeSlots: undefined
      }
    },
    volumetric_concrete: {
      create: {
        steps: [
          {
            id: 'address'
          },
          {
            id: 'type',
            key: 'strength'
          },
          {
            id: 'quantity'
          },
          {
            id: 'schedule'
          },
          {
            id: 'availability'
          },
          {
            id: 'options'
          },
          {
            id: 'checkout'
          }
        ]
      },
      timeBetweenTrucks: [0, 15, 30, 45, 60, 90],
      additionalInfo: {
        unloadingMethods: ['wheelBarrow', 'buggyMachinery', 'directPour', 'linePump', 'boomPump', 'other'],
        projectTypes: [
          'footing',
          'wall',
          'column',
          'support',
          'slab',
          'floor',
          'sidewalk',
          'patio',
          'stairs',
          'driveway',
          'pool'
        ],
        desiredSlump: {
          min: 0,
          max: 10
        }
      },
      options: true,
      images: true,
      trucks: true,
      icons: {
        truckToCalendar: undefined,
        truck: undefined,
        truckInOrder: undefined,
        truckAddIcon: undefined,
        calendarToTimeSlots: undefined
      }
    },
    related_products: {
      create: {
        steps: [
          {
            id: 'type',
            visible: false
          },
          {
            id: 'summary'
          },
          {
            id: 'address'
          },
          {
            id: 'availability'
          },
          {
            id: 'checkout'
          }
        ]
      },
      timeBetweenTrucks: [0],
      options: false,
      images: true,
      trucks: false,
      icons: {
        calendarToTimeSlots: undefined
      }
    }
  } as ConfigOrderType,
  contractor: {
    types: ['concrete', 'landscape', 'pool', 'general'] as const,
    additionalInfo: [
      {
        id: 'averageVolumeConcretePerYear',
        values: ['0-100 m3/yd', '100-500 m3/yd', '500+ m3/yd']
      },
      {
        id: 'averageVolumeAggregatePerYear',
        values: ['0-100 TO', '100-500 TO', '500+ TO']
      }
    ] as const
  },
  project: {
    types: ['landscapingAndPool', 'residential', 'commercial', 'industrial', 'municipal', 'other'] as const,
    typesRequireDetails: ['other']
  },
  identityTypes: ['contractor', 'driver', 'supplier', 'partner', 'super_admin'] as const,
  experienceRates: ['driver', 'supplier', 'product', 'brokrete'] as const
};

export const clientConfig = {
  core: {
    priceBreakdown: true,
    paymentSystem: PaymentSystem.stripe,
    timeBetweenTrucks: {
      ready_mix_concrete: [0, 15, 30, 45, 60, 90],
      aggregate_and_stone: [0, 15, 30, 45, 60, 90],
      volumetric_concrete: [0, 15, 30, 45, 60, 90],
      related_products: [0],
      service: [0],
      concrete_pumping: [0],
      ceiling_tile: [0]
    },
    calculateTrucksAvailability: true
  }
};

const SUPPORTED_IDENTITY_TYPES = (process.env.REACT_APP_SUPPORTED_IDENTITY_TYPES || '').split(',');
const IDENTITY_TYPES = ['contractor', 'driver', 'supplier', 'partner', 'super_admin'];

export default Config;

export { SUPPORTED_IDENTITY_TYPES, IDENTITY_TYPES };
