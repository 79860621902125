import { PaymentLink } from '@/interfaces/brokrete';
import { isEmpty } from '@/libs';
import ICONS from '@/assets/images/icons';
import { PaymentMethod } from '@/gql/types.generated';

export const getPaymentIcon = (paymentMethod: PaymentMethod | undefined, paymentLink: PaymentLink | undefined = undefined) => {
  if (!isEmpty(paymentLink)) return ICONS.icPaymentLinkXs;
  const { card, provider } = paymentMethod ?? {};

  if (provider === 'card' || provider === 'stripe' || provider === 'eway') {
    const brandLowerCase = card?.brand?.toLocaleLowerCase();
    switch (brandLowerCase) {
      case 'visa':
      // return ICONS.icVisaXs;
      case 'mastercard':
      // return ICONS.icMastercard;
      case 'amex':
      // return ICONS.icAmex;
      case 'dinners':
        // return ICONS.icDinners;
        return ICONS.icCard;
    }
  }

  if (provider === 'billd') {
    return ICONS.icBilldXs;
  }

  if (provider === 'stripe_b2b' || provider === 'stripe_b2b_ca' || provider === 'stripe_b2b_us') {
    return ICONS.icBankXs;
  }

  if (provider === 'flex') {
    return ICONS.icFlexXs;
  }

  if (provider === 'offline') {
    return ICONS.icOffline;
  }

  return ICONS.icCreditAccount;
};
