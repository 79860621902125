import styled, { css } from 'styled-components';

type OptionItemBaseProps = {
  isFocused: boolean;
  isDisabled: boolean;
};

const OptionItemBase = styled.div<OptionItemBaseProps>`
  //height: 30px;
  width: 100%;
  display: flex;
  align-items: center;

  padding: 8px;

  cursor: pointer;

  background-color: ${(props: OptionItemBaseProps) => (props.isFocused ? 'var(--gray-1)' : 'initial')};

  :hover {
    background-color: var(--gray-1);
  }

  ${(props: OptionItemBaseProps) =>
    props.isDisabled
      ? css`
          pointer-events: none;
          opacity: 0.3;
        `
      : ''};
`;

export default OptionItemBase;
