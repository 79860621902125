import type { IdentityTypeEnum } from '@/gql/types.generated';
type Type = IdentityTypeEnum | null | undefined;

function isSupplier(type: Type) {
  return type === 'supplier';
}

function isDriver(type: Type): boolean {
  return type === 'driver';
}

function isAdmin(type: Type): boolean {
  return type === 'super_admin';
}

function isPartner(type: Type): boolean {
  return type === 'partner';
}

export { isAdmin, isDriver, isPartner, isSupplier };
