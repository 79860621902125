import { ApolloLink } from '@apollo/client';
// @ts-ignore
import omitDeep from 'omit-deep-lodash';

const initClearTypeNameLink = () => {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = omitDeep(operation.variables, ['__typename']);
    }
    return forward(operation).map(data => {
      return data;
    });
  });
};

export { initClearTypeNameLink };
