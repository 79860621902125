import { getIn } from 'formik';
import React from 'react';
import { FormGroup, FormText, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import { FormLabel } from './FormLabel';

type InputProps = {
  [key: string]: any;
  id?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  formGroupClassNames?: string;
  formText?: string;
  mask?: string | string[];
  addonType: 'prepend' | 'append';
  inputGroupText: string | number | React.ReactNode;
  inputGroupClassName?: string;
};

const FormInputGroup: React.FC<InputProps> = (props): JSX.Element => {
  const {
    field,
    label,
    placeholder,
    form: { errors: formErrors, touched: formTouched },
    formText,
    formGroupClassNames,
    addonType,
    inputGroupText,
    inputGroupClassName,
    ...restProps
  } = props;

  const { name } = field;
  const { id } = restProps;

  const error = getIn(formErrors, name);
  const touched = getIn(formTouched, name);

  const invalid: boolean = !!(error && touched);
  return (
    <FormGroup className={formGroupClassNames}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <InputGroup>
        {addonType === 'prepend' && (
          <InputGroupAddon addonType={addonType}>
            <InputGroupText className={inputGroupClassName}>{inputGroupText}</InputGroupText>
          </InputGroupAddon>
        )}
        <Input invalid={invalid} placeholder={placeholder} {...field} {...restProps} />
        {addonType === 'append' && (
          <InputGroupAddon addonType={addonType}>
            <InputGroupText className={inputGroupClassName}>{inputGroupText}</InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>

      {formText && <FormText color="muted">{formText}</FormText>}
    </FormGroup>
  );
};

export default FormInputGroup;
