import { ContractorsSearchResponse, ContractorsSearchOption } from '../types';
import { useFormikContext, Field } from 'formik';
import React, { useState, useCallback, useMemo } from 'react';
import { Contractor } from '@/interfaces/brokrete';
import { FormInputAsyncSearchSelect } from '@components/forms/controls';
import gql from 'graphql-tag';
import ContractorOption from './ContractorOption';
import { useGettext } from '@cranium/i18n';

type Props = {
  validate: (value: any) => string | void | Promise<string | void>;
};

const ContractorsSearchField: React.FC<Props> = (props: Props): JSX.Element => {
  const { gettext } = useGettext();
  const { validate } = props;

  const { setFieldValue } = useFormikContext();
  const [search, setSearch] = useState<string | undefined>();

  const contractorInputChange = useCallback(
    (value: string) => {
      setSearch(value);
    },
    [setSearch]
  );

  const dataAdapter = useCallback((responseData: ContractorsSearchResponse) => {
    if (!responseData.contractors) return [];
    return [
      ...responseData.contractors.values.map((value: Contractor) => ({
        value: value.id,
        label: value.info.name,
        id: value.id,
        name: value.info.name,
        phone: value.owner.phone,
        email: value.owner.email
      }))
    ];
  }, []);

  const onContractorSelect = useCallback(
    (value: ContractorsSearchOption) => {
      setFieldValue('name', value.name, false);
      setFieldValue('phone', value.phone, false);
      setFieldValue('email', value.email, false);
    },
    [setFieldValue]
  );

  const queryOptions = useMemo(
    () => ({
      query: CONTRACTORS,
      fetchPolicy: 'no-cache',
      variables: {
        ignoreScope: true,
        search,
        limit: 20
      }
    }),
    [search]
  );

  return (
    <Field
      name="contractor"
      id="contractor"
      placeholder={gettext('Search by name, email or phone')}
      component={FormInputAsyncSearchSelect}
      queryOptions={queryOptions}
      onInputChange={contractorInputChange}
      dataAdapter={dataAdapter}
      onSelectValue={onContractorSelect}
      components={{ Option: ContractorOption }}
      validate={validate}
      formGroupClassNames="mb-0"
    />
  );
};

export default ContractorsSearchField;

/* gql */
const CONTRACTORS = gql`
  query contractors($ignoreScope: Boolean, $limit: Int, $skip: Int, $search: String) {
    contractors(ignoreScope: $ignoreScope, limit: $limit, skip: $skip, search: $search) {
      count
      values {
        id
        info {
          name
        }
        owner {
          email
          phone
        }
      }
    }
  }
`;
