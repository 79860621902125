import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput, FormInputRadios } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

enum WorkingMode {
  TEST_MODE = 'test_mode',
  LIVE_MODE = 'live_mode'
}

type FormInitialProps = {
  testKey: string;
  liveKey: string;
  mode: WorkingMode;
};

type Props = FormikProps<FormInitialProps>;

export const stripeSettingsFormInitialProps: FormInitialProps = {
  testKey: '',
  liveKey: '',
  mode: WorkingMode.TEST_MODE
};

const StripeSettingsForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  const { gettext } = useGettext();
  const radiosList = [
    {
      value: WorkingMode.TEST_MODE,
      label: i18n.t('cards.settings.stripe.testMode')
    },
    {
      value: WorkingMode.LIVE_MODE,
      label: i18n.t('cards.settings.stripe.liveMode')
    }
  ];

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Field name="testKey" id="testKey" placeholder={gettext('Test key')} component={FormInput} validate={required} />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field name="liveKey" id="liveKey" placeholder={gettext('Live key')} component={FormInput} validate={required} />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="mode"
            radios={radiosList}
            placeholder={gettext('Live key')}
            component={FormInputRadios}
            validate={required}
          />
        </Col>
      </Row>

      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(StripeSettingsForm);
