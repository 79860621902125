import createReducer from './../../libs/createReducer';
import { Actions, ActionTypes } from './actions';
import { SUPPORTED_IDENTITY_TYPES } from '@/config';

export type SettingsState = {
  identityType: string | null;
};

const initialState: SettingsState = {
  identityType: null
};

export default createReducer(initialState, {
  //eslint-disable-next-line no-useless-computed-key
  ['persist/REHYDRATE'](state, action: any) {
    // If stored type is not supported at the moment, initialize the store with identityType as null
    // So that user could not have possibility to login
    // At the moment stored identityType is used only in SignInFormWizard that's why that action should not affect the hole application
    if (action.payload && !SUPPORTED_IDENTITY_TYPES.includes(action.payload.settings.identityType)) {
      action.payload.settings.identityType = null;
    }

    return state;
  },
  [ActionTypes.UPDATE](state, action: ReturnType<typeof Actions.update>) {
    const { payload } = action;

    return {
      ...state,
      ...payload
    };
  }
});
