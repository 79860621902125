import styled from 'styled-components';
import { colors } from '@/defaultStyles';

const InputError = styled.div<{ visible?: boolean }>`
  display: ${props => (props.visible ? 'block' : 'none')};
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${colors.PINKISH_RED};
`;

export default InputError;
