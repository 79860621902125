import { ProductCategory } from '@/interfaces/brokrete';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { ApolloQueryResult, useQuery } from '@apollo/client';

export const usePlantCategories = (
  plantId: string
): [ProductCategory[] | undefined, boolean, (variables?: any) => Promise<ApolloQueryResult<any>>] => {
  const [products, setProducts] = useState<ProductCategory[]>();

  const { data, loading, refetch } = useQuery(PLANT_CATEGORIES_QUERY, {
    variables: {
      id: plantId
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data) {
      const {
        plant: { productCategories }
      } = data;
      setProducts([...productCategories]);
    }
  }, [setProducts, data]);

  return [products, loading, refetch];
};

export const PLANT_CATEGORIES_QUERY = gql`
  query plant($id: ID!) {
    plant(id: $id) {
      productCategories {
        id
        image
        industry
        industryColor
        name
        permissions {
          decorates
          deliveryFees
          fullEdit
          options
          productColors
          productModifications
          seasonalFees
          trucks
          underloadFees
          washoutFees
        }
        root {
          id
          name
        }
        serviceName
        sortOrder
      }
    }
  }
`;
