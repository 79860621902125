import React from 'react';
import styled from 'styled-components';
import { CloseButton } from '@components/elements/buttons';

type Props = {
  item: string;
  onRemove?: () => void;
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin: 0 10px 2px 0;
  padding: 7px 12px 7px 17px;
  border-radius: 16px;
  border: solid 1px #dcdcdf;
  font-size: 14px;

  cursor: pointer;
`;

const Label = styled.div``;

const Tag: React.FunctionComponent<Props> = (props: Props) => {
  const { item, onRemove } = props;

  return (
    <Root>
      <Label>{item}</Label>
      <CloseButton onClick={onRemove} />
    </Root>
  );
};

export default Tag;
