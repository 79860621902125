import { i18n } from '@/core';
import { faCaretSquareDown, faCaretSquareUp } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle, faBrush, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ProductTypeFormPrice, ProductTypePricesFormInitialProps } from '../types';
import { PricesCells } from './PricesCells';
import styles from './ProductPriceRow.module.scss';

type ProductPriceRowProps = {
  namePrefix: string;
};

type ComponentState = {
  withModification: boolean;
  opened: boolean;
};

export const ProductPriceRow: React.FC<ProductPriceRowProps> = props => {
  const { namePrefix } = props;
  const { values } = useFormikContext<ProductTypePricesFormInitialProps>();

  const [state, setState] = useState<ComponentState>({ withModification: false, opened: false });

  const optionsPrices = useMemo(() => {
    return (get(values, namePrefix) as ProductTypeFormPrice).optionsPrices;
  }, [values, namePrefix]);

  const colorsPrices = useMemo(() => {
    return (get(values, namePrefix) as ProductTypeFormPrice).decoratePrices;
  }, [values, namePrefix]);

  useEffect(
    () =>
      setState(() => ({
        opened: !!optionsPrices.length || !!colorsPrices.length,
        withModification: !!optionsPrices.length || !!colorsPrices.length
      })),
    [optionsPrices, colorsPrices, setState]
  );

  return (
    <>
      <tr className={cx(styles.mainRow)}>
        <td colSpan={state.withModification ? 6 : 1}>
          <div className="d-flex flex-row align-items-center">
            {/* toggle button */}
            {state.withModification && (
              <FontAwesomeIcon
                onClick={() => setState(prev => ({ ...prev, opened: !state.opened }))}
                icon={state.opened ? faCaretSquareUp : faCaretSquareDown}
                className={cx(styles.collapseIcon)}
              />
            )}
            {/* product type name */}
            <span className={styles.productName}>{`${get(values, namePrefix).name}`}</span>
            {state.withModification && (
              <span className={cx(styles.legend, 'text-info')}>
                <FontAwesomeIcon icon={faInfoCircle} /> {`${i18n.t('forms.productType.modification.label')}`}
              </span>
            )}
          </div>
        </td>
        {!state.withModification && <PricesCells namePrefix={namePrefix} />}
      </tr>
      {/* render options prices rows */}
      {state.withModification && state.opened && (
        <>
          {!!optionsPrices.length && !!colorsPrices.length && (
            <tr className={cx(styles.titleRow)}>
              <td colSpan={6}>
                <FontAwesomeIcon icon={faList} />
                {i18n.t('labels.modifications')}
              </td>
            </tr>
          )}
          {optionsPrices.map((priceEntity, index) => (
            <tr key={priceEntity.name} className={cx(styles.optionsRow)}>
              <td colSpan={1} className="text-left">
                <div className="d-flex flex-row align-items-center">
                  <div>{`${priceEntity.name}`}</div>
                </div>
              </td>
              <PricesCells namePrefix={`${namePrefix}.optionsPrices[${index}]`} />
            </tr>
          ))}
          {!!colorsPrices.length && !!optionsPrices.length && (
            <tr className={cx(styles.titleRow)}>
              <td colSpan={6}>
                <FontAwesomeIcon icon={faBrush} />
                {i18n.t('labels.colors')}
              </td>
            </tr>
          )}
          {colorsPrices.map((priceEntity, index) => (
            <tr key={priceEntity.name} className={cx(styles.optionsRow)}>
              <td colSpan={1} className="text-left">
                <div className="d-flex flex-row align-items-center">
                  <div>{`${priceEntity.name}`}</div>
                </div>
              </td>
              <PricesCells namePrefix={`${namePrefix}.decoratePrices[${index}]`} />
            </tr>
          ))}
        </>
      )}
    </>
  );
};
