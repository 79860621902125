import { colors } from '@/defaultStyles';

const selectStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isHovered || state.isSelected ? `${colors.EGG_WHITE}` : `${colors.WHITE}`,
    color: `${colors.CHARCOAL_GREY}`,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: `${colors.EGG_WHITE}`,
      cursor: 'pointer'
    }
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    position: 'initial'
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none'
  }),
  control: (provided: any) => ({
    ...provided,
    borderColor: `${colors.LIGHT_PERIWINKLE}`,
    height: '40px'
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: '100'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '14px'
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '14px'
  })
};

const SelectStyles = {
  default: selectStyles
};

export { SelectStyles };
