import { isEmpty } from '@/libs';

export function checkIfFeeIsEmpty<T>(values: T[]) {
  return (
    values.length === 1 &&
    Object.values(values[0]).every(item => {
      return isEmpty(item);
    })
  );
}
