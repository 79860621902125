import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';

export type BaseModalProps = {
  title?: string;
  isOpen: boolean;
  width?: number;
  onClosed?: () => void;
};

const Title = styled.div`
  padding: 30px 20px 0 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
`;

const ModalBodyStyled = styled(ModalBody)`
  position: relative;
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const BaseModal: React.FC<BaseModalProps> = ({ isOpen, title, children, width = 500, onClosed }) => {
  return (
    <Modal isOpen={isOpen} backdrop="static" onClosed={onClosed} keyboard={false} centered className="shadow-none">
      {title && <Title>{title}</Title>}
      <ModalBodyStyled>{children}</ModalBodyStyled>
    </Modal>
  );
};

export default BaseModal;
