import { FC, useMemo } from 'react';
import type { OrderQuery } from '../queries/orderWithConfigQuery.generated';
import { useDroppable } from '@dnd-kit/core';
import styled from 'styled-components';
import { Product } from '@/shared/components/modal/DNDProductDeliveryConfirmation';
import { gettext } from '@cranium/i18n';
type DeliveryItems = OrderQuery['order']['trucksDrivers'][0]['deliveryItems'];

type UnisignedProductsProps = {
  value?: DeliveryItems;
  errors?: string[] | null;
};
export const UnisignedProducts: FC<UnisignedProductsProps> = ({ value, errors }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: `unisigned`
  });
  const hasProducts = useMemo(() => {
    return Array.isArray(value) && value.filter(({ quantity }) => quantity).length;
  }, [value]);
  return (
    <Main ref={setNodeRef} empty={!hasProducts} hasError={Boolean(errors && errors.length)} isOver={isOver}>
      {value?.map(pr => {
        return <Product key={pr.id} {...pr} index="unisigned" />;
      })}
      {hasProducts ? null : <Info>{gettext('Drag products here to unassign')}</Info>}
    </Main>
  );
};

const Info = styled.div`
  display: flex;
  flex: 1;
  color: var(--grey-7);
  font: var(--font-regular-14);
`;

const Main = styled.div<{ empty: boolean; hasError: boolean; isOver?: boolean }>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
  flex-direction: column;
  min-width: 156px;
  max-width: 156px;
  background-color: var(--brand-surface-lihgt);
  border: 1px dashed var(--brand-boarder);
  padding: 4px 8px ;
  border-radius: 2px;
  transition: background-color 0.3s, border-color 0.3s;
  ${({ empty }) =>
    empty
      ? `
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--gray-5);
    border-radius: 4px;
  `
      : ''}

  ${({ hasError }) => (hasError ? 'border: 2px solid var(--red-6); border-radius: 4px;' : '')}
  ${({ isOver }) =>
    isOver
      ? `
     background-color: var(--green-1);
     border-color: var(--green-7);
  `
      : ''}}
`;
