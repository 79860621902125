import { useGettext } from '@cranium/i18n';
import { useMemo } from 'react';

export function useFormContentOptions() {
  const { gettext } = useGettext();

  return useMemo(
    () => [
      { value: 'quantity', data: 'unit', label: gettext('per Quantity') },
      { value: 'load', data: 'load', label: gettext('per Load') },
      { value: 'fixed', data: 'onetime', label: gettext('Fixed') },

      { value: 'quantity<br/>formula', data: 'unitF', label: gettext('per Quantity + Formula') },
      { value: 'load<br/>formula', data: 'loadF', label: gettext('per Load + Formula') },
      { value: 'fixed<br/>formula', data: 'onetimeF', label: gettext('Fixed + Formula') },

      // This is custom options for related products with rolls/sqft functionality
      { value: 'roll', data: 'roll', label: gettext('per Roll') },
      { value: 'unit-roll', data: 'unit-roll', label: gettext('per Unit') }
    ],
    [gettext]
  );
}
