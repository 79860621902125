import React from 'react';

import styled from 'styled-components';
import { Popover } from 'antd';
import { TipItem } from './components';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { useGetClientSettings } from '@/shared/access';

type UserTipProps = {
  name?: string;
  placement?: TooltipPlacement;
  offset?: {
    x?: number;
    y?: number;
  };
};

const Container = styled.div<{ offsetX?: number; offsetY?: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  .user-tip {
    z-index: 9998;
  }

  .ant-icon {
    color: var(--gray-6) !important;
    margin-top: ${({ offsetY = 0 }) => `${offsetY}px`};
    margin-left: ${({ offsetX = 0 }) => `${offsetX}px`};
  }
`;

export const UserTip: React.FC<UserTipProps> = ({ name, offset, placement, children }) => {
  const clientTipsAvailable = useGetClientSettings('client_tips.visible');

  if (!name || !clientTipsAvailable) {
    return <>{children}</>;
  }

  return (
    <Container offsetX={offset?.x} offsetY={offset?.y}>
      {children}

      <Popover
        className="user-tip"
        placement={placement}
        content={<TipItem name={name} />}
        destroyTooltipOnHide
        mouseEnterDelay={1}
        zIndex={9999}
      >
        <span className="ant-icon regular-18 icon-info-circle1 gray-6 cursor-help" />
      </Popover>
    </Container>
  );
};
