import React, { useRef, useEffect } from 'react';
import { usePaintSquare } from './hooks';
import styled from 'styled-components';

type PickerSLSquareProps = {
  hue: number;
  squareXY: number[];
  colour: string | undefined;
  setColour: (colour: string) => void;
  setSquareXY: (squareXY: number[]) => void;
};

const ColourPickerSLSquare: React.FunctionComponent<PickerSLSquareProps> = props => {
  const { hue, squareXY, colour, setColour, setSquareXY } = props;

  const square = useRef<any>(null);
  const canvas = useRef<any>(null);

  usePaintSquare(canvas, hue);

  useEffect(() => {
    const canvasRef = canvas.current;
    const ctx = canvasRef.getContext('2d');

    const computePosition = (e: MouseEvent) => {
      let { x, y } = canvasRef.getBoundingClientRect();

      x = Math.max(-2, Math.min(e.clientX - x - 12, 198));
      y = Math.max(-3, Math.min(e.clientY - y - 10, 198));

      return [x, y];
    };

    const changeColor = (e: MouseEvent) => {
      const [x, y] = computePosition(e);
      const x1 = Math.min(x + 2, 219);
      const y1 = Math.min(y + 3, 219);
      const [r, g, b] = ctx.getImageData(x1, y1, 1, 1).data;

      setColour(`rgb(${r},${g},${b})`);
      setSquareXY([x1, y1]);
    };

    const onMouseMove = (e: MouseEvent) => {
      changeColor(e);
    };

    const onMouseUp = (e: MouseEvent) => {
      changeColor(e);
      document.body.removeEventListener('mousemove', onMouseMove);
      document.body.removeEventListener('mouseup', onMouseUp);
    };

    const onMouseDown = () => {
      document.body.addEventListener('mousemove', onMouseMove);
      document.body.addEventListener('mouseup', onMouseUp);
    };

    canvasRef.addEventListener('mousedown', onMouseDown);

    return () => {
      canvasRef.removeEventListener('mousedown', onMouseDown);
    };
  }, [setSquareXY, setColour]);

  useEffect(() => {
    const canvasRef = canvas.current;
    const ctx = canvasRef.getContext('2d');
    const [x, y] = squareXY;
    const [r, g, b] = ctx.getImageData(x, y, 1, 1).data;

    setColour(`rgb(${r},${g},${b})`);
  }, [hue, setColour, squareXY]);

  return (
    <PickerSLSquareContainer ref={square}>
      <PickerSLSquareHandle top={squareXY[1]} left={squareXY[0]}>
        <PickerSLSquareHandleSvg id="picker-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle id="picker-circle" cx="50" cy="50" r="45" stroke="white" strokeWidth="10" fill={colour} />
        </PickerSLSquareHandleSvg>
      </PickerSLSquareHandle>
      <PickerSLSquareCanvas id="picker-canvas" ref={canvas} />
    </PickerSLSquareContainer>
  );
};

type PickerRefProps = {
  ref: any;
};

const PickerSLSquareContainer = styled.div<PickerRefProps>`
  position: relative;
  width: 220px;
  height: 220px;
  cursor: pointer;
`;

const PickerSLSquareCanvas = styled.canvas.attrs(() => ({
  width: '220px',
  height: '220px'
}))<PickerRefProps>``;

type PickerSLSquareHandleProps = {
  top: number;
  left: number;
};

const PickerSLSquareHandle = styled.div.attrs<PickerSLSquareHandleProps>((props: PickerSLSquareHandleProps) => ({
  style: {
    top: `${props.top}px`,
    left: `${props.left}px`
  }
}))<PickerSLSquareHandleProps>`
  position: absolute;
  display: grid;
  justify-items: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;

const PickerSLSquareHandleSvg = styled.svg`
  width: 100%;
  height: 100%;
  z-index: 3;
  -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
`;

export default ColourPickerSLSquare;
