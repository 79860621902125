import React from 'react';
import styled from 'styled-components';
import { colors } from '@/defaultStyles';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ToolTipContainer = styled.div<{ css?: string }>`
  position: relative;
  display: inline-block;
  margin-left: 5px;
  ${props => props.css && props.css};

  & > .tool-tip-icon:hover ~ .tool-tip-description {
    visibility: visible;
    opacity: 0.95;
  }
`;

const ToolTipDescription = styled.div<DescriptionProps>`
  visibility: hidden;
  position: absolute;
  width: ${({ descriptionWidth }: DescriptionProps) => descriptionWidth + 'px'};
  font-weight: normal;
  opacity: 0;
  border-radius: 6px;
  padding: 10px;
  z-index: 1;
  white-space: pre-wrap;
  text-transform: initial;
  background-color: ${colors.LIGHT};
  color: ${colors.CHARCOAL_GREY};
  transition: opacity 0.3s ease-in-out;
  ${({ position, size }: DescriptionProps) => {
    if (position === 'top-right') {
      return `top: -${size}px; left: ${size}px;`;
    } else if (position === 'top-left') {
      return `top: -${size}px; right: ${size}px;`;
    } else if (position === 'bottom-left') {
      return `top: ${size}px; right: ${size}px;`;
    } else {
      return `top: ${size}px; left: ${size}px;`;
    }
  }}
`;

const ToolTipIcon = styled.div<IconProps>`
  height: ${({ size }: IconProps) => size + 'px'};
  width: ${({ size }: IconProps) => size + 'px'};
  border-radius: 100%;
  border: 1px solid ${({ primaryColor }: IconProps) => primaryColor};
  background-color: ${({ primaryColor }: IconProps) => primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

type IconProps = {
  primaryColor: string;
  size: number;
};

type PositionTypes = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

type DescriptionProps = {
  descriptionWidth: number;
  position: PositionTypes;
  size: number;
};

type ToolTipProps = {
  description: React.ReactNode;
  descriptionWidth?: number;
  className?: string;
  primaryColor?: string;
  iconColor?: string;
  iconSize?: 'xs' | 'sm';
  size?: number;
  css?: string;
  position?: PositionTypes;
};

const ToolTip: React.FunctionComponent<ToolTipProps> = ({
  description,
  descriptionWidth = 100,
  className,
  css,
  primaryColor = colors.CLOUDY_BLUE,
  iconColor = colors.WHITE,
  iconSize = 'xs',
  position = 'top-right',
  size = 15
}) => (
  <ToolTipContainer className={className} css={css}>
    <ToolTipIcon className="tool-tip-icon" size={size} primaryColor={primaryColor}>
      <FontAwesomeIcon icon={faInfo} color={iconColor} size={iconSize} />
    </ToolTipIcon>
    <ToolTipDescription className="tool-tip-description" descriptionWidth={descriptionWidth} position={position} size={size}>
      {description}
    </ToolTipDescription>
  </ToolTipContainer>
);

export default ToolTip;
