import { isEmpty } from '@/libs';
import { encode } from 'js-base64';
import { ApolloLink } from '@apollo/client';
import { getAccountfromUrl } from '@/core/getAccountfromUrl';
import { LocalStorage } from '@/core/Storage';

const getClientInfo = (language: string) => {
  return encode(
    JSON.stringify({
      version: {
        platform: 'web-admin',
        version: '1.0',
        build: 1
      },
      name: 'BrokreteWebAdmin',
      language
    })
  );
};

const initAuthLink = () => {
  return new ApolloLink((operation, forward) => {
    let URLAccount = getAccountfromUrl(window.location.pathname);
    if (operation?.operationName === 'getParnterToken') {
      //skip save token in case it is a follow up access request
      URLAccount = null;
    }
    const useRefreshToken = (operation.variables || {}).useRefreshToken;
    const token = LocalStorage.getItem(['token', URLAccount].filter(Boolean).join('-'));
    operation.setContext(({ headers = {}, skipTokenSave = false }) => {
      return {
        headers: {
          Authorization: token || LocalStorage.getItem('token'),
          ...headers,
          ClientInfo: getClientInfo(LocalStorage.getItem('lang') || 'en'),
          ...(useRefreshToken
            ? {
                'x-refresh-token': LocalStorage.getItem(['refresh-token', URLAccount].filter(Boolean).join('-'))
              }
            : {})
        }
      };
    });

    return forward(operation).map(response => {
      const context = operation.getContext();
      const {
        response: { headers }
      } = context;
      if (headers) {
        if (context.skipTokenSave) {
          //skip save token in case it is a follow up access request
          return response;
        }
        const authorizationHeader = headers.get('authorization');
        const refreshToken = headers.get('x-refresh-token');
        // if it is signIn (login)  set accountID to null so that it will be saved under token key in storage
        const accountID = operation?.operationName === 'signIn' ? null : response?.data?.authSignIn?.partner?.id || URLAccount;
        if (!isEmpty(authorizationHeader)) {
          LocalStorage.setItem(['token', accountID].filter(Boolean).join('-'), authorizationHeader);
        }

        if (!isEmpty(refreshToken)) {
          LocalStorage.setItem(['refresh-token', accountID].filter(Boolean).join('-'), refreshToken);
        }
      }

      return response;
    });
  });
};

export { initAuthLink };
