import { DropdownMenu } from '@/shared/components/navigation/DropdownButton';
import { i18n } from '@/core';
import { useMemo } from 'react';
import { useGettext } from '@cranium/i18n';

export enum DropdownKeysEnum {
  withoutNotification = 'withoutNotification',
  withNotification = 'withNotification',
  askApprove = 'askApprove'
}

export function useDropdownButtonMenu() {
  const { gettext } = useGettext();
  return useMemo(() => {
    const list: DropdownMenu[] = [
      {
        title: gettext(`Save / Without Notify`),
        label: gettext(`Save Without Notify`),
        description: gettext(`No contractor notification sent`),
        key: DropdownKeysEnum.withoutNotification,
        default: true
      },

      {
        title: gettext(`Save / With Notify`),
        label: gettext(`Save With Notify`),
        description: gettext(`Contractor notification sent`),
        key: DropdownKeysEnum.withNotification
      },
      {
        title: gettext(`Save / Ask for Approval`),
        label: gettext(`Ask for Approval`),
        description: gettext(`Ask contractor to approve changes`),
        key: DropdownKeysEnum.askApprove
      }
    ];
    return list;
  }, [gettext]);
}
