import { FC } from 'react';
import { Skeleton } from 'antd';
import { DotChartOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const AppSkeleton: FC = () => {
  return (
    <Main>
      <SideBar>
        <Logo active />
        <SideBarItem>
          <Skeleton.Avatar active size="small" shape="circle" />
          <Skeleton.Input active size="small" />
        </SideBarItem>
        <SideBarItem>
          <Skeleton.Avatar active size="small" shape="circle" />
          <Skeleton.Input active size="small" />
        </SideBarItem>
        <SideBarItem>
          <Skeleton.Avatar active size="small" shape="circle" />
          <Skeleton.Input active size="small" />
        </SideBarItem>
        <SideBarItem>
          <Skeleton.Avatar active size="small" shape="circle" />
          <Skeleton.Input active size="small" />
        </SideBarItem>
        <SideBarItem>
          <Skeleton.Avatar active size="small" shape="circle" />
          <Skeleton.Input active size="small" />
        </SideBarItem>
        <SideBarItem>
          <Skeleton.Avatar active size="small" shape="circle" />
          <Skeleton.Input active size="small" />
        </SideBarItem>
        <SideBarItem>
          <Skeleton.Avatar active size="small" shape="circle" />
          <Skeleton.Input active size="small" />
        </SideBarItem>
      </SideBar>
      <Content>
        <Header>
          <Skeleton.Input active size="small" />
          <SideBarItem>
            <Skeleton.Avatar active size="small" shape="circle" />
            <Skeleton.Input active size="small" />
          </SideBarItem>
        </Header>
        <App>
          <div>
            <Skeleton avatar paragraph={{ rows: 3 }} />
            <Skeleton avatar paragraph={{ rows: 3 }} />
            <Skeleton avatar paragraph={{ rows: 3 }} />
          </div>
          <div>
            <Map active />
          </div>
        </App>
      </Content>
    </Main>
  );
};

const Map = styled(Skeleton.Node)`
  width: 100% !important;
  height: 100% !important;
`;

const App = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  > div {
    flex: 1;
  }
`;

const SideBarItem = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Logo = styled(Skeleton.Image)`
  align-self: stretch;
  width: 200px !important;
  height: 40px !important;
  margin: 10px auto;

  svg {
    width: 30px !important;
    height: 30px !important;
  }
`;
const Main = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const SideBar = styled.aside`
  width: 240px;
  box-shadow: rgba(38, 42, 54, 0.08) 0px 0px 5px 1px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Content = styled.div`
  flex: 1;
`;

const Header = styled.header`
  flex: 1;
  height: 60px;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02),
    0 2px 4px 0 rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;
