import { FC, useState } from 'react';
import { Modal, InputNumber, Form } from 'antd';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';
import { gettext } from '@cranium/i18n';
import { DeliveryItem, mergeData } from './mergeData';
import styled from 'styled-components';

type ChangeValue = {
  from: { value: DeliveryItem[] | undefined; key: string | (string | number)[] };
  to: { value: DeliveryItem[] | undefined; key: string | (string | number)[] };
};
type DNDProductDeliveryConfirmationProps = {
  onChange: (value: ChangeValue) => void;
  getFieldValue: (key: string | (string | number)[]) => DeliveryItem[] | undefined;
};

type DataType = DeliveryItem & { index: 'unisigned' | number };

export const DNDProductDeliveryConfirmation: FC<DNDProductDeliveryConfirmationProps> = ({
  children,
  onChange,
  getFieldValue
}) => {
  const [eventData, setEventData] = useState<DragEndEvent | undefined>(undefined);
  const [value, setValue] = useState<number | null>(null);
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={event => {
        if (`${event?.over?.id}` === `${event?.active?.data?.current?.index}` || event?.over?.id === undefined) {
          return;
        }
        setEventData(event);
        setValue(event.active?.data?.current?.quantity);
      }}
    >
      {children}
      <Modal
        open={Boolean(eventData)}
        title={gettext('Please select the amount')}
        destroyOnClose
        onCancel={() => {
          setEventData(undefined);
          setValue(null);
        }}
        onOk={async () => {
          if (!value) return;
          const { index: from, ...dragItem } = (eventData?.active?.data?.current || {}) as DataType;
          const to = eventData?.over?.id;
          const fromFormItemkey = from === 'unisigned' ? ['unisignedProducts'] : ['trucks', from, 'deliveryItems'];
          const toFormItemkey = to === 'unisigned' ? ['unisignedProducts'] : ['trucks', to + '', 'deliveryItems'];

          onChange({
            from: {
              key: fromFormItemkey,
              value: mergeData({ ...dragItem, quantity: -value }, getFieldValue(fromFormItemkey))
            },
            to: {
              key: toFormItemkey,
              value: mergeData({ ...dragItem, quantity: value }, getFieldValue(toFormItemkey))
            }
          });
          setEventData(undefined);
          setValue(null);
        }}
      >
        <Form layout="vertical">
          <Name>{eventData?.active?.data?.current?.productType?.name}</Name>
          <Row>
            <Form.Item label={gettext('Total QTY')}>
              <InputNumber value={eventData?.active?.data?.current?.quantity} disabled type="number" />
            </Form.Item>
            <i className="icon-right1"></i>
            <Form.Item label={gettext('qty to move')}>
              <InputNumber
                value={value}
                min={1}
                max={eventData?.active?.data?.current?.quantity}
                onChange={(value: number | null) => {
                  if (value === null) return;
                  setValue(value);
                }}
                type="number"
              />
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </DndContext>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  i {
    color: var(--gray-7);
    font-size: 20px;
    font-weight: 900;
  }
`;

const Name = styled.p`
  margin-bottom: 24px;
  font: var(--font-semibold-14);
`;
