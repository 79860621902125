import withReactContent from 'sweetalert2-react-content';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { TranslationsProvider } from '@/shared/i18n/TranslationsProvider';
const swal = withReactContent(Swal);

export const showModal = (params: SweetAlertOptions) => {
  return swal.fire({
    showCloseButton: true,
    showConfirmButton: false,
    showCancelButton: false,
    buttonsStyling: false,
    stopKeydownPropagation: false,
    customClass: {
      container: 'text-start',
      content: 'text-start',
      confirmButton: 'btn btn-danger mr-2',
      cancelButton: 'btn btn-info'
    },
    ...params,
    html: <TranslationsProvider>{params.html}</TranslationsProvider>
  });
};
