export const colors = {
  BATTLESHIP_GREY: '#72747e',
  CHARCOAL_GREY: '#282a31',
  CLOUDY_BLUE: '#babbc0',
  DARK_BLUE: '#262a36',
  ALGAE_GREEN: '#1fb841',
  DARK_MINT: '#50c468',
  WHITE: '#ffffff',
  LIGHT: '#fafafa',
  LIGHT_BLUE: '#ddf2ff',
  LIGHT_PERIWINKLE: '#dcdcdf',
  PINKISH_RED: '#f41d39',
  GREEN: '#1fb841',
  EGG_WHITE: '#f6f6f6',
  OFF_WHITE: '#eeeef1',
  CERULEAN: '#0064c7',
  OPTIONS_HOVER_BACKGROUND: '#eaeaec',
  YELLOWISH_ORANGE: '#fcab10',
  LIGHT_GREEN: '#ebfbee',
  DUST_RED: '#ff4d4f',
  GEEK_BLUE: '#014AA5'
};
