import cx from 'classnames';
import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup, FormText } from 'reactstrap';
import { ValueType } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { FormLabel } from '@components/forms/controls/FormLabel';
import { QueryOptions, useApolloClient } from '@apollo/client';

const FormInputAsyncSearchSelect: React.FC<SelectInputProps> = (props): JSX.Element => {
  const {
    field,
    label,
    multi,
    form,
    formText,
    formGroupClassNames,
    isClearable,
    queryOptions,
    dataAdapter,
    disabled,
    onInputChange,
    onSelectValue,
    components,
    placeholder,
    ...restProps
  } = props;

  const { errors, touched, setFieldValue } = form;
  const { name, value } = field;
  const { id } = restProps;
  const invalid: boolean = !!(errors[name] && touched[name]);

  const client = useApolloClient();

  const loadOptions = useCallback(
    () =>
      client.query(queryOptions).then(response => {
        const { data } = response;
        const options = dataAdapter(data);
        return options;
      }),
    [client, queryOptions, dataAdapter]
  );

  const handleOnChange = useCallback(
    async values => {
      if (!onSelectValue) {
        setFieldValue(name, values);
      } else {
        const result = await onSelectValue(values);
        setFieldValue(name, result || values);
      }
    },
    [name, setFieldValue, onSelectValue]
  );

  return (
    <FormGroup className={formGroupClassNames}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <AsyncSelect
        className={cx('react-select-container', { 'is-invalid': invalid })}
        id={name}
        value={value}
        isDisabled={disabled}
        inputId={name}
        isMulti={multi}
        isClearable={isClearable}
        onChange={handleOnChange}
        onInputChange={onInputChange}
        loadOptions={loadOptions}
        cacheOptions={false}
        components={components}
        placeholder={placeholder}
        isSearchable={true}
        allowCreateWhileLoading={true}
      />
      {formText && <FormText color="muted">{formText}</FormText>}
    </FormGroup>
  );
};

export default FormInputAsyncSearchSelect;

type SelectInputProps = {
  [key: string]: any;
  id?: string;
  label?: string;
  className?: string;
  formGroupClassNames?: string;
  formText?: string;
  queryOptions: QueryOptions<any>;
  dataAdapter: <T>(value: T) => OptionType[];
  onSelectValue?: <T>(value: T) => Promise<ValueType<OptionType, boolean> | null>;
  onInputChange?: (search: string) => void;
  components?: SelectComponents<any, boolean>;
};

type OptionType = {
  label: string;
  value: string;
};
