import { OverrideFields } from '@/shared/utils/types';
import { OrderPreparatoryConfigDefaultFeePriceQuery } from '@components/OrderFeePanel/queries/useOrderFeeQueries.generated';
import { Fee, Price } from '@/interfaces/brokrete';

export enum Statuses {
  old = 'old',
  changed = 'changed',
  new = 'new'
}

export type OrderPreparatoryConfigDefaultFeePrice = OverrideFields<
  OrderPreparatoryConfigDefaultFeePriceQuery['orderPreparatoryConfig']['fees'][number],
  {
    price: OverrideFields<
      OrderPreparatoryConfigDefaultFeePriceQuery['orderPreparatoryConfig']['fees'][number]['price'],
      {
        id: string;
      }
    >;
  }
>;
export type OrderFeePanelFeePrice = { fee: Fee; price: Price; value: number };
export type OrderFeePrice = OrderPreparatoryConfigDefaultFeePrice & { status: Statuses; value: number };

export type OrderFeePanelInstance = {
  requireActionConfirmation: () => boolean;
  confirmAction: () => Promise<void>;
  getCurrentPrice: () => PromiseLike<{ value: number } | undefined>;
  getFeePrices: () => Omit<OrderFeePrice, 'status'>[] | undefined;
  getFee: () => Array<{ id: string; price: { id: string } }> | undefined;
  setChecked: (checked: boolean) => void;
};
