import gql from 'graphql-tag';
import { plant } from '@/core/gql/fragments';

export const plants = gql`
  query Supplier($id: ID!, $limit: Int, $skip: Int) {
    supplier(id: $id) {
      plants(limit: $limit, skip: $skip) {
        values {
          ...plant
        }
        count
      }
    }
  }
  ${plant}
`;
