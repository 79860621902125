import React from 'react';
import styled from 'styled-components';

import ProductModificationTag from './ProductModificationTag';
import ImageUpload from './ImageUpload';

type TextureModificationTagProps = {
  tagIndex: number;
  item: any;
  onRemove?: () => void;
  onChange: (key: number, item: any) => void;
};

const ProductModificationTagStyled = styled(ProductModificationTag)`
  padding: 7px 12px 7px 4px;
`;

const TextureModificationTag: React.FC<TextureModificationTagProps> = ({ tagIndex, item, onChange, onRemove }) => {
  const onTextureChanged = (file: File) => {
    const newItem = {
      ...item,
      image: file
    };

    onChange(tagIndex, newItem);
  };

  return (
    <ProductModificationTagStyled
      Icon={<ImageUpload source={item.image} onChange={onTextureChanged} />}
      item={item}
      onRemove={onRemove}
    />
  );
};

export default TextureModificationTag;
