import { i18n } from '@/core';
import { Country } from '@/interfaces/brokrete';
import { isEmpty } from '@/libs';
import composeValidators from '@/libs/composeValidators';
import { email, phone, required } from '@/libs/validators';
import { FormInput, FormInputAsyncSelect, FormInputGroup, FormInputPhone } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { withApolloContext } from '@components/withApolloContext';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Field, FormikProps } from 'formik';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { Button, Card, CardBody, Col, Form, Row } from 'reactstrap';
import type { InputOptionType } from '../ContractorEditForm/types';
import ContractorInfo from './components/ContractorInfo';
import ContractorsSearchField from './components/ContractorsSearchField';
import CountryOption from './components/CountryOption';
import styles from './ContractorCreateForm.module.scss';
const ContractorCreateForm: React.FC<Props> = props => {
  const { handleSubmit, isSubmitting, isValid, dirty, type, values, errors, resetForm } = props;

  const existedContractorSelected = useMemo(() => {
    if (type === 'create') {
      return !isEmpty(values.contractor);
    }
    return false;
  }, [values, type]);

  const validateFunction = useMemo(() => {
    return (value: any) => {
      delete errors['contractor'];

      if (Object.keys(errors).length > 0) {
        return required(value);
      }

      return undefined;
    };
  }, [errors]);

  return (
    <Form onSubmit={handleSubmit}>
      {type === 'create' && (
        <Card className="mb-3">
          <CardBody>
            <span className="text-muted">{i18n.t('labels.addExistingContractor')}</span>
            <Row form className="mt-3">
              <Col>
                <ContractorsSearchField validate={validateFunction} />
              </Col>
              {/* clear the selected contractor */}
              {existedContractorSelected && (
                <Col
                  md={2}
                  className={cx(styles.clearButtonContainer)}
                  onClick={() =>
                    resetForm({
                      values: {
                        ...contractorCreateFormInitialProps
                      }
                    })
                  }
                >
                  <Button color="warning">{i18n.t('labels.clear')}</Button>
                </Col>
              )}
            </Row>
            {existedContractorSelected && <ContractorInfo {...values.contractor} />}
          </CardBody>
        </Card>
      )}
      {/* divider */}
      {type === 'create' && !existedContractorSelected && <hr />}
      {/* new contractor fields */}
      {!existedContractorSelected && (
        <Card>
          <CardBody>
            {type === 'create' && <span className="text-muted mb-3">{i18n.t('labels.createNewContractor')}</span>}
            <Row form className="mt-3">
              <Col>
                <Field
                  name="name"
                  id="name"
                  label={i18n.t(`labels.name`)}
                  component={FormInput}
                  validate={required}
                  disabled={isSubmitting}
                  autoComplete={`name`}
                />
              </Col>
              <Col>
                <Field
                  name="companyName"
                  id="companyName"
                  label={i18n.t(`labels.companyName`)}
                  component={FormInput}
                  disabled={isSubmitting}
                  autoComplete={`companyName`}
                />
              </Col>
            </Row>
            <Row form>
              <Col>
                <Field
                  name="country"
                  id="country"
                  label={i18n.t('labels.country')}
                  component={FormInputAsyncSelect}
                  queryOptions={{ query: COUNTRIES_QUERY, fetchPolicy: 'no-cache' }}
                  dataAdapter={(responseData: { countries: Country[] }) => {
                    if (!responseData.countries) return [];
                    return [
                      ...responseData.countries.map((value: Country) => ({
                        id: value.id,
                        label: value.name,
                        value: value.id,
                        code: value.code
                      }))
                    ];
                  }}
                  components={{ Option: CountryOption }}
                  validate={required}
                />
              </Col>
            </Row>
            {type === 'create' && (
              <Row form>
                <Col>
                  <Field
                    name="phone"
                    id="phone"
                    label={i18n.t(`labels.phone`)}
                    component={FormInputPhone}
                    linkedFieldName="country"
                    validate={composeValidators(phone, required)}
                    disabled={isSubmitting}
                  />
                </Col>
                <Col>
                  <Field
                    name="email"
                    id="email"
                    label={i18n.t(`labels.email`)}
                    component={FormInputGroup}
                    addonType="prepend"
                    inputGroupText={<FontAwesomeIcon icon={faEnvelope} />}
                    validate={composeValidators(email, required)}
                    disabled={isSubmitting}
                    type="email"
                  />
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      )}
      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {type === 'create' ? i18n.t('labels.add') : i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

const FormWithFormik = makeFormik<ContractorCreateFormInitialProps, FormProps>(ContractorCreateForm);
export default FormWithFormik;

export const ContractorCreateFormWithApollo = withApolloContext(FormWithFormik);

/* components */

/* gql */
const COUNTRIES_QUERY = gql`
  query countries {
    countries {
      id
      name
      code
    }
  }
`;

/* constants */
export const contractorCreateFormInitialProps: ContractorCreateFormInitialProps = {
  contractor: '',
  name: '',
  companyName: '',
  phone: '',
  email: '',
  country: ''
};

/* types */
export type ContractorCreateFormInitialProps = {
  contractor?: any;
  name: string;
  companyName: string;
  phone?: string;
  email?: string;
  country: InputOptionType | string;
};

type FormProps = {
  type: 'create' | 'edit';
};
type Props = FormikProps<ContractorCreateFormInitialProps> & FormProps;
