import styled from 'styled-components';
import { colors } from '@/defaultStyles';
import React, { useCallback, useMemo } from 'react';
import { useFileViewerContext } from '@/shared/components/dataDisplay/FileVIewer/FileViewerContext';
import { noop } from 'lodash';
import { Modal, Tooltip } from 'antd';
import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { i18n } from '@/core';

const Options = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  width: 100%;
  background-color: black;
  color: ${colors.WHITE};
`;
const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 25px;
  height: 25px;
  background-color: ${colors.OFF_WHITE};
  border-radius: 50%;
  color: ${colors.BATTLESHIP_GREY};
  cursor: pointer;

  :hover {
    color: ${colors.ALGAE_GREEN};
  }
`;

export const OptionsPanel: React.FC<{ id: string }> = ({ id }) => {
  const [state] = useFileViewerContext();
  const item = useMemo(() => state.items.find(item => item.id === id), [id, state.items]);
  const { onOpen = noop, onDownload = noop, onDelete = noop } = state.controlsConfig ?? {};

  const confirm = useCallback(
    () =>
      Modal.confirm({
        zIndex: 1049, // Same z-index as for sweetalert modals
        title: i18n.t('fileViewer.deleteConfirmationModal.title'),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t('fileViewer.deleteConfirmationModal.content'),
        okText: i18n.t('fileViewer.deleteConfirmationModal.okText'),
        cancelText: i18n.t('fileViewer.deleteConfirmationModal.cancelText'),
        onOk: () => onDelete(item)
      }),
    [item]
  );

  if (!item) return null;
  return (
    <Options>
      <Tooltip title={i18n.t('fileViewer.options.preview')}>
        <Option onClick={() => onOpen(item)}>
          <EyeOutlined />
        </Option>
      </Tooltip>
      <Tooltip title={i18n.t('fileViewer.options.delete')}>
        <Option onClick={confirm}>
          <DeleteOutlined />
        </Option>
      </Tooltip>
      <Tooltip title={i18n.t('fileViewer.options.download')}>
        <Option onClick={() => onDownload(item)}>
          <DownloadOutlined />
        </Option>
      </Tooltip>
    </Options>
  );
};
