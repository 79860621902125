import React, { useEffect } from 'react';
import ApplicationRouter from '@/ApplicationRouter';
import { ToastContainer } from 'react-toastify';
import ApplicationProvider from './view/components/context/ApplicationProvider';
import { useClientSettings } from '@/shared/access';
import moment from 'moment-timezone';
import { defaultDateLocalFormat, defaultLocale, defaultLongDateFormat } from '@/libs/date';

export default function Application() {
  const settings = useClientSettings();

  useEffect(() => {
    const locale = settings?.locale || defaultLocale;
    const dateFormat = settings?.date?.local_format || defaultDateLocalFormat[locale.toLocaleLowerCase()];

    moment.locale(locale);

    if (dateFormat) {
      moment.updateLocale(locale, {
        longDateFormat: {
          ...defaultLongDateFormat,
          L: dateFormat
        }
      });
    }
  }, [settings?.locale]);

  return (
    <>
      <ApplicationProvider>
        <ApplicationRouter />
      </ApplicationProvider>
      <ToastContainer />
    </>
  );
}
