import gql from 'graphql-tag';

export const members = gql`
  query Supplier($id: ID!, $limit: Int, $skip: Int) {
    supplier(id: $id) {
      members(limit: $limit, skip: $skip) {
        values {
          id
          name
          identities {
            provider
            uid
          }
        }
        count
      }
    }
  }
`;
