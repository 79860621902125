import { FC } from 'react';
import { SVGProps } from './types';
export const SVGCloneGreen: FC<SVGProps> = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" {...props}>
      <g fill="none" fillRule="evenodd">
        <g id="ic-clone" fill="#1FB842">
          <g>
            <g>
              <path
                d="M4.455 0C3.75 0 3.182.57 3.182 1.273v.636h11.454c.704 0 1.273.57 1.273 1.273v8.273h.636c.704 0 1.273-.57 1.273-1.273v-8.91C17.818.57 17.248 0 16.545 0H4.455zM1.273 3.182C.57 3.182 0 3.752 0 4.455v8.272C0 13.43.57 14 1.273 14h12.09c.704 0 1.273-.57 1.273-1.273V4.455c0-.704-.57-1.273-1.272-1.273H1.273zM7 5.727c.351 0 .636.285.636.637v1.909h1.91c.35 0 .636.285.636.636 0 .351-.285.636-.637.636H7.636v1.91c0 .35-.285.636-.636.636-.351 0-.636-.285-.636-.636v-1.91h-1.91c-.35 0-.636-.285-.636-.636 0-.351.285-.636.637-.636h1.909v-1.91c0-.35.285-.636.636-.636z"
                transform="translate(-1343 -412) translate(270 300) translate(1073 112)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
