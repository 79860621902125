import React from 'react';
import { DefaultExtensionType, defaultStyles, FileIcon as ReactFileIcon } from 'react-file-icon';
import { last } from 'lodash';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    width: 100%;
  }
`;

export const FileIcon: React.FunctionComponent<{ fileName: string }> = ({ fileName }) => {
  const extension = last(fileName.split('.'));
  const styles = defaultStyles[extension as DefaultExtensionType];
  return (
    <Container>
      <div className="container">
        <ReactFileIcon extension={extension} {...styles} />
      </div>
    </Container>
  );
};
