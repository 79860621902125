import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, getIn, useFormikContext } from 'formik';

import { FieldArrayControlButtons, FormInput, FormInputSelect } from '@components/forms/controls';
import { required } from '@/libs/validators';

import { OrderProductTypePrice } from './types';
import { SelectedOptionType } from '@/interfaces/common';
import { Order, OrderPreparatoryConfigProductDecoratePrice } from '@/interfaces/brokrete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Text from '@components/elements/Text';
import { ProductsContainer, RelatedProductsContainer, BoldContent } from './common';
import { colors } from '@/defaultStyles';
import { FlattenSimpleInterpolation } from 'styled-components';
import { OrderProductsFromInitialProps } from '@components/forms/settings/types';
import { toNumber } from '@/libs/string';

type Props = {
  editable?: boolean;
  prefix: string;
  index: number;
  remove<T>(index: number): T | undefined;
  push: (obj: any) => void;
  order: Order;
  availableProductTypePrices: SelectedOptionType<OrderProductTypePrice>[];
  availableDecoratePrices: SelectedOptionType<OrderPreparatoryConfigProductDecoratePrice>[];
  canChangeProductType?: boolean;
  onOrderScheduleChange: () => void;
  useGeneralGridView?: boolean;
  gridStyles?: FlattenSimpleInterpolation;
  currentProduct: SelectedOptionType<OrderProductTypePrice>;
};

const orderProductTypeInitialValues = {};

const ProductTypeRow: React.FC<Props> = (props: Props) => {
  const {
    editable,
    availableProductTypePrices,
    availableDecoratePrices,
    index,
    prefix,
    order,
    canChangeProductType,
    push,
    remove,
    onOrderScheduleChange,
    gridStyles,
    useGeneralGridView = true,
    currentProduct
  } = props;
  const [quantityValue, setQuantityValue] = useState<string>('');
  const { values, setFieldValue } = useFormikContext<OrderProductsFromInitialProps>();
  const { productTypes } = values;

  const canSetTrucks = order.product.permissions.trucks;
  const canSetDecorates = order.product.permissions.decorates;
  const industry = order.product.industry;
  const trucks = values.trucks;
  const trucksSecondary = values.trucksSecondary;
  const isServiceIndustry = industry === 'service' || industry === 'concrete_pumping';

  const productTypeRow: SelectedOptionType<OrderProductTypePrice> = getIn(values, prefix);
  const { data } = productTypeRow;
  const productQuantity = data.quantity;

  // We change quantity this way only for related products, because for other types
  // of products we change quantity through the EditSchedule modal window
  const quantity =
    industry === 'related_products'
      ? productTypes.reduce((sum: number, productType: any) => {
          return sum + toNumber(productType.data?.quantity);
        }, 0)
      : isServiceIndustry
      ? productQuantity
      : values.quantity;

  const needSetupTrucks =
    (industry === 'related_products' && quantity !== trucks[0]) ||
    (isServiceIndustry && ((index === 0 && quantity !== trucks) || (index === 1 && quantity !== trucksSecondary)));

  useEffect(() => {
    if (needSetupTrucks) {
      if (isServiceIndustry) {
        setFieldValue(index === 0 ? 'trucks' : 'trucksSecondary', [toNumber(quantity)]);
      } else {
        setFieldValue('trucks', [quantity]);
      }
    }
  }, [needSetupTrucks, quantity, index, industry, setFieldValue]);

  const decorate = (data && (data.decorate as unknown)) as SelectedOptionType<OrderPreparatoryConfigProductDecoratePrice>;

  useEffect(() => {
    if (!decorate) {
      setFieldValue(`${prefix}.data.decoratePrice`, null);
      setFieldValue(`${prefix}.data.decoratePriceValue`, null);
    }
  }, [decorate, setFieldValue, availableDecoratePrices, prefix]);

  const Container = useMemo(() => {
    return industry !== 'related_products' && !isServiceIndustry ? ProductsContainer : RelatedProductsContainer;
  }, [industry]);

  const onQuantityValidate = useCallback(
    (value: string | null) => {
      if (value === null) setFieldValue(`${prefix}.data.quantity`, quantityValue);
      else setQuantityValue(value);
      return required(value);
    },
    [quantityValue, setQuantityValue]
  );

  const getNextProduct = useCallback(
    value => {
      const availableProducts = availableProductTypePrices.filter(p => p.id !== value.id);
      return availableProducts.find(p => !productTypes.find((pt: SelectedOptionType<OrderProductTypePrice>) => pt.id === p.id));
    },
    [availableProductTypePrices, productTypes]
  );

  const nextProduct = getNextProduct(currentProduct);
  const availableProductList = useMemo(
    () =>
      availableProductTypePrices.filter(apt => {
        let shouldFilter = !productTypes.find((pt: SelectedOptionType<OrderProductTypePrice>) => pt.id === apt.id);

        if (isServiceIndustry) {
          shouldFilter = shouldFilter && apt.data.type.measuredAs === (index === 0 ? 'primary' : 'secondary');
        }

        return shouldFilter;
      }),
    [productTypes, index, industry]
  );

  return (
    <Fragment>
      {/* @ts-ignore */}
      <Container styles={gridStyles}>
        {industry !== 'related_products' && !isServiceIndustry && <Text text="Type" size={18} color={colors.CHARCOAL_GREY} />}

        <BoldContent>
          <Field
            formGroupClassNames="w-100 m-0 mr-3"
            name={`${prefix}`}
            id={`${prefix}`}
            disabled={!editable || !canChangeProductType}
            options={availableProductList}
            component={FormInputSelect}
            validate={required}
          />
        </BoldContent>

        <div>
          <Field
            name={`${prefix}.data.typePrice.value`}
            id={`${prefix}.data.typePrice.value`}
            numeric
            readOnly
            formGroupClassNames="m-0"
            component={FormInput}
          />
        </div>

        <div>
          <Field
            name={`${prefix}.data.typePriceValue`}
            id={`${prefix}.data.typePriceValue`}
            numeric
            disabled={!editable}
            formGroupClassNames="m-0"
            component={FormInput}
          />
        </div>

        {industry === 'related_products' || isServiceIndustry ? (
          <div>
            <Field
              name={`${prefix}.data.quantity`}
              id={`${prefix}.data.quantity`}
              disabled={!editable}
              formGroupClassNames="m-0"
              component={FormInput}
              validate={onQuantityValidate}
            />
          </div>
        ) : (
          useGeneralGridView && <div />
        )}

        <BoldContent>
          <Field
            name={`${prefix}.data.typePriceTotal`}
            id={`${prefix}.data.typePriceTotal`}
            numeric
            readOnly
            formGroupClassNames="m-0"
            component={FormInput}
          />
        </BoldContent>

        {industry === 'related_products' && (
          <div>
            {editable && (
              <FieldArrayControlButtons
                initialValue={orderProductTypeInitialValues}
                // @ts-ignore
                data={productTypes}
                index={index}
                push={() => push(nextProduct)}
                remove={remove}
                canAdd={!!nextProduct}
                canRemoveLast={false}
              />
            )}
          </div>
        )}
        {isServiceIndustry && <div />}
      </Container>

      {canSetDecorates && (
        // @ts-ignore
        <Container styles={gridStyles}>
          <Text text="Colour" size={18} color={colors.CHARCOAL_GREY} />
          <BoldContent>
            <Field
              formGroupClassNames="w-100 m-0"
              name={`${prefix}.data.decorate`}
              id={`${prefix}.data.decorate`}
              disabled={!editable}
              isClearable
              options={availableDecoratePrices}
              component={FormInputSelect}
              onChange={(value: any) => {
                if (value) {
                  // FIXME: Just a note for all: don't do like this!
                  //  We have different data in decorate.data between updates. That's not good amd should be refactored
                  if (value.data.price) {
                    setFieldValue(`${prefix}.data.decoratePrice`, value.data.price);
                  }
                }
                return value;
              }}
            />
          </BoldContent>

          <div>
            <Field
              name={`${prefix}.data.decoratePrice.value`}
              id={`${prefix}.data.decoratePrice.value`}
              numeric
              readOnly
              formGroupClassNames="m-0"
              component={FormInput}
            />
          </div>

          <div>
            <Field
              name={`${prefix}.data.decoratePriceValue`}
              id={`${prefix}.data.decoratePriceValue`}
              numeric
              disabled={!editable}
              formGroupClassNames="m-0"
              component={FormInput}
            />
          </div>

          {useGeneralGridView && <div />}

          <BoldContent>
            <Field
              name={`${prefix}.data.decoratePriceTotal`}
              id={`${prefix}.data.decoratePriceTotal`}
              numeric
              readOnly
              formGroupClassNames="m-0"
              component={FormInput}
            />
          </BoldContent>

          {industry === 'related_products' && <div />}
          {isServiceIndustry && <div />}
        </Container>
      )}

      {canSetTrucks && (
        // @ts-ignore
        <Container styles={gridStyles}>
          <Text text="Quantity" size={18} color={colors.CHARCOAL_GREY} />
          <div className="d-flex flex-row align-items-center">
            <Field
              formGroupClassNames="w-100 m-0"
              name="quantity"
              id="quantity"
              component={FormInput}
              validate={required}
              disabled
              readOnly
            />
            {editable && <FontAwesomeIcon className="cursor-pointer ml-2" icon={faPen} onClick={onOrderScheduleChange} />}
          </div>
        </Container>
      )}
    </Fragment>
  );
};

export default ProductTypeRow;
