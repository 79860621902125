import { i18n } from '@/core';
import { makeFormik } from '@components/forms/FormikForm';
import { FormikProps, Field } from 'formik';
import React from 'react';
import { Button, Form } from 'reactstrap';
import { FormInputFile } from '@components/forms/controls';
import { withApolloContext } from '../../withApolloContext';

const ChangeLogoForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Field name={'image'} id={'image'} label={i18n.t(`labels.image`)} component={FormInputFile} accept="image/jpeg,image/png" />
      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

const FormWithFormik = makeFormik<FormInitialProps>(ChangeLogoForm);
const ChangeLogoFormWithApolloContext = withApolloContext(FormWithFormik);

export default FormWithFormik;
export { ChangeLogoFormWithApolloContext };

/* constants */
export const changeLogoFormInitialProps: FormInitialProps = {
  image: ''
};

/* types */
type FormInitialProps = {
  image: string;
};

type FormProps = {};
type Props = FormikProps<FormInitialProps> & FormProps;
