import { FC, useMemo } from 'react';
import { OrderQuery } from '@/view/modals/order/ChangeOrderTrucksModal/queries/orderWithConfigQuery.generated';
import { Popover } from 'antd';
import styled from 'styled-components';
import { DeliveryItemsDetailsTooltip } from '@/shared/components/tooltips/DeliveryItemsDetailsTooltip';

type DeliveryItems = OrderQuery['order']['trucksDrivers'][0]['deliveryItems'];
type DeliveryProps = {
  value?: DeliveryItems;
};
export const Delivery: FC<DeliveryProps> = ({ value }) => {
  const count = useMemo(() => {
    if (!Array.isArray(value)) {
      return null;
    }
    return value.reduce((res, item) => {
      return res + item.quantity;
    }, 0);
  }, [value]);
  if (!count) return null;
  return (
    <Popover
      showArrow={false}
      placement="bottom"
      trigger="hover"
      content={<DeliveryItemsDetailsTooltip items={value} completed={false} />}
    >
      <Item>
        {count} {value ? value[0]?.units : null}
      </Item>
    </Popover>
  );
};

const Item = styled.span`
  font: var(--font-regular-12);
  cursor: pointer;
  text-decoration: underline;
  cursor: pointer;
`;
