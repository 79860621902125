import IMAGES from '@/assets/images';

type CountryData = {
  name: string;
  // FIXME
  icon: any;
};

export const COUNTRIES = {
  CA: {
    name: 'Canada',
    icon: IMAGES.FLAGS.CA
  },
  US: {
    name: 'United States',
    icon: IMAGES.FLAGS.US
  }
};

export const DEFAULT_COUNTRY_CODE = 'CA';

export type CountryCode = keyof typeof COUNTRIES;

export function countryData(countryCode: CountryCode): CountryData {
  return COUNTRIES[countryCode] || COUNTRIES[DEFAULT_COUNTRY_CODE];
}
