import { useEffect } from 'react';

const usePaintSquare = (canvas: any, hue: number) => {
  const squareHeight = 250;
  const squareWidth = 220;

  useEffect(() => {
    const ctx = canvas.current.getContext('2d');
    ctx.fillStyle = `hsl(${hue}, 100%, 50%)`;
    ctx.fillRect(0, 0, squareWidth, squareHeight);

    const gradientWhite = ctx.createLinearGradient(0, 0, squareWidth, 0);
    gradientWhite.addColorStop(0, `rgba(255, 255, 255, 1)`);
    gradientWhite.addColorStop(1, `rgba(255, 255, 255, 0)`);
    ctx.fillStyle = gradientWhite;
    ctx.fillRect(0, 0, squareWidth, squareHeight);

    const gradientBlack = ctx.createLinearGradient(0, 0, 0, squareHeight);
    gradientBlack.addColorStop(0, `rgba(0, 0, 0, 0)`);
    gradientBlack.addColorStop(1, `rgba(0, 0, 0, 1)`);
    ctx.fillStyle = gradientBlack;
    ctx.fillRect(0, 0, squareWidth, squareHeight);
  }, [canvas, hue]);
};

const usePaintHue = (canvas: any) => {
  const barHeight = 10;
  const barWidth = 220;

  useEffect(() => {
    const ctx = canvas.current.getContext('2d');
    ctx.rect(0, 0, barWidth, barHeight);

    const gradient = ctx.createLinearGradient(0, 0, barWidth, 0);
    for (let i = 0; i <= 360; i += 30) {
      gradient.addColorStop(i / 360, `hsl(${i}, 100%, 50%)`);
    }
    ctx.fillStyle = gradient;
    ctx.fill();
  }, [canvas]);
};

export { usePaintSquare, usePaintHue };
