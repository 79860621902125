import { i18n } from '@/core';
import { Fee, FeeList } from '@/interfaces/brokrete';
import { required } from '@/libs/validators';
import {
  FieldArrayControlButtonsOld,
  FormInput,
  FormInputAsyncSelect,
  FormInputSelect,
  FormInputGroup
} from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { Field, FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { useFormContentOptions } from './options';
import { same } from '@/libs/array';
import { checkIfFeeIsEmpty } from '@components/cards/GeneralFeeSettings/helpers';
import { useGettext } from '@cranium/i18n';
import type { InputOptionType } from '@components/forms/settings/ContractorEditForm/types';

function validateGroupIsEmptyOtherwiseRequired(allIsEmpty: boolean): (v: any) => undefined | string {
  return (value: any): undefined | string => {
    return allIsEmpty ? undefined : required(value);
  };
}

const GeneralFeesForm: React.FC<Props> = (props: Props) => {
  const { gettext } = useGettext();
  const formContentOptions = useFormContentOptions();
  const { handleSubmit, isSubmitting, initialValues, values, isValid, dirty } = props;

  const canSubmitEmpty = useMemo(() => {
    const isEmpty = checkIfFeeIsEmpty(values.values);
    const theSame = same<GeneralFeesProps>(initialValues.values, values.values);

    return isEmpty && !theSame;
  }, [initialValues.values, values.values]);

  const validationFunc = useMemo(() => {
    return validateGroupIsEmptyOtherwiseRequired(canSubmitEmpty);
  }, [canSubmitEmpty]);

  return (
    <Form onSubmit={handleSubmit}>
      <Field className="m-0" hidden name="category.id" id="category.id" component={FormInput} validate={required} />
      <FieldArray
        name="values"
        render={({ form, remove, push }: FieldArrayRenderProps) => {
          const { values } = form.values;
          return values.map((value: GeneralFeesProps, index: number) => {
            const namePrefix = `values[${index}]`;
            return (
              <Row form key={namePrefix}>
                <Col md={4} sm={4} lg={4} style={{ minWidth: '72px' }}>
                  <Field
                    name={`${namePrefix}.list`}
                    id={`${namePrefix}.list`}
                    formText={i18n.t('cards.settings.listOfTypicalFees')}
                    component={FormInputAsyncSelect}
                    queryOptions={{ query: FEES_LIST_QUERY }}
                    dataAdapter={(responseData: { fees: FeeList }) => {
                      if (!responseData.fees) return [];
                      return responseData.fees.values.map((value: Fee) => ({
                        label: value.name,
                        value: value.id,
                        id: value.id
                      }));
                    }}
                    validate={validationFunc}
                  />
                </Col>
                <Col md={4} sm={4} lg={4} style={{ minWidth: '72px' }}>
                  <Field
                    name={`${namePrefix}.content`}
                    id={`${namePrefix}.content`}
                    placeholder={gettext('Type')}
                    formText={i18n.t('cards.settings.fixFee')}
                    component={FormInputSelect}
                    options={formContentOptions}
                    autoComplete={`minDeliveryRow.content`}
                    validate={validationFunc}
                  />
                </Col>
                <Col>
                  <Field
                    name={`${namePrefix}.fee`}
                    id={`${namePrefix}.fee`}
                    component={FormInputGroup}
                    addonType="append"
                    type="number"
                    formText={i18n.t('cards.settings.enterAmountFormText')}
                    inputGroupText={i18n.t('common.priceSymbol')}
                    formGroupClassNames="m-0"
                    validate={validationFunc}
                    placeholder={gettext('00.0')}
                    inputGroupClassName="p-0 pr-2 pl-2"
                  />
                </Col>

                <Col md={1} sm={1} lg={1} className="pt-1" style={{ minWidth: '72px' }}>
                  <FieldArrayControlButtonsOld
                    index={index}
                    initialValue={initialValue}
                    data={values}
                    push={push}
                    remove={remove}
                  />
                </Col>
              </Row>
            );
          });
        }}
      />
      <div className="float-left">
        <Button
          type="submit"
          size="lg"
          disabled={!canSubmitEmpty && (isSubmitting || !isValid || !dirty)}
          className="btn-success mt-3"
        >
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<GeneralFeesFormInitialProps>(GeneralFeesForm);

/* props */
const initialValue = {
  content: '',
  list: '',
  fee: ''
};

export const generalFeesFormInitialValues: GeneralFeesFormInitialProps = {
  category: {
    id: ''
  },
  values: [{ ...initialValue }]
};

const FEES_LIST_QUERY = gql`
  query feesList {
    fees(type: [common]) {
      count
      values {
        id
        name
      }
    }
  }
`;

/* types */

type GeneralFeesProps = {
  content: InputOptionType | string;
  list: InputOptionType | string;
  fee: string | number;
};

export type GeneralFeesFormInitialProps = {
  category: { id: string };
  values: GeneralFeesProps[];
};

type FormProps = {};
type Props = FormikProps<GeneralFeesFormInitialProps> & FormProps;
