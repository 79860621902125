import cx from 'classnames';
import React, { PropsWithChildren } from 'react';
import styles from './InfoContainer.module.scss';

const InfoContainer: React.FC<PropsWithChildren<InfoContainerProps>> = ({ children }): JSX.Element => {
  return <div className={cx(styles.root)}>{children}</div>;
};

export default InfoContainer;

/* types */
export type InfoContainerProps = {};
