import { i18n } from '@/core';
import { isEmpty } from '@/libs';
import { required } from '@/libs/validators';
import ActivityIndicator from '@components/elements/ActivityIndicator';
import { FormInput } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { withApolloContext } from '@components/withApolloContext';
import { Field, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import styles from './SupplierForm.module.scss';
import cx from 'classnames';
import SupplierSearchField from './components/SupplierSearchField';
import { useGettext } from '@cranium/i18n';

const SupplierForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty, mode, resetForm, values }) => {
  const { gettext } = useGettext();
  const hasSelectedSupplier = useMemo(() => {
    return !isEmpty(values.supplier);
  }, [values]);

  return (
    <Form onSubmit={handleSubmit} className="text-left">
      {mode === 'create' && (
        <>
          <span className="text-muted">{'Add existed supplier'}</span>
          <Row form className="mt-3">
            <Col className="brk-form-group">
              <SupplierSearchField />
            </Col>
            {/* clear the selected contractor */}
            {hasSelectedSupplier && (
              <Col
                md={2}
                className={cx(styles.clearButtonContainer, 'brk-form-group')}
                onClick={() =>
                  resetForm({
                    values: {
                      ...supplierFormInitialProps
                    }
                  })
                }
              >
                <Button color="warning">{i18n.t('labels.clear')}</Button>
              </Col>
            )}
          </Row>
        </>
      )}
      {/* divider */}
      {mode === 'create' && !hasSelectedSupplier && <hr />}
      {!hasSelectedSupplier && (
        <Row form>
          <Col className="brk-form-group">
            <Field
              name="name"
              id="name"
              label={i18n.t('forms.supplier.fields.name.label')}
              placeholder={gettext('Name')}
              component={FormInput}
              validate={required}
              disabled={isSubmitting}
            />
          </Col>
        </Row>
      )}

      {isSubmitting ? (
        <div className="d-flex justify-content-start align-items-center">
          <ActivityIndicator />
        </div>
      ) : (
        <div className="float-left">
          <Button type="submit" size="lg" disabled={!isValid || isSubmitting || !dirty} className="btn-success mt-3">
            {mode === 'create' ? i18n.t('labels.add') : i18n.t('labels.update')}
          </Button>
        </div>
      )}
    </Form>
  );
};

const FormWithFormik = makeFormik<FormInitialProps, FormProps>(SupplierForm);
export default FormWithFormik;

export const SupplierFormWithApollo = withApolloContext(FormWithFormik);

type FormInitialProps = {
  name: string;
  supplier?: any;
};

type FormMode = 'create' | 'edit';

type FormProps = {
  mode: FormMode;
};

type Props = FormikProps<FormInitialProps> & FormProps;

export const supplierFormInitialProps: FormInitialProps = {
  name: '',
  supplier: ''
};
