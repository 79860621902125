import { Action } from './action';

export default function createReducer<T, TA extends Action<string>>(
  initialState: T,
  handlers: { [type: string]: (arg0: T, arg1: TA) => T }
): (arg0: T, arg1: TA) => T {
  return function reducer(state: T = initialState, action: TA): T {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}
