import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@/defaultStyles';
import { keys } from 'lodash';
import ProductModificationSection from '@components/forms/settings/ProductTypeForm/components/ProductModificationSection';
import { ProductTypeModificationGroup, ProductTypeModifications, ProductTypeOptionValue } from '@/interfaces/brokrete';
import Label from '@/shared/components/formik/fields/Label';
import FormGroup from '@/shared/components/formik/fields/FormGroup';
import { Input, Textarea } from '@components/elements';
import { SVGCloneGreen, SVGDelete } from '@components/elements/icons';
import { i18n } from '@/core';

type OptionCombinationProps = {
  productId: string;
  optionCombination: { add?: boolean; change?: boolean; remove?: boolean } & ProductTypeOptionValue;
  groupedModifications: { [key: string]: ProductTypeModificationGroup };
  onChange: (optionCombination: ({ add?: boolean; change?: boolean; remove?: boolean } & ProductTypeOptionValue) | null) => void;
  onClone: () => void;
};

const Root = styled.div`
  padding: 0 0;
`;

const Heading = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: center;
  justify-content: flex-end;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  margin-top: 30px;
  margin-bottom: 10px;
  line-height: 1.29;
  letter-spacing: 0.23px;
  white-space: pre-line;
  color: ${colors.CHARCOAL_GREY};
  :first-child {
    margin-top: 20px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-left: 10px;
`;

const Hint = styled.div`
  font-size: 10px;
  color: ${colors.BATTLESHIP_GREY};
`;

const Info = styled.div`
  font-size: 14px;
  margin-bottom: 30px;
`;

const Container = styled.div`
  & > * {
    margin-bottom: 30px;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 45px;
`;

const InputStyled = styled(Input)`
  input {
    font-size: 14px;
    background-color: ${colors.WHITE};
    border-color: ${colors.LIGHT_PERIWINKLE};
  }
`;

const Divider = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${colors.OFF_WHITE};
  margin: 20px 0;
`;

const DeleteIcon = styled(SVGDelete)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 15px;

  #ic_trash_box,
  #ic_trash {
    fill: ${colors.BATTLESHIP_GREY};
  }

  :hover {
    #ic_trash_box,
    #ic_trash {
      fill: ${colors.ALGAE_GREEN};
    }
  }
`;

const CloneIcon = styled(SVGCloneGreen)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 15px;

  #ic-clone {
    fill: ${colors.BATTLESHIP_GREY};
  }

  :hover {
    #ic-clone {
      fill: ${colors.ALGAE_GREEN};
    }
  }
`;

const OptionCombination: React.FC<OptionCombinationProps> = ({
  productId,
  optionCombination,
  groupedModifications,
  onClone,
  onChange
}) => {
  const onOptionCombinationLabelChanged = (value: string) => {
    onChange({ ...optionCombination, change: !optionCombination.add, value });
  };

  const onOptionCombinationDescriptionChanged = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value: description } = e.target;
    onChange({ ...optionCombination, change: !optionCombination.add, description });
  };

  const onSelectModification = (modification: ProductTypeModifications) => {
    const modifications = [...optionCombination.modifications];

    const optionCombinationModificationIndex = modifications.findIndex(m => m.id === modification.id);

    if (optionCombinationModificationIndex === -1) {
      const modificationIndexOfTheSameType = modifications.findIndex(m => {
        // TODO
        // if (modification.type === 'list') {
        //   return m.type === modification.type && m.label === modification.label;
        // }
        // return m.type === modification.type;

        return m.type === modification.type && m.label === modification.label;
      });

      if (modificationIndexOfTheSameType === -1) {
        modifications.push(modification);
      } else {
        modifications.splice(modificationIndexOfTheSameType, 1, modification);
      }
    } else {
      modifications.splice(optionCombinationModificationIndex, 1);
    }

    onChange({ ...optionCombination, change: !optionCombination.add, modifications });
  };

  const onCloneModificationCombination = () => {
    onClone();
  };

  const onRemoveModification = () => {
    if (optionCombination.add) {
      onChange(null);
    } else {
      onChange({ ...optionCombination, remove: true });
    }
  };

  return (
    <Root>
      <Divider />

      <Heading>
        <Title>{i18n.t('labels.setOfProdMods')}</Title>
        <Buttons>
          <CloneIcon onClick={onCloneModificationCombination} />
          <DeleteIcon onClick={onRemoveModification} />
        </Buttons>
      </Heading>

      <Info>{i18n.t('labels.productParamsCaption')}</Info>

      <Container>
        <FormGroup>
          <Label>{i18n.t('labels.title')}</Label>
          <InputStyled name="Modification Set Name" value={optionCombination.value} onChange={onOptionCombinationLabelChanged} />
        </FormGroup>

        <FormGroup>
          <Label>{i18n.t('labels.description')}</Label>
          <Textarea
            name="Modification Set Description"
            value={optionCombination.description}
            onChange={onOptionCombinationDescriptionChanged}
          />
          <Hint>{i18n.t('labels.descriptionCaption')}</Hint>
        </FormGroup>

        {keys(groupedModifications).map((modificationType, idx) => {
          return (
            <ProductModificationSection
              editable={false}
              getModificationTagStyles={(modification: ProductTypeModifications) => {
                const selected = optionCombination.modifications.find(m => m.id === modification.id);
                return css`
                  border-color: ${selected ? colors.ALGAE_GREEN : '#dcdcdf'};
                `;
              }}
              onModificationTagClick={onSelectModification}
              key={`${idx}_${groupedModifications[modificationType].label}`}
              productId={productId}
              modification={groupedModifications[modificationType]}
            />
          );
        })}
      </Container>
    </Root>
  );
};

export default OptionCombination;
