// import { getIn } from 'formik';
import React, { useState } from 'react';
import ReactMde from 'react-mde';
import { FormGroup, FormText } from 'reactstrap';
import * as Showdown from 'showdown';
import { FormLabel } from '@components/forms/controls/FormLabel';
/* import 'react-mde/lib/styles/css/react-mde-all.css'; */

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

type InputProps = {
  [key: string]: any;
  id?: string;
  label?: string;
  className?: string;
  formGroupClassNames?: string;
  disabled?: boolean;
};

const FormInputMarkdown: React.FC<InputProps> = (props): JSX.Element => {
  const { field, label, form, formText, formGroupClassNames, onChange, disabled, ...restProps } = props;

  const { name } = field;
  const { id } = restProps;
  const { /*errors: formErrors, touched: formTouched,*/ setFieldValue, values } = form;
  // const error = getIn(formErrors, name);
  // const touched = getIn(formTouched, name);

  // const invalid: boolean = !!(error && touched);
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

  return (
    <FormGroup className={formGroupClassNames}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <ReactMde
        {...restProps}
        toolbarCommands={
          disabled
            ? []
            : [
                ['header', 'bold', 'italic'],
                ['unordered-list', 'ordered-list']
              ]
        }
        value={values[name]}
        readOnly={disabled}
        disablePreview={disabled}
        onChange={value => {
          onChange && onChange(value);
          setFieldValue(name, value);
        }}
        generateMarkdownPreview={markdown => Promise.resolve(converter.makeHtml(markdown))}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        childProps={{
          textArea: {}
        }}
      />

      {formText && <FormText color="muted">{formText}</FormText>}
      {/* {invalid && <FormFeedback>{String(errors[name])}</FormFeedback>} */}
      {/* //TODO return when fixed validators */}
    </FormGroup>
  );
};

export default FormInputMarkdown;
