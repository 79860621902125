import React from 'react';
import styled from 'styled-components';
import { useFileViewerContext } from '@/shared/components/dataDisplay/FileVIewer/FileViewerContext';
import { noop } from 'lodash';
import { EllipsisOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const Container = styled.div<{ height: number }>`
  height: ${props => props.height}px;
  width: ${props => props.height}px;
  position: relative;
  border: 2px solid #1fb841;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;

  .preview {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(${props => props.height / 86} * 48px);
    width: 100%;
    height: 100%;
  }
`;
/**
 * Item with three sots icon and shows rest count in tooltip.
 * @param restCount
 * @param onClick
 * @constructor
 */
export const RestFileItem: React.FC<{ restCount: number; onClick?: () => void }> = ({ restCount, onClick = noop }) => {
  const [state] = useFileViewerContext();
  return (
    <Tooltip placement="bottom" title={`+${restCount} more`}>
      <Container height={state.height} onClick={onClick}>
        <div className="preview">
          <EllipsisOutlined />
        </div>
      </Container>
    </Tooltip>
  );
};
