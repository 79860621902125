import * as queries from './queries';
import * as mutations from './mutations';

export const contractors = {
  queries: {
    ...queries
  },
  mutations: {
    ...mutations
  }
};
