import { FC } from 'react';
import { SVGProps } from './types';
export const SVGButtonRemove: FC<SVGProps> = props => {
  return (
    <svg
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>button_remove</title>
      <desc>Created with Sketch.</desc>
      <g id="UI-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2.1.1.-Order-Schedule" transform="translate(-224.000000, -343.000000)">
          <g id="Group-11" transform="translate(0.000000, 329.000000)">
            <g id="Group-3" transform="translate(225.000000, 15.000000)">
              <g id="button-group">
                <rect id="button-border" stroke="#BABBC0" x="0" y="0" width="40" height="40" rx="20"></rect>
                <rect id="button-icon" fill="#72747E" x="12" y="19" width="16" height="2" rx="1"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
