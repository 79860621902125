import { AnyAction } from 'redux';
import { createReducer } from '@/rdx/libs';
import sideBar6 from '@/assets/styles/utils/images/sidebar/city1.jpg';

export const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE';

export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
export const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL';

export const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER';
export const SET_ENABLE_HEADER_SHADOW = 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW';
export const SET_ENABLE_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW';
export const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR';
export const SET_ENABLE_CLOSED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR';
export const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER';

export const SET_ENABLE_PAGETITLE_ICON = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON';
export const SET_ENABLE_PAGETITLE_SUBHEADING = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING';
export const SET_ENABLE_PAGE_TABS_ALT = 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT';

export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE';
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR';
export const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME';
export const SET_BACKGROUND_IMAGE_OPACITY = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY';

export const SET_HEADER_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR';

export const setEnableBackgroundImage = (enableBackgroundImage: boolean) => ({
  type: SET_ENABLE_BACKGROUND_IMAGE,
  enableBackgroundImage
});

export const setEnableFixedHeader = (enableFixedHeader: boolean) => ({
  type: SET_ENABLE_FIXED_HEADER,
  enableFixedHeader
});

export const setEnableHeaderShadow = (enableHeaderShadow: boolean) => ({
  type: SET_ENABLE_HEADER_SHADOW,
  enableHeaderShadow
});

export const setEnableSidebarShadow = (enableSidebarShadow: boolean) => ({
  type: SET_ENABLE_SIDEBAR_SHADOW,
  enableSidebarShadow
});

export const setEnablePageTitleIcon = (enablePageTitleIcon: boolean) => ({
  type: SET_ENABLE_PAGETITLE_ICON,
  enablePageTitleIcon
});

export const setEnablePageTitleSubheading = (enablePageTitleSubheading: boolean) => ({
  type: SET_ENABLE_PAGETITLE_SUBHEADING,
  enablePageTitleSubheading
});

export const setEnablePageTabsAlt = (enablePageTabsAlt: boolean) => ({
  type: SET_ENABLE_PAGE_TABS_ALT,
  enablePageTabsAlt
});

export const setEnableFixedSidebar = (enableFixedSidebar: boolean) => ({
  type: SET_ENABLE_FIXED_SIDEBAR,
  enableFixedSidebar
});

export const setEnableClosedSidebar = (enableClosedSidebar: boolean) => ({
  type: SET_ENABLE_CLOSED_SIDEBAR,
  enableClosedSidebar
});

export const setEnableMobileMenu = (enableMobileMenu: boolean) => ({
  type: SET_ENABLE_MOBILE_MENU,
  enableMobileMenu
});

export const setEnableMobileMenuSmall = (enableMobileMenuSmall: boolean) => ({
  type: SET_ENABLE_MOBILE_MENU_SMALL,
  enableMobileMenuSmall
});

export const setEnableFixedFooter = (enableFixedFooter: boolean) => ({
  type: SET_ENABLE_FIXED_FOOTER,
  enableFixedFooter
});

export const setBackgroundColor = (backgroundColor: string) => ({
  type: SET_BACKGROUND_COLOR,
  backgroundColor
});

export const setHeaderBackgroundColor = (headerBackgroundColor: string) => ({
  type: SET_HEADER_BACKGROUND_COLOR,
  headerBackgroundColor
});

export const setColorScheme = (colorScheme: string) => ({
  type: SET_COLOR_SCHEME,
  colorScheme
});

export const setBackgroundImageOpacity = (backgroundImageOpacity: string) => ({
  type: SET_BACKGROUND_IMAGE_OPACITY,
  backgroundImageOpacity
});

// FIXME image type??
export const setBackgroundImage = (backgroundImage: any) => ({
  type: SET_BACKGROUND_IMAGE,
  backgroundImage
});

export interface State {
  backgroundColor: string;
  headerBackgroundColor: string;
  enableMobileMenuSmall: boolean;
  enableBackgroundImage: boolean;
  enableClosedSidebar: boolean;
  enableFixedHeader: boolean;
  enableHeaderShadow: boolean;
  enableSidebarShadow: boolean;
  enableFixedFooter: boolean;
  enableFixedSidebar: boolean;
  colorScheme: string;
  backgroundImage: string;
  backgroundImageOpacity: string;
  enablePageTitleIcon: boolean;
  enablePageTitleSubheading: boolean;
  enablePageTabsAlt: boolean;
  enableMobileMenu: boolean;
  closedSmallerSidebar: boolean;
}

const initialState: State = {
  backgroundColor: '',
  headerBackgroundColor: '',
  enableMobileMenuSmall: false,
  enableBackgroundImage: false,
  enableClosedSidebar: false,
  enableFixedHeader: true,
  enableHeaderShadow: true,
  enableSidebarShadow: true,
  enableFixedFooter: true,
  enableFixedSidebar: true,
  colorScheme: 'white',
  backgroundImage: sideBar6,
  backgroundImageOpacity: 'opacity-06',
  enablePageTitleIcon: true,
  enablePageTitleSubheading: true,
  enablePageTabsAlt: true,
  enableMobileMenu: false,
  closedSmallerSidebar: false
};

export const reducer = createReducer<State, AnyAction>(initialState, {
  [SET_ENABLE_BACKGROUND_IMAGE]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enableBackgroundImage: action.enableBackgroundImage
    };
  },
  [SET_ENABLE_HEADER_SHADOW]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enableHeaderShadow: action.enableHeaderShadow
    };
  },
  [SET_ENABLE_FIXED_HEADER]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enableFixedHeader: action.enableFixedHeader
    };
  },
  [SET_ENABLE_SIDEBAR_SHADOW]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enableSidebarShadow: action.enableSidebarShadow
    };
  },
  [SET_ENABLE_PAGETITLE_ICON]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enablePageTitleIcon: action.enablePageTitleIcon
    };
  },
  [SET_ENABLE_PAGETITLE_SUBHEADING]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enablePageTitleSubheading: action.enablePageTitleSubheading
    };
  },
  [SET_ENABLE_PAGE_TABS_ALT]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enablePageTabsAlt: action.enablePageTabsAlt
    };
  },
  [SET_ENABLE_FIXED_SIDEBAR]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enableFixedSidebar: action.enableFixedSidebar
    };
  },
  [SET_ENABLE_MOBILE_MENU]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enableMobileMenu: action.enableMobileMenu
    };
  },
  [SET_ENABLE_MOBILE_MENU_SMALL]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enableMobileMenuSmall: action.enableMobileMenuSmall
    };
  },
  [SET_ENABLE_CLOSED_SIDEBAR]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enableClosedSidebar: action.enableClosedSidebar
    };
  },
  [SET_ENABLE_FIXED_FOOTER]: (state: State, action: AnyAction) => {
    return {
      ...state,
      enableFixedFooter: action.enableFixedFooter
    };
  },
  [SET_BACKGROUND_COLOR]: (state: State, action: AnyAction) => {
    return {
      ...state,
      backgroundColor: action.backgroundColor
    };
  },
  [SET_HEADER_BACKGROUND_COLOR]: (state: State, action: AnyAction) => {
    return {
      ...state,
      headerBackgroundColor: action.headerBackgroundColor
    };
  },
  [SET_COLOR_SCHEME]: (state: State, action: AnyAction) => {
    return {
      ...state,
      colorScheme: action.colorScheme
    };
  },
  [SET_BACKGROUND_IMAGE]: (state: State, action: AnyAction) => {
    return {
      ...state,
      backgroundImage: action.backgroundImage
    };
  },
  [SET_BACKGROUND_IMAGE_OPACITY]: (state: State, action: AnyAction) => {
    return {
      ...state,
      backgroundImageOpacity: action.backgroundImageOpacity
    };
  }
});

export interface ThemeState extends State {}
