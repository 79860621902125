import React from 'react';
import styled from 'styled-components';
import { UserTip } from '@/shared/tips';

type Props = {
  name?: string;
  title: React.ReactNode;
  icon?: JSX.Element;
  titleSecondary?: string;
  subTitle?: React.ReactNode;
  info?: React.ReactNode;
  actions?: JSX.Element;
};

const PageTitle: React.FC<Props> = ({ icon, name, title, titleSecondary, subTitle, info, actions }): JSX.Element => {
  return (
    <StyledPageTitle className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          {icon && <div className="page-title-icon">{icon}</div>}
          <div>
            <UserTip name={name}>
              <div className="page-title font-weight-extra-bold">{title}</div>
            </UserTip>

            {titleSecondary && <div className="page-title font-weight-regular">{titleSecondary}</div>}

            {subTitle && <div className="page-title-subheading font-weight-regular">{subTitle}</div>}

            {info && (
              <div className="font-weight-regular mt-2" style={{ color: '#4A90E2' }}>
                {info}
              </div>
            )}
          </div>
        </div>

        {actions && <div className="page-title-actions d-flex">{actions}</div>}
      </div>
    </StyledPageTitle>
  );
};

const StyledPageTitle = styled.div`
  border: 1px solid #dee2e6;
  margin: -1rem -1rem 1rem;
  background-color: rgba(255, 255, 255, 0.8);
`;

export default PageTitle;
