import { i18n } from '@/core';
import { Supplier } from '@/interfaces/brokrete';
import { FormInputAsyncSearchSelect } from '@components/forms/controls';
import { useGettext } from '@cranium/i18n';
import { Field, useFormikContext } from 'formik';
import gql from 'graphql-tag';
import React, { useCallback, useMemo, useState } from 'react';
import SupplierFieldOption from './SupplierFieldOption';

const SupplierSearchField: React.FC<any> = (): JSX.Element => {
  const { gettext } = useGettext();
  const { setFieldValue } = useFormikContext();
  const [search, setSearch] = useState<string | undefined>();

  const fieldInputChange = useCallback(
    (value: string) => {
      setSearch(value);
    },
    [setSearch]
  );

  const dataAdapter = useCallback((responseData: any) => {
    if (!responseData.suppliers) return [];
    return [
      ...responseData.suppliers.values.map((value: Supplier) => ({
        value: value.id,
        label: value.name,
        id: value.id,
        name: value.name
      }))
    ];
  }, []);

  const onSelect = useCallback(
    (value: any) => {
      setFieldValue('name', value.name, false);
    },
    [setFieldValue]
  );

  const queryOptions = useMemo(
    () => ({
      query: SUPPLIERS,
      fetchPolicy: 'no-cache',
      variables: {
        ignoreScope: true,
        search,
        limit: 20
      }
    }),
    [search]
  );

  return (
    <Field
      name="supplier"
      id="supplier"
      placeholder={gettext('Search by name')}
      component={FormInputAsyncSearchSelect}
      queryOptions={queryOptions}
      onInputChange={fieldInputChange}
      dataAdapter={dataAdapter}
      onSelectValue={onSelect}
      components={{ Option: SupplierFieldOption }}
      formGroupClassNames="mb-0"
    />
  );
};

export default SupplierSearchField;

/* gql */
const SUPPLIERS = gql`
  query suppliers($ignoreScope: Boolean, $limit: Int, $skip: Int, $search: String) {
    suppliers(ignoreScope: $ignoreScope, limit: $limit, skip: $skip, search: $search) {
      count
      values {
        id
        name
      }
    }
  }
`;
