import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFileViewerContext } from '@/shared/components/dataDisplay/FileVIewer/FileViewerContext';
import { last } from 'lodash';
import { Tooltip } from 'antd';
import { Resource } from '@/interfaces/brokrete';
import { TooltipView } from '@/shared/components/dataDisplay/FileVIewer/items/FileItem/TooltipView';
import { FileIcon } from '@/shared/components/dataDisplay/FileVIewer/items/FileIcon';

const Container = styled.div<{ height: number }>`
  height: ${props => props.height}px;
  width: ${props => props.height}px;
  position: relative;
  border: 2px solid #1fb841;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;

  .preview {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(${props => props.height / 86} * 48px);
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`;

type SimpleFileItemProps = Omit<Resource, 'fileUrl'> & { createdAt: string };

/**
 * Item without options panel, preview open by click on whole container.
 * @param source
 * @param mimeType
 * @param id
 * @param createdAt
 * @constructor
 */
export const SimpleFileItem: React.FC<SimpleFileItemProps> = ({ source, mimeType, id, createdAt }) => {
  const [state, dispatch] = useFileViewerContext();
  const name = last(source.split('/')) ?? '';
  const type = mimeType?.includes('image') ? 'image' : 'file';
  useEffect(() => {
    dispatch({ type: 'addItem', item: { id, name, type, source, createdAt, mimeType } });
  }, [id, source, createdAt, mimeType]);

  const onPreview = () => {
    const item = state.items.find(item => item.id === id);
    if (state.controlsConfig?.onOpen) state.controlsConfig?.onOpen(item!);
  };

  return (
    <Tooltip placement="bottom" title={<TooltipView name={name} date={createdAt} />}>
      <Container height={state.height} onClick={onPreview}>
        {type === 'image' ? <img src={source} alt="image" /> : <FileIcon fileName={source} />}
      </Container>
    </Tooltip>
  );
};
