// @ts-nocheck
import { isNaN } from 'lodash';

export default function isEmpty<T>(value: T | undefined | null): value is null | undefined {
  if (value != null && Array.isArray(value)) {
    return value.length === 0;
  }

  if (value && value.constructor === Object) {
    return Object.entries(value).length === 0;
  }

  if (isNaN(value)) {
    return true;
  }

  switch (value) {
    case '':
    case 0:
    case '0':
    case null:
    case undefined:
    case false:
      return true;
    default:
      return false;
  }
}
