import { FC, Suspense, lazy } from 'react';
import { DEV_TOOLS_KEY } from './utils';
import { useLocalStorage } from '@/core/Storage';
const DevToolsUI = lazy(() => import('./DevtoolsUI').then(module => ({ default: module.DevToolsUI })));

export const DevTools: FC = () => {
  const { value } = useLocalStorage<{ debugger?: boolean }>(DEV_TOOLS_KEY);
  if (value?.debugger) {
    return (
      <Suspense fallback={null}>
        <DevToolsUI />
      </Suspense>
    );
  }
  return null;
};
