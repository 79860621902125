import gql from 'graphql-tag';

export const orderPreparatoryConfig = gql`
  query orderPreparatoryConfig(
    $contractor: IdInput
    $input: OrderPreparatoryConfigInput
    $order: IdInput
    $currency: String
    $productTypePrice: IdInput
  ) {
    orderPreparatoryConfig(contractor: $contractor, order: $order, input: $input) {
      products {
        id
        name
        industry
        permissions {
          options
          trucks
          decorates
        }
      }
      productTypes {
        price {
          id
          value
          currency
          content
        }
        productType {
          id
          name
        }
        units
      }
      productDecorates(productTypePrice: $productTypePrice) {
        price {
          id
          value
          currency
          content
        }
        productDecorate {
          id
          name
        }
      }
      options {
        option {
          id
          name
          customized
        }
        price {
          id
          value
          content
          currency
        }
      }
      fees(currency: $currency, filter: true, withPossibles: true) {
        fee {
          id
          name
          type
        }
        price {
          id
          priority
          value
          content
        }
      }
      trucksConfig {
        count {
          max
        }
        quantity {
          max
          min
          step
        }
      }
      totalPrice {
        components {
          fee {
            id
          }
          option {
            id
          }
          productDecorate {
            id
          }
          productType {
            id
          }
          value
        }
        value
      }
    }
  }
`;

export const buildOrderPreparatoryConfig = ({
  decorates,
  products,
  productTypes,
  options,
  fees,
  trucksConfig,
  trucksConfigs,
  availabilityTimes,
  totalPrices
}: {
  decorates?: boolean;
  options?: boolean;
  fees?: boolean;
  trucksConfig?: boolean;
  trucksConfigs?: boolean;
  products?: boolean;
  productTypes?: boolean;
  availabilityTimes?: boolean;
  totalPrices?: boolean;
}) => {
  const productFields = products
    ? ` products(region: $region) {
          id
          name
          industry
          permissions {
            options
            trucks
            decorates
          }
        }`
    : '';

  const productTypeFields = productTypes
    ? `productTypes(currency: $currency) {
         price {
           id
           value
           currency
           content
         }
         productType {
           id
           name
           measuredAs
         }
         units
       }`
    : '';

  const decorateFields = decorates
    ? `productDecorates(currency: $currency, productTypePrice: $productTypePrice) {
         price {
           id
           value
           currency
           content
         }
         productDecorate {
          id
          name
         }
       }`
    : '';

  const optionFields = options
    ? `options(currency: $currency) {
         option {
           id
           name
         }
         price {
           id
           value
           content
           currency
         }
       }`
    : '';

  const feesFields = fees
    ? `fees(currency: $currency, filter: true, withPossibles: true) {
         fee {
           id
           name
           type
         }
         price {
           id
           priority
           value
           content
         }
       }
       taxes(currency: $currency) {
          fee {
            id
            name
            type
          }
          price {
            id
            priority
            value
            content
          }
       }
       
       `
    : '';

  const trucksConfigFields = trucksConfig
    ? `trucksConfig {
         balanceTruck 
         count {
           max
         }
         quantity {
           max
           min
           step
         }
       }`
    : '';

  const trucksConfigsFields = trucksConfigs
    ? `trucksConfigs {
        balanceTruck
         deliveryMethod {
           name
           caption
           description 
         } 
         count {
           max
         }
         quantity {
           max
           min
           step
         }
         secondaryQuantity {
           max
           min
           step
         }  
       }`
    : '';

  const availabilityTimesFields = availabilityTimes
    ? `availabilityTimes(days: $days) {
        timezone
       }`
    : '';

  const totalPricesFields = totalPrices
    ? `totalPrice(currency: $currency) {
        components {
          fee {
            id
          }
          option {
            id
          }
          productDecorate {
            id
          }
          productType {
            id
          }
          value
        }
        value
      }`
    : '';

  const queryString = `
    query orderPreparatoryConfig(
        $contractor: IdInput
        $input: OrderPreparatoryConfigInput
        $order: IdInput
        ${fees || decorates || products || options || totalPrices ? '$currency: String' : ''}
        ${decorates ? '$productTypePrice: IdInput' : ''}
        ${products ? '$region: RegionInput' : ''}
        ${availabilityTimes ? '$days: [String!]!' : ''}
      ) {
        orderPreparatoryConfig(contractor: $contractor, order: $order, input: $input) {
          ${productFields}
          ${productTypeFields}
          ${decorateFields}
          ${optionFields}
          ${feesFields}
          ${trucksConfigFields}
          ${trucksConfigsFields}
          ${availabilityTimesFields}
          ${totalPricesFields}
        }
      }
  `;

  return gql(queryString);
};
