import createReducer from './../../libs/createReducer';
import { Actions, ActionTypes } from './actions';
import { LogoutActionTypes } from './signOut';
import { User } from '@/interfaces/brokrete';

export type AuthorizationState = {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  user: User | null;
  token: string | null;
  refreshToken: string | null;
  error: any;
};

const initialState: AuthorizationState = {
  isAuthenticated: false,
  isAuthenticating: false,
  user: null,
  token: null,
  refreshToken: null,
  error: null
};

export default createReducer(initialState, {
  [ActionTypes.START]() {
    return {
      ...initialState,
      isAuthenticating: true
    };
  },
  [ActionTypes.UPDATE](state, action: ReturnType<typeof Actions.update>) {
    const { payload } = action;
    return {
      ...state,
      ...payload
    };
  },
  [ActionTypes.UPDATE_USER](state, action: ReturnType<typeof Actions.updateUser>) {
    const { payload } = action;
    return { ...state, user: Object.assign({}, state.user, payload.user) };
  },
  [ActionTypes.COMPLETE](state) {
    return {
      ...state,
      isAuthenticating: false
    };
  },
  [LogoutActionTypes.LOGOUT]() {
    return {
      ...initialState
    };
  }
});
