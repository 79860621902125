import gql from 'graphql-tag';
import { product } from '@/core/gql/fragments';

export const categories = gql`
  query productCategories($limit: Int, $skip: Int, $partner: IdInput, $supplier: IdInput) {
    productCategories(limit: $limit, skip: $skip, partner: $partner, supplier: $supplier) {
      count
      values {
        ...product
      }
    }
  }
  ${product}
`;

export const categoriesMinimal = gql`
  query productCategories($limit: Int, $skip: Int, $partner: IdInput, $supplier: IdInput, $region: RegionInput) {
    productCategories(limit: $limit, skip: $skip, partner: $partner, supplier: $supplier, region: $region) {
      count
      values {
        id
        name
        industry
      }
    }
  }
`;
