import { Component, ErrorInfo, ReactNode } from 'react';
import { sendError } from '@/errorMonitoring';
import browser from '@sentry/browser';

class ErrorBoundary extends Component<{ fallback?: ReactNode }, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, { componentStack }: ErrorInfo) {
    this.setState({ hasError: true });
    if (process.env.REACT_APP_SENTRY_AUTH_TOKEN) {
      const errorBoundaryError = new Error(error.message);
      errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
      errorBoundaryError.stack = componentStack;

      //@ts-ignore Sentry Error configuration
      error.cause = errorBoundaryError;
      browser?.captureException(error, { contexts: { react: { componentStack } } });
    } else {
      sendError({
        error,
        stack: componentStack
      });
    }
  }

  render() {
    if (this.state.hasError) {
      //TODO: ask designs for Error Page
      return (
        this.props.fallback || (
          <>
            <h1>Something went wrong, please refresh the page.</h1>
            <a href="/">Home Page</a>
          </>
        )
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
