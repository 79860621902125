import gql from 'graphql-tag';
import { plant, product } from '@/core/gql/fragments';

export const supplier = gql`
  query Supplier($id: ID!) {
    supplier(id: $id) {
      ...supplier
      plants {
        values {
          ...plant
        }
      }
      categories {
        values {
          ...product
        }
      }
    }
  }
  ${plant}
  ${product}
`;
