import moment, { Moment, unitOfTime } from 'moment-timezone';

export { utc } from 'moment-timezone';

export const defaultLocale = 'en-CA';
export const defaultDateLocalFormat: { [key: string]: string } = {
  'en-us': 'MM/DD/YYYY'
};

export const defaultLongDateFormat = {
  LT: 'h:mm A',
  LTS: 'h:mm:ss A',
  L: 'MM/DD/YYY',
  l: 'M/D/YYYY',
  LL: 'MMMM Do YYYY',
  ll: 'MMM D YYYY',
  LLL: 'MMMM Do YYYY LT',
  lll: 'MMM D YYYY LT',
  LLLL: 'dddd, MMMM Do YYYY LT',
  llll: 'ddd, MMM D YYYY LT'
};

export class Date {
  private readonly moment: Moment;

  constructor(moment: Moment) {
    this.moment = moment;
  }

  get minutes(): number {
    return this.moment.minutes();
  }

  get hours(): number {
    return this.moment.hours();
  }

  get unix(): number {
    return this.moment.unix();
  }

  get uxTime(): number {
    return this.moment.unix() * 1000;
  }

  get month(): number {
    return this.moment.month();
  }

  get dayOfMonth(): number {
    return this.moment.day();
  }

  get _moment(): Moment {
    return this.moment;
  }

  clone() {
    return new Date(this.moment.clone());
  }

  startOfDay() {
    this.moment.startOf('day');

    return this;
  }

  endOfDay() {
    this.moment.endOf('day');

    return this;
  }

  add(value: { days?: number; hours?: number; minutes?: number }) {
    this.moment.add(value);
    return this;
  }

  subtract(value: { days?: number; hours?: number; minutes?: number }) {
    this.moment.subtract(value);
    return this;
  }

  /**
   * https://momentjs.com/docs/#/displaying/
   */
  format(format?: string) {
    return this.moment.format(format);
  }

  isSame(other: Date | null, granularity?: unitOfTime.StartOf) {
    if (other == null || !(other instanceof Date)) {
      return false;
    }
    return this.moment.isSame(other.moment, granularity);
  }

  isBefore(other: Date | null, granularity?: unitOfTime.StartOf): boolean {
    if (other == null || !(other instanceof Date)) {
      return false;
    }

    return this.moment.isBefore(other.moment, granularity);
  }

  isAfter(other: Date | null, granularity?: unitOfTime.StartOf): boolean {
    if (other == null || !(other instanceof Date)) {
      return false;
    }

    return this.moment.isAfter(other.moment, granularity);
  }

  isSameOrBefore(other: Date | null, granularity?: unitOfTime.StartOf): boolean {
    if (other == null || !(other instanceof Date)) {
      return false;
    }

    return this.moment.isSameOrBefore(other.moment, granularity);
  }

  isSameOrAfter(other: Date | null, granularity?: unitOfTime.StartOf): boolean {
    if (other == null || !(other instanceof Date)) {
      return false;
    }

    return this.moment.isSameOrAfter(other.moment, granularity);
  }

  diff(end: Date, unitOfTime?: unitOfTime.Diff) {
    return this.moment.diff(end.moment, unitOfTime);
  }

  toString() {
    return this.format();
  }

  local(keepLocalTime = true) {
    this.moment.tz(moment.tz.guess(), keepLocalTime);
    return this;
  }

  tz(timezone: string, keepLocalTime = true) {
    this.moment.tz(timezone, keepLocalTime);
    return this;
  }

  // eslint-disable-next-line no-unused-vars
  utc(keepLocalTime = true) {
    this.moment.utc(true);
    return this;
  }

  toSystemDate() {
    return this.moment.toDate();
  }
}

export const now = () => {
  return new Date(moment(moment.now()));
};

export const min = (dates: Date[]): Date => {
  return fromString(moment.min(dates.map(date => date._moment)).toString());
};

export const max = (dates: Date[]): Date => {
  return fromString(moment.max(dates.map(date => date._moment)).toString());
};

export const fromUxTime = (uxtime: number, timezone?: string) => {
  if (timezone) {
    return new Date(moment.tz(uxtime, 'UTC').tz(timezone, true));
  } else {
    return new Date(moment(uxtime));
  }
};

export const fromString = (time: string, timezone?: string) => {
  return new Date(timezone ? moment.tz(time, timezone) : moment.parseZone(time));
};
