import { getIn } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { CustomInput, FormGroup, FormText } from 'reactstrap';
import { FormLabel } from '@components/forms/controls/FormLabel';

type InputProps = {
  [key: string]: any;
  id?: string;
  label?: string;
  className?: string;
  formGroupClassNames?: string;
  formText?: string;
  previewHidden?: boolean;
};

const FormInputImage: React.FunctionComponent<InputProps> = (props): JSX.Element => {
  const {
    field,
    label,
    form: { errors: formErrors, touched: formTouched, setFieldValue, initialValues },
    formText,
    formGroupClassNames,
    previewHidden,
    ...restProps
  } = props;

  const { name } = field;
  const { id } = restProps;

  const error = getIn(formErrors, name);
  const touched = getIn(formTouched, name);

  const invalid: boolean = !!(error && touched);

  const [imageSrc, setImageSrc] = useState<string | undefined>(initialValues[name] || undefined);

  return (
    <FormGroup className={formGroupClassNames}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <div className="d-flex flex-row justify-content-start align-items-center">
        {!previewHidden
          ? imageSrc && (
              <img
                className="mr-2"
                src={imageSrc}
                alt={name}
                style={{
                  maxWidth: '65px',
                  maxHeight: '65px'
                }}
              />
            )
          : null}

        <CustomInput
          invalid={invalid}
          {...field}
          {...restProps}
          onChange={(event: ChangeEvent<any>) => {
            const file: File = event.currentTarget.files[0];
            setFieldValue(`files.${name}`, file, false);
            if (file.type.includes('image')) {
              setImageSrc(URL.createObjectURL(file));
            }
          }}
          type="file"
        />
        {/* {invalid && <FormFeedback>{String(errors[name])}</FormFeedback>} */}
        {/* //TODO return when fixed validators */}
      </div>
      {formText && <FormText color="muted">{formText}</FormText>}
    </FormGroup>
  );
};

export default FormInputImage;
