import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { FormInputAsyncSelect, SelectStyles } from '@components/forms/controls';
import { required } from '@/libs/validators';
import { isEmpty } from '@/libs';
import { FormikTextField } from '@/shared/components/formik/fields';
import type { ProductTypeFormInitialProps } from '@components/forms/settings/ProductTypeForm/types';
import { productTypeFormInitialProps } from '../constants';
import { useDebounce } from '@/view/hooks/useDebounce';
import { ProductType } from '@/interfaces/brokrete';
import InlineConfirmationModal from '@components/modals/InlineConfirmationModal';
import { prefetchInitialProps } from '@components/forms/settings/CloneProductCategoryForm/helpers';
import prepareFormProps from '@components/forms/settings/CloneProductCategoryForm/helpers/prepareFormProps';
import { useGettext } from '@cranium/i18n';

type Props = {
  isFullEditState: boolean;
};

type ProductTypeResponseQueryType = {
  productTypes: {
    count: number;
    values: ProductTypeOptionType[];
  };
};

type ProductTypeOptionType = {
  id: string;
  name: string;
  serviceName: string;
};

const Root = styled.div`
  *[class*='-control'] {
    display: none;
  }
`;

const productTypesSearchQuery = gql`
  query productTypes($limit: Int, $search: String, $skip: Int) {
    productTypes(limit: $limit, skip: $skip, search: $search) {
      count
      values {
        id
        name
        serviceName
      }
    }
  }
`;

export function productTypeDataAdapter(data: ProductTypeResponseQueryType) {
  if (!data.productTypes) return [];

  return data.productTypes.values.map((value: ProductTypeOptionType) => ({
    data: value,
    label: `${value.serviceName}  (${value.name})`,
    value: value.name,
    id: value.id
  }));
}

const SelectOrAddProductName: React.FC<Props> = ({ isFullEditState }: Props) => {
  const { gettext } = useGettext();
  const confirmModalRef = useRef() as React.RefObject<InlineConfirmationModal>;
  const {
    values: { serviceName: productTypeName },
    setValues
  } = useFormikContext<ProductTypeFormInitialProps>();

  const [search, setSearch] = useState<undefined | string>(undefined);
  const debouncedSearch = useDebounce(search);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [initialProductTypeName, setInitialProductTypeName] = useState<undefined | string>(undefined);

  useEffect(() => {
    if (search === undefined) {
      setSearch(productTypeName);
    }

    if (initialProductTypeName === undefined) {
      setInitialProductTypeName(productTypeName);
    }
  }, [productTypeName, search, initialProductTypeName]);

  const onInputChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value: string) => {
    if (!menuIsOpen) {
      setMenuIsOpen(true);
    }

    setSearch(e.target.value);
  };

  const onSelectProductTypeClone = useCallback(
    async (value: { data: Partial<ProductType> & { id: string; name: string } }) => {
      if (confirmModalRef.current) {
        const { id, ...restProps } = value.data;

        // TODO: Text is made for demo. Probably we should change it
        const { confirmed } = await confirmModalRef.current.open({
          title: 'Do you confirm this action?',
          canCancel: true,
          content: (
            <div>
              <div>You are going to create a clone from {restProps.name} Product Type.</div>
              <div>All previous data you entered will be overwritten!</div>
            </div>
          )
        });

        if (confirmed) {
          const initialProps = (await prefetchInitialProps({
            productTypeId: id,
            initialProps: restProps as unknown as Partial<ProductTypeFormInitialProps>
          })) as unknown as ProductTypeFormInitialProps;

          setInitialProductTypeName(restProps.name);
          setSearch(restProps.name);
          setMenuIsOpen(false);

          setValues({
            ...productTypeFormInitialProps,
            ...(await prepareFormProps(initialProps))
          });
        }
      }
    },
    [confirmModalRef]
  );

  const onInputFieldFocus = () => {
    if (search !== initialProductTypeName) {
      setMenuIsOpen(true);
    }
  };

  const onInputFieldBlur = () => {
    setMenuIsOpen(false);
  };

  const productTypeQueryOptions = useMemo(() => {
    return {
      query: productTypesSearchQuery,
      variables: {
        skip: 0,
        limit: 20,
        ...(debouncedSearch && !isEmpty(debouncedSearch) && { search: debouncedSearch })
      }
    };
  }, [debouncedSearch]);

  return (
    <Root>
      <FormikTextField
        name="serviceName"
        label={gettext('Product Name')}
        placeholder="e.g. “ADHL-500”"
        validate={required}
        onChange={onInputChanged}
        onFocus={onInputFieldFocus}
        onBlur={onInputFieldBlur}
        autoComplete="off"
        disabled={!isFullEditState}
      />

      <Field
        name="productTypeToClone"
        id="productTypeToClone"
        placeholder={gettext('Select Contractor')}
        component={FormInputAsyncSelect}
        queryOptions={productTypeQueryOptions}
        dataAdapter={productTypeDataAdapter}
        onChange={onSelectProductTypeClone}
        menuIsOpen={menuIsOpen}
        styles={SelectStyles.default}
        test={true}
      />

      <InlineConfirmationModal ref={confirmModalRef} />
    </Root>
  );
};

export default SelectOrAddProductName;
