import React from 'react';
import styled from 'styled-components';
import { colors } from '@/defaultStyles';

const Textarea = styled.textarea`
  border: 1px solid ${colors.LIGHT_PERIWINKLE};
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  outline: none;
  min-height: 100px;
  &:active,
  &:focus {
    border-color: ${colors.ALGAE_GREEN};
  }
`;

export default Textarea;
