import { User } from '@/interfaces/brokrete';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

export const useMePartnerLogo = () => {
  const [logo, setLogo] = useState<string | 'default' | undefined>();
  const { data, refetch } = useQuery<{ me: User }>(ME_QUERY, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data) {
      const { me } = data;
      if (me.partner && me.partner.logo) {
        setLogo(`${me.partner.logo}?rnd=${Math.random()}`);
      } else {
        setLogo('default');
      }
    }
  }, [data, setLogo]);

  return { logo, refetch };
};

/* gql */
const ME_QUERY = gql`
  query me {
    me {
      partner {
        id
        logo
      }
    }
  }
`;
