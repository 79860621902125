import React from 'react';
import styled from 'styled-components';
import { colors } from '@/defaultStyles';

const Container = styled.div<{ css: string }>`
  padding-bottom: 10px;
  text-align: start;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${colors.CHARCOAL_GREY};
  ${props => props.css};
`;
const Header: React.FunctionComponent<{ css?: string }> = ({ children, css = '', ...rest }) => {
  return (
    <Container css={css} {...rest}>
      {children}
    </Container>
  );
};
export default Header;
