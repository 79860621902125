import moment from 'moment';

type TObject = { [key: string]: any };
// TODO: Fix ts errors
// @ts-nocheck
function merge<TSource = TObject, TDestination = TObject>(
  source: TSource,
  destination: TDestination,
  options: { deep?: boolean | number; strict?: boolean } = {}
): TSource & Partial<TDestination> {
  if (options.deep === undefined) {
    options.deep = true;
  }

  if (typeof options.deep === 'number') {
    options.deep -= 1;

    if (options.deep <= 0) {
      options.deep = false;
    }
  }

  // eslint-disable-next-line no-unused-vars
  const destinationEntries = Object.entries(destination || {}).filter(([name, value]) => {
    if (options.strict) {
      return value !== undefined;
    } else {
      return value != null;
    }
  });

  if (destinationEntries.length === 0) {
    return source;
  }

  return destinationEntries.reduce(
    (result: TSource & Partial<TDestination>, [name, value]) => {
      // @ts-ignore
      if (options.deep === true || options.deep > 0) {
        // @ts-ignore
        if (result[name] != null && typeof result[name] === typeof value) {
          if (typeof value === 'object' && !Array.isArray(value) && !(value instanceof moment)) {
            // @ts-ignore
            value = merge(result[name], value, options);
          }
        }
      }

      // @ts-ignore
      result[name] = value;

      return result;
    },
    { ...source }
  );
}

export default merge;
