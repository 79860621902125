import React, { useMemo, useState } from 'react';
import { i18n } from '@/core';
import { ModificationTypeEnum, ProductTypeModifications, ProductTypeOptionValue } from '@/interfaces/brokrete';
import { required } from '@/libs/validators';
import ActivityIndicator from '@components/elements/ActivityIndicator';
import { FormInputAsyncSelect, SelectStyles } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { withApolloContext } from '@components/withApolloContext';
import { Field, FormikProps, FieldArray } from 'formik';
import gql from 'graphql-tag';
import styled from 'styled-components';
import ICONS from '@/assets/images/icons';
import { FormikTextField, FormikTextAreaField } from '@/shared/components/formik/fields';
import BaseModalView, { Body, Footer, Navigation } from '@/shared/components/modal/BaseModalView';
import Button, { Type } from '@/shared/components/buttons/Button';
import { colors } from '@/defaultStyles';
import SortableGrid from './components/SortableGrid';
import { hideModal } from '@components/modals';

import Section from './components/Section';
import ProductModificationModal from './components/ProductModificationModal';
import { date, isEmpty } from '@/libs';
import ProductModificationSection from './components/ProductModificationSection';
import { keys } from 'lodash';
import ProductTypeOptionCombinations from '@components/forms/settings/ProductTypeForm/components/ProductTypeOptionCombinations';
import SelectOrAddProductName from '@components/forms/settings/ProductTypeForm/components/SelectOrAddProductName';
import FileUpload from '@pages/Projects/NewProjectScreen/Sections/Components/FileUpload';
import { FileViewer } from '@/shared/components/dataDisplay/FileVIewer';
import Label from '@/shared/components/formik/fields/Label';
import { FormGroup } from 'reactstrap';
import type { ProductTypeFormInitialProps } from './types';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ActiveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  margin-top: 30px;
  margin-bottom: 20px;
  line-height: 1.29;
  letter-spacing: 0.23px;
  white-space: pre-line;
  color: ${colors.CHARCOAL_GREY};
  :first-child {
    margin-top: 20px;
  }
`;

const BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div<{ noMarging?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => (props.noMarging ? '0' : '20px')};
`;

const InputContainer = styled.div`
  min-width: 230px;
  span {
    font-size: 10px;
  }
  .form-group {
    display: grid;
    grid-gap: 4px;
    width: 100%;
    margin-bottom: 0;
  }
`;

const TextAreaContainer = styled(InputContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
  > div > textarea {
    width: 100%;
    min-height: 84px;
  }
`;

const CreateNewButton = styled.button`
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 30px;
  color: ${colors.ALGAE_GREEN};
  background-color: transparent;
  border: none;
  > span {
    margin-left: 10px;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
`;

const PhotoBoundary = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 260px;
  max-height: 260px;
  margin-bottom: 20px;
`;

const PlaceholderContainer = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

const PhotoPlaceholder = styled.div`
  width: 115px;
  height: 115px;
  border-radius: 10px;
  margin-right: 14px;
  :nth-child(4n) {
    margin-right: 0;
  }
  & img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: dashed 2px ${colors.LIGHT_PERIWINKLE};
  }
  &:hover {
    cursor: not-allowed;
  }
`;

const Add = styled.img`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 20px;

  cursor: pointer;
`;

const ProductModificationListContainer = styled.div`
  & > * {
    margin-bottom: 20px;
  }
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.ALGAE_GREEN};
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 30px;

  cursor: pointer;

  ${Add} {
    margin-right: 10px;
  }
`;

const AddModificationSetButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 5px;
  background-color: #e8f7ec;
  color: ${colors.ALGAE_GREEN};

  cursor: pointer;
`;

const ProductTypeForm: React.FunctionComponent<Props> = props => {
  const { handleSubmit, isSubmitting, isValid, dirty, initialValues, values, setFieldValue, mode } = props;
  const { id, name, group, permissions, product } = initialValues;
  const { modifications, optionCombinations } = values;
  const specification = values.specification;
  const [formState, setFormState] = useState<FormState>({ allowImages: true, newGroupState: false });
  const [productModificationModalIsOpen, setProductModificationModalIsOpen] = useState(false);

  const groupedModifications = useMemo(() => {
    return modifications.reduce<{
      [key: string]: {
        label: string;
        type: ModificationTypeEnum;
        values: ProductTypeModifications[];
      };
    }>((memo, modification) => {
      if (memo[modification.label] != null) {
        memo[modification.label].values.push(modification);
      } else {
        memo[modification.label] = {
          label: modification.label,
          type: modification.type,
          values: [modification]
        };
      }

      return memo;
    }, {});
  }, [modifications]);

  const isCloneMode = mode === 'clone';
  const isEditState = useMemo(() => !!name && !!group, [name, group]);
  const isFullEditState = useMemo(
    () => (permissions && permissions.fullEdit != null ? permissions.fullEdit : !isEditState),
    [permissions, isEditState]
  );

  const onAddModification = () => {
    setProductModificationModalIsOpen(true);
  };

  const onAddModificationCombination = () => {
    const newOptionCombinations = optionCombinations ? [...optionCombinations] : [];
    newOptionCombinations.push({
      add: true,
      id: Math.random().toString(),
      value: '',
      description: '',
      modifications: []
    });

    setFieldValue('optionCombinations', newOptionCombinations);
  };

  const onCloneModificationCombination = (optionCombination: ProductTypeOptionValue) => {
    const newOptionCombinations = optionCombination ? [...optionCombinations] : [];
    newOptionCombinations.push({
      add: true,
      id: Math.random().toString(),
      value: optionCombination.value + ' (copy)',
      description: optionCombination.description,
      modifications: [...optionCombination.modifications]
    });

    setFieldValue('optionCombinations', newOptionCombinations);
  };

  const addSpecification = async (files: File[]) => {
    const file: File = files[0];

    const resource = {
      id: Math.random().toString(),
      source: URL.createObjectURL(files[0]),
      mymeType: file.type,
      createdAt: date.now().toString(),
      file: file
    };

    setFieldValue('specification', resource);
  };

  return (
    <BaseModalView title={i18n.t('labels.newProductType')} css="padding: 20px 10px 10px; text-align: left">
      <Body>
        <Form onSubmit={handleSubmit}>
          <Title>{i18n.t('labels.basicInformation')}</Title>
          <BasicInfoContainer>
            <Row>
              <InputContainer>
                <SelectOrAddProductName isFullEditState={isFullEditState || isCloneMode} />
              </InputContainer>
              <InputContainer>
                <FormikTextField
                  name="name"
                  label={i18n.t('labels.storefrontName')}
                  placeholder="e.g. “Sealer ADHL”"
                  validate={required}
                />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <FormikTextField name="exportCode" label={i18n.t('labels.exportCode')} />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                {!formState.newGroupState ? (
                  <Field
                    name="group"
                    label={i18n.t('labels.chooseSubcategory')}
                    component={FormInputAsyncSelect}
                    styles={SelectStyles.default}
                    queryOptions={{
                      query: GROUPS_QUERY,
                      variables: {
                        product: { id: product.id }
                      },
                      fetchPolicy: 'no-cache'
                    }}
                    dataAdapter={(responseData: { productTypes: { groups: string[] } }) => {
                      if (!responseData.productTypes) return [];
                      return responseData.productTypes.groups
                        .filter(v => !!v)
                        .map((value: string) => ({
                          label: value,
                          value
                        }));
                    }}
                    disabled={!isFullEditState && !isCloneMode}
                  />
                ) : (
                  <FormikTextField
                    name="group"
                    label={i18n.t('labels.addSubcategory')}
                    validate={required}
                    placeholder='e.g. "Sealers"'
                    disabled={!isFullEditState && !isCloneMode}
                  />
                )}
              </InputContainer>
              <InputContainer>
                {(isFullEditState || isCloneMode) && (
                  <CreateNewButton
                    data-testid="newGroupButton"
                    disabled={!isFullEditState && !isCloneMode}
                    type="button"
                    onClick={() => {
                      setFieldValue('group', '');
                      setFormState(prev => ({ ...prev, newGroupState: !prev.newGroupState }));
                    }}
                  >
                    <img src={ICONS.icPlusGreenCircle} width="20" height="20" alt="Add new item" />
                    <span>{i18n.t('labels.addNewSubcategory')}</span>
                  </CreateNewButton>
                )}
              </InputContainer>
            </Row>
            <Title>{i18n.t('labels.productPhoto')}</Title>
            <FieldArray
              name="descriptionResources"
              render={arrayHelpers => {
                return (
                  <PhotoBoundary>
                    <PlaceholderContainer>
                      {Array(8)
                        .fill(null)
                        .map((_, index: number) => (
                          <PhotoPlaceholder key={`photo-placeholder_${index}`}>
                            <img src={ICONS.icSealer} alt="Add new product" />
                          </PhotoPlaceholder>
                        ))}
                    </PlaceholderContainer>
                    <SortableGrid photos={values.descriptionResources} {...arrayHelpers} />
                  </PhotoBoundary>
                );
              }}
            />
            <Row noMarging>
              <TextAreaContainer>
                <FormikTextAreaField
                  name="shortDescription"
                  label={i18n.t('labels.shortDescription')}
                  placeholder="e.g. “Alkyd Resin, Epoxy, Fillers”"
                />
                <span>Use a short description of your product</span>
              </TextAreaContainer>
            </Row>

            <Row noMarging>
              <TextAreaContainer>
                <FormikTextAreaField
                  name="description"
                  label={i18n.t('labels.description')}
                  placeholder="e.g. “Alkyd Resin, Epoxy, Fillers”"
                />
                <span>Use a general description of your product</span>
              </TextAreaContainer>
            </Row>

            <Row noMarging>
              <FormGroup>
                <Label>{i18n.t('labels.specification')}</Label>
                {specification ? (
                  <FileViewer
                    height={90}
                    controlsConfig={{
                      onDelete: item => {
                        setFieldValue('specification', null);
                      }
                    }}
                  >
                    <FileViewer.Item
                      id={specification.id}
                      source={specification.source}
                      mimeType={specification.mimeType}
                      createdAt={specification.createdAt}
                    />
                  </FileViewer>
                ) : (
                  <FileUpload addFiles={addSpecification} multiple={false} />
                )}
              </FormGroup>
            </Row>

            <Section
              title={i18n.t('labels.generalProductMods')}
              info={i18n.t('labels.productModsCaption')}
              modificationSection={true}
            >
              {id && (
                <ProductModificationListContainer>
                  {keys(groupedModifications).map((modificationType, idx) => {
                    return (
                      <ProductModificationSection
                        key={`${idx}_${groupedModifications[modificationType].label}`}
                        productId={id}
                        optionCombinations={optionCombinations}
                        modification={groupedModifications[modificationType]}
                        onChanged={({ modifications }) => {
                          setFieldValue('modifications', modifications);
                        }}
                      />
                    );
                  })}

                  <AddButton data-testid="addNewOptionButton" onClick={onAddModification}>
                    <Add src={ICONS.icPlusGreenCircle} alt="Add new option" />
                    Add Parameter
                  </AddButton>

                  {!isEmpty(modifications) && (
                    <>
                      <ProductTypeOptionCombinations
                        productType={{ id, modifications, optionCombinations }}
                        onClone={onCloneModificationCombination}
                        onChange={(
                          optionCombination: ({ delete?: boolean; add?: boolean } & ProductTypeOptionValue) | null,
                          index
                        ) => {
                          if (optionCombination) {
                            setFieldValue(`optionCombinations.[${index}]`, optionCombination);
                          } else {
                            const newOptionCombinations = optionCombination ? [...optionCombinations] : [];

                            newOptionCombinations.splice(index, 1);

                            setFieldValue('optionCombinations', newOptionCombinations);
                          }
                        }}
                      />

                      <AddModificationSetButton data-testid="addModificationButton" onClick={onAddModificationCombination}>
                        + {i18n.t('labels.addModificationSet')}
                      </AddModificationSetButton>
                    </>
                  )}

                  {productModificationModalIsOpen && (
                    <ProductModificationModal
                      productId={id}
                      modification={{
                        label: '',
                        // @ts-ignore
                        type: undefined,
                        values: []
                      }}
                      isOpened={productModificationModalIsOpen}
                      onClosed={() => setProductModificationModalIsOpen(false)}
                      onSubmit={({ modifications }) => {
                        setProductModificationModalIsOpen(false);
                        setFieldValue('modifications', modifications);
                      }}
                    />
                  )}
                </ProductModificationListContainer>
              )}
            </Section>
          </BasicInfoContainer>
          {isSubmitting ? (
            <ActiveContainer>
              <ActivityIndicator />
            </ActiveContainer>
          ) : (
            <Footer navigation={Navigation.CUSTOM} css="padding: 0; display: flex; justify-content: flex-end;">
              <Button data-testid="cancel" type={Type.TEXT} onClick={() => hideModal()}>
                {i18n.t('labels.cancel')}
              </Button>
              <Button
                data-testid="add"
                disabled={!isValid || isSubmitting || !dirty}
                onClick={() => {
                  setFieldValue('submitAndContinue', false, false);
                  handleSubmit();
                }}
                css="width: 101px;"
              >
                {i18n.t('labels.add')}
              </Button>
            </Footer>
          )}
        </Form>
      </Body>
    </BaseModalView>
  );
};

const productTypeFormWithFormik = makeFormik<ProductTypeFormInitialProps>(ProductTypeForm);
const ProductTypeFormWithApollo = withApolloContext(productTypeFormWithFormik);

export default productTypeFormWithFormik;
export { ProductTypeFormWithApollo };

/* constants */

const GROUPS_QUERY = gql`
  query productTypes($product: IdInput) {
    productTypes(product: $product, root: false) {
      groups
    }
  }
`;

type Props = { mode?: 'clone' } & FormikProps<ProductTypeFormInitialProps>;

type FormState = {
  allowImages: boolean;
  newGroupState: boolean;
};
