import React from 'react';
import styled from 'styled-components';

import Text from '@components/elements/Text';
import Column from '@components/elements/Column';
import { colors } from '@/defaultStyles';
import { SVGToughCommerceLogo } from '@components/elements/icons/SVGToughCommerceLogo';

type PoweredProps = {
  className?: string;
};

const Powered: React.FC<PoweredProps> = props => {
  return (
    <div className={props.className}>
      <Column alignItems="center">
        <StyledText transform="uppercase" text="Powered by" />
        <SVGToughCommerceLogo />
      </Column>
    </div>
  );
};

const StyledPowered = styled(Powered)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 72px;
`;

const StyledText = styled(Text)`
  font-size: 10px;
  color: ${colors.BATTLESHIP_GREY};
  margin-bottom: 5px;
`;

export default StyledPowered;
