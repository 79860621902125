import React, { MutableRefObject, useCallback } from 'react';
import InputMask from 'react-input-mask';
import { Input } from 'reactstrap';
import { InputProps } from 'reactstrap/lib/Input';

type MaskedInputProps = {
  innerRef?: MutableRefObject<Input>;
  onChange?: (value: number) => void;
  mask: string | (string | RegExp)[];
} & InputProps;

const MaskedInput: React.FC<MaskedInputProps> = (props: MaskedInputProps) => {
  const { innerRef, className, placeholder, value, mask, name, disabled, readOnly, onChange } = props;

  const handleInputChange = useCallback(
    e => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  return (
    <InputMask mask={mask} placeholder={placeholder} value={value || ''} onChange={handleInputChange}>
      <Input className={className} name={name} ref={innerRef} disabled={disabled} readOnly={readOnly} />
    </InputMask>
  );
};

export default MaskedInput;
