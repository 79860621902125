import { createAction } from '@/rdx/libs/action';
import { ThunkDispatch } from 'redux-thunk';
import { setup } from '@/core/StoreProxy';
import type { RootStoreState } from '@/rdx';

export const ActionTypes = {
  INITIALIZE: 'session.initialize',
  RESET: 'session.reset'
};

const initialize = () => createAction(ActionTypes.INITIALIZE);
const reset = () => createAction(ActionTypes.RESET);

const start = () => {
  return async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootStoreState) => {
    setup({ dispatch, getState });

    await dispatch(initialize());
  };
};

export const Actions = {
  start,
  reset
};
