export function camelToSnakeCase(text: string) {
  return text
    .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
    .replace(/([a-z0-9])([A-Z])/, '$1_$2')
    .toLowerCase();
}

export function toNumber(value?: string | number | null): number {
  if (value === undefined || value === null) {
    return 0;
  }
  if (typeof value === 'string') {
    return parseFloat(value);
  }

  return value;
}

export function toArrayBuffer(value: string) {
  const buf = new ArrayBuffer(value.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < value.length; i++) {
    bufView[i] = value.charCodeAt(i);
  }

  return buf;
}

export function capitalize(value: string | undefined) {
  if (!value) {
    return '';
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
}
