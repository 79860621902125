import gql from 'graphql-tag';

export const productCategories = gql`
  query productCategories($contractor: IdInput, $input: OrderPreparatoryConfigInput, $region: RegionInput) {
    orderPreparatoryConfig(input: $input, contractor: $contractor) {
      products(region: $region) {
        id
        name
        industry
        permissions {
          options
          service
          trucks
          decorates
        }
      }
    }
  }
`;
