import { ApolloLink } from '@apollo/client';
import { LocalStorage } from '@/core/Storage';

export const initLangLink = () => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'Accept-Language': LocalStorage.getItem('lang') || 'en'
      }
    }));

    return forward(operation);
  });
};
