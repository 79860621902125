import React from 'react';
import styled from 'styled-components';
import Header from './Header';

const Container = styled.div<{ css?: string }>`
  display: grid;
  ${props => props.css && props.css}
`;
interface Props {
  title?: React.ReactNode;
  css?: string;
}

/**
 * Used as base component for the showModal html
 * showModal({ html: <BaseModalView">...</BaseModalView>, showCloseButton: false });
 */
const BaseModalView: React.FunctionComponent<Props> = ({ title, children, css, ...rest }) => {
  return (
    <Container css={css} {...rest}>
      {title && <Header>{title}</Header>}
      {children}
    </Container>
  );
};
export default BaseModalView;
