import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { colors } from '@/defaultStyles';

export enum Type {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OUTLINED = 'outlined',
  TEXT = 'text'
}
const colorConfig = {
  [Type.PRIMARY]: {
    color: colors.WHITE,
    background: colors.GREEN,
    border: colors.GREEN
  },
  [Type.SECONDARY]: {
    color: colors.WHITE,
    background: colors.CLOUDY_BLUE,
    border: colors.CLOUDY_BLUE
  },
  [Type.OUTLINED]: {
    color: colors.BATTLESHIP_GREY,
    background: 'transparent',
    border: colors.LIGHT_PERIWINKLE
  },
  [Type.TEXT]: {
    color: colors.BATTLESHIP_GREY,
    background: 'transparent',
    border: 'transparent'
  }
};
const getColor = (type: Type): string => colorConfig[type].color;
const getBackgroundColor = (type: Type): string => colorConfig[type].background;
const getBorder = (type: Type): string => colorConfig[type].border;

const Container = styled.div<{ disabled?: boolean; css?: CSSProp }>`
  cursor: pointer;
  ${props =>
    props.disabled &&
    `
      cursor: not-allowed;
    `}
  ${props => props.css}
`;

const ButtonInput = styled.button<{ disabled?: boolean; buttonType: Type; css: string }>`
  padding: 9px 21px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => getColor(props.buttonType)};
  background-color: ${props => getBackgroundColor(props.buttonType)};
  border: solid 1px ${props => getBorder(props.buttonType)};
  border-radius: 2px;
  transition: all 0.2s ease 0.2s;
  ${props =>
    props.disabled &&
    `
      border-color: transparent;
      background-color: ${colors.CLOUDY_BLUE};
      pointer-events: none;
    `}
  ${props => props.css}
`;

interface Props {
  [key: string]: any;
  type?: Type | Type.PRIMARY;
  disabled?: boolean;
  onClick?: () => void;
  css?: string;
  containerStyles?: CSSProp;
}
const Button: React.FunctionComponent<Props> = ({
  type = Type.PRIMARY,
  containerStyles,
  htmlType = 'button',
  css = '',
  disabled,
  onClick,
  children,
  ...rest
}) => {
  return (
    <Container disabled={disabled} css={containerStyles}>
      <ButtonInput type={htmlType} buttonType={type} css={css} disabled={disabled} onClick={onClick} {...rest}>
        {children}
      </ButtonInput>
    </Container>
  );
};

export default Button;
