import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import styled from 'styled-components';
import { FieldConfig, useField, useFormikContext } from 'formik';

import Label from './Label';
import CheckboxGreen from '@components/elements/CheckboxGreen';

interface FormikCheckboxProps<T = {}> extends FieldConfig {
  className?: string;
  label: string;
  data?: T;
  useBoolean?: boolean;
  disabled?: boolean;
}

const Root = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const CheckboxFormLabel = styled(Label)`
  margin-left: 8px;
  margin-top: 1px;
  text-transform: none;
  font-size: 14px;
  font-weight: normal;
  height: unset;
`;

const FormikCheckbox: React.FC<FormikCheckboxProps> = ({ className, data, useBoolean, label, disabled = false, ...props }) => {
  const [{ name, value }] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    const nextState = !checked;
    const fieldValue = useBoolean ? nextState : nextState ? data : undefined;

    setFieldValue(name, fieldValue);
    setChecked(nextState);
  };

  useEffect(() => {
    if (value !== checked) {
      setChecked(value);
    }
  }, [value, checked, setChecked]);

  return (
    <Root className={className} onClick={handleChange} disabled={disabled}>
      <CheckboxGreen checked={checked} />
      <CheckboxFormLabel>{label || startCase(props.name)}</CheckboxFormLabel>
    </Root>
  );
};

export default FormikCheckbox;
