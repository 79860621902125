import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import styled from 'styled-components';
import { colors } from '@/defaultStyles';
import SortableItem from './SortableItem';
import ICONS from '@/assets/images/icons';
import { i18n } from '@/core';
import { ProductTypeImageResource } from '@/interfaces/brokrete';

const SortableListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 260px;
  max-height: 260px;
  overflow-x: hidden;
  .product-preview-photo {
    :nth-child(4n) {
      margin-right: 0;
    }
  }
`;

const AddPhotoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 115px;
  height: 115px;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 115px;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  background-color: #e8f7ec;
  color: ${colors.ALGAE_GREEN};
  border: dashed 2px ${colors.ALGAE_GREEN};
  border-radius: 10px;
  & img {
    position: absolute;
    filter: invert(51%) sepia(46%) saturate(782%) hue-rotate(82deg) brightness(100%) contrast(91%);
  }
  & span {
    position: relative;
    top: 33px;
  }
  & input[type='file'] {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const SortableList = SortableContainer(({ photos, form, ...arrayHelpers }: SortableListProps) => (
  <SortableListContainer>
    {photos.map((photo, index: number) => (
      <SortableItem key={`sortable-product_${photo.id}`} index={index} productIndex={index} photo={photo.url} {...arrayHelpers} />
    ))}
    {photos.length !== 8 && (
      <AddPhotoContainer>
        <Label htmlFor="add-product-image-input" className="add-product-image">
          <input
            id="add-product-image-input"
            name={`descriptionResources[${photos.length}]`}
            type="file"
            accept="image/png, image/jpeg"
            onChange={(event: React.ChangeEvent<any>) => {
              const file: File = event.currentTarget.files[0];
              form.setFieldValue(`descriptionResources[${photos.length}]`, { file, url: URL.createObjectURL(file) }, false);

              event.target.value = null;
            }}
          />
          <img src={ICONS.icAdd} width="60" height="60" alt="Add new product" />
          {photos.length === 0 ? <span>{i18n.t('labels.mainPhoto')}</span> : null}
        </Label>
      </AddPhotoContainer>
    )}
  </SortableListContainer>
));

type SortableListProps = {
  photos: Array<ProductTypeImageResource>;
  [key: string]: any;
};

export default SortableList;
