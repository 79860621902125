import React, { useEffect, useMemo, useState } from 'react';
import ColourPickerSLSquare from './ColourPickerSLSquare';
import ColourPickerHueBar from './ColourPickerHueBar';
import styled from 'styled-components';
import { convertRGBtoHEX } from './util';
import SuggestedColours from './SuggestedColours';
import { colors } from '@/defaultStyles';

type PickerProps = {
  hue: number;
  hueX: number;
  squareXY: number[];
  colour: string | undefined;
  css?: {
    buttonCss?: string;
    colorPickerCss?: string;
    hueBarCss?: string;
  };
  setHue: (hue: number) => void;
  setHueX: (hueX: number) => void;
  setSquareXY: (squareXY: number[]) => void;
  setColour: (colour: string | undefined) => void;
  defaultColours?: boolean;
  onCancel: () => void;
};

const ButtonGroup = styled.div`
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Cancel = styled.div`
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.BATTLESHIP_GREY};
  cursor: pointer;
`;

const Accept = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.ALGAE_GREEN};
  cursor: pointer;
`;

const ColourPickerButton: React.FunctionComponent<PickerProps> = props => {
  const { hue, hueX, squareXY, css, setHue, setHueX, setSquareXY, setColour, defaultColours, onCancel } = props;

  const [color, setColor] = useState(props.colour);
  useEffect(() => {
    setColor(props.colour);
  }, [props.colour]);

  const colourHex = color ? `#${convertRGBtoHEX(color)}` : null;

  const onSetColor = (color: any) => {
    setColor(color);
  };

  const onAcceptColor = () => {
    setColour(color ? `#${convertRGBtoHEX(color)}` : '');
    onCancel();
  };

  return (
    <PickerContainer css={css?.colorPickerCss} defaultColours={defaultColours}>
      <ColourPickerSLSquare hue={hue} squareXY={squareXY} colour={color} setSquareXY={setSquareXY} setColour={onSetColor} />
      <ColourPickerHueBar hue={hue} hueX={hueX} setHueX={setHueX} setHue={setHue} css={css?.hueBarCss} />
      <PickerText>{colourHex}</PickerText>
      {defaultColours && <SuggestedColours setColour={onSetColor} setHue={setHue} setHueX={setHueX} setSquareXY={setSquareXY} />}
      <Spacer />

      <ButtonGroup>
        <Cancel onClick={onCancel}>Cancel</Cancel>
        <Accept onClick={onAcceptColor}>Accept</Accept>
      </ButtonGroup>
    </PickerContainer>
  );
};

const PickerContainer = styled.div<{ css?: string; defaultColours?: boolean }>`
  position: absolute;
  z-index: 2;
  margin-top: 5px;
  margin-left: 8px;
  height: ${props => (props.defaultColours ? '320px' : '296px')};
  width: 220px;
  background-color: #fff;
  display: block;
  box-shadow: 0 3px 12px 2px rgba(38, 42, 54, 0.08);
  ${props => props.css && props.css}
`;

const PickerText = styled.div`
  margin-top: 12px;
  margin-left: 12px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 10px;
  font-weight: 700;
  display: flex;
`;

const Spacer = styled.div`
  height: 10px;
  background: transparent;
`;

export default ColourPickerButton;
