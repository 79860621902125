import React, { useState } from 'react';
import { isEqual, omit, pick } from 'lodash';
import { Modal } from 'reactstrap';
import styled from 'styled-components';
import { colors } from '@/defaultStyles';
import FormGroup from '@/shared/components/formik/fields/FormGroup';
import Label from '@/shared/components/formik/fields/Label';
import { Input } from '@components/elements';

import { IdInput, ModificationTypeEnum, ProductTypeModificationGroup, ProductTypeModifications } from '@/interfaces/brokrete';

import Button, { Type } from '@/shared/components/buttons/Button';
import TagsInput from '@/shared/components/dataEntry/TagsInput';
import Select, { useGetSelectOptions } from './Select';

import ProductModificationTag from './ProductModificationTag';
import ColorModificationTag from './ColorModificationTag';
import TextureModificationTag from './TextureModificationTag';
import { isEmpty } from '@/libs';
import { productTypeModificationsUpdate } from './gql';
import { buildIdInput } from '@/shared/api/brokrete/helper';
import { showToastify } from '@components/modals';
import { i18n } from '@/core';

type ProductModificationModalProps = {
  productId: string;
  modification: ProductTypeModificationGroup;
  isOpened: boolean;
  onClosed: () => void;
  onSubmit: (values: {
    productTypeModificationGroup: ProductTypeModificationGroup;
    modifications: ProductTypeModifications[];
  }) => void;
};

interface ProductTypeModificationStateValue extends ProductTypeModifications {
  new?: boolean;
  deleted?: boolean;
  changed?: boolean;
}

export type SubmitValues = {
  add?: {
    label: string;
    type: ModificationTypeEnum;
    values: ProductTypeModifications[];
  };
  change?: {
    label: string;
    type: ModificationTypeEnum;
    values: ProductTypeModifications[];
  };
  remove?: IdInput[];
};

const ModalStyled = styled(Modal)`
  min-width: 563px;
`;

const ModalBodyStyled = styled.div`
  padding: 20px 30px;

  ${FormGroup} {
    margin-bottom: 30px;
  }
`;

const InputStyled = styled(Input)`
  input {
    background-color: ${colors.WHITE};
    border-color: ${colors.LIGHT_PERIWINKLE};
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${colors.CHARCOAL_GREY};

  margin-bottom: 20px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 45px;
`;

const TagsInputStyled = styled(TagsInput)`
  background-color: #fff;
  border: 1px solid ${colors.LIGHT_PERIWINKLE};
  border-radius: 4px;
  padding-left: 5px;
  padding-top: 5px;

  &.react-tagsinput--focused {
    border-color: ${colors.ALGAE_GREEN};
  }

  & > span:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
  }

  .react-tagsinput-input {
    flex: 1;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const DisplayTypeToTagInputComponent = {
  list: ProductModificationTag,
  color: ColorModificationTag,
  image: TextureModificationTag
};

const ProductModificationModal: React.FC<ProductModificationModalProps> = ({
  isOpened,
  productId,
  modification: initialModification,
  onClosed,
  onSubmit: onChanged
}) => {
  const [, setLoading] = useState(false);
  const DisplayAsOptions = useGetSelectOptions();
  const [displayAsOption, setDisplayAsOption] = useState(
    (initialModification.type && DisplayAsOptions.find(opt => opt.value === initialModification.type)) || DisplayAsOptions[0]
  );
  const [state, setState] = useState<{
    label: string;
    type: ModificationTypeEnum;
    values: ProductTypeModificationStateValue[];
  }>({ ...initialModification, type: displayAsOption.value as ModificationTypeEnum });

  const onOptionChanged = (tags: any[], changed: any[], changedIndexes: number[]) => {
    const productTypeModifications = state.values;

    // New modification was added
    if (tags.length > productTypeModifications.length) {
      // @ts-ignore
      const modification: ProductTypeModificationStateValue = {
        name: changed[0],
        type: state.type,
        label: state.label,
        new: true,
        deleted: false,
        changed: false
      };

      // @ts-ignore
      setState(st => ({ ...st, values: [...st.values, modification] }));
    }
    // Modification was removed
    else if (tags.length < productTypeModifications.length) {
      const removedModification = changed[0];

      if (removedModification.new) {
        setState(st => ({ ...st, values: tags }));
      } else {
        const modification = { ...removedModification, deleted: true };
        setState(st => ({ ...st, values: [...tags, modification] }));
      }
    }
    // Modification was changed
    else {
      const changedModification = changed[0];

      tags[changedIndexes[0]] = {
        ...changedModification,
        changed: !changedModification.new
      };

      // @ts-ignore
      setState(st => ({ ...st, values: [...tags] }));
    }
  };

  // TODO: Doesn't work correctly
  const valid = () => {
    return !isEqual(state, initialModification) || (!isEmpty(state.label) && !isEmpty(state.type));
  };

  const onSubmit = async () => {
    const label = state.label;
    const type = state.type;

    const changes = state.values.reduce<SubmitValues>((memo, modification) => {
      const isNew = modification.new ?? false;
      const isChanged = modification.changed ?? false;
      const isDeleted = modification.deleted ?? false;

      const mod = omit(modification, ['new', 'changed', 'deleted']);
      let action: 'add' | 'change' | 'remove' | null = null;

      if (isNew) action = 'add';
      if (isChanged) action = 'change';
      if (isDeleted) action = 'remove';

      if (!action) {
        if (initialModification.label !== state.label || initialModification.type !== state.type) {
          action = 'change';
        }
      }

      if (action) {
        if (!(action in memo)) {
          if (action === 'remove') {
            memo[action] = [];
          } else {
            memo[action] = { label, type, values: [] };
          }
        }

        if (action === 'remove') {
          // @ts-ignore
          memo[action].push(buildIdInput(mod));
        } else {
          // @ts-ignore
          memo[action].values.push({ ...pick(mod, ['id', 'name', 'image', 'color']) });
        }
      }

      return memo;
    }, {});

    setLoading(true);

    const result = await productTypeModificationsUpdate({
      id: productId,
      modifications: changes
    });

    if (!result || !result.productTypeUpdate.success) {
      showToastify('Something went wrong!', 'error');
    }

    setLoading(false);

    const productTypeModificationGroup = {
      ...state,
      values: state.values.filter(v => !v.deleted).map(v => omit(v, ['new', 'deleted', 'changed']))
    };

    onChanged({
      productTypeModificationGroup: productTypeModificationGroup,
      modifications: result?.productTypeUpdate?.productType?.modifications || []
    });
  };

  return (
    <ModalStyled isOpen={isOpened} backdrop="static" keyboard={false} onClosed={onClosed}>
      <ModalBodyStyled>
        <Title>{i18n.t('labels.productModification')}</Title>

        <Row>
          <FormGroup>
            <Label>{i18n.t('labels.title')}</Label>
            <InputStyled
              name="title"
              value={state.label}
              onChange={value => {
                setState(st => ({ ...st, label: value }));
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label>{i18n.t('labels.displayAs')}</Label>
            <Select
              value={displayAsOption}
              onChange={(option: any) => {
                setState(st => ({ ...st, type: option.value }));
                setDisplayAsOption(option);
              }}
            />
          </FormGroup>
        </Row>

        <FormGroup>
          <Label>{i18n.t('labels.values')}</Label>
          <TagsInputStyled
            tags={state.values}
            // @ts-ignore
            tagComponent={DisplayTypeToTagInputComponent[displayAsOption.value] || ProductModificationTag}
            onChange={onOptionChanged}
          />
        </FormGroup>

        <ButtonGroup>
          <Button data-testid="cancel" type={Type.SECONDARY} onClick={onClosed}>
            {i18n.t('labels.cancel')}
          </Button>
          <Button data-testid="done" type={Type.PRIMARY} onClick={onSubmit} disabled={!valid()}>
            {i18n.t('labels.done')}
          </Button>
        </ButtonGroup>
      </ModalBodyStyled>
    </ModalStyled>
  );
};

export default ProductModificationModal;
