import { FC } from 'react';
import { SVGProps } from './types';
export const SVGShevron: FC<SVGProps> = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" {...props}>
      <path
        fill="#72747E"
        d="M0 1.385V.462C0 .283.093.12.238.045c.146-.077.319-.054.442.06L4 3.019 7.32.105c.123-.114.296-.137.442-.06.145.076.238.238.238.417v.923c0 .139-.057.27-.153.357L4.264 4.896c-.153.139-.375.139-.528 0L.153 1.742C.057 1.655 0 1.524 0 1.385z"
      />
    </svg>
  );
};
