import * as Types from '../../../../../gql/types.generated';

import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeOrderTrucksModalOrderUpdateMutationVariables = Types.Exact<{
  order: Types.OrderUpdateInput;
  plant?: Types.InputMaybe<Types.IdInput>;
  quiet?: Types.InputMaybe<Types.Scalars['Boolean']>;
  askToConfirm?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type ChangeOrderTrucksModalOrderUpdateMutation = { __typename?: 'Mutation', orderUpdate: { __typename?: 'OrderUpdatePayload', success: boolean, order?: { __typename?: 'Order', id: string, timeBetweenTrucks: number, trucks: Array<number>, trucksSecondary: Array<number>, deliveryMethod: { __typename?: 'DeliveryMethod', name: string, caption?: string | null, description?: string | null }, trucksDrivers: Array<{ __typename?: 'OrderTruck', id: number, truckId: string, quantity: number, driver?: { __typename?: 'Driver', id: string, info: { __typename?: 'DriverInfo', name?: string | null } } | null, truck?: { __typename?: 'Truck', id: string, name: string, type: string } | null, plant?: { __typename?: 'Plant', id: string, name: string } | null }> } | null } };


export const ChangeOrderTrucksModalOrderUpdateDocument = gql`
    mutation ChangeOrderTrucksModalOrderUpdate($order: OrderUpdateInput!, $plant: IdInput, $quiet: Boolean, $askToConfirm: Boolean) {
  orderUpdate(
    order: $order
    plant: $plant
    quiet: $quiet
    askToConfirm: $askToConfirm
  ) {
    order {
      id
      deliveryMethod {
        name
        caption
        description
      }
      timeBetweenTrucks
      trucks
      trucksSecondary
      trucksDrivers {
        id
        truckId
        quantity
        driver {
          id
          info {
            name
          }
        }
        truck {
          id
          name
          type
        }
        plant {
          id
          name
        }
      }
    }
    success
  }
}
    `;
export type ChangeOrderTrucksModalOrderUpdateMutationFn = Apollo.MutationFunction<ChangeOrderTrucksModalOrderUpdateMutation, ChangeOrderTrucksModalOrderUpdateMutationVariables>;

/**
 * __useChangeOrderTrucksModalOrderUpdateMutation__
 *
 * To run a mutation, you first call `useChangeOrderTrucksModalOrderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderTrucksModalOrderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrderTrucksModalOrderUpdateMutation, { data, loading, error }] = useChangeOrderTrucksModalOrderUpdateMutation({
 *   variables: {
 *      order: // value for 'order'
 *      plant: // value for 'plant'
 *      quiet: // value for 'quiet'
 *      askToConfirm: // value for 'askToConfirm'
 *   },
 * });
 */
export function useChangeOrderTrucksModalOrderUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOrderTrucksModalOrderUpdateMutation, ChangeOrderTrucksModalOrderUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOrderTrucksModalOrderUpdateMutation, ChangeOrderTrucksModalOrderUpdateMutationVariables>(ChangeOrderTrucksModalOrderUpdateDocument, options);
      }
export type ChangeOrderTrucksModalOrderUpdateMutationHookResult = ReturnType<typeof useChangeOrderTrucksModalOrderUpdateMutation>;
export type ChangeOrderTrucksModalOrderUpdateMutationResult = Apollo.MutationResult<ChangeOrderTrucksModalOrderUpdateMutation>;
export type ChangeOrderTrucksModalOrderUpdateMutationOptions = Apollo.BaseMutationOptions<ChangeOrderTrucksModalOrderUpdateMutation, ChangeOrderTrucksModalOrderUpdateMutationVariables>;


export function useChangeOrderTrucksModalOrderUpdateRequest() {
    const client = useApolloClient();
    return useCallback<(variables: ChangeOrderTrucksModalOrderUpdateMutationVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<ChangeOrderTrucksModalOrderUpdateMutation>>>( (variables, options) => {
        return client.query<ChangeOrderTrucksModalOrderUpdateMutation>({
            fetchPolicy: 'no-cache',
          ...options,
          query: ChangeOrderTrucksModalOrderUpdateDocument,
          variables
        })
    }, []);
}

