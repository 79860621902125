import gql from 'graphql-tag';

export const productOptions = gql`
  query productTypes($contractor: IdInput, $input: OrderPreparatoryConfigInput) {
    orderPreparatoryConfig(contractor: $contractor, input: $input) {
      options {
        option {
          id
          name
        }
        price {
          id
          value
          currency
        }
      }
    }
  }
`;
