import React from 'react';
import styled from 'styled-components';
import Button, { Type } from '@/shared/components/buttons/Button';
import { noop } from 'lodash';

export enum Navigation {
  CANCEL_SAVE,
  SAVE_ONLY,
  CUSTOM
}

const Container = styled.div<{ css?: string; type?: Navigation }>`
  padding: 20px;
  ${props =>
    props.type !== Navigation.CUSTOM &&
    `
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: end;
    grid-column-gap: 10px;
  `}
  ${props => props.css && props.css}
`;

interface Props {
  navigation?: Navigation;
  buttonTypes?: [Type, Type];
  onSave?: () => void;
  onCancel?: () => void;
  disableSaveButton?: boolean;
  css?: string;
}
const Footer: React.FunctionComponent<Props> = ({
  navigation = Navigation.CANCEL_SAVE,
  buttonTypes = [],
  onSave = noop,
  onCancel = noop,
  disableSaveButton = false,
  css = '',
  children,
  ...rest
}) => {
  if (navigation === Navigation.CUSTOM)
    return (
      <Container css={css} type={Navigation.CUSTOM} {...rest}>
        {children}
      </Container>
    );
  if (navigation === Navigation.SAVE_ONLY)
    return (
      <Container css={css} {...rest}>
        <Button type={buttonTypes[1] ?? Type.PRIMARY} onClick={onSave} disabled={disableSaveButton}>
          Save
        </Button>
      </Container>
    );
  return (
    <Container css={css} {...rest}>
      <Button type={buttonTypes[0] ?? Type.OUTLINED} onClick={onCancel}>
        Cancel
      </Button>
      <Button type={buttonTypes[1] ?? Type.PRIMARY} onClick={onSave} disabled={disableSaveButton}>
        Save
      </Button>
    </Container>
  );
};
export default Footer;
