import { faCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { useFormikContext } from 'formik';

export type FieldArrayControlProps = {
  remove<T>(index: number): T | undefined;
  push: (obj: any) => void;
  data: any[];
  index: number;
  initialValue: any;
  disabled?: boolean;
  keepEmptyRow?: boolean;
  canAdd?: boolean;
};

const FieldArrayControlButtons: React.FC<
  FieldArrayControlProps & {
    className?: string;
    size?: string;
  }
> = (props): JSX.Element => {
  const { canAdd, className, index, data, initialValue, push, remove, size, disabled, keepEmptyRow } = props;
  const isDisabled = disabled !== undefined ? disabled : false;
  const [deleteState, setDeleteState] = useState<boolean>(false);
  const { validateForm } = useFormikContext();

  const addEntityCallback = useCallback(() => {
    push(initialValue);
    setTimeout(async () => {
      await validateForm();
    }, 0);
  }, [validateForm, push, initialValue]);

  const removeEntityCallback = useCallback(() => {
    setDeleteState(false);
    remove(index);
    /* add empty row */
    index === 0 && data.length === 1 && keepEmptyRow && push(initialValue);
  }, [remove, index, setDeleteState, initialValue, push, data, keepEmptyRow]);

  const initDeleteState = useCallback(() => setDeleteState(true), [setDeleteState]);

  const disableDeleteState = useCallback(() => {
    deleteState && setDeleteState(false);
  }, [setDeleteState, deleteState]);

  return (
    <div className={cx('d-flex flex-row align-items-center justify-content-start', className)}>
      {data.length >= 1 && (
        <>
          {deleteState ? (
            <Button
              disabled={isDisabled}
              onClick={removeEntityCallback}
              onMouseLeave={disableDeleteState}
              className={cx('btn btn-danger mr-1')}
              size={size || 'sm'}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          ) : (
            <Button disabled={isDisabled} onClick={initDeleteState} className={cx('btn btn-warning mr-1')} size={size || 'sm'}>
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          )}
        </>
      )}
      {canAdd && index === data.length - 1 && (
        <Button disabled={isDisabled} onClick={addEntityCallback} className={cx('btn btn-success')} size={size || 'sm'}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      )}
    </div>
  );
};

FieldArrayControlButtons.defaultProps = {
  canAdd: true,
  keepEmptyRow: true
};

export default FieldArrayControlButtons;
