import React, { useCallback, useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { startCase } from 'lodash';
import { FormGroup, FormText } from 'reactstrap';
import Select, { ValueType, ActionMeta, components } from 'react-select';

const OptionComponent = components.Option;

import { FormLabel } from '@components/forms/controls/FormLabel';
import { useApplicationContext } from '@components/context/ApplicationContext';
import { isEmpty } from '@/libs';

type SelectInputProps = {
  [key: string]: any;
  id?: string;
  label?: string;
  className?: string;
  formGroupClassNames?: string;
  autoSelect?: boolean;
  formText?: string;
  options: OptionType[];
  onChange?: (
    value: ValueType<OptionType, boolean>,
    actionMeta?: ActionMeta<OptionType>
  ) => Promise<ValueType<OptionType, boolean> | null>;
  isClearable?: boolean;
  readOnly?: boolean;
  initialValue?: OptionType;
  prepareInputValue?: (value: any) => { [key: string]: any; value: string; label: string };
  prepareFieldValue?: (value: { [key: string]: any }) => any;
};

type OptionType = {
  label: string;
  value: string;
};

const FormInputSelect: React.FC<SelectInputProps> = (props): JSX.Element => {
  const {
    isClearable,
    field,
    label,
    multi,
    form,
    formText,
    formGroupClassNames,
    disabled,
    onChange,
    autoSelect,
    readOnly,
    prepareInputValue,
    prepareFieldValue,
    initialValue,
    ...restProps
  } = props;

  const context = useApplicationContext();
  const ref: any = useRef();
  const { errors, touched, setFieldValue } = form;
  const { name, value } = field;
  const { id, options } = restProps;

  const invalid: boolean = !!(errors[name] && touched[name]);
  let inputValue = value ?? initialValue;

  if (prepareInputValue) {
    inputValue = prepareInputValue(inputValue);
  }

  const handleOnChange = useCallback(
    async (values, actionsMeta) => {
      if (!onChange) {
        setFieldValue(name, values);
      } else {
        const result = await onChange(values, actionsMeta);

        setFieldValue(name, result || values);
      }
    },
    [setFieldValue, name, onChange]
  );
  const onMenuOpen = useCallback(async () => {
    setTimeout(() => {
      const element = ref.current.select?.menuListRef;
      const parent = context?.page?.selectParentId ? window.document.getElementById(context.page.selectParentId) : null;
      if (element && parent) {
        if (parent.getBoundingClientRect().bottom < element.getBoundingClientRect().bottom) element.scrollIntoView(false);
      }
    }, 100);
  }, [ref]);

  useEffect(() => {
    setFieldValue(name, prepareFieldValue ? prepareFieldValue(value) : value);
  }, [setFieldValue, name, value, prepareFieldValue]);

  useEffect(() => {
    if (autoSelect) {
      (async () => {
        if (options.length === 1) {
          const option = options[0] as any;

          if (isEmpty(value) || value.id !== option.id) {
            if (!onChange) {
              setFieldValue(name, option);
            } else {
              const result = await onChange(option);

              setFieldValue(name, result || option);
            }
          }
        }
      })();
    }
  }, [options, setFieldValue, name, onChange, value, autoSelect]);

  return (
    <FormGroup className={formGroupClassNames}>
      {label && <FormLabel htmlFor={id}>{label || startCase(name)}</FormLabel>}
      <Select
        ref={ref}
        id={name}
        name={name}
        isDisabled={disabled || readOnly}
        inputId={name}
        isMulti={multi}
        readOnly={true}
        isClearable={isClearable}
        className={cx('react-select-container', { 'is-invalid': invalid, readonly: readOnly })}
        onChange={handleOnChange}
        onMenuOpen={onMenuOpen}
        value={inputValue}
        defaultMenuIsOpen={false}
        components={{
          IndicatorSeparator: () => null,
          Option: props => (
            <OptionComponent {...props} className={[props.className, 'brk-option-select'].filter(Boolean).join(' ')} />
          )
        }}
        {...restProps}
      />
      {formText && <FormText color="muted">{formText}</FormText>}
    </FormGroup>
  );
};

export default FormInputSelect;
