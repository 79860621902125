import gql from 'graphql-tag';

export const suppliersDataTable = gql`
  query Suppliers($limit: Int, $skip: Int, $partner: IdInput, $search: String) {
    suppliers(limit: $limit, skip: $skip, partner: $partner, search: $search) {
      count
      values {
        id
        name
        plants {
          count
          values {
            id
          }
        }
        categories {
          values {
            id
            name
          }
        }
      }
    }
  }
`;
