import React, { useState, useEffect, useRef, useCallback } from 'react';
import ColourPickerButton from './ColourPickerButton';
import styled from 'styled-components';
import ICONS from '@/assets/images/icons';

type InputProps = {
  [key: string]: any;
  customCss?: {
    buttonCss?: string;
    colorPickerCss?: string;
    hueBarCss?: string;
  };
  className?: string;
  value: string | undefined;
  onChanged: (color: string | undefined) => void;
  defaultColours?: boolean;
};

const ColorPicker: React.FunctionComponent<InputProps> = props => {
  const { className, value, customCss, defaultColours, onChanged } = props;

  const [hue, setHue] = useState<number>(180);
  const [hueX, setHueX] = useState<number>(105);
  const [squareXY, setSquareXY] = useState<number[]>([100, 50]);
  const [isPickerVisible, setPickerVisible] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onClick = (e: Event) => {
      if (isPickerVisible) {
        if (!ref.current || !ref.current.contains(e.target as Node)) {
          setPickerVisible(!isPickerVisible);
        }
      }
    };

    if (isPickerVisible) {
      document.addEventListener('click', onClick);
    } else {
      document.removeEventListener('click', onClick);
    }
    return () => document.removeEventListener('click', onClick);
  }, [setPickerVisible, isPickerVisible]);

  return (
    <Picker className={className} ref={ref}>
      <CircleButton backgroundColor={value} css={customCss?.buttonCss} onClick={() => setPickerVisible(visible => !visible)}>
        {value ? null : <img src={ICONS.icColor} alt="Colour Picker" />}
      </CircleButton>
      {isPickerVisible ? (
        <ColourPickerButton
          hue={hue}
          hueX={hueX}
          squareXY={squareXY}
          colour={value}
          setHue={setHue}
          setHueX={setHueX}
          setSquareXY={setSquareXY}
          onCancel={() => setPickerVisible(false)}
          setColour={onChanged}
          css={customCss}
          defaultColours={defaultColours}
        />
      ) : null}
    </Picker>
  );
};

type CircleButtonProps = {
  backgroundColor?: string;
  css?: string;
};

const CircleButton = styled.div.attrs(({ backgroundColor }: CircleButtonProps) => ({
  style: {
    backgroundColor: backgroundColor ? backgroundColor : 'transparent'
  }
}))<CircleButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px #f6f6f6 solid;
  width: 40px;
  height: 40px;
  margin-top: 22px;
  margin-left: 8px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${({ css }: CircleButtonProps) => css && css}

  & img {
    height: 100%;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  img {
    border-radius: 50%;
  }
`;

const Picker = styled.div`
  position: relative;
`;

export default ColorPicker;
