import React from 'react';
import styled, { css } from 'styled-components';
import { i18n } from '@/core';
import { colors } from '@/defaultStyles';
import type { I18nKeys } from '@/assets/locales';

type TextProps = {
  text?: string | React.ReactNode;
  translationKey?: I18nKeys;
  className?: string;
  tag?: string | React.ElementType;
} & StyledTextProps;

type StyledTextProps = {
  transform?: 'uppercase' | 'capitalize' | 'lowercase' | 'inherit';
  size?: number | string;
  color?: string;
  weight?: 'bold' | 'normal' | '500';
};

const Text: React.FC<TextProps> = props => {
  const { className, tag: Tag, text, translationKey, ...rest } = props;

  return (
    // @ts-ignore
    <Tag className={className} {...rest}>
      {text || (translationKey && i18n.t(translationKey))}
    </Tag>
  );
};

Text.defaultProps = {
  tag: 'span'
};

const StyledTextStyles = css`
  text-transform: ${(props: StyledTextProps) => props.transform || 'none'};
  color: ${({ color }: StyledTextProps) => color || colors.CHARCOAL_GREY};
  ${({ size, weight }) => {
    return `
      ${size != null ? `font-size: ${size}${typeof size === 'number' ? 'px' : ''};` : ''};
      font-weight: ${weight != null ? weight : 'normal'};
    `;
  }};
`;

const StyledText = styled(Text)`
  ${StyledTextStyles};
`;

export default StyledText;

export { StyledTextStyles };
