import { i18n } from '@/core';
import { FormInput } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

type FormInitialProps = {
  note: string;
  deliveryDisclaimers: string;
  policy: string;
  contractorRequirements: string;
  additionalCharges: string;
};

type Props = FormikProps<FormInitialProps>;

export const termsAndConditionsFormInitialProps: FormInitialProps = {
  note: '',
  deliveryDisclaimers: '',
  policy: '',
  contractorRequirements: '',
  additionalCharges: ''
};

const TermsConditionForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  const { gettext } = useGettext();
  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col>
          <Field name="note" id="note" placeholder={gettext('Note')} component={FormInput} type="textarea" />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="deliveryDisclaimers"
            id="deliveryDisclaimers"
            placeholder={gettext('Delivery Disclaimers')}
            component={FormInput}
            type="textarea"
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field name="policy" id="policy" placeholder={gettext('policy')} component={FormInput} type="textarea" />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="contractorRequirements"
            id="contractorRequirements"
            placeholder={gettext('Contractor Requirements')}
            component={FormInput}
            type="textarea"
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="additionalCharges"
            id="additionalCharges"
            placeholder={gettext('Additional Charges')}
            component={FormInput}
            type="textarea"
          />
        </Col>
      </Row>

      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(TermsConditionForm);
