import * as Types from '../../../gql/types.generated';

import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccessPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AccessPermissionsQuery = { __typename?: 'Query', me: { __typename?: 'MeInfo', permissionsNew: any, settings: any, type?: Types.IdentityTypeEnum | null, superAdmin?: { __typename?: 'SuperAdmin', id: string } | null, partnerMember?: { __typename?: 'PartnerMember', email?: string | null, id: string } | null, partner?: { __typename?: 'Partner', id: string, logo?: string | null, name?: string | null, phone?: string | null, email?: string | null, description?: string | null, code?: string | null, timezone?: string | null, countryState: { __typename?: 'CountryStateInstance', country: { __typename?: 'Country', code: string } }, address?: { __typename?: 'Address', country: string, location: { __typename?: 'Location', latitude: number, longitude: number } } | null, account?: { __typename?: 'PartnerAccount', currency: string } | null } | null } };


export const AccessPermissionsDocument = gql`
    query AccessPermissions {
  me {
    superAdmin {
      id
    }
    partnerMember {
      email
      id
    }
    permissionsNew
    settings
    type
    partner {
      id
      logo
      name
      phone
      email
      description
      code
      countryState {
        country {
          code
        }
      }
      address {
        country
        location {
          latitude
          longitude
        }
      }
      account {
        currency
      }
      timezone
    }
  }
}
    `;

/**
 * __useAccessPermissionsQuery__
 *
 * To run a query within a React component, call `useAccessPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<AccessPermissionsQuery, AccessPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccessPermissionsQuery, AccessPermissionsQueryVariables>(AccessPermissionsDocument, options);
      }
export function useAccessPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessPermissionsQuery, AccessPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccessPermissionsQuery, AccessPermissionsQueryVariables>(AccessPermissionsDocument, options);
        }
export type AccessPermissionsQueryHookResult = ReturnType<typeof useAccessPermissionsQuery>;
export type AccessPermissionsLazyQueryHookResult = ReturnType<typeof useAccessPermissionsLazyQuery>;
export type AccessPermissionsQueryResult = Apollo.QueryResult<AccessPermissionsQuery, AccessPermissionsQueryVariables>;


export function useAccessPermissionsRequest() {
    const client = useApolloClient();
    return useCallback<(variables: AccessPermissionsQueryVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<AccessPermissionsQuery>>>( (variables, options) => {
        return client.query<AccessPermissionsQuery>({
            fetchPolicy: 'no-cache',
          ...options,
          query: AccessPermissionsDocument,
          variables
        })
    }, []);
}

