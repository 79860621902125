import dompurify from 'dompurify';

const sanitize = (markup: string): { __html: string } => {
  return {
    __html: dompurify.sanitize(markup, {
      ADD_TAGS: ['video', 'source', 'iframe'],
      ADD_ATTR: ['controls', 'type', 'allowfullscreen', 'allow', 'target']
    })
  };
};
export default sanitize;
