import { FC } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { Typography } from 'antd';
import styled from 'styled-components';
import { ProductProps } from './types';

export const Product: FC<ProductProps> = props => {
  const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({
    id: (props.id || '') + props.productType.id + props.index,
    data: props
  });
  if (!props?.quantity) {
    return null;
  }
  return (
    <DragItem
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={
        isDragging
          ? {
              transform: `translate(${transform?.x}px,${transform?.y}px)`,
              zIndex: 99
            }
          : {}
      }
    >
      <Name ellipsis={{ tooltip: props.productType?.name, rows: 2 }}>{props.productType?.name}</Name>
      <QTY>{props.quantity}</QTY>
    </DragItem>
  );
};

const Name = styled(Typography.Paragraph)`
  margin-bottom: 0 !important;
  font: var(--font-normal-12);
  color: var(--gray-10);
  flex: 1;
`;

const QTY = styled.b`
  font: var(--font-semibold-14);
  color: var(--gray-10);
`;

const DragItem = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  background: var(--white);
  border-radius: 4px;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 4px 12px;
  max-width: 140px;
  min-width: 140px;
  overflow: hidden;
`;
