import { Order, OrderPreparatoryConfigInput, Place, Price, ProductPrice, Region } from '@/interfaces/brokrete';
import { isEmpty, order as orderHelper } from '@/libs';
import { SelectedOptionType } from '@/interfaces/common';
import { getIn } from 'formik';
import { toNumber } from '@/libs/string';
import { Address, AddressInput as AddressInputGenerated } from '@/gql/types.generated';

export function buildProductInput(order: Order) {
  const productPrices = orderHelper.extractProductPrices(order);
  return {
    ...buildIdInput(order.product),
    prices: productPrices.map(value => ({
      type: buildIdInput(value.productTypePrice.productType),
      typePrice: buildIdInput(value.productTypePrice.price),
      ...(value.productDecoratePrice && {
        decorate: buildIdInput(value.productDecoratePrice.productDecorate),
        decoratePrice: buildIdInput(value.productDecoratePrice.price)
      }),
      ...(!isEmpty(value.quantity) && {
        quantity: value.quantity
      })
    }))
  };
}

export function buildProductPricesInput(prices: Array<ProductPrice>) {
  return prices.map(value => ({
    typePrice: buildIdInput(value.productTypePrice.price),
    ...(value.productDecoratePrice && {
      decoratePrice: buildIdInput(value.productDecoratePrice.price)
    }),
    ...(!isEmpty(value.quantity) && {
      quantity: value.quantity
    })
  }));
}

type AddressInput = {
  location: {
    latitude: number;
    longitude: number;
  };
  state: string;
  city?: string;
  zip?: string;
};

export function buildAddressInput(place: Place): AddressInput {
  return {
    location: {
      latitude: place.location.latitude,
      longitude: place.location.longitude
    },
    state: place.state,
    city: place.city,
    zip: place.zip
  };
}

export function buildAddressInputNew(
  address: Partial<Address>,
  fixed: boolean = true,
  custom: boolean = false
): AddressInputGenerated {
  return {
    city: address.city,
    location: address.location,
    state: address.state,
    streetAddress: address.mainText,
    zip: address.zip,
    fixed,
    custom
  };
}

export function buildPriceIdInput(values: Array<{ price: Price }>) {
  return values.map(value => {
    return buildIdInput(value.price);
  });
}

export function buildIdInput<T = string>(value: { id?: T | null }) {
  return {
    id: value?.id || ''
  };
}

export function buildRegionInput(region: Region) {
  return {
    latitude: region.latitude,
    longitude: region.longitude,
    deltaLatitude: region.deltaLatitude,
    deltaLongitude: region.deltaLongitude,
    radius: region.radius
  };
}

export function buildOptionType<T>(value: T, rootObjPath?: string[]): SelectedOptionType<T> {
  const id = getIn(value, rootObjPath || 'id');
  const label = getIn(value, rootObjPath || 'name');

  return {
    data: value,
    id: id,
    value: id,
    label: label
  };
}

export function buildOrderPreparatoryConfigInputType(input: OrderPreparatoryConfigInput) {
  return {
    ...(input.address && {
      address: buildAddressInput(input.address as Place)
    }),
    ...(input.options && {
      options: input.options
    }),
    ...(input.category && {
      category: buildIdInput(input.category)
    }),
    ...(input.deliveryTime && {
      deliveryTime: input.deliveryTime
    }),
    ...(input.fees && {
      fees: input.fees
    }),
    ...(input.plant && {
      plant: buildIdInput(input.plant)
    }),
    ...(input.trucks && {
      trucks: input.trucks
    }),
    ...(input.productPrices && {
      productPrices: input.productPrices.map(productPrice => {
        return {
          ...(productPrice.decorate && {
            decorate: buildIdInput(productPrice.decorate),
            ...(productPrice.decoratePriceValue
              ? {
                  decoratePriceValue: productPrice.decoratePriceValue
                }
              : {
                  decoratePrice: buildIdInput(productPrice.decoratePrice as Price)
                })
          }),
          quantity: toNumber(productPrice.quantity),
          ...(productPrice.type && {
            type: buildIdInput(productPrice.type)
          }),
          ...(productPrice.typePriceValue
            ? {
                typePriceValue: productPrice.typePriceValue
              }
            : {
                typePrice: buildIdInput(productPrice.typePrice as Price)
              })
        };
      })
    }),
    ...(input.deliveryMethod && {
      deliveryMethod: input.deliveryMethod
    })
  };
}
