import cx from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { get } from 'lodash';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import styled from 'styled-components';
import { FormLabel } from '@components/forms/controls/FormLabel';
import { FormGroup } from 'reactstrap';
import { useApplicationContext } from '@components/context/ApplicationContext';
import { QueryOptions, useApolloClient } from '@apollo/client';

function isPromise(value: any) {
  return value && value.then && typeof value.then === 'function';
}

const FormInputAsyncSelect: React.FC<SelectInputProps> = (props): JSX.Element => {
  const {
    field,
    label,
    multi,
    form,
    formText,
    formGroupClassNames,
    isClearable,
    queryOptions,
    dataAdapter,
    disabled,
    onInputChange,
    onSelectValue,
    components,
    placeholder,
    onChange,
    classNamePrefix,
    forcedValue,
    autoSelect,
    loadOptions,
    forceKey,
    menuIsOpen,
    ...restProps
  } = props;

  const context = useApplicationContext();
  const { errors, touched, setFieldValue, initialValues } = form;
  const { name, value } = field;
  const { id } = restProps;
  const invalid: boolean = !!(errors[name] && touched[name]);
  const client = useApolloClient();
  const asyncSelectRef: any = useRef();

  useEffect(() => {
    if (forcedValue) {
      setFieldValue(name, forcedValue);
    }
  }, [forcedValue, name, setFieldValue]);

  const promiseOptions = async (inputValue: string) => {
    const queryOptionsPrams =
      typeof queryOptions === 'function' ? (queryOptions as any)(inputValue) : (queryOptions as QueryOptions<any>);

    const response: any = isPromise(queryOptions)
      ? await queryOptions
      : await client.query({ fetchPolicy: 'cache-first', ...queryOptionsPrams });

    const { data } = response;

    return restProps.filterInput ? filterInput(inputValue, dataAdapter(data)) : dataAdapter(data);
  };

  const handleOnChange = useCallback(
    async values => {
      if (!onSelectValue) {
        setFieldValue(name, values);
        onChange && onChange(values);
      } else {
        const result = await onSelectValue(values);
        setFieldValue(name, result || values);
        onChange && onChange(result || values);
      }
    },
    [setFieldValue, name, onSelectValue, onChange]
  );
  const onMenuOpen = useCallback(async () => {
    setTimeout(() => {
      const element = asyncSelectRef.current.select?.select?.menuListRef;
      const parent = context?.page?.selectParentId ? window.document.getElementById(context.page.selectParentId) : null;
      if (element && parent) {
        if (parent.getBoundingClientRect().bottom < element.getBoundingClientRect().bottom) element.scrollIntoView(false);
      }
    }, 100);
  }, [asyncSelectRef]);

  useEffect(() => {
    asyncSelectRef.current.loadOptions('', (options: any) => {
      if (asyncSelectRef.current) {
        asyncSelectRef.current.setState({ defaultOptions: options });
        if (autoSelect && options?.length === 1) {
          setFieldValue(name, options[0]);
          onChange && onChange(options[0]);
        }
      }
    });
  }, [queryOptions, setFieldValue, name, onChange, autoSelect]);

  const keyMemo = useMemo(() => {
    if (forceKey) return forceKey;
    const key = get(initialValues, name);
    return key ? key.value : name;
  }, [initialValues, name, forceKey]);

  return (
    <FormGroup className={formGroupClassNames}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <AsyncSelect
        key={keyMemo}
        ref={asyncSelectRef}
        className={cx('react-select-container', { 'is-invalid': invalid })}
        classNamePrefix={classNamePrefix ? classNamePrefix : ''}
        id={name}
        value={value}
        isDisabled={disabled}
        inputId={name}
        menuIsOpen={menuIsOpen}
        isMulti={multi}
        isClearable={isClearable}
        onChange={handleOnChange}
        onInputChange={onInputChange}
        onMenuOpen={onMenuOpen}
        loadOptions={loadOptions ?? promiseOptions}
        cacheOptions={false}
        defaultOptions
        components={{
          ...components,
          IndicatorSeparator: () => null
        }}
        placeholder={placeholder}
        {...restProps}
      />
      {formText && <FormText color="muted">{formText}</FormText>}
    </FormGroup>
  );
};

export default FormInputAsyncSelect;

const filterInput = (inputValue: string, data: any[]) =>
  inputValue ? data.filter(value => value.label && value.label.toLowerCase().includes(inputValue.toLowerCase())) : data;

type SelectInputProps = {
  [key: string]: any;
  id?: string;
  label?: string;
  className?: string;
  autoSelect?: boolean;
  formGroupClassNames?: string;
  formText?: string;
  queryOptions: QueryOptions<any> | Promise<QueryOptions<any>>;
  dataAdapter: <T>(value: T) => OptionType[];
  onSelectValue?: <T>(value: T) => Promise<ValueType<OptionType, boolean> | null>;
  onInputChange?: (search: string) => void;
  components?: SelectComponents<any, boolean>;
  classNamePrefix?: string;
  forcedValue?: {
    name?: string;
    value?: string;
  };
  filterInput?: boolean;
};

type OptionType = {
  label: string;
  value: string;
};

const FormText = styled.span`
  font-size: 10px;
  font-weight: normal;
  float: left;
  padding-top: 3px;
`;
