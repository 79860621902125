import React from 'react';
import styled from 'styled-components';
import { colors } from '@/defaultStyles';
import ICONS from '@/assets/images/icons';
import ToolTip from './ToolTip';
import { i18n } from '@/core';

type SectionProps = {
  title: React.ReactNode;
  info: React.ReactNode;
  modificationSection?: boolean;
  onAdd?: () => void;
};

const Root = styled.div``;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  margin-top: 30px;
  margin-bottom: 10px;
  line-height: 1.29;
  letter-spacing: 0.23px;
  white-space: pre-line;
  color: ${colors.CHARCOAL_GREY};
  :first-child {
    margin-top: 20px;
  }
`;

const Container = styled.div``;

const Add = styled.img`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 20px;

  cursor: pointer;
`;

const Info = styled.div`
  font-size: 14px;
  margin-bottom: 30px;
`;

const ToolTipStyled = styled(ToolTip)`
  .tool-tip-icon {
    cursor: pointer;
  }

  .tool-tip-description {
    white-space: unset;
    font-size: 14px;
    background-color: black;
    color: white;
    line-height: 1.43;
  }
`;

const Section: React.FC<SectionProps> = ({ title, info, onAdd, modificationSection = false, children }) => {
  return (
    <Root>
      <Header>
        <Title>{title}</Title>
        {!modificationSection && onAdd && <Add src={ICONS.icPlusGreenCircle} alt="Add new option" onClick={onAdd} />}
        {modificationSection && (
          <ToolTipStyled size={16} descriptionWidth={250} description={i18n.t('labels.productCombinationsCaption')} />
        )}
      </Header>
      <Info>{info}</Info>
      <Container>{children}</Container>
    </Root>
  );
};

export default Section;
