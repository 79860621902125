import { Converter } from 'showdown';
import { gettext } from '@cranium/i18n';

export const converter = new Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  extensions: [
    {
      type: 'lang',
      regex: /@youtube-video\[(.*?)\]\((.*?)\)/g,
      replace: function (_: string, description: string, url: string) {
        return `<div>${
          description ? `<p>${description}</p>` : ''
        }<div class="video-wrapper"><iframe src="${url}" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div></div>`;
      }
    },
    {
      type: 'lang',
      regex: /@cdn-video\[(.*?)\]\((.*?)\)/g,
      replace: function (_: string, description?: string, url?: string) {
        return `<div>${
          description ? `<p>${description}</p>` : ''
        }<div class="video-wrapper"><video controls><source src="${url}" type="video/mp4">${gettext(
          'Not Supported By The Browser'
        )}</video></div></div>`;
      }
    },
    {
      type: 'lang',
      regex: /\[((?:\[[^\]]*]|[^\[\]])*)]\([ \t]*<?(.*?(?:\(.*?\).*?)?)>?[ \t]*((['"])(.*?)\4[ \t]*)?\)/g,
      replace: function (_: string, linkText?: string, url?: string) {
        return `<a href="${url}" target="_blank">${linkText || url}</a>`;
      }
    }
  ]
});
