import { i18n } from '@/core';
import { makeFormik } from '@components/forms/FormikForm';
import { ArrayHelpers, FieldArray, FormikProps } from 'formik';
import React from 'react';
import { Button, Table } from 'reactstrap';
import { ProductPriceRow } from './components/ProductPriceRow';
import type { ProductTypeFormPrice, ProductTypePricesFormInitialProps } from './types';
import styles from './ProductTypePricesForm.module.scss';

const ProductTypePricesForm: React.FC<Props> = (props: Props) => {
  const { handleSubmit, isSubmitting, isValid, dirty } = props;

  return (
    <form className="w-100" onSubmit={handleSubmit}>
      <FieldArray
        name="productTypePrices"
        render={({ form }: ArrayHelperProps<ProductTypePricesFormInitialProps>) => {
          const { productTypePrices } = form.values;

          return (
            <div className={styles.wrapper}>
              {productTypePrices.length > 0 ? (
                <Table hover>
                  <thead>
                    <tr>
                      <th>{i18n.t('labels.name')}</th>
                      <th>{i18n.t('labels.price')}</th>
                      <th>{i18n.t('labels.specialPrice')}</th>
                      <th>{i18n.t('labels.specialPrice')}</th>
                      <th>{i18n.t('labels.specialPrice')}</th>
                      <th>{i18n.t('labels.specialPrice')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productTypePrices.map((value: ProductTypeFormPrice, idx: number) => {
                      return <ProductPriceRow key={`${value.id}_${idx}`} namePrefix={`productTypePrices[${idx}]`} />;
                    })}
                  </tbody>
                </Table>
              ) : (
                <h5>{i18n.t('common.emptyPrices')}</h5>
              )}
              {productTypePrices.length > 0 ? (
                <div className="float-left">
                  <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
                    {i18n.t('labels.save')}
                  </Button>
                </div>
              ) : null}
            </div>
          );
        }}
      />
    </form>
  );
};

export default makeFormik<ProductTypePricesFormInitialProps>(ProductTypePricesForm);

export const productTypePricesInitialValues: ProductTypeFormPrice = {
  id: '',
  productTypeId: '',
  name: '',
  price: '',
  optionsPrices: [],
  decoratePrices: [],
  group: ''
};

/* types */

type Props = FormikProps<ProductTypePricesFormInitialProps>;

type ArrayHelperProps<Values> = ArrayHelpers & {
  form: FormikProps<Values>;
  name: string;
};
