import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, CardSubtitle, Col, Form, Row } from 'reactstrap';

type FormInitialProps = {
  lockToCancelTime: string;
  cancelingFeePrice: string;
};

type Props = FormikProps<FormInitialProps>;

export const deleteOrderParamsInitialProps: FormInitialProps = {
  lockToCancelTime: '',
  cancelingFeePrice: ''
};

const OrderParamsDeleteForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid }) => {
  const { gettext } = useGettext();
  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col md={4}>
          <Field
            name={'lockToCancelTime'}
            id={'lockToCancelTime'}
            placeholder={gettext('Time')}
            formText={i18n.t('cards.settings.inMinutes')}
            component={FormInput}
            validate={required}
            type="number"
          />
        </Col>
        <Col>
          <div className="mt-1">
            {i18n.t('cards.settings.orderParams.amountOfTime', {
              status: i18n.t('cards.settings.orderParams.cantCancel')
            })}
          </div>
        </Col>
      </Row>

      <CardSubtitle className="mt-2">{i18n.t('cards.settings.cancelFee')}</CardSubtitle>
      <Row form>
        <Col md={4}>
          <Field name={'cancelingFeePrice'} id={'cancelingFeePrice'} placeholder={gettext('Fee')} component={FormInput} />
        </Col>
        <Col>
          <div className="mt-1">{i18n.t('cards.settings.orderParams.cancelFee')}</div>
        </Col>
      </Row>

      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(OrderParamsDeleteForm);
