import { FC, useMemo } from 'react';
import type { OrderQuery } from '../queries/orderWithConfigQuery.generated';
import { useDroppable } from '@dnd-kit/core';
import { Product, mergeData } from '@/shared/components/modal/DNDProductDeliveryConfirmation';
import styled from 'styled-components';
import { gettext } from '@cranium/i18n';
import { Form, Tooltip } from 'antd';

type DeliveryItems = OrderQuery['order']['trucksDrivers'][0]['deliveryItems'];

type ProductsProps = {
  value?: DeliveryItems;
  index: number;
  remove: () => void;
};
export const Products: FC<ProductsProps> = ({ value, remove, index, ...rest }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: `${index}`
  });
  const form = Form.useFormInstance<{
    trucks: {
      deliveryItems?: DeliveryItems;
    }[];
    unisignedProducts?: DeliveryItems;
  }>();

  const notEmpty = useMemo(() => {
    return Array.isArray(value) && value.filter(({ quantity }) => quantity).length;
  }, [value]);
  const hasProducts = useMemo(() => {
    const trucks = form.getFieldValue('trucks');
    return trucks?.length > 1;
  }, [value, form.getFieldValue('trucks')]);
  const handleDelete = () => {
    if (!Array.isArray(value)) return remove();
    const newValue = value.reduce((res, item) => {
      return mergeData(item, res);
    }, form.getFieldValue('unisignedProducts'));
    form.setFields([
      {
        name: 'unisignedProducts',
        value: newValue,
        touched: true
      }
    ]);
    remove();
    form.setFieldsValue({});
  };
  return (
    <Main ref={setNodeRef} empty={!notEmpty} isOver={isOver}>
      <Row>
        {value?.map(pr => {
          return <Product key={pr.id} {...pr} index={index} />;
        })}
      </Row>
      {!notEmpty && <Info>{gettext('Drag products here')}</Info>}
      {Boolean(hasProducts) && (
        <Tooltip title={gettext('Delete truck')}>
          <DeleteButton onClick={handleDelete}>
            <i className={'icon-trash'} />
          </DeleteButton>
        </Tooltip>
      )}
    </Main>
  );
};

const Info = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-7);
  font: var(--font-regular-14);
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  :empty {
    display: none;
  }
`;

const Main = styled.div<{ empty: boolean; isOver?: boolean }>`
  flex: 1;
  display: flex;
  margin-right: 16px;
  align-self: stretch;
  background-color: var(--brand-surface-lihgt);
  border: 1px dashed var(--brand-boarder);
  padding: 4px 8px ;
  border-radius: 2px;
  transition: background-color 0.3s, border-color 0.3s;
  ${({ empty }) =>
    empty
      ? `
     align-items: center;
     justify-content: center;
  `
      : ''}}
${({ isOver }) =>
  isOver
    ? `
     background-color: var(--green-1);
     border-color: var(--green-7);
  `
    : ''}}
`;

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  padding: 0 4px;
  align-self: stretch;
  i {
    color: var(--gray-7);
    font-size: 18px;
  }
`;
