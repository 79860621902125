import React, { ReactNode } from 'react';
import { InputNumber, Space } from 'antd';
import styled from 'styled-components';
import { noop } from 'lodash';
import { number } from '@/libs';
import { TruckIcon } from '@pages/OrderCreatePage/sections/DeliveryDetailsSection/components/TruckIcon';

const StyledInput = styled(InputNumber)`
  width: 68px !important;
`;

const Row = styled.div`
  display: flex;
  max-width: 150px;
  gap: 20px;
  align-items: center;
  > div {
    flex: 1;
  }
`;

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  index: number;
  max: number;
  min: number;
  step: number;
  disabled?: boolean;
  inputElememt?: ReactNode;
}

export const TruckItem: React.FunctionComponent<Props> = ({
  index,
  value = 0,
  onChange = noop,
  max,
  min,
  step,
  disabled = false,
  inputElememt
}) => {
  const onChangeCount = (value: number) => {
    const fixedValue = number.round(Math.max(min, value), { math: true, factor: 4 });
    onChange(fixedValue);
  };
  return (
    <Row>
      <TruckIcon index={index} />
      {inputElememt || (
        <StyledInput
          disabled={disabled}
          value={value}
          formatter={value => value?.toString() ?? ''}
          onChange={val => val != null && onChangeCount(val as number)}
          controls={false}
          min={min}
        />
      )}
    </Row>
  );
};
