import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { ComponentType } from 'react';
import styles from './CountryOption.module.scss';
import { OptionProps } from 'react-select';

const CountryOption: ComponentType<OptionProps<any, boolean>> = (props): JSX.Element => {
  const { innerProps, data, isSelected } = props;
  return (
    <div {...innerProps} className={cx(styles.countryOption)}>
      <span>{data.label}</span>
      {isSelected && <FontAwesomeIcon icon={faCheck} />}
    </div>
  );
};

export default CountryOption;
