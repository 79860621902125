import React, { useState, useEffect } from 'react';
import * as core from '@/core';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';

export const withApolloContext = (Component: React.FC<any>) => (props: any) => {
  const [client, setClient] = useState<null | ApolloClient<NormalizedCacheObject>>(null);

  useEffect(() => {
    const getContext = async () => {
      const apolloClient = await core.apolloClient;
      setClient(apolloClient as ApolloClient<NormalizedCacheObject>);
    };
    getContext();
  }, [setClient]);

  return (
    <React.Fragment>
      {client && (
        <ApolloProvider client={client}>
          <Component {...props} />
        </ApolloProvider>
      )}
    </React.Fragment>
  );
};
