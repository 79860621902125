import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput, FormInputSelect, FormInputGroup } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { useFormContentOptions } from './options';
import type { InputOptionType } from '@components/forms/settings/ContractorEditForm/types';

const SeasonalityFeeForm: React.FC<FormProps> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  const { gettext } = useGettext();
  const formContentOptions = useFormContentOptions();
  return (
    <Form onSubmit={handleSubmit}>
      <Field className="m-0" hidden name="category.id" id="category.id" component={FormInput} validate={required} />
      <Row form>
        <Col>
          <Field
            name={`value.content`}
            id={`value.content`}
            placeholder={gettext('Type')}
            formText={i18n.t('cards.settings.fixFee')}
            component={FormInputSelect}
            options={formContentOptions}
            autoComplete={`minDeliveryRow.content`}
            validate={required}
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="value.value"
            id="value.value"
            component={FormInputGroup}
            addonType="append"
            type="number"
            label={i18n.t('cards.settings.fee')}
            formText={i18n.t('cards.settings.enterAmountFormText')}
            inputGroupText={i18n.t('common.priceSymbol')}
            formGroupClassNames="m-0"
            validate={required}
            placeholder={gettext('00.0')}
            inputGroupClassName="p-0 pr-2 pl-2"
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="value.from"
            id="value.from"
            placeholder={gettext('Start Date')}
            formText={i18n.t('cards.settings.from')}
            component={FormInput}
            autoComplete={`seasonalityFee.startDate`}
            validate={required}
            type="date"
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="value.to"
            id="value.to"
            placeholder={gettext('End Date')}
            formText={i18n.t('cards.settings.to')}
            component={FormInput}
            autoComplete={`seasonalityFee.endDate`}
            validate={required}
            type="date"
          />
        </Col>
      </Row>

      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<SeasonalityFeeFormInitialProps>(SeasonalityFeeForm);

export const seasonalityFeeFormInitialValues: SeasonalityFeeFormInitialProps = {
  category: { id: '' },
  value: {
    content: '',
    from: '',
    to: '',
    value: ''
  }
};

export type SeasonalityFeeFormInitialProps = {
  category: { id: string };
  value: {
    content: InputOptionType | string;
    from: string;
    to: string;
    value: number | string;
  };
};

type FormProps = FormikProps<SeasonalityFeeFormInitialProps>;
