import React, { useCallback } from 'react';
import styled from 'styled-components';
import convert from 'color-convert';

type Props = {
  setColour: (colour: string | undefined) => void;
  setHue: (hue: number) => void;
  setHueX: (hueX: number) => void;
  setSquareXY: (squareXY: number[]) => void;
};

type ColourInfo = {
  Hue: number;
  HueX: number;
  squareXY: number[];
  colour: string;
};

const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 2px;
  grid-row-gap: 6px;
  align-items: center;
  justify-items: center;
  padding: 10px 10px;
`;

const ColorSquare = styled.div<{ colour: string }>`
  height: 17.5px;
  width: 17.5px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => props.colour};
`;

const defaultColors = [
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7'
];

const SuggestedColours: React.FunctionComponent<Props> = ({ setColour, setHue, setHueX, setSquareXY }) => {
  const handleColourChange = (colour: string) => {
    const [r, g, b] = convert.hex.rgb(colour);
    setColour(`rgb(${r},${g},${b})`);
  };

  return (
    <ColorGrid>
      {defaultColors.map(colour => (
        <ColorSquare key={colour} colour={colour} onClick={() => handleColourChange(colour)} />
      ))}
    </ColorGrid>
  );
};

export default SuggestedColours;
