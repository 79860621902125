// FIXME: resolve all types
// @ts-nocheck
export default class Logger {
  public static getLogger(target: string): Logger {
    return new Logger(target);
  }

  public static getFullLog(): string {
    return Logger.logData.join('\n');
  }

  private static linesInLog: number = 0;
  private static logData: Array<string> = [];

  private readonly _target: string;

  private constructor(target: string) {
    this._target = target;
  }

  public info(message?: any, ...other: any[]): void {
    const mainMessage: string = `${new Date().toLocaleTimeString()} [${this._target}] ${message}`;
    this.log(mainMessage, ...other);
  }

  public error(message?: any, ...other: any[]): void {
    if (message instanceof Error) {
      this.log(`${new Date().toLocaleTimeString()} [${this._target}] ${message.name}: ${message.message}`, ...other);
    } else {
      this.log(`${new Date().toLocaleTimeString()} [${this._target}] ${message}`, ...other);
    }
  }

  private log(message?: any, ...other: any[]): void {
    console.group(message);
    console.info(...other);

    if (other != null && other.length > 0) {
      other.forEach(() => {
        //TODO Fix console output
        //const v = "  " + JSON.stringify(item);
      });
    }

    console.groupEnd();
  }
}

export const defaultLogger = Logger.getLogger('Common');

export function log(logger?: Logger, async: boolean = true) {
  return (target, key, descriptor) => {
    if (!descriptor) return descriptor;

    if (!async) {
      const func = descriptor.value;
      descriptor.value = function wrapper(...args) {
        if (!logger) {
          logger = this.logger || defaultLogger;
        }

        logger.info(`"${key}" method called with`, args);

        try {
          const result = func.call(this, ...args);
          logger.info(`"${key}" method returns`, result);
          return result;
        } catch (e) {
          logger.info(`"${key}" method returns error`, e);
          throw e;
        }
      };
      return descriptor;
    }

    const func = descriptor.value;
    descriptor.value = async function wrapper(...args) {
      if (!logger) {
        logger = this.logger || defaultLogger;
      }

      logger.info(`"${key}" method called with`, args);

      try {
        const result = await func.call(this, ...args);
        logger.info(`"${key}" method returns`, result);
        return result;
      } catch (e) {
        logger.info(`"${key}" method returns error`, e);
        throw e;
      }
    };
    return descriptor;
  };
}
