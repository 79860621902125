import React, { useState, useEffect, PropsWithChildren, Fragment } from 'react';
import { useAuthorization } from '@/core/gql/client';
import { IdentityTypeEnum } from '@/interfaces/brokrete';

type ComponentProps = {
  allowedUserTypes?: IdentityTypeEnum[];
};

type Props = PropsWithChildren<ComponentProps>;

const ProtectedRenderer: React.FC<Props> = ({ children, allowedUserTypes = [] }) => {
  const [canRender, setCanRender] = useState(false);
  const { authorization } = useAuthorization();

  useEffect(() => {
    if (authorization) {
      const { user } = authorization;
      const accessPermitted = user?.type && allowedUserTypes.includes(user?.type);

      if (accessPermitted !== canRender) {
        setCanRender(true);
      }
    }
  }, [canRender, authorization, allowedUserTypes]);

  return canRender ? <>{children}</> : <Fragment />;
};

export default ProtectedRenderer;
