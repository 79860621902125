import gql from 'graphql-tag';
import { supplier } from '@/core/gql/fragments';

export const suppliers = gql`
  query suppliers($limit: Int, $skip: Int) {
    suppliers(limit: $limit, skip: $skip) {
      count
      values {
        ...supplier
      }
    }
  }
  ${supplier}
`;

export const suppliersMinimal = gql`
  query suppliers($region: RegionInput, $limit: Int, $skip: Int, $search: String, $project: IdInput) {
    suppliers(search: $search, region: $region, limit: $limit, skip: $skip, project: $project) {
      count
      values {
        id
        name
      }
    }
  }
`;
