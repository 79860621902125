import { createAction } from '@/rdx/libs/action';

import type { SettingsState } from './reducers';

export const ActionTypes = {
  UPDATE: 'settings.update'
};

const update = (payload: Partial<SettingsState>) => createAction(ActionTypes.UPDATE, payload);

export const Actions = {
  update
};
