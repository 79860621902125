import gql from 'graphql-tag';

export const create = gql`
  mutation orderCreate($contractor: IdInput, $draft: Boolean, $order: OrderCreateInput!, $plant: IdInput) {
    orderCreate(contractor: $contractor, draft: $draft, order: $order, plant: $plant) {
      success
      order {
        id
      }
    }
  }
`;
