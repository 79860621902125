import React, { useEffect, useMemo, useState } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useUIContext } from '@components/context/ApplicationContext';

const TitleWrapper = styled.div`
  height: 40px;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const ArrowIcon = styled.span<{ isOpen: boolean }>`
  transform: ${props => (props.isOpen ? 'rotate(90deg)' : 'rotate(0)')};
  transition: transform 0.15s ease;
`;

const LinksContainer = styled.div<{ isOpen: boolean; active: boolean; minimized: boolean }>`
  max-height: ${props => (props.isOpen ? '1000px' : 0)};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
  background-color: ${props => (!props.minimized ? (props.active ? 'var(--green-1)' : 'var(--gray-1)') : 'transparent')};
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
`;

interface Props {
  title: string;
  paths: string[];
  openByDefault: boolean;
}

export const CollapsibleMenuItem: React.FunctionComponent<Props> = ({ title, paths, openByDefault, children }) => {
  const {
    sidebar: { minimized }
  } = useUIContext();
  const location = useLocation();

  const active = useMemo(() => {
    return !!paths.find(path => location.pathname.includes(path));
  }, [paths]);

  const [isOpen, setIsOpen] = useState(active || openByDefault);

  useEffect(() => {
    minimized && setIsOpen(true);
  }, [minimized]);

  return (
    <div>
      {!minimized && (
        <TitleWrapper className="cursor-pointer" onClick={() => setIsOpen(prev => !prev)}>
          <ArrowIcon isOpen={isOpen} className="icon-chevron-right gray-7" />
          <Typography className="normal-14 gray-9">{title}</Typography>
        </TitleWrapper>
      )}
      <LinksContainer isOpen={isOpen} active={active} minimized={minimized}>
        {children}
      </LinksContainer>
    </div>
  );
};
