import { FC, useMemo } from 'react';
import {
  //eslint-disable-next-line no-restricted-imports
  Link as RouterLink,
  //eslint-disable-next-line no-restricted-imports
  NavLink as RouterNavLink,
  LinkProps,
  NavLinkProps,
  useParams,
  useLocation,
  //eslint-disable-next-line no-restricted-imports
  useHistory as useRouterHistory
} from 'react-router-dom';
import { getAccountfromUrl } from '@/core/getAccountfromUrl';

function parseLocation(location: LinkProps['to'], partnerId?: string | null) {
  if (!partnerId) return location;
  if (typeof location === 'string') {
    if (location.includes(partnerId)) {
      return location;
    } else {
      return `/p-${partnerId}/${location}`.replace('//', '/');
    }
  }
  if (typeof location === 'object' && location.pathname) {
    if (location.pathname.includes(partnerId)) {
      return location;
    } else {
      return {
        ...location,
        pathname: `/p-${partnerId}/${location.pathname}`.replace('//', '/')
      };
    }
  }
  return location;
}
export const Link: FC<LinkProps> = props => {
  const params = useParams<{ partnerId: string }>();
  const location = useLocation();
  const partnerId = params.partnerId || getAccountfromUrl(location.pathname);

  const path = useMemo(() => {
    return parseLocation(props.to, partnerId);
  }, [props.to, partnerId]);
  return <RouterLink {...props} to={path || props.to} />;
};

export const NavLink: FC<NavLinkProps & { shouldParseLocation?: boolean }> = ({ shouldParseLocation = true, ...props }) => {
  const params = useParams<{ partnerId: string }>();
  const location = useLocation();
  const partnerId = params.partnerId || getAccountfromUrl(location.pathname);
  const path = useMemo(() => {
    if (shouldParseLocation) {
      return parseLocation(props.to, partnerId);
    }
    return props.to;
  }, [props.to, partnerId, shouldParseLocation]);
  return <RouterNavLink {...props} to={path} />;
};

export function useHistory() {
  const original = useRouterHistory();
  const params = useParams<{ partnerId: string }>();
  const location = useLocation();
  const partnerId = params.partnerId || getAccountfromUrl(location.pathname);
  const push = (location: any, state?: any) => {
    return original.push(parseLocation(location, partnerId) as any, state);
  };
  return {
    ...original,
    push
  };
}

export { useLocation, useParams };
