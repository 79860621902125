import gql from 'graphql-tag';

export const countries = gql`
  query countries($limit: Int, $skip: Int) {
    countries(limit: $limit, skip: $skip) {
      code
      currency
      id
      name
    }
  }
`;
