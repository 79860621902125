export default function composeValidators(...validators: any[]) {
  return (value: any) => {
    const validationErrors = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const validator of validators) {
      const error = validator(value);

      if (error) {
        validationErrors.push(error);
      }
    }

    return validationErrors.length > 0 ? validationErrors : undefined;
  };
}
