import * as Types from '../../../gql/types.generated';

import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetParnterTokenMutationVariables = Types.Exact<{
  email?: Types.InputMaybe<Types.IdentityEmailInput>;
  type: Types.IdentityTypeEnum;
  partner?: Types.InputMaybe<Types.IdInput>;
}>;


export type GetParnterTokenMutation = { __typename?: 'Mutation', authSignIn: { __typename?: 'AuthSignInPayload', success: boolean, type?: Types.IdentityTypeEnum | null, token?: string | null, refreshToken?: string | null, contractor?: { __typename?: 'Contractor', id: string, displayName?: string | null } | null, superAdmin?: { __typename?: 'SuperAdmin', id: string, email: string } | null, partner?: { __typename?: 'Partner', id: string, logo?: string | null, name?: string | null, phone?: string | null, email?: string | null, description?: string | null, code?: string | null, timezone?: string | null, address?: { __typename?: 'Address', country: string } | null, account?: { __typename?: 'PartnerAccount', currency: string } | null } | null } };


export const GetParnterTokenDocument = gql`
    mutation getParnterToken($email: IdentityEmailInput, $type: IdentityTypeEnum!, $partner: IdInput) {
  authSignIn(email: $email, type: $type, partner: $partner) {
    success
    type
    token
    refreshToken
    contractor {
      id
      displayName
    }
    superAdmin {
      id
      email
    }
    partner {
      id
      logo
      name
      phone
      email
      description
      code
      address {
        country
      }
      account {
        currency
      }
      timezone
    }
  }
}
    `;
export type GetParnterTokenMutationFn = Apollo.MutationFunction<GetParnterTokenMutation, GetParnterTokenMutationVariables>;

/**
 * __useGetParnterTokenMutation__
 *
 * To run a mutation, you first call `useGetParnterTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetParnterTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getParnterTokenMutation, { data, loading, error }] = useGetParnterTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      type: // value for 'type'
 *      partner: // value for 'partner'
 *   },
 * });
 */
export function useGetParnterTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetParnterTokenMutation, GetParnterTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetParnterTokenMutation, GetParnterTokenMutationVariables>(GetParnterTokenDocument, options);
      }
export type GetParnterTokenMutationHookResult = ReturnType<typeof useGetParnterTokenMutation>;
export type GetParnterTokenMutationResult = Apollo.MutationResult<GetParnterTokenMutation>;
export type GetParnterTokenMutationOptions = Apollo.BaseMutationOptions<GetParnterTokenMutation, GetParnterTokenMutationVariables>;


export function useGetParnterTokenRequest() {
    const client = useApolloClient();
    return useCallback<(variables: GetParnterTokenMutationVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<GetParnterTokenMutation>>>( (variables, options) => {
        return client.query<GetParnterTokenMutation>({
            fetchPolicy: 'no-cache',
          ...options,
          query: GetParnterTokenDocument,
          variables
        })
    }, []);
}

