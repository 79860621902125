import React, { useRef, useEffect } from 'react';
import { usePaintHue } from './hooks';
import styled from 'styled-components';

type PickerHueBarProps = {
  hue: number;
  hueX: number;
  setHueX: (hueX: number) => void;
  setHue: (hue: number) => void;
  css?: string;
};

const ColourPickerHueBar: React.FunctionComponent<PickerHueBarProps> = props => {
  const { hue, hueX, setHueX, setHue, css } = props;

  const bar = useRef<any>(null);
  const canvas = useRef<any>(null);
  usePaintHue(canvas);

  useEffect(() => {
    const canvasRef = canvas.current;

    const computePosition = (e: MouseEvent) => {
      const { x } = canvasRef.getBoundingClientRect();

      return Math.max(-3, Math.min(e.clientX - x - 10, 208));
    };

    const computeHue = (x: number) => {
      return Math.round(x * (360 / 220));
    };

    const onMouseMove = (e: MouseEvent) => {
      const x = computePosition(e);
      const hue = computeHue(x);

      setHueX(x);
      setHue(hue);
    };

    const onMouseUp = (e: MouseEvent) => {
      const x = computePosition(e);
      const hue = computeHue(x);

      setHueX(x);
      setHue(hue);

      document.body.removeEventListener('mousemove', onMouseMove);
      document.body.removeEventListener('mouseup', onMouseUp);
    };

    const onMouseDown = () => {
      document.body.addEventListener('mousemove', onMouseMove);
      document.body.addEventListener('mouseup', onMouseUp);
    };

    const barRef = bar.current;
    barRef.addEventListener('mousedown', onMouseDown);

    return () => {
      barRef.removeEventListener('mousedown', onMouseDown);
      document.body.removeEventListener('mousemove', onMouseMove);
      document.body.removeEventListener('mouseup', onMouseUp);
    };
  }, [setHue, setHueX]);

  return (
    <PickerHueBarContainer ref={bar}>
      <PickerHueBarHandle left={hueX}>
        <PickerHueBarHandleSvg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="35" stroke="white" strokeWidth="15" fill={`hsl(${hue}, 100%, 50%)`} />
        </PickerHueBarHandleSvg>
      </PickerHueBarHandle>
      <PickerHueBarCanvas ref={canvas} css={css} />
    </PickerHueBarContainer>
  );
};

type PickerRefProps = {
  ref: any;
  css?: string;
};

const PickerHueBarContainer = styled.div<PickerRefProps>`
  position: relative;
  width: 220px;
  height: 10px;
  cursor: pointer;
`;

const PickerHueBarCanvas = styled.canvas.attrs(() => ({
  width: '220px',
  height: '10px'
}))<PickerRefProps>`
  ${props => (props.css ? props.css : 'position: absolute;')}
`;

type PickerHueBarHandleProps = {
  left: number;
};

const PickerHueBarHandle = styled.div.attrs<PickerHueBarHandleProps>((props: PickerHueBarHandleProps) => ({
  style: {
    left: `${props.left}px`
  }
}))<PickerHueBarHandleProps>`
  position: absolute;
  top: -4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 18px;
  height: 18px;
  pointer-events: none;
`;

const PickerHueBarHandleSvg = styled.svg`
  width: 100%;
  height: 100%;
  z-index: 3;
  -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
`;

export default ColourPickerHueBar;
