import { useLocalStorage } from '@/core/Storage';
import type { DevToolsType } from './types';
import { DEV_TOOLS_KEY } from './utils';

export function useDevTools() {
  const { value } = useLocalStorage<DevToolsType & { debugger?: boolean }>(DEV_TOOLS_KEY);
  if (value?.debugger) {
    return value as DevToolsType;
  }
  return {} as DevToolsType;
}
