import React from 'react';
import ColourPickerSLSquare from './ColourPickerSLSquare';
import ColourPickerHueBar from './ColourPickerHueBar';
import styled from 'styled-components';
import { convertRGBtoHEX } from './util';
import SuggestedColours from './SuggestedColours';

type PickerProps = {
  hue: number;
  hueX: number;
  squareXY: number[];
  colour: string | undefined;
  css?: {
    buttonCss?: string;
    colorPickerCss?: string;
    hueBarCss?: string;
  };
  setHue: (hue: number) => void;
  setHueX: (hueX: number) => void;
  setSquareXY: (squareXY: number[]) => void;
  setColour: (colour: string | undefined) => void;
  defaultColours?: boolean;
};

const ColourPickerButton: React.FunctionComponent<PickerProps> = props => {
  const { hue, hueX, squareXY, colour, css, setHue, setHueX, setSquareXY, setColour, defaultColours } = props;
  const colourHex = colour ? `#${convertRGBtoHEX(colour)}` : null;

  return (
    <PickerContainer css={css?.colorPickerCss} defaultColours={defaultColours} aria-label="colorPicker">
      <ColourPickerSLSquare hue={hue} squareXY={squareXY} colour={colour} setSquareXY={setSquareXY} setColour={setColour} />
      <ColourPickerHueBar hue={hue} hueX={hueX} setHueX={setHueX} setHue={setHue} css={css?.hueBarCss} />
      <PickerText>{colourHex}</PickerText>
      {defaultColours && <SuggestedColours setColour={setColour} setHue={setHue} setHueX={setHueX} setSquareXY={setSquareXY} />}
      <Spacer />
    </PickerContainer>
  );
};

const PickerContainer = styled.div<{ css?: string; defaultColours?: boolean }>`
  position: absolute;
  z-index: 2;
  margin-top: 5px;
  margin-left: 8px;
  height: ${props => (props.defaultColours ? '320px' : '296px')};
  width: 220px;
  background-color: #fff;
  display: block;
  box-shadow: 0 3px 12px 2px rgba(38, 42, 54, 0.08);
  ${props => props.css && props.css}
`;

const PickerText = styled.div`
  margin-top: 12px;
  margin-left: 12px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 10px;
  font-weight: 700;
  display: flex;
`;

const Spacer = styled.div`
  height: 30px;
  background: transparent;
`;

export default ColourPickerButton;
