import React from 'react';
import styled from 'styled-components';
import { useFileViewerContext } from '@/shared/components/dataDisplay/FileVIewer/FileViewerContext';
import ActivityIndicator from '@components/elements/ActivityIndicator';

const Container = styled.div<{ height: number }>`
  height: ${props => props.height}px;
  width: ${props => props.height}px;
  position: relative;
  border: 2px solid #1fb841;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
/**
 * Item with loading icon.
 * @constructor
 */
export const LoadingFileItem = () => {
  const [state] = useFileViewerContext();
  return (
    <Container height={state.height}>
      <ActivityIndicator />
    </Container>
  );
};
