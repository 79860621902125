// FIXME: Need to refactor
const getCurrentPosition = async (): Promise<GeolocationCoordinates> => {
  return new Promise((resolve, reject) => {
    const options = {
      enableHighAccuracy: true,
      // FIXME: timeout error on 5000???
      timeout: 10000,
      maximumAge: 0
    };

    function onSuccess(position: GeolocationPosition) {
      resolve(position.coords);
    }

    function onError(error: GeolocationPositionError) {
      console.warn(`ERROR(${error.code}): ${error.message}`);

      reject(null);
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
  });
};

export { getCurrentPosition };

const geolocation = {
  getCurrentPosition
};
export default geolocation;
