import React, { MutableRefObject, useCallback } from 'react';
import ReactNumberFormat from 'react-number-format';

import Input from '@/shared/components/formik/fields/Input';
import { InputProps } from 'reactstrap/lib/Input';
import { toNumber } from '@/libs/string';

type FormattedInputProps = {
  innerRef?: MutableRefObject<any>;
  onChange?: (value: number) => void;
  value: string;
  prefix?: string;
} & Omit<InputProps, 'onChange'>;

const FormattedInput: React.FC<FormattedInputProps> = (props: FormattedInputProps) => {
  const { className, value, prefix = '$', name, onChange, disabled, readOnly } = props;

  const handleInputChange = useCallback(
    e => {
      if (onChange) {
        let strValue: string = e.target.value.split(',').join('');
        const numValue: number = prefix === '' ? toNumber(strValue) : toNumber(strValue?.substr(1));
        onChange(numValue);
      }
    },
    [prefix, onChange]
  );

  return (
    //@ts-ignore
    <ReactNumberFormat
      className={className}
      thousandSeparator={true}
      prefix={prefix}
      name={name}
      value={value ?? ''}
      customInput={Input}
      disabled={disabled}
      onChange={handleInputChange}
      readOnly={readOnly}
      allowLeadingZeros
      allowNegative
    />
  );
};

export default FormattedInput;
