import React from 'react';
import styled from 'styled-components';
import { FormGroup, Input, FormText } from 'reactstrap';
import { getIn, FieldProps } from 'formik';
import { FormLabel } from '@components/forms/controls/FormLabel';

type InputProps = {
  label: string;
  formGroupClassNames?: string;
  formText?: string;
  onChange: (e: any) => void;
};

const FormGroupStyled = styled(FormGroup)`
  display: flex !important;
  align-items: center !important;

  input {
    margin-top: auto !important;
  }
`;

const FormInputCheckBox: React.FC<FieldProps<any, any> & InputProps> = (props): JSX.Element => {
  const {
    field,
    label,
    form: { errors: formErrors, touched: formTouched },
    formText,
    formGroupClassNames,
    onChange,
    ...restProps
  } = props;

  const { name } = field;
  const error = getIn(formErrors, name);
  const touched = getIn(formTouched, name);

  const invalid: boolean = !!(error && touched);

  const handleOnChange = (e: any) => {
    field.onChange(e);
    onChange && onChange(e);
  };

  return (
    <FormGroupStyled check className={formGroupClassNames}>
      <Input id={name} invalid={invalid} {...field} {...restProps} type="checkbox" onChange={handleOnChange} />
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {formText && <FormText color="muted">{formText}</FormText>}
      {/* {invalid && <FormFeedback>{String(errors[name])}</FormFeedback>} */}
      {/* //TODO return when fixed validators */}
    </FormGroupStyled>
  );
};

export default FormInputCheckBox;
