// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CloseButton-module__root--agw4E{cursor:pointer}.CloseButton-module__root--agw4E path{fill:#ccc}.CloseButton-module__root--agw4E:hover path{fill:#000}", "",{"version":3,"sources":["webpack://./src/view/components/elements/buttons/CloseButton.module.scss"],"names":[],"mappings":"AAAA,iCACE,cAAA,CAEA,sCACE,SAAA,CAIA,4CACE,SAAA","sourcesContent":[".root {\n  cursor: pointer;\n\n  path {\n    fill: rgb(204, 204, 204);\n  }\n\n  &:hover {\n    path {\n      fill: #000;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CloseButton-module__root--agw4E"
};
export default ___CSS_LOADER_EXPORT___;
