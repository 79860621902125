import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { colors } from '@/defaultStyles';
import { useDebounce } from '@/view/hooks/useDebounce';

type InputProps = {
  className?: string;
  value?: string;
  debounceTime?: number;
  name: string;
  placeholder?: string;
  defaultValue?: string;
  leftIcon?: string | React.ReactNode;
  onChange?: (value: string) => void;
  autoComplete?: string;
};

type InputControlProps = {
  leftPad?: boolean;
};

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  box-sizing: border-box;
`;

const Icon = styled.img`
  position: absolute;
  margin-left: 12px;
`;

const InputControl = styled.input<InputControlProps>`
  height: 40px;
  width: 100%;
  padding: 12px 14px 12px
    ${(props: InputControlProps) => {
      return props.leftPad ? '32px' : '12px';
    }};

  border-radius: 4px;
  border: 1px solid ${colors.LIGHT};
  background-color: ${colors.LIGHT};

  transition: border-color 0.2s linear;
  outline: none;
  box-sizing: border-box;

  font-size: 18px;

  &:focus {
    border-color: ${colors.ALGAE_GREEN};
  }

  &::placeholder {
    font-size: 14px;
    color: ${colors.CLOUDY_BLUE};
  }
`;

const Input: React.FC<InputProps> = (props: InputProps) => {
  const { autoComplete, className, debounceTime, leftIcon, placeholder, value, onChange } = props;

  const [inputValue, setInputValue] = useState<string | undefined>(value);
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  const debouncedValue = useDebounce(inputValue, debounceTime || 0);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (onChange) {
      if (debouncedValue != ref.current) {
        ref.current = debouncedValue;
        onChange(debouncedValue);
      }
    }
  }, [debouncedValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Root className={className}>
      {leftIcon ? typeof leftIcon === 'string' ? <Icon src={leftIcon} /> : leftIcon : null}

      <InputControl
        placeholder={placeholder}
        value={inputValue || ''}
        onChange={handleInputChange}
        leftPad={!!leftIcon}
        autoComplete={autoComplete}
      />
    </Root>
  );
};

export default Input;
