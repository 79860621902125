// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoContainer-module__root--cUI_w{min-height:140px;height:auto;display:flex;flex-direction:column;justify-content:center;align-items:center;border:1px dashed #979797;border-radius:.45rem;margin:.25rem}.InfoContainer-module__root--cUI_w .InfoContainer-module__emptyDataTitle--Rdd4l{font-size:1.25rem;color:rgba(18,21,78,.7);font-weight:400;height:3.5rem;display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/view/components/elements/InfoContainer.module.scss"],"names":[],"mappings":"AAAA,mCACE,gBAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,yBAAA,CACA,oBAAA,CACA,aAAA,CAEA,gFACE,iBAAA,CACA,uBAAA,CACA,eAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".root {\n  min-height: 140px;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border: 1px dashed #979797;\n  border-radius: 0.45rem;\n  margin: 0.25rem;\n\n  .emptyDataTitle {\n    font-size: 1.25rem;\n    color: rgba(18, 21, 78, 0.7);\n    font-weight: 400;\n    height: 3.5rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "InfoContainer-module__root--cUI_w",
	"emptyDataTitle": "InfoContainer-module__emptyDataTitle--Rdd4l"
};
export default ___CSS_LOADER_EXPORT___;
