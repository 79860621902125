import * as orders from './order';
import * as plants from './plant';
import * as contractors from './contractor';
import * as cities from './city';
import * as countries from './country';
import * as suppliers from './supplier';
import * as products from './product';
import * as authorization from './authorization';
import * as orderPreparatoryConfig from './orderPreparatoryConfig';

export const GQL = {
  ...cities,
  ...countries,
  ...contractors,
  ...authorization,
  ...orders,
  ...plants,
  ...products,
  ...suppliers,
  ...orderPreparatoryConfig
};
