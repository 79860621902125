import gql from 'graphql-tag';

export const supplierPlantsDataTable = gql`
  query Supplier($id: ID!, $limit: Int, $skip: Int) {
    supplier(id: $id) {
      plants(limit: $limit, skip: $skip) {
        values {
          id
          name
          address {
            display
            country
            city
            location {
              latitude
              longitude
            }
          }
          deliveryRadius
          supplier {
            id
            name
          }
        }
        count
      }
    }
  }
`;
