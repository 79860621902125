import { useUserData } from '@/shared/access';
import { useMemo } from 'react';

export const useAuthorization = () => {
  const user = useUserData();

  return useMemo(() => {
    return { authorization: { user } };
  }, [user]);
};
