import styled from 'styled-components';
import { colors } from '@/defaultStyles';

const Label = styled.label`
  margin-bottom: 0 !important;

  font-family: Catamaran;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  height: 12px;
  color: ${colors.CHARCOAL_GREY};

  text-transform: uppercase;
  white-space: nowrap;
`;

export default Label;
