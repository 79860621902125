import gql from 'graphql-tag';

export const update = gql`
  mutation supplierUpdate($input: SupplierUpdateInput!) {
    supplierUpdate(input: $input) {
      success
      supplier {
        id
        name
      }
    }
  }
`;
