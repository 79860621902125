import styled, { css } from 'styled-components';
import { colors } from '@/defaultStyles';

type ButtonProps = {
  disabled?: boolean;
  active?: boolean;
  round?: boolean;
  width?: number;
  height?: number;
  size?: 'lg' | 'md' | 'sm';
  color?: 'monochrome' | 'light' | 'primary';
  outline?: boolean;
  type?: 'button' | 'submit';
};

const mixins = {
  size: {
    lg: css`
      padding: 8px 16px;
    `,
    md: css`
      min-height: 33px;
      padding: 6px 12px;
    `,
    sm: css`
      padding: 4px 8px;
    `
  },
  color: {
    monochrome: css`
      color: ${colors.BATTLESHIP_GREY};
      background-color: ${colors.WHITE};

      @media (hover: hover) {
        &:hover {
          color: ${colors.WHITE};
          background-color: ${colors.DARK_BLUE};
        }
      }

      ${(props: ButtonProps) =>
        props.active
          ? `
              color: ${colors.WHITE};
              background-color: ${colors.DARK_BLUE}; 
            `
          : ''}
    `,
    light: css`
      color: ${colors.BATTLESHIP_GREY};
      background-color: ${colors.WHITE};

      @media (hover: hover) {
        &:hover {
          color: ${colors.BATTLESHIP_GREY};
          background-color: ${colors.LIGHT};
        }
      }
    `,
    primary: css`
      color: ${({ outline }: ButtonProps) => (outline ? colors.ALGAE_GREEN : colors.WHITE)};
      border: 1px solid ${colors.ALGAE_GREEN};
      background-color: ${({ outline }: ButtonProps) => (outline ? colors.WHITE : colors.ALGAE_GREEN)};

      @media (hover: hover) {
        &:hover {
          color: ${colors.WHITE};
          background-color: ${colors.ALGAE_GREEN};
        }
      }
    `
  }
};

const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  border-radius: ${({ round }) => (round ? '50%' : '2px')};
  color: ${colors.BATTLESHIP_GREY};
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  transition:
    background-color 0.2s ease,
    color 0.2s ease,
    transform 0.2s ease,
    box-shadow 0.2s ease;

  > svg {
    path {
      transition: fill 0.2s ease;
    }
  }

  user-select: none;
  outline: none;

  pointer-events: ${(props: ButtonProps) => (props.disabled ? 'none' : 'all')};

  opacity: ${(props: ButtonProps) => (props.disabled ? 0.5 : 1)};

  ${(props: ButtonProps) => {
    return css`
      ${mixins.size[props.size || 'md']}
      ${mixins.color[props.color || 'primary']}
    `;
  }};

  &:focus {
    box-shadow: none;
  }

  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
`;

export default Button;
