import { useMemo, useRef } from 'react';
import { useIsMounted } from '@/view/hooks/useIsMounted';
import { v4 } from 'uuid';

export type RequestIdentity = {
  getUniqueId: () => string;
  isValid: (id: string) => boolean;
  createIdentity: (key: string) => { id: string; isValid: () => boolean };
};

/**
 * Can be used to determine request identity in response. Also check if component still alive.
 * <pre>
 * const { getUniqueId, isValid } = useRequestIdentity();
 * useEffect(()=>{
 *   const requestId = getUniqueId();
 *   fetch().then({} => {
 *     if(!isValid(requestId)) return;
 *   })
 * },[])
 * </pre>
 * For different requests you can use createIdentity:
 * <pre>
 * const { createIdentity } = useRequestIdentity();
 * useEffect(()=>{
 *   const { isValid } = createIdentity('someKey');
 *   fetch().then({} => {
 *     if(!isValid()) return;
 *   })
 * },[])
 * </pre>
 */
export const useRequestIdentity = (): RequestIdentity => {
  const isMounted = useIsMounted();
  const ref = useRef<string>(v4());
  const identities = useRef<Record<string, string>>({});

  return useMemo(
    () => ({
      /**
       * @deprecated please use createIdentity
       */
      getUniqueId: (): string => {
        const id = v4();
        ref.current = id;
        return id;
      },
      /**
       * @deprecated please use createIdentity
       */
      isValid: (id: string) => isMounted.current && ref.current === id,
      /**
       * Allow to use multiple identities by key.
       * It works like singleton.
       */
      createIdentity: (key: string) => {
        const _id = v4();
        identities.current[key] = _id;
        return {
          get id() {
            return _id;
          },
          isValid: () => isMounted.current && identities.current[key] === _id
        };
      }
    }),
    []
  );
};
