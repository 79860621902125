import type { ProductTypeFormInitialProps } from '@components/forms/settings/ProductTypeForm/types';
import { ProductTypeImageResource } from '@/interfaces/brokrete';
import { isEmpty } from '@/libs';

const prepareFormProps = async (incomingProps: Partial<ProductTypeFormInitialProps>): Promise<ProductTypeFormInitialProps> => {
  const { group, descriptionResources, ...restProps } = incomingProps;

  let files = undefined;
  if (descriptionResources) {
    files = await Promise.all(
      descriptionResources.map(async (resource: ProductTypeImageResource, index) => {
        const blob = await fetch(resource.url).then(r => r.blob());
        const urlObject = URL.createObjectURL(blob);
        const file = new File([urlObject], `image_${incomingProps.name}_${index}`);

        return {
          file,
          url: urlObject
        };
      })
    );
  }

  // @ts-ignore
  return {
    ...restProps,
    ...(group && { group: { value: group, label: group } }),
    ...(!isEmpty(descriptionResources) && {
      descriptionResources: files
    })
  } as ProductTypeFormInitialProps;
};

export default prepareFormProps;
