import gql from 'graphql-tag';

export const contractor = gql`
  query contractor($id: ID) {
    contractor(id: $id) {
      id
      displayName
      exportCode
      customField1
      customField2
      lockedPartner {
        id
      }
      testMode
      account {
        credit
        balance
      }
      accounts {
        credit
        balance
        id
        overdueDays
        veryOverdueDays
        unlimited
        autoCollect
        autoCollectGroup
        partner {
          id
          name
          logo
        }
      }
      paymentsInfo {
        savedCardsNew(provider: "stripe") {
          id
          provider
          card {
            uid
            brand
            country
            expMonth
            expYear
            last4
            cardholderName
          }
        }
      }
      info {
        additional
        avatar
        country {
          code
          id
          name
          currency
        }
        name
        type
      }
      companyInfo {
        address
        billingAddress
        billingAddressDetailed {
          city
          streetAddress
          state
          zip
        }
        logo
        name
      }
      owner {
        id
        role
        info {
          name
        }
        identities {
          id
          provider
          uid
        }
      }
      members {
        id
        role
        info {
          avatar
          name
        }
      }
      account {
        credit
      }
      productTypePrices {
        count
        values {
          id
          price {
            id
            currency
            priority
            value
          }
          productType {
            id
            name
            category {
              id
              name
              industry
            }
          }
          city {
            id
            name
          }
          supplier {
            id
            name
          }
          plant {
            id
            name
            productCategories {
              id
              name
            }
          }
        }
      }
    }
  }
`;
