import { getIn } from 'formik';
import React, { ChangeEvent, useState, useMemo, useEffect } from 'react';
import { CustomInput, FormGroup, FormText, Label } from 'reactstrap';
import { get } from 'lodash';

const imageUrlRegexp = new RegExp(/(http(s?):\/\/).*\.(?:jpg|gif|png|image)/);
// const pdfUrlRegexp = new RegExp(/(http(s?):\/\/).*\.(?:pdf)/);

type InputProps = {
  [key: string]: any;
  id?: string;
  label?: string;
  className?: string;
  formGroupClassNames?: string;
  formText?: string;
};

const FormInputFile: React.FC<InputProps> = (props): JSX.Element => {
  const {
    field,
    label,
    form: { errors: formErrors, touched: formTouched, setFieldValue, values },
    formText,
    formGroupClassNames,
    ...restProps
  } = props;
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  const { name } = field;
  const { id } = restProps;

  const error = getIn(formErrors, name);
  const touched = getIn(formTouched, name);

  const invalid: boolean = !!(error && touched);

  const filePath: string = useMemo(() => get(values, name), [values, name]);

  const inputLabel = useMemo(() => (filePath ? filePath.split('/').pop() : ''), [filePath]);

  useEffect(() => {
    !!filePath && imageUrlRegexp.test(filePath) ? setImageSrc(filePath) : setImageSrc(undefined);
  }, [filePath]);

  return (
    <FormGroup className={formGroupClassNames}>
      {label && <Label for={id}>{label}</Label>}
      <div className="d-flex flex-row justify-content-start align-items-center">
        {imageSrc && (
          <img
            className="mr-2"
            src={imageSrc}
            alt={name}
            style={{
              maxWidth: '65px',
              maxHeight: '65px'
            }}
          />
        )}

        <CustomInput
          invalid={invalid}
          {...field}
          {...restProps}
          name={`${name}.input`}
          value={''}
          label={inputLabel}
          onChange={(event: ChangeEvent<any>) => {
            const file: File = event.currentTarget.files[0];
            setFieldValue(`files.${name}`, file, false);
            if (file.type.includes('image')) {
              setImageSrc(URL.createObjectURL(file));
            }
          }}
          type="file"
        />
        {/* {invalid && <FormFeedback>{String(errors[name])}</FormFeedback>} */}
        {/* //TODO return when fixed validators */}
      </div>
      {formText && <FormText color="muted">{formText}</FormText>}
    </FormGroup>
  );
};

export default FormInputFile;
