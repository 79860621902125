import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ProductType, ProductTypeModificationGroup, ProductTypeOptionValue } from '@/interfaces/brokrete';

import OptionCombination from './OptionCombination';

type ProductModificationSetProps = {
  productType: Pick<ProductType, 'id' | 'modifications' | 'optionCombinations'>;
  onClone: (optionCombination: ProductTypeOptionValue) => void;
  onChange: (optionCombination: ({ [key: string]: any } & ProductTypeOptionValue) | null, index: number) => void;
};

const Root = styled.div``;

const ProductTypeOptionCombinations: React.FC<ProductModificationSetProps> = ({ productType, ...restProps }) => {
  const { id, optionCombinations = [], modifications } = productType;

  const groupedModifications = useMemo(() => {
    return modifications.reduce<{ [key: string]: ProductTypeModificationGroup }>((memo, modification) => {
      if (memo[modification.label] != null) {
        memo[modification.label].values.push(modification);
      } else {
        memo[modification.label] = {
          label: modification.label,
          type: modification.type,
          values: [modification]
        };
      }

      return memo;
    }, {});
  }, [modifications]);

  const onChange = (optionCombination: ProductTypeOptionValue | null, index: number) => {
    restProps.onChange(optionCombination, index);
  };

  const onClone = (optionCombination: ProductTypeOptionValue) => {
    restProps.onClone(optionCombination);
  };

  return (
    <Root>
      {(optionCombinations || []).map((optionCombination, idx) => {
        if ((optionCombination as { remove?: boolean } & ProductTypeOptionValue).remove) {
          return null;
        }

        return (
          <OptionCombination
            key={`key_${id}-${optionCombination.id}`}
            productId={id}
            optionCombination={optionCombination}
            groupedModifications={groupedModifications}
            onChange={(optionCombination: ProductTypeOptionValue | null) => onChange(optionCombination, idx)}
            onClone={() => onClone(optionCombination)}
          />
        );
      })}
    </Root>
  );
};

export default ProductTypeOptionCombinations;
