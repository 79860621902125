import { first, orderBy } from 'lodash';

import type { ApolloType } from './gql/apollo';
import { date, isEmpty } from '@/libs';

import { Order } from '@/interfaces/brokrete';
import gql from 'graphql-tag';

export default class BackgroundOrdersJobService {
  constructor(apollo: ApolloType) {
    this.apollo = apollo;
  }
  static readonly STATUSES = {
    STARTED: 'started',
    STOPPED: 'stopped'
  };

  public static readonly DELAY = 10 * 1000;
  // public static readonly DELAY = 15000;

  public static readonly EVENTS = { UPDATED: 'updated' };

  private _interval: NodeJS.Timeout | null = null;
  private _variables: any;
  private _status = BackgroundOrdersJobService.STATUSES.STOPPED;
  private updatedFrom: date.Date | null = null;
  private apollo: ApolloType;

  public watchFor(variables: any) {
    this.clearTimer();

    this._variables = variables;
    this._status = BackgroundOrdersJobService.STATUSES.STARTED;

    this.fetchOrders();
    this._interval = setInterval(() => {
      if (this._status !== BackgroundOrdersJobService.STATUSES.STOPPED && BackgroundOrdersJobService.isVisible()) {
        this.fetchOrders();
      }
    }, BackgroundOrdersJobService.DELAY);
  }

  public stopWatch = () => {
    this._status = BackgroundOrdersJobService.STATUSES.STOPPED;
    this.clearTimer();
  };

  public async fetchOrders() {
    try {
      const variables = { ...this._variables };
      if (this.updatedFrom) variables.updatedAtFrom = this.updatedFrom.toString();

      const { data } = await this.apollo.client.query({
        query: getOrdersQuery,
        variables,
        fetchPolicy: 'no-cache'
      });

      if (this.updatedFrom && data.orders.count > 0) {
        const event = new CustomEvent(BackgroundOrdersJobService.EVENTS.UPDATED, {
          detail: data.orders.values
        });

        document.dispatchEvent(event);
      }

      if (!isEmpty(data.orders.values)) {
        const order = first(
          orderBy(
            data.orders.values,
            (order: Order) => {
              return date.fromString(order.updatedAt);
            },
            ['desc']
          )
        );

        const updatedAt = date.fromString(order.updatedAt);
        if (!this.updatedFrom || this.updatedFrom.isBefore(updatedAt)) {
          this.updatedFrom = updatedAt;
        }
      }
    } catch (e) {
      // showToastify(e.message, 'error');
    }
  }

  public get status() {
    return this._status;
  }

  private clearTimer() {
    if (this._interval) {
      clearInterval(this._interval);
    }
  }

  private static isVisible() {
    //@ts-ignore
    const hidden = document['hidden'] ?? document['webkitHidden'] ?? document['mozHidden'] ?? document['msHidden'];
    return !hidden;
  }
}

export const EVENTS = BackgroundOrdersJobService.EVENTS;
export const STATUSES = BackgroundOrdersJobService.STATUSES;

const getOrdersQuery = gql`
  query orders(
    $status: [String!]
    $supplier: [IdInput!]
    $contractor: IdInput
    $plant: [IdInput!]
    $search: String
    $from: String
    $to: String
    $product: [IdInput!]
    $withTest: Boolean
    $updatedAtFrom: String
  ) {
    orders(
      status: $status
      search: $search
      supplier: $supplier
      contractor: $contractor
      plant: $plant
      from: $from
      to: $to
      sortOrder: asc
      product: $product
      withTest: $withTest
      updatedAtFrom: $updatedAtFrom
    ) {
      count
      values {
        id
        updatedAt
      }
    }
  }
`;
