import React from 'react';
import styled from 'styled-components';
import { startCase } from 'lodash';
import { FieldConfig, useField, useFormikContext } from 'formik';

import FormattedInput from '@components/elements/FormattedInput';
import FormGroup from './FormGroup';
import Label from './Label';
import InputError from './InputError';

import { colors } from '@/defaultStyles';

interface Props extends FieldConfig {
  className?: string;
  label?: string;
  hint?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const Addon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-left: 10px;
  color: ${colors.BATTLESHIP_GREY};
`;

const FormikNumberInputField: React.FC<Props> = ({ className, label, disabled = false, placeholder, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const error = meta.touched && !!meta.error;

  const onChange = (value: number) => {
    setFieldValue(field.name, value);
  };

  return (
    <FormGroup className={className}>
      {label !== '' && <Label>{label || startCase(props.name)}</Label>}
      <FormattedInput {...field} placeholder={placeholder} isNumericString prefix="" disabled={disabled} onChange={onChange} />
      <InputError visible={error}>{meta.error}</InputError>
    </FormGroup>
  );
};

export default FormikNumberInputField;
