import { ApolloClient, DefaultOptions, from, InMemoryCache } from '@apollo/client';
import { IConfiguration } from '@/typings';
import { sentryLink } from '@/errorMonitoring';
import BackgroundOrdersJobServiceClass from '../BackgroundOrdersJobService';
import { initAuthLink, initErrorLink, initClearTypeNameLink, initLangLink } from './links';
import { createUploadLink } from 'apollo-upload-client';

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

export const Apollo: { client: ApolloClient<any> } = {
  // @ts-ignore
  client: null
};

export type ApolloType = typeof Apollo;

export const BackgroundOrdersJobService = new BackgroundOrdersJobServiceClass(Apollo);

const apollo = async (configuration: IConfiguration) => {
  const cache = new InMemoryCache({ addTypename: true });

  const uploadLink = createUploadLink({ uri: `${configuration.apiUrl}/gql` });
  const errorLink = initErrorLink(BackgroundOrdersJobService.stopWatch);
  const authLink = initAuthLink();
  const langLink = initLangLink();
  const clearTypeNameLink = initClearTypeNameLink();

  const client = new ApolloClient({
    //@ts-ignore not really clever TS does not understand that undefined values will be filtered..
    link: from([sentryLink, clearTypeNameLink, authLink, langLink, errorLink, uploadLink].filter(Boolean)),
    cache: cache,
    defaultOptions: defaultOptions
  });

  Apollo.client = client;

  return client;
};

export default apollo;
