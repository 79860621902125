import { LocalStorage } from '@/core/Storage';
import type { DevToolsType } from './types';

export const DEV_TOOLS_KEY = 'DEV_TOOLS_KEY';

export function setValue(key: keyof DevToolsType | 'debugger', isEnabled: boolean) {
  const prev = LocalStorage.getItem<DevToolsType>(DEV_TOOLS_KEY);
  return LocalStorage.setItem(DEV_TOOLS_KEY, prev ? { ...prev, [key]: isEnabled } : { [key]: isEnabled });
}

export function getDevTools() {
  const data = LocalStorage.getItem<DevToolsType & { debugger?: boolean }>(DEV_TOOLS_KEY);
  if (data?.debugger) {
    return data;
  }
  return {} as DevToolsType;
}

export function subscribeToDevTools(cb: (devtools: DevToolsType | null) => void) {
  return LocalStorage.subscribe<DevToolsType>(DEV_TOOLS_KEY, cb);
}

class Debugger {
  enable() {
    setValue('debugger', true);
  }
  disable() {
    setValue('debugger', false);
  }
  activateTool(tool: keyof DevToolsType) {
    setValue(tool, true);
  }
  deActivateTool(tool: keyof DevToolsType) {
    setValue(tool, false);
  }
}

window.DevToolsDebugger = new Debugger();
