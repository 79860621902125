import gql from 'graphql-tag';

export const skids = gql`
  query skids($input: OrderPreparatoryConfigInput) {
    orderPreparatoryConfig(input: $input) {
      skids {
        quantity
        skid {
          id
          name
        }
      }
    }
  }
`;
