import React, { useReducer } from 'react';
import styled from 'styled-components';
import { FileItem } from '@/shared/components/dataDisplay/FileVIewer/items/FileItem';
import { ControlsConfig, FileViewerContext, reducer } from '@/shared/components/dataDisplay/FileVIewer/FileViewerContext';
import { FilePreview } from '@/shared/components/dataDisplay/FileVIewer/FilePreview';
import { SimpleFileItem } from '@/shared/components/dataDisplay/FileVIewer/items/SimpleFIleItem';
import { RestFileItem } from '@/shared/components/dataDisplay/FileVIewer/items/RestFIleItem';
import { LoadingFileItem } from '@/shared/components/dataDisplay/FileVIewer/items/LoadingFIleItem';

const Container = styled.div<{ width: number }>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, ${props => props.width}px);
  gap: calc(${props => props.width} / 90 * 10px);
`;

export interface CompositionFileViewer<P> extends React.FC<P> {
  Item: typeof FileItem;
  SimpleItem: typeof SimpleFileItem;
  RestItem: typeof RestFileItem;
  LoadingItem: typeof LoadingFileItem;
}

interface FileViewerProps {
  height: number;
  controlsConfig?: ControlsConfig;
}

export const FileViewer: CompositionFileViewer<FileViewerProps> = ({ height, controlsConfig, children }) => {
  const value = useReducer(reducer, {
    height,
    controlsConfig: undefined,
    items: []
  });

  return (
    <FileViewerContext.Provider value={value}>
      <Container width={height}>{children}</Container>
      <FilePreview controlsConfig={controlsConfig} />
    </FileViewerContext.Provider>
  );
};

FileViewer.Item = FileItem;
FileViewer.SimpleItem = SimpleFileItem;
FileViewer.RestItem = RestFileItem;
FileViewer.LoadingItem = LoadingFileItem;
