import styled, { css } from 'styled-components';
import { colors } from '@/defaultStyles';

export const Header = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 2fr 2fr 1fr;
  background-color: ${colors.LIGHT};
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;

  > * {
    padding: 10px 10px 10px 17px;
  }
`;

const ContainerStyles = css`
  > * {
    padding: 5px;
    margin-top: auto;
    margin-bottom: auto;

    [class*='-singleValue'],
    input {
      font-size: 18px;
    }
  }
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 1fr;
`;

const RelatedProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 2fr 2fr 1fr;
`;

export const ProductsHeader = styled(ProductsGrid)`
  background-color: ${colors.LIGHT};
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;

  > * {
    padding: 10px 10px 10px 17px;
  }
`;

export const ProductsContainer = styled(ProductsGrid)`
  ${ContainerStyles};

  > *:first-child {
    min-width: 80px;
  }

  ${({ styles }: { styles: string }) => styles || ''};
`;

export const BoldContent = styled.div`
  [class*='-singleValue'],
  input {
    font-weight: bold;
  }
`;

export const RelatedProductsContainer = styled(RelatedProductsGrid)`
  ${ContainerStyles};
  ${({ styles }: { styles: string }) => styles || ''};
`;

export const OptionsHeader = styled(Header)`
  grid-template-columns: 3fr 2fr 2fr 2fr 2fr 1fr;
`;

export const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 2fr 2fr 1fr;
  ${ContainerStyles};
`;

export const Section = styled.div`
  padding-bottom: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.LIGHT};
`;
