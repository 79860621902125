import gql from 'graphql-tag';
import { city } from '@/core/gql/fragments';

export const cities = gql`
  query cities($limit: Int, $skip: Int, $partner: IdInput) {
    cities(limit: $limit, skip: $skip, partner: $partner) {
      ...city
    }
  }
  ${city}
`;

export const citiesMinimal = gql`
  query cities($limit: Int, $skip: Int, $partner: IdInput) {
    cities(limit: $limit, skip: $skip, partner: $partner) {
      id
      name
      country {
        name
      }
      countryState {
        code
      }
    }
  }
`;
