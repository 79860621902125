import { FC } from 'react';
import { sumBy } from 'lodash';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useGettext } from '@cranium/i18n';
import { isEmpty } from '@/libs';

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  width: 280px;
  min-height: 40px;
  max-height: 234px;
  overflow: hidden;
`;

const Header = styled.div`
  margin-bottom: 10px;
`;

const DeliveryItemsList = styled.div`
  background-color: var(--gray-1);
  border-radius: 4px;
  padding: 6px 12px;
  overflow-y: scroll;

  .delivery-item:not(:last-child) {
    margin-bottom: 4px;
  }

  .ant-typography {
    display: block;
  }
`;

type DeliveryItem = {
  productType: {
    name: string;
    group?: string | null;
  };
  quantity: number;
  units?: string | null;
};
type DeliveryItemsDetailsTooltip = {
  items?: DeliveryItem[];
  completed?: boolean;
};
export const DeliveryItemsDetailsTooltip: FC<DeliveryItemsDetailsTooltip> = ({ items, completed = true }) => {
  const { gettext } = useGettext();

  if (!items) return null;

  const units = items[0]?.units;

  return (
    <Root>
      <>
        <Header>
          <Typography.Text className="bold-14 gray-10">{gettext('Quantity: ')}</Typography.Text>
          <Typography.Text className="regular-14 gray-10">
            {completed ? '-' : ''}
            {gettext('%(count) %(units)', {
              count: sumBy(items, 'quantity'),
              units: units || gettext('items')
            })}
          </Typography.Text>
        </Header>

        <DeliveryItemsList>
          {items.map(({ productType, quantity, units }, index) => {
            const separator = ' • ';
            const description = separator + [productType.group, `${quantity} ${units}`].filter(v => !isEmpty(v)).join(separator);

            return (
              <div className="delivery-item" key={index}>
                <Typography.Text className="bold-12 gray-9">{productType.name.toUpperCase()}</Typography.Text>
                <Typography.Text className="regular-12 gray-9">{description}</Typography.Text>
              </div>
            );
          })}
        </DeliveryItemsList>
      </>
    </Root>
  );
};
