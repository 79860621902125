// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContractorOption-module__contractorOption--A_Cgj{display:flex;flex-direction:row;justify-content:flex-start;align-items:center;padding:.25rem .6rem;font-size:.85rem;cursor:pointer}.ContractorOption-module__contractorOption--A_Cgj :first-child{flex-grow:1;margin:0 .25rem;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start}.ContractorOption-module__contractorOption--A_Cgj:hover{background:rgba(197,197,197,.125)}.ContractorOption-module__contractorOption--A_Cgj .ContractorOption-module__row--_zbTS{display:flex;flex-direction:row;justify-content:flex-start;align-items:center}.ContractorOption-module__contractorOption--A_Cgj .ContractorOption-module__row--_zbTS :first-child{flex-grow:1;margin:0 .25rem}", "",{"version":3,"sources":["webpack://./src/view/components/forms/settings/ContractorCreateForm/components/ContractorOption.module.scss"],"names":[],"mappings":"AAAA,kDACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBAAA,CACA,oBAAA,CAEA,gBAAA,CAEA,cAAA,CAEA,+DACE,WAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,sBAAA,CAGF,wDACE,iCAAA,CAGF,uFACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBAAA,CAEA,oGACE,WAAA,CACA,eAAA","sourcesContent":[".contractorOption {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 0.25rem 0.6rem;\n\n  font-size: 0.85rem;\n\n  cursor: pointer;\n\n  :first-child {\n    flex-grow: 1;\n    margin: 0 0.25rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n  }\n\n  &:hover {\n    background: rgba(197, 197, 197, 0.125);\n  }\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n\n    :first-child {\n      flex-grow: 1;\n      margin: 0 0.25rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contractorOption": "ContractorOption-module__contractorOption--A_Cgj",
	"row": "ContractorOption-module__row--_zbTS"
};
export default ___CSS_LOADER_EXPORT___;
