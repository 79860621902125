import gql from 'graphql-tag';

export const totalPrices = gql`
  query productTypes($contractor: IdInput, $input: OrderPreparatoryConfigInput, $order: IdInput, $currency: String) {
    orderPreparatoryConfig(contractor: $contractor, input: $input, order: $order) {
      totalPrice(currency: $currency) {
        value
        components {
          fee {
            id
            name
          }
          option {
            id
            name
          }
          productDecorate {
            id
            name
          }
          productType {
            id
            name
          }
          value
        }
      }
    }
  }
`;
