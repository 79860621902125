import React, { useState, useEffect, useCallback, useRef } from 'react';
import ColourPickerButton from './ColourPickerButton';
import styled from 'styled-components';
import ICONS from '@/assets/images/icons';

type InputProps = {
  [key: string]: any;
  customCss?: {
    buttonCss?: string;
    colorPickerCss?: string;
    hueBarCss?: string;
  };
  setNewColor?: (color: string) => void;
  defaultColours?: boolean;
};

//TODO remove it and use ColorPicker from shared/components/dataEntry/ColorPicker instead @e.batrak
const ColourPicker: React.FunctionComponent<InputProps> = props => {
  const {
    field: { value, name } = {
      value: '#495057',
      name: ''
    },
    form: { setFieldValue } = {
      setFieldValue: () => {}
    },
    customCss,
    setNewColor,
    defaultColours
  } = props;

  //TODO these values should be taken from props.field.value
  const [hue, setHue] = useState<number>(180);
  const [hueX, setHueX] = useState<number>(105);
  const [squareXY, setSquareXY] = useState<number[]>([100, 50]);
  const [colour, setColour] = useState<string | undefined>(value);
  const [isPickerVisible, setPickerVisible] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  const onClick = useCallback(
    (e: Event) => {
      if (isPickerVisible) {
        if (!ref.current || !ref.current.contains(e.target as Node)) {
          setPickerVisible(!isPickerVisible);
          if (setNewColor && colour) setNewColor(colour ? colour : '');
        }
      }
    },
    [setPickerVisible, isPickerVisible, colour]
  );

  useEffect(() => {
    const hasColorChanged = value !== colour;
    if (!isPickerVisible && hasColorChanged) {
      setFieldValue(name, colour, false);
      if (setNewColor && colour) setNewColor(colour ? colour : '');
    }
  }, [setFieldValue, isPickerVisible, value, name, colour]);

  useEffect(() => {
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [setPickerVisible, isPickerVisible, onClick]);

  return (
    <Picker ref={ref}>
      <CircleButton backgroundColor={colour} css={customCss?.buttonCss} onClick={() => setPickerVisible(visible => !visible)}>
        {colour ? null : <img src={ICONS.icColor} alt="Colour Picker" />}
      </CircleButton>
      {isPickerVisible ? (
        <ColourPickerButton
          hue={hue}
          hueX={hueX}
          squareXY={squareXY}
          colour={colour}
          setHue={setHue}
          setHueX={setHueX}
          setSquareXY={setSquareXY}
          setColour={setColour}
          css={customCss}
          defaultColours={defaultColours}
        />
      ) : null}
    </Picker>
  );
};

type CircleButtonProps = {
  backgroundColor?: string;
  css?: string;
};

const CircleButton = styled.div.attrs(({ backgroundColor }: CircleButtonProps) => ({
  style: {
    backgroundColor: backgroundColor ? backgroundColor : 'transparent'
  }
}))<CircleButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px #f6f6f6 solid;
  width: 40px;
  height: 40px;
  margin-top: 22px;
  margin-left: 8px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${({ css }: CircleButtonProps) => css && css}

  & img {
    height: 100%;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Picker = styled.div`
  position: relative;
`;

export default ColourPicker;
