import { useUIContext } from '@components/context/ApplicationContext';
import { useGettext } from '@cranium/i18n';
import useBackgroundFetcher from '@pages/RequestsPage/useBackgroundFetcher';
import React, { useEffect } from 'react';
import { NavLinkItem } from '@components/layout/Sidebar/components/NavLinkItem';
import { useLocation } from 'react-router-dom';
import { useMePartnerSelector } from '@/core/gql/helpers/hooks';
import useUnreadMessagesCountFetcher from '@pages/Messages/hooks/useUnreadMessagesCountFetcher';

export const RequestNavItem = () => {
  const uiContext = useUIContext();
  const { gettext } = useGettext();
  const [{ all }] = useBackgroundFetcher();

  useEffect(() => {
    uiContext.sidebar.setProps({ requestsCount: all });
  }, [all]);

  return (
    <NavLinkItem
      iconClass="icon-request"
      label={gettext('Request')}
      path="/requests"
      count={uiContext.sidebar.props.requestsCount}
      userTipName="sidebar.requests"
    />
  );
};

export const MessagesNavItem = () => {
  const { pathname } = useLocation();
  const { gettext } = useGettext();
  const partner = useMePartnerSelector();
  const { sidebar } = useUIContext();
  const [{ count: unreadMessagesCount }, { stop, start }] = useUnreadMessagesCountFetcher({
    vars: { partners: [{ id: partner!.id }] }
  });

  useEffect(() => {
    if (pathname.includes('messages')) stop();
    else start();
  }, [pathname]);

  useEffect(() => {
    sidebar.setProps({ unreadMessagesCount });
  }, [unreadMessagesCount]);

  return (
    <NavLinkItem
      iconClass="icon-message-1"
      label={gettext('Messages')}
      path="/messages"
      count={sidebar.props.unreadMessagesCount}
      userTipName="sidebar.messages"
    />
  );
};

export const PayoutRequestNavItem = () => {
  const { pathname } = useLocation();
  const { gettext } = useGettext();
  const { sidebar } = useUIContext();
  const renderCount = !pathname.includes('finance') ? sidebar.props.payoutRequestCount : undefined;

  return (
    <NavLinkItem
      iconClass="icon-finance"
      label={gettext('Finance')}
      path="/finance"
      count={renderCount}
      userTipName="sidebar.finance"
    />
  );
};
