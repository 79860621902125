import React from 'react';
import styled from 'styled-components';
import { colors } from '@/defaultStyles';
import ICONS from '@/assets/images/icons';

type ImageUploadProps = {
  source?: File | string;
  onChange: (file: File) => void;
};

const Root = styled.label<{ isFilled: boolean }>`
  margin: 0 10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${colors.LIGHT};
  color: ${colors.BATTLESHIP_GREY};

  opacity: ${props => (props.isFilled ? 0.5 : 1)};

  & img {
    filter: invert(51%) sepia(2%) saturate(1994%) hue-rotate(191deg) brightness(88%) contrast(90%);
  }

  & input[type='file'] {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const PreviewImageContainer = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
`;

const Image = styled.img`
  object-fit: fill;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 10px;
`;

const ImageUpload: React.FC<ImageUploadProps> = ({ source, onChange }) => {
  if (source) {
    return (
      <PreviewImageContainer>
        <Image src={typeof source === 'string' ? source : URL.createObjectURL(source)} alt="profile-logo" />
      </PreviewImageContainer>
    );
  }

  return (
    <Root isFilled={false} htmlFor="image-upload">
      <input
        id="image-upload"
        name="image-upload"
        type="file"
        accept="image/png, image/jpeg"
        onChange={(event: React.ChangeEvent<any>) => {
          const file: File = event.currentTarget.files[0];
          onChange(file);
        }}
      />
      <img src={ICONS.icAdd} width="18" height="18" alt="Add new product" />
    </Root>
  );
};

export default ImageUpload;
