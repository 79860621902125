import createReducer from './../../libs/createReducer';
import { ActionTypes } from './actions';

export type SessionState = {
  initialized: boolean;
};

const initialState: SessionState = {
  initialized: false
};

export default createReducer(initialState, {
  [ActionTypes.INITIALIZE]() {
    return {
      ...initialState,
      initialized: true
    };
  },
  [ActionTypes.RESET]() {
    return {
      ...initialState
    };
  }
});
