import React from 'react';
import ReactLoader from 'react-loaders';
import style from './ActivityIndicator.module.scss';
import cx from 'classnames';
import styled from 'styled-components';

type Props = {
  className?: string;
  center?: boolean;
};
const Container = styled.div<{ center?: boolean }>`
  ${props =>
    props.center &&
    `
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
  justify-content: center;
  `}
`;

const ActivityIndicator: React.FC<Props> = ({ className, center }): JSX.Element => {
  return (
    <Container className={cx(style.root, 'text-center')} center={center}>
      <ReactLoader type="ball-pulse" active innerClassName={cx(style.loader, className)} />
    </Container>
  );
};

export default ActivityIndicator;
