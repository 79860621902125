import gql from 'graphql-tag';

export const options = gql`
  query options($limit: Int, $skip: Int, $supplier: IdInput, $category: IdInput, $root: Boolean) {
    options(limit: $limit, skip: $skip, category: $category, supplier: $supplier, root: $root) {
      count
      values {
        id
        name
        sortOrder
        category {
          id
          industry
          name
        }
      }
    }
  }
`;
