import { i18n } from '@/core';
import styled, { css } from 'styled-components';
import { makeFormik } from '@components/forms/FormikForm';
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'reactstrap';
import { SelectedOptionType } from '@/interfaces/common';
import type { OrderProductTypePrice } from '@/view/screens/OrderInvoiceScreen/components/types';
import { Order, OrderPreparatoryConfigProductDecoratePrice } from '@/interfaces/brokrete';
import ProductTypeRow from '@/view/screens/OrderInvoiceScreen/components/ProductTypeRow';
import { array, isEmpty } from '@/libs';
import { fetchTotalPrices } from '@/core/api/orderPreparatoryConfig';
import { prepareConfigPayload } from '@/view/screens/OrderInvoiceScreen/components/utils';
import { buildIdInput, buildOrderPreparatoryConfigInputType } from '@/shared/api/brokrete/helper';
import { toNumber } from '@/libs/string';
import Text from '@components/elements/Text';
import Button from '@components/elements/Button';
import { colors } from '@/defaultStyles';
import { OrderFeePanel } from '../../OrderFeePanel';
import { ChangeOrderTrucksModal } from '@/view/modals/order/ChangeOrderTrucksModal';
import type { OrderProductsFromInitialProps } from '@components/forms/settings/types';

const ProductsHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1.5fr 1.5fr 1.5fr;
  background-color: ${colors.LIGHT};
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;

  > * {
    padding: 10px 10px 10px 17px;
  }
`;

const RelatedProductsHeader = styled(ProductsHeader)`
  grid-template-columns: 3fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const SwalContentContainer = styled.div`
  text-align: left;
`;

const OrderProductsFrom: React.FC<FormProps & DefaultProps> = props => {
  const {
    handleSubmit,
    setFieldValue,
    isSubmitting,
    isValid,
    values,
    dirty,
    availableDecoratePrices,
    availableProductTypePrices
  } = props;

  const feeInstance = OrderFeePanel.useInstance();
  const [feesLoading, setFeesLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>(props.order);
  const [showOrderTrucksModal, setShowOrderTrucksModal] = useState(false);

  const { productTypes } = values;
  const industry = order.product.industry;

  const isServiceIndustry = order.product.industry === 'service' || order.product.industry === 'concrete_pumping';

  const onOrderScheduleChanged = (
    deliveryMethod: string,
    trucks: number[],
    trucksSecondary: number[],
    timeBetweenTrucks: number,
    drivers: Array<string | undefined>
  ) => {
    setOrder({
      ...order,
      trucks,
      trucksSecondary,
      deliveryMethod: {
        id: '',
        caption: '',
        description: '',
        name: deliveryMethod
      },
      timeBetweenTrucks
    });

    setFieldValue('deliveryMethod', deliveryMethod);
    setFieldValue('quantity', array.sum(trucks));
    setFieldValue('trucks', trucks);
    setFieldValue('trucksSecondary', trucksSecondary);
    setFieldValue('timeBetweenTrucks', timeBetweenTrucks);
    setFieldValue(
      'drivers',
      drivers.filter(v => !!v)
    );

    setShowOrderTrucksModal(false);
  };

  const onSubmit = (e: any) => {
    // @ts-ignore
    e.preventDefault();

    if (feeInstance.requireActionConfirmation()) {
      void feeInstance.confirmAction();
    } else {
      setFieldValue('fees', feeInstance.getFee());

      handleSubmit(e);
    }
  };

  const feesPanelInput = useMemo(() => {
    const isServiceIndustry = order.product.industry === 'service' || order.product.industry === 'concrete_pumping';

    const product = {
      ...buildIdInput(order.product),
      prices: productTypes.map((productType: any) => {
        return {
          type: buildIdInput(productType.data.type),
          ...(!isEmpty(productType.data.typePriceValue)
            ? {
                typePriceValue: toNumber(productType.data.typePriceValue)
              }
            : {
                typePrice: buildIdInput(productType.data.typePrice)
              }),
          ...(productType.data.decorate && {
            decorate: buildIdInput(productType.data.decorate),
            ...(!isEmpty(productType.data.decoratePriceValue)
              ? {
                  decoratePriceValue: toNumber(productType.data.decoratePriceValue)
                }
              : {
                  decoratePrice: buildIdInput(productType.data.decoratePrice)
                })
          }),
          ...(order.product.industry === 'related_products' || isServiceIndustry
            ? {
                quantity: toNumber(productType.data.quantity)
              }
            : {
                ...(!isEmpty(productType.quantity) && {
                  quantity: toNumber(productType.quantity)
                })
              })
        };
      })
    };

    const configPayload = prepareConfigPayload(order, {
      id: order.id,
      product: product,
      trucks: order.trucks,
      trucksSecondary: order.trucksSecondary
    });

    return buildOrderPreparatoryConfigInputType(configPayload);
  }, [order, productTypes]);

  const precalculateTotalPrices = useCallback(async () => {
    const data = await fetchTotalPrices({
      order: order,
      contractor: order.contractor,
      input: feesPanelInput
    });

    if (data) {
      const { orderPreparatoryConfig } = data;

      const productTypesUpdated = productTypes.map((productPrice: any) => {
        const typePriceTotal =
          orderPreparatoryConfig.totalPrice &&
          orderPreparatoryConfig.totalPrice.components.find(priceComponent => {
            return priceComponent.productType && priceComponent.productType.id === productPrice.data.type.id;
          });

        const decoratePriceTotal =
          orderPreparatoryConfig.totalPrice &&
          orderPreparatoryConfig.totalPrice.components.find(priceComponent => {
            return priceComponent.productDecorate && priceComponent.productDecorate.id === productPrice.data.decorate?.id;
          });

        return {
          ...productPrice,
          data: {
            ...productPrice.data,
            ...(typePriceTotal && { typePriceTotal: typePriceTotal.value }),
            ...(decoratePriceTotal && { decoratePriceTotal: decoratePriceTotal.value })
          }
        };
      });

      setFieldValue('productTypes', productTypesUpdated);
      setFieldValue('totalPrice', orderPreparatoryConfig.totalPrice?.value);
    }
  }, [order, feesPanelInput, setFieldValue]);

  return (
    <SwalContentContainer>
      <Form>
        {industry !== 'related_products' && !isServiceIndustry ? (
          <ProductsHeader>
            <div />
            <Text transform="uppercase" weight="500" color={colors.BATTLESHIP_GREY} size={12} text={i18n.t('labels.product')} />
            <Text transform="uppercase" weight="500" color={colors.BATTLESHIP_GREY} size={12} text={i18n.t('labels.price')} />
            <Text
              transform="uppercase"
              weight="500"
              color={colors.BATTLESHIP_GREY}
              size={12}
              text={i18n.t('labels.customPrice')}
            />
            <Text
              transform="uppercase"
              weight="500"
              color={colors.BATTLESHIP_GREY}
              size={12}
              text={i18n.t('labels.totalPrice')}
            />
          </ProductsHeader>
        ) : (
          <RelatedProductsHeader>
            <Text transform="uppercase" weight="500" color={colors.BATTLESHIP_GREY} size={12} text={i18n.t('labels.product')} />
            <Text transform="uppercase" weight="500" color={colors.BATTLESHIP_GREY} size={12} text={i18n.t('labels.price')} />
            <Text
              transform="uppercase"
              weight="500"
              color={colors.BATTLESHIP_GREY}
              size={12}
              text={i18n.t('labels.customPrice')}
            />
            <Text transform="uppercase" weight="500" color={colors.BATTLESHIP_GREY} size={12} text={i18n.t('labels.quantity')} />
            <Text
              transform="uppercase"
              weight="500"
              color={colors.BATTLESHIP_GREY}
              size={12}
              text={i18n.t('labels.totalPrice')}
            />
            <div />
          </RelatedProductsHeader>
        )}

        <FieldArray
          validateOnChange
          name="productTypes"
          render={({ remove, push }: FieldArrayRenderProps) => {
            return productTypes.map((value: SelectedOptionType<OrderProductTypePrice>, index: number) => {
              return (
                <ProductTypeRow
                  key={index}
                  editable
                  prefix={`productTypes.${index}`}
                  index={index}
                  remove={remove}
                  push={push}
                  order={order}
                  gridStyles={
                    industry === 'related_products' || isServiceIndustry
                      ? css`
                          grid-template-columns: 3fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
                        `
                      : css`
                          grid-template-columns: 1fr 3fr 1.5fr 1.5fr 1.5fr;
                        `
                  }
                  useGeneralGridView={false}
                  canChangeProductType
                  onOrderScheduleChange={() => setShowOrderTrucksModal(true)}
                  availableProductTypePrices={availableProductTypePrices}
                  availableDecoratePrices={availableDecoratePrices}
                  currentProduct={value}
                />
              );
            });
          }}
        />

        <OrderFeePanel orderId={order.id} input={feesPanelInput} instance={feeInstance} onLoading={setFeesLoading} />

        <hr />

        {showOrderTrucksModal && (
          <ChangeOrderTrucksModal
            orderId={order.id}
            onChange={order => {
              if (order) {
                const drivers = order.trucksDrivers?.map(d => d.driver?.id);
                onOrderScheduleChanged(
                  order.deliveryMethod?.name,
                  order.trucks,
                  order.trucksSecondary,
                  order.timeBetweenTrucks,
                  drivers
                );
              } else {
                setShowOrderTrucksModal(false);
              }
            }}
          />
        )}

        <ButtonGroup>
          <OrderFeePanel.TotalPrice
            feePanelInstance={feeInstance}
            defaultValue={order.totalPrice}
            onClick={precalculateTotalPrices}
          />

          <Button size="lg" disabled={isSubmitting || !isValid || !dirty || feesLoading} onClick={onSubmit}>
            {i18n.t('labels.update')}
          </Button>
        </ButtonGroup>
      </Form>
    </SwalContentContainer>
  );
};

export default makeFormik<OrderProductsFromInitialProps, DefaultProps>(OrderProductsFrom);

type DefaultProps = {
  order: Order;
  availableProductTypePrices: SelectedOptionType<OrderProductTypePrice>[];
  availableDecoratePrices: SelectedOptionType<OrderPreparatoryConfigProductDecoratePrice>[];
};

type FormProps = DefaultProps & FormikProps<OrderProductsFromInitialProps>;
