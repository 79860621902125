import gql from 'graphql-tag';

export const contractorUpdate = gql`
  mutation contractorUpdate($contractor: IdInput, $productTypePrices: [ProductTypePriceCreateInput!]) {
    contractorUpdate(contractor: $contractor, productTypePrices: $productTypePrices) {
      success
      contractor {
        productTypePrices {
          count
          values {
            id
            price {
              id
              currency
              priority
              value
            }
            productType {
              id
              category {
                id
                name
                industry
              }
            }
          }
        }
      }
    }
  }
`;
