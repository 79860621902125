import { MeType } from '../types';

export function initHubSpot(data?: MeType) {
  //already loaded
  if (document.getElementById('hs-script-loader') && window.HubSpotConversations) {
    window.HubSpotConversations.widget.refresh();
    return Promise.resolve();
  }
  return new Promise(resolve => {
    const elem = document.createElement('script');
    elem.setAttribute('type', 'text/javascript');
    elem.id = 'hs-script-loader';
    elem.setAttribute('async', 'true');
    elem.setAttribute('defer', 'true');
    elem.src = `//js-na1.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_API || ''}.js`;

    window.hsConversationsSettings = {
      loadImmediately: false,
      inlineEmbedSelector: '#hubspot',
      enableWidgetCookieBanner: false,
      identificationEmail: data?.me?.partnerMember?.email,
      identificationToken: data?.me?.service?.hubspotToken
    };

    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.load();
        resolve(null);
      }
    ];
    document.body.appendChild(elem);
  });
}
