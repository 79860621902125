import { ComponentType } from 'react';
import { ContractorsSearchOption } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faCheck, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import styles from './ContractorOption.module.scss';
import cx from 'classnames';
import React from 'react';
import { OptionProps } from 'react-select';

const ContractorOption: ComponentType<OptionProps<any, boolean>> = (props): JSX.Element => {
  const { innerProps, data, isSelected } = props;
  const optionData: ContractorsSearchOption = data;
  return (
    <div {...innerProps} className={cx(styles.contractorOption)}>
      <div>
        <div className={cx(styles.row)}>
          <FontAwesomeIcon icon={faUser} />
          <span className="font-weight-bold">{optionData.name}</span>
        </div>
        <div className={cx(styles.row, 'text-muted')}>
          <FontAwesomeIcon icon={faPhone} />
          <span>{optionData.phone}</span>
        </div>
        <div className={cx(styles.row, 'text-muted')}>
          <FontAwesomeIcon icon={faEnvelope} />
          <span>{optionData.email}</span>
        </div>
      </div>
      {isSelected && <FontAwesomeIcon icon={faCheck} />}
    </div>
  );
};

export default ContractorOption;
