import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SVGCheckGreen } from '@components/elements/icons';
import { colors } from '@/defaultStyles';

type CheckboxGreenProps = {
  className?: string;
  css?: string;
  checked?: boolean;
  onClick?: (checked: boolean) => void;
};

type RootProps = {
  checked?: boolean;
  css?: string;
};

const CheckStyled = styled(SVGCheckGreen)`
  width: 10px;
  path {
    fill: ${colors.WHITE} !important;
  }
`;

const Root = styled.div<RootProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border-radius: 2px;
  border: 2px solid ${({ checked }: RootProps) => (checked ? colors.ALGAE_GREEN : colors.CLOUDY_BLUE)};
  background-color: ${({ checked }: RootProps) => (checked ? colors.ALGAE_GREEN : colors.WHITE)};
  box-sizing: border-box;
  ${CheckStyled} {
    display: ${({ checked }: RootProps) => (checked ? 'block' : 'none')};
  }
  ${({ css }: RootProps) => css && css};

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

const CheckboxGreen: React.FunctionComponent<CheckboxGreenProps> = ({ className, css, checked = false, onClick = () => {} }) => {
  const [checkedState, setChecked] = useState<boolean>(checked);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <Root className={className} css={css} checked={checked} onClick={() => onClick(!checkedState)}>
      <CheckStyled />
    </Root>
  );
};

export default CheckboxGreen;
