import type {
  Fee,
  FeePrice,
  Option,
  OptionPrice,
  Order,
  OrderStatus,
  Price,
  PriceContent,
  Product,
  ProductPrice,
  Service,
  TimeBetweenTrucks,
  TrucksConfig
} from '@/interfaces/brokrete';

import { Platform } from '@/interfaces/types';

import * as array from './array';
import * as date from './date';
import isEmpty from './isEmpty';
import * as number from './number';

import { get } from 'lodash';

type CalculateParams = {
  product: Product;
  productPrices?: Array<ProductPrice>;
  options?: Array<OptionPrice>;
  trucks: Array<number>;
  trucksConfig: TrucksConfig;
  fees?: Array<FeePrice>;
  taxes?: Array<FeePrice>;
};

type CalculateFeesParams = {
  product: Product;
  productPrices?: Array<ProductPrice>;
  decorated?: boolean;
  trucks?: Array<number>;
  trucksConfig: TrucksConfig;
  fees: Array<FeePrice>;
};

type CalculateTaxesParams = {
  fees: Array<FeePrice>;
};

export const defaultServiceConfiguration = {
  primary_component: {
    name: '',
    visible: true
  },
  secondary_component: {
    name: '',
    visible: true,
    enabled: true
  }
};

class OrderHelper {
  public nowTime(order: Order) {
    return date.now().tz(order.timezone, false);
  }

  public deliveryTime(order: Order) {
    return date.fromString(order.deliveryTime, order.timezone);
  }

  public pouringTime(order: Order) {
    return date.fromString(order.pouringTime, order.timezone);
  }

  public lockToUpdateTime(order: Order) {
    return date.fromString(order.lockToUpdateTime, order.timezone);
  }

  public lockToCancelTime(order: Order) {
    return date.fromString(order.lockToCancelTime, order.timezone);
  }

  public platformIcon(order: Order) {
    const sourceInfo = order.sourceInfo;

    if (!sourceInfo) {
      return '';
    }

    switch (sourceInfo.platform) {
      case Platform.androidStorefront:
      case Platform.iosStorefront:
        return 'phone-2';
      case Platform.webSite:
        return 'planet';
      case Platform.webWidget:
        return 'desktop';
      case Platform.webStorefront:
        return 'store';
      case Platform.webDashboard:
        return 'orders';
      case Platform.admin:
        return 'admin';
      default:
        return '';
    }

    return '';
  }

  public status(order: Order): OrderStatus | null {
    if (order) {
      if (order.status === 'live') {
        if (this.lockToCancelTime(order).isSameOrBefore(this.nowTime(order))) {
          return 'delivering';
        }

        if (this.pouringTime(order).isSameOrBefore(this.nowTime(order))) {
          return 'delivering';
        }

        return 'live';
      }

      return order.status;
    }

    return null;
  }

  public canEdit(
    order: Order,
    reason: 'address' | 'deliveryTime' | 'deliveryDetails' | 'product' | 'quantity' | 'options'
  ): boolean {
    // @ts-ignore
    if (['in_progress', 'pouring', 'on_hold'].indexOf(this.status(order)) < 0) return false;

    if (reason === 'address' || reason === 'deliveryTime') {
      return this.lockToCancelTime(order).isAfter(this.nowTime(order));
    }

    return this.lockToUpdateTime(order).isAfter(this.nowTime(order));
  }

  public canHold(order: Order): boolean {
    if (this.status(order) !== 'live') return false;
    if (this.lockToCancelTime(order).isSameOrBefore(this.nowTime(order))) return false;

    return order.holdLimits > 0;
  }

  public canRelease(order: Order): boolean {
    return this.status(order) === 'on_hold';
  }

  public canCancel(order: Order): boolean {
    if (this.status(order) !== 'live') return false;

    return this.lockToCancelTime(order).isAfter(this.nowTime(order));
  }

  public haveToUpdateDeliveryTimeBeforeRelease(order: Order): boolean {
    const pouringTime = date.fromString(order.pouringTime, order.timezone).add({ minutes: -30 });
    const now = date.now().tz(order.timezone, false);

    return pouringTime.isSameOrBefore(now);
  }

  public isActive(order: Order): boolean {
    // @ts-ignore
    return ['new', 'live', 'delivering', 'pouring', 'on_hold'].indexOf(this.status(order)) >= 0;
  }

  public isDelivered(order: Order): boolean {
    // @ts-ignore
    return ['delivered'].indexOf(this.status(order)) >= 0;
  }

  private underloadedTrucks({
    trucks,
    fee,
    trucksConfig
  }: {
    trucks: Array<number>;
    fee: Fee;
    trucksConfig: TrucksConfig;
  }): Array<number> {
    const from = fee.details.from || 0;
    const to = fee.details.to || 0;

    if (fee.type === 'underload' && trucks.length > 1) {
      const quantityMax = trucksConfig.quantity.max;

      if (array.sum(trucks) >= quantityMax) {
        const requiredTrucksCount = Math.ceil(array.sum(trucks) / quantityMax);

        trucks = trucks.sort((a, b) => b - a).slice(requiredTrucksCount);
      }
    }

    return trucks.filter(truck => truck >= from && truck < to);
  }

  // eslint-disable-next-line no-unused-vars
  public calculate({ product, productPrices, options, trucks, fees, taxes, trucksConfig }: CalculateParams): number {
    let total = (productPrices || []).reduce((result, value) => {
      if (value.productTypePrice) {
        result =
          result +
          100 *
            this.calculatePrice({
              price: value.productTypePrice.price,
              // @ts-ignore
              trucks: isEmpty(value.quantity) ? trucks : [value.quantity]
            });
      }

      if (value.productDecoratePrice) {
        result =
          result +
          100 *
            this.calculatePrice({
              price: value.productDecoratePrice.price,
              // @ts-ignore
              trucks: isEmpty(value.quantity) ? trucks : [value.quantity]
            });
      }

      return result;
    }, 0);

    total = (options || [])
      .filter(value => value != null)
      .reduce((result, value) => {
        return result + 100 * this.calculatePrice({ price: value.price, trucks });
      }, total);

    if (fees != null) {
      total = fees.reduce((result, value) => {
        const fee = value.fee;
        if (fee.type !== 'underload') {
          return result + 100 * this.calculatePrice({ price: value.price, trucks });
        } else {
          return (
            result +
            100 *
              this.calculatePrice({
                price: value.price,
                trucks: this.underloadedTrucks({ trucks, fee, trucksConfig })
              })
          );
        }
      }, total);
    }

    if (taxes != null) {
      total =
        total +
        100 *
          this.calculateTaxes({
            taxes,
            orderTotalPrice: total / 100
          });
    }

    return number.round(total / 100);
  }

  public washoutFees({ decorated, productPrices, fees }: CalculateFeesParams): Array<FeePrice> {
    const hasProductDecoratePrice = (productPrices || []).find(value => value.productDecoratePrice != null) != null;

    if (isEmpty(fees) || (!decorated && !hasProductDecoratePrice)) {
      return [];
    }

    return fees.filter(value => value.fee.type === 'washout');
  }

  public underloadFees({ trucks, fees, trucksConfig }: CalculateFeesParams): Array<FeePrice> {
    if (trucks == null || isEmpty(fees)) {
      return [];
    }

    return fees
      .filter(value => value.fee.type === 'underload')
      .filter(fee => {
        return !isEmpty(this.underloadedTrucks({ trucks, fee: fee.fee, trucksConfig }));
      });
  }

  public taxes({ fees }: CalculateTaxesParams): Array<FeePrice> {
    if (isEmpty(fees)) {
      return [];
    }

    return fees.filter(value => value.fee.type === 'tax');
  }

  public prepareTimeSlots(
    day: date.Date,
    trucks: Array<number>,
    timeBetweenTrucks: TimeBetweenTrucks,
    times: Array<date.Date>,
    availabilityTimes: Array<date.Date>
  ): Array<{ time: date.Date; disabled: boolean }> {
    const activeDates = [...availabilityTimes];

    const canPutTruck = (index: number, items: Array<{ disabled: boolean }>): boolean => {
      // @ts-ignore
      return trucks.reduce((result, value, truckIndex) => {
        if (!result) {
          return false;
        }

        const itemIndex = index + (truckIndex * timeBetweenTrucks) / 15;
        if (itemIndex >= items.length) {
          return false;
        }

        return result && !items[itemIndex].disabled;
      }, true);
    };

    return times
      .map(value => {
        const next = activeDates.length > 0 ? activeDates[0] : null;
        if (value.isSame(next)) {
          activeDates.shift();
        }

        return {
          disabled: !value.isSame(next),
          time: value
        };
      })
      .map((value, index, items) => {
        return {
          ...value,
          disabled: value.disabled || !canPutTruck(index, items)
        };
      });
  }

  public calculateTaxes({ taxes, orderTotalPrice }: { taxes: Array<FeePrice>; orderTotalPrice: number }): number {
    return number.round(
      array.sum(
        taxes
          // @ts-ignore
          .map(value => number.round(orderTotalPrice * value.fee.details.multiplier))
      )
    );
  }

  private calculatePrice({ price, trucks }: { price: Price; trucks: Array<number> }): number {
    const quantity = array.sum(trucks);

    if (price == null) {
      return 0;
    }

    if (price.content === 'onetime') {
      return price.value;
    }

    if (price.content === 'load') {
      return number.round(price.value * trucks.length);
    }

    return number.round(price.value * quantity);
  }

  public extractProductPrices(order: Order): Array<ProductPrice> {
    const helper: { [id: string]: ProductPrice } = {};

    return order.prices
      .filter(value => value.productType != null || value.productDecorate != null)
      .sort((a, b) => {
        const keyA = a.productType != null ? 1 : 0;
        const keyB = b.productType != null ? 1 : 0;

        return keyB - keyA;
      })
      .reduce<Array<ProductPrice>>((result, value) => {
        if (value.productType != null) {
          const productPrice: ProductPrice = {
            productTypePrice: {
              productType: value.productType,
              price: value.price
            },
            quantity: isEmpty(value.quantity) ? undefined : value.quantity,
            value: value.value
          };

          helper[value.id] = productPrice;

          return [...result, productPrice];
        } else if (value.productDecorate != null) {
          if (value.anchorPrice != null) {
            const anchoredPrice = helper[value.anchorPrice.id];
            if (anchoredPrice != null) {
              anchoredPrice.productDecoratePrice = {
                productDecorate: value.productDecorate,
                price: value.price
              };
            }
          }
        }

        return result;
      }, []);
  }

  public extractFeesPrices(order: Order): Array<FeePrice> {
    // @ts-ignore
    return order.prices
      .filter(value => value.fee != null)
      .map(value => ({
        fee: value.fee,
        price: value.price,
        totalPrice: value.value
      }));
  }

  public extractOptionsPrices(order: Order): Array<OptionPrice> {
    // @ts-ignore
    return order.prices
      .filter(value => value.option != null)
      .map(value => ({
        option: value.option,
        price: value.price,
        totalPrice: value.value,
        quantity: value.quantity || undefined
      }));
  }

  public extractOptions(order: Order): Array<Option> {
    // @ts-ignore
    return order.prices.filter(value => value.option != null).map(value => value.option);
  }

  public groupOrders = (orders: Order[]) => {
    return orders.reduce((memo: { [key: string]: Order[] }, order: Order) => {
      const dateString = date.fromString(order.deliveryTime, order.timezone).format('dddd, MMMM DD');

      if (!(dateString in memo)) {
        memo[dateString] = [];
      }

      memo[dateString].push(order);

      return memo;
    }, {});
  };

  public calculateTrucks(
    quantity: number,
    trucksConfig: { quantity: { max: number; min: number; step: number } },
    evenlyAlgorithm: boolean
  ) {
    if (!trucksConfig) return [];

    const quantityConfig = trucksConfig.quantity;

    const countTrucks = Math.ceil(quantity / quantityConfig.max);

    const result = array.prepare(countTrucks, evenlyAlgorithm ? quantityConfig.min : quantityConfig.max).map(value => {
      return value;
    });

    let tempQuantity = array.sum(result);

    const round = (value: number) => {
      const factor = number.extractFactor(quantityConfig.step);
      return number.round(value, { math: true, factor: factor });
    };

    if (tempQuantity < quantity) {
      let i = 0;
      while (tempQuantity < quantity) {
        result[i] = round(result[i] + quantityConfig.step);
        tempQuantity = array.sum(result);

        i = (i + 1) % result.length;
      }
    } else if (tempQuantity > quantity) {
      let i = result.length - 1;
      while (tempQuantity > quantity) {
        const truckQuantity = round(result[i] - quantityConfig.step);
        result[i] = Math.max(truckQuantity, quantityConfig.min);
        tempQuantity = array.sum(result);

        if (truckQuantity < quantityConfig.min) {
          --i;

          if (i < 0) {
            break;
          }
        }
      }
    }

    return result;
  }

  public defaultFilterDatesRange = () => {
    return {
      from: date.now().utc().startOfDay().add({ days: -3 }),
      to: date.now().utc().endOfDay().add({ days: 5 })
    };
  };

  public quantity = (order: Pick<Order, 'trucks'>): number => {
    return order.trucks.reduce((acc, v) => number.round(acc + v), 0);
  };

  public quantitySecondary = (order: Pick<Order, 'trucksSecondary'>): number => {
    return order.trucksSecondary.reduce((acc, v) => number.round(acc + v), 0);
  };

  public recalculatePriceValue = ({
    content,
    value,
    quantity,
    trucks
  }: {
    content: PriceContent;
    value: number;
    quantity: number;
    trucks: number[];
  }): number => {
    if (content === 'load') {
      return value / trucks.length;
    }

    return value / quantity;
  };

  public extractProductPrice = (order: Order, productPrice: ProductPrice): Price => {
    const {
      price: { content }
    } = productPrice.productTypePrice;
    const quantity = productPrice.quantity || order.quantity;

    return {
      ...productPrice.productTypePrice.price,
      value: number.round(
        this.recalculatePriceValue({
          content,
          value: productPrice.value,
          quantity,
          trucks: order.trucks
        })
      )
    };
  };

  public decimalsCount(value: number) {
    if (value % 1 != 0) return value.toString().split('.')[1].length;
    return 0;
  }

  public isModulo(num: number, modul: number) {
    if (modul < 1) {
      const _num = parseFloat([0, String(num).split('.')[1]].join('.'));
      return (
        this.roundToDecimal(_num % modul, this.decimalsCount(_num) + 1) !== 0 &&
        this.roundToDecimal(_num % modul, this.decimalsCount(_num) + 1) !== modul
      );
    }

    return num % modul !== 0;
  }
  public roundToDecimal(value: number, precision: number) {
    const power = Math.pow(10, precision);
    return Math.round(value * power + Number.EPSILON * power) / power;
  }
  public calculateQuantity(input: number, quantitiesConfig: { min: number; step: number; factor?: number }): [number, number] {
    const { min, step } = quantitiesConfig;

    if (input < min) {
      return [min, min];
    }

    const factor = input % step;

    const minValue = this.roundToDecimal(input - factor, this.decimalsCount(step));
    const maxValue = this.roundToDecimal(input - factor + step, this.decimalsCount(step));

    // Return [min, max] values
    return [minValue < min ? maxValue : minValue, maxValue];
  }

  public productCategoryServiceConfiguration(order: Order): Service {
    //@ts-ignore
    return get(order, 'product.permissions.service', defaultServiceConfiguration);
  }
}

export default new OrderHelper();
