import { useRef, useEffect, MutableRefObject } from 'react';

/**
 * use useIsMounted().current for detecting if component still mounted
 */
export function useIsMounted(): MutableRefObject<boolean> {
  const componentIsMounted = useRef(true);
  useEffect(
    () => () => {
      componentIsMounted.current = false;
    },
    []
  );
  return componentIsMounted;
}
