// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SupplierFieldOption-module__selectOption--pz1lN{display:flex;flex-direction:row;justify-content:flex-start;align-items:center;padding:.75rem .6rem;font-size:.85rem;cursor:pointer}.SupplierFieldOption-module__selectOption--pz1lN :first-child{flex-grow:1;margin:0 .25rem;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start}.SupplierFieldOption-module__selectOption--pz1lN:hover{background:rgba(197,197,197,.125)}.SupplierFieldOption-module__selectOption--pz1lN .SupplierFieldOption-module__row--OVvRp{display:flex;flex-direction:row;justify-content:flex-start;align-items:center}.SupplierFieldOption-module__selectOption--pz1lN .SupplierFieldOption-module__row--OVvRp :first-child{flex-grow:1;margin:0 .25rem}", "",{"version":3,"sources":["webpack://./src/view/pages/Suppliers/components/forms/SupplierForm/components/SupplierFieldOption.module.scss"],"names":[],"mappings":"AAAA,iDACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBAAA,CACA,oBAAA,CAEA,gBAAA,CAEA,cAAA,CAEA,8DACE,WAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,sBAAA,CAGF,uDACE,iCAAA,CAGF,yFACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBAAA,CAEA,sGACE,WAAA,CACA,eAAA","sourcesContent":[".selectOption {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 0.75rem 0.6rem;\n\n  font-size: 0.85rem;\n\n  cursor: pointer;\n\n  :first-child {\n    flex-grow: 1;\n    margin: 0 0.25rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n  }\n\n  &:hover {\n    background: rgba(197, 197, 197, 0.125);\n  }\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n\n    :first-child {\n      flex-grow: 1;\n      margin: 0 0.25rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectOption": "SupplierFieldOption-module__selectOption--pz1lN",
	"row": "SupplierFieldOption-module__row--OVvRp"
};
export default ___CSS_LOADER_EXPORT___;
