import React from 'react';

import { OptionItemBase } from '@/shared/components/dataEntry/Select';
import { OptionProps } from 'react-select/src/components/Option';

const OptionItem: React.FC<OptionProps<any, boolean>> = props => {
  const { innerRef, data, selectOption, selectProps, isFocused, isDisabled } = props;

  const {
    inputValue,
    formatOptionLabel = data => {
      return data.name || data.label || data.caption;
    }
  } = selectProps;

  const optionLabel = formatOptionLabel
    ? formatOptionLabel(data, {
        context: 'value',
        inputValue: inputValue || '',
        selectValue: data
      })
    : '';

  return (
    <OptionItemBase
      className="brk-option-select"
      ref={innerRef}
      isFocused={isFocused}
      isDisabled={isDisabled}
      onClick={() => {
        selectOption(data);
      }}
    >
      {optionLabel}
    </OptionItemBase>
  );
};

export default OptionItem;
