import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput, FormInputAsyncSelect } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { IdInput } from '@/interfaces/brokrete';
import gql from 'graphql-tag';
import type { InputOptionType } from './ContractorEditForm/types';
import { useGettext } from '@cranium/i18n';

const QuantityForm: React.FC<FormProps> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  const { gettext } = useGettext();
  return (
    <Form onSubmit={handleSubmit}>
      <Field className="m-0" hidden name="category.id" id="category.id" component={FormInput} validate={required} />
      <Row form>
        <Col>
          <Field
            name="units"
            id="units"
            label={gettext('Unit')}
            component={FormInputAsyncSelect}
            queryOptions={{ query: UNITS_QUERY, fetchPolicy: 'no-cache' }}
            dataAdapter={(responseData: UnitsResponseType) => {
              if (!responseData.config) return [];
              return responseData.config.units.map((value: UnitType) => ({
                id: value.code,
                label: value.name,
                value: value.code
              }));
            }}
            disabled={isSubmitting}
            validate={required}
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <Field
            name="step"
            id="step"
            placeholder={gettext('Increment')}
            formText={gettext(
              'Are you selling full or partial quantities. (Ex: 8 cubic meters OR 8.2 cubic meters.) Please add the order increments.'
            )}
            component={FormInput}
            autoComplete={`quantity.step`}
            validate={required}
            type="number"
          />
        </Col>
      </Row>
      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(QuantityForm);

export const quantityFormInitialProps: FormInitialProps = {
  category: {
    id: ''
  },
  units: '',
  step: ''
};

const UNITS_QUERY = gql`
  query {
    config {
      units {
        code
        name
      }
    }
  }
`;

type UnitType = {
  code: string;
  name: string;
};

type UnitsResponseType = {
  config: {
    units: UnitType[];
  };
};

type FormInitialProps = {
  category: IdInput;
  units: InputOptionType | string;
  step: number | string;
};

type FormProps = FormikProps<FormInitialProps>;
