import React, { useCallback } from 'react';
import styled from 'styled-components';

import ProductModificationTag from './ProductModificationTag';
import ColorPicker from '@/shared/components/ColorPicker';

type Props = {
  tagIndex: number;
  item: any;
  onRemove?: () => void;
  onChange: (key: number, item: any) => void;
};

const ProductModificationTagStyled = styled(ProductModificationTag)`
  padding: 7px 12px 7px 4px;
`;

const ColorPickerStyled = styled(ColorPicker)`
  margin-right: 10px;
`;

const ColorModificationTag: React.FC<Props> = ({ item, tagIndex, onChange, onRemove }) => {
  const onColorChanged = useCallback(
    (color: string | undefined) => {
      const newItem = {
        ...item,
        color
      };

      onChange(tagIndex, newItem);
    },
    [tagIndex, item]
  );

  return (
    <ProductModificationTagStyled
      Icon={
        <ColorPickerStyled
          value={item.color}
          onChanged={onColorChanged}
          customCss={{
            buttonCss: `
              margin: 0;
              width: 24px;
              height: 24px;
              border-radius: 50%;
            `
          }}
        />
      }
      item={item}
      onRemove={onRemove}
    />
  );
};

export default ColorModificationTag;
