export const convertRGBtoHEX = (rgbString: string) => {
  const rgb = rgbString
    .substring(4, rgbString.length - 1)
    .split(',')
    .map(value => Number(value));

  return rgb
    .map(value => value.toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase();
};
