import styled from 'styled-components';

type ColumnProps = {
  alignItems?: 'center' | 'start' | 'end';
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props: ColumnProps) => {
    return `
      ${props.alignItems ? `align-items: ${props.alignItems};` : ''}
    `;
  }}
`;

export default Column;
