import React, { useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import Select from 'react-select';
import { FieldConfig, useField, useFormikContext } from 'formik';
import { startCase } from 'lodash';

import Label from './Label';
import FormGroup from './FormGroup';
import InputError from './InputError';
import { OptionItem } from '@/view/screens/DetailedOrdersScreen/FilterPanel/components';
import styled from 'styled-components';
import { useApplicationContext } from '@components/context/ApplicationContext';
import { isEmpty } from '@/libs';

interface Props extends FieldConfig {
  isClearable?: boolean;
  readOnly?: boolean;
  className?: string;
  isLoading?: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  options: any;
  autoSelect?: boolean;
  onChange?: (option: any) => void;
  formatOptionLabel?: (option: any) => string;
  isOptionSelected?: (option: any, options: any[]) => boolean;
}

const SelectStyled = styled(Select)`
  *[class*='-control'] {
    height: 40px;
  }
`;

const FormikSelect: React.FC<Props> = ({
  className,
  label,
  disabled = false,
  isLoading = false,
  placeholder,
  options,
  isClearable,
  readOnly,
  onChange,
  autoSelect,
  formatOptionLabel = (option: any) => option.name || option.label || option.caption,
  isOptionSelected = (option: any, options: any[]) => {
    return options.includes(option) || options.find(opt => opt.id === option.id) !== null;
  },
  ...props
}) => {
  const context = useApplicationContext();
  const ref: any = useRef();
  const [{ name, value = '' }, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const error = meta.touched && !!meta.error;

  useEffect(() => {
    if (autoSelect && options?.length === 1) {
      const option = options[0];

      if (isEmpty(value) || value.id !== option.id) {
        setFieldValue(name, value);
        if (onChange) {
          onChange(value);
        }
      }
    }
  }, [autoSelect, setFieldValue, name, onChange, value, options]);

  const handleOnChange = (fieldValue: any) => {
    setFieldValue(name, fieldValue);
    if (onChange) {
      onChange(fieldValue);
    }
  };
  const onMenuOpen = useCallback(async () => {
    setTimeout(() => {
      const element = ref.current.select?.menuListRef;
      const parent = context?.page?.selectParentId ? window.document.getElementById(context.page.selectParentId) : null;
      if (element && parent) {
        if (parent.getBoundingClientRect().bottom < element.getBoundingClientRect().bottom) element.scrollIntoView(false);
      }
    }, 100);
  }, [ref]);

  const getOptionValue = (option: any) => {
    return formatOptionLabel(option);
  };

  return (
    <FormGroup className={className}>
      {label !== '' && <Label>{label || startCase(props.name)}</Label>}
      <SelectStyled
        ref={ref}
        id={name}
        name={name}
        isClearable={isClearable}
        formatOptionLabel={formatOptionLabel}
        isDisabled={disabled || readOnly}
        isLoading={isLoading}
        options={options}
        placeholder={placeholder}
        // @ts-ignore
        isOptionSelected={isOptionSelected}
        className={cx('react-select-container', { 'is-invalid': !!error })}
        onChange={handleOnChange}
        onMenuOpen={onMenuOpen}
        value={value}
        defaultMenuIsOpen={false}
        getOptionValue={getOptionValue}
        components={{
          IndicatorSeparator: () => null,
          Option: OptionItem
        }}
      />
      <InputError visible={error}>{meta.error}</InputError>
    </FormGroup>
  );
};

export default FormikSelect;
