import React from 'react';
import styled from 'styled-components';

import BaseModal, { BaseModalProps } from '@components/modals/BaseModal';
import Button, { Type } from '@/shared/components/buttons/Button';
import { i18n } from '@/core';

type ConfirmationModalProps = {
  text?: React.ReactNode;
  buttonConfirmTitle?: string;
  onCancel?: () => void;
  onConfirm: () => void;
} & BaseModalProps;

const Container = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  & > *:first-child {
    margin-right: 10px;
  }
`;

const Text = styled.div``;

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  isOpen,
  text,
  children,
  buttonConfirmTitle = 'Confirm',
  onClosed,
  onCancel,
  onConfirm
}) => {
  return (
    <BaseModal isOpen={isOpen} title={title} onClosed={onClosed}>
      <Container>{children ? children : <Text>{text}</Text>}</Container>
      <ButtonGroup>
        {onCancel && (
          <Button type={Type.SECONDARY} onClick={onCancel}>
            {i18n.t('labels.cancel')}
          </Button>
        )}
        <Button type={Type.PRIMARY} onClick={onConfirm}>
          {buttonConfirmTitle}
        </Button>
      </ButtonGroup>
    </BaseModal>
  );
};

export default ConfirmationModal;
