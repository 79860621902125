import { FC } from 'react';
import { SVGProps } from './types';
export const SVGDelete: FC<SVGProps> = props => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 384 384"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g>
          <g>
            <path
              id="ic_trash"
              d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z"
            />
            <polygon
              id="ic_trash_box"
              points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333 "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
