import gql from 'graphql-tag';

const buildProductTypeQuery = (paramsToFetch: string[]) => {
  const queryString = `
    query productType($id: ID!) {
      productType(id: $id) {
        ${paramsToFetch.map(param => {
          if (param === 'descriptionResources') {
            return `
              descriptionResources {
                id
                description
                sortOrder
                url
              }
            `;
          }
          return `${param}\n`;
        })}
      }
    }
  `;

  return gql(queryString);
};

export { buildProductTypeQuery };
