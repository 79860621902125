import gql from 'graphql-tag';

export const productDecoratePrices = gql`
  query productDecoratePrices($productDecorates: [IdInput!]) {
    productDecoratePrices(productDecorates: $productDecorates) {
      count
      values {
        id
        price {
          id
          priority
          value
        }
        productDecorate {
          id
        }
      }
    }
  }
`;
