import { FC } from 'react';
import { SVGProps } from './types';
export const SVGClose: FC<SVGProps> = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#BABBC0">
          <g>
            <path
              d="M76.858 11.142c.19.19.19.496 0 .686L72.686 16l4.172 4.172c.19.19.19.497 0 .686-.19.19-.496.19-.686 0L72 16.686l-4.172 4.172c-.19.19-.497.19-.686 0-.19-.19-.19-.496 0-.686L71.314 16l-4.172-4.172c-.19-.19-.19-.497 0-.686.19-.19.496-.19.686 0L72 15.314l4.172-4.172c.19-.19.497-.19.686 0z"
              transform="translate(-337 -136) translate(270 125)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
