import { useEffect, useState } from 'react';
import { useUIContext } from '@components/context/ApplicationContext';

export const useSidebarSize = () => {
  const {
    sidebar: { minimized, setMinimized }
  } = useUIContext();
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1200);

  useEffect(() => {
    window.innerWidth < 1200 ? setMinimized(true) : setMinimized(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200 && !minimized) {
        setMinimized(true);
        setSmallScreen(true);
      } else if (window.innerWidth >= 1200 && minimized) {
        setMinimized(false);
        setSmallScreen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [minimized, setMinimized]);

  return smallScreen;
};
