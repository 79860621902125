import { ProductCategory } from '@/interfaces/brokrete';
import gql from 'graphql-tag';
import { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';

export const useSupplierCategories = (supplierId?: string): [ProductCategory[] | null, boolean, () => Promise<void>] => {
  /* getting apollo client */
  const client = useApolloClient();
  /* init hook state */
  const [categories, setCategories] = useState<ProductCategory[] | null>(null);
  /* init loading state */
  const [loading, setLoading] = useState<boolean>(false);

  const fetchSupplierCategories = useCallback(async () => {
    if (supplierId) {
      setLoading(true);
      const { data } = await client.query({
        query: PRODUCTS_CATEGORIES_QUERY,
        variables: {
          root: false,
          supplier: {
            id: supplierId
          }
        }
      });

      const {
        productCategories: { values }
      } = data;

      setCategories([...values]);

      setLoading(false);
    }
  }, [client, supplierId, setLoading]);

  useEffect(() => {
    if (supplierId) {
      fetchSupplierCategories();
    }
  }, [supplierId, fetchSupplierCategories]);

  return [categories, loading, fetchSupplierCategories];
};

const PRODUCTS_CATEGORIES_QUERY = gql`
  query productCategories($root: Boolean, $supplier: IdInput) {
    productCategories(root: $root, supplier: $supplier) {
      count
      values {
        id
        image
        name
        industry
        root {
          id
          industry
        }
        permissions {
          decorates
          deliveryFees
          fullEdit
          options
          productColors
          productModifications
          seasonalFees
          trucks
          underloadFees
          washoutFees
        }
      }
    }
  }
`;
