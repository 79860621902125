import { Price } from '@/interfaces/brokrete';
import { useState, useEffect } from 'react';
import { ProductTypeFormPrice } from '@/view/components/forms/settings/ProductTypePricesForm/types';
import { productTypePricesInitialValues } from '@/view/components/forms/settings/ProductTypePricesForm/ProductTypePricesForm';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

interface Values {
  id: string;
  name: string;
}

export const usePriceList = (currency: string, values?: Values[]) => {
  const [priceList, setPriceList] = useState<ProductTypeFormPrice[] | null>();

  const { data } = useQuery(PRODUCT_TYPE_PRICE_QUERY, {
    variables: {
      productTypes: values ? values.map(value => ({ id: value.id })) : []
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && values && values.length) {
      const { productTypePrices } = data;

      // @ts-ignore
      const productTypePricesGrouped = productTypePrices.values.reduce((memo: any, { plant, price, productType }) => {
        if (!memo[productType.id]) {
          memo[productType.id] = [];
        }

        memo[productType.id].push({ ...price, ...(plant && { id: plant.id }) });

        return memo;
      }, {});

      Object.keys(productTypePricesGrouped).forEach(key => {
        productTypePricesGrouped[key] = productTypePricesGrouped[key].sort(sortPrices);
      });

      setPriceList(
        values.map(({ id, name }) => {
          const price = productTypePricesGrouped[id];
          return {
            ...productTypePricesInitialValues,
            id,
            name,
            ...(price &&
              price.length > 0 && {
                price: price[0].value,
                priceId: price[0].id
              }),
            currency: currency.toUpperCase()
          };
        })
      );
    }
  }, [currency, values, data]);

  return priceList;
};

function sortPrices(a: Price, b: Price) {
  const result = b.priority - a.priority;
  if (result !== 0) {
    return result;
  }

  return a.value - b.value;
}

const PRODUCT_TYPE_PRICE_QUERY = gql`
  query productTypePrices($productTypes: [IdInput!]) {
    productTypePrices(productTypes: $productTypes) {
      count
      values {
        plant {
          id
        }
        price {
          id
          value
          priority
          currency
          content
        }
        productType {
          id
          name
        }
      }
    }
  }
`;
