import { createAction } from '@/rdx/libs/action';
import { ThunkDispatch } from 'redux-thunk';
import { pick, omitBy } from 'lodash';
import gql from 'graphql-tag';
import { push } from 'connected-react-router';

import { Apollo } from '@/core/gql/apollo';
import { GQL } from '@/core/gql';
import type { AuthorizationState } from '@/rdx/reducers/authorization/reducers';
import { isEmpty } from '@/libs';
import { User } from '@/interfaces/brokrete';
import { LocalStorage } from '@/core/Storage';

export const ActionTypes = {
  START: 'authorization.start',
  UPDATE: 'authorization.update',
  UPDATE_USER: 'authorization.updateUser',
  COMPLETE: 'authorization.complete'
};

export const start = () => createAction(ActionTypes.START);
export const complete = () => createAction(ActionTypes.COMPLETE);

export const update = (payload: Partial<AuthorizationState>) => createAction(ActionTypes.UPDATE, payload);
export const updateUser = (payload: Partial<AuthorizationState>) => createAction(ActionTypes.UPDATE_USER, payload);

type SingInParams = {
  email: string;
  password: string;
};

const signIn = (authorizationData: SingInParams) => {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    const lastLocation = localStorage.getItem('location.beforeLogin') ?? '/orders';
    LocalStorage.clearAll();
    dispatch(start());
    dispatch(update({ error: null }));

    try {
      const { data, errors } = await Apollo.client.mutate({
        mutation: GQL.authorization.mutations.signIn,
        variables: authorizationData,
        fetchPolicy: 'no-cache'
      });

      if (data && data.authSignIn) {
        const { token, refreshToken, success } = data.authSignIn;

        const userData = omitBy(
          pick(data.authSignIn, [
            'contractor',
            'contractorMember',
            'driver',
            'partner',
            'partnerMember',
            'superAdmin',
            'supplier',
            'supplierMember'
          ]),
          isEmpty
        );

        const { data: meData } = await Apollo.client.query({
          query: meQuery,
          fetchPolicy: 'no-cache'
        });

        if (meData) {
          const user = {
            partner: meData.partner,
            permissions: meData.me.permissions.admin,
            ...userData,
            type: meData.me.type
          } as User;

          localStorage.setItem('token', token);
          localStorage.setItem('refresh-token', refreshToken);

          if (success) {
            await dispatch(
              update({
                isAuthenticated: true,
                user: user,
                token: token,
                refreshToken: refreshToken
              })
            );
            await dispatch(push(lastLocation));
          }
        }
      }
      if (errors) {
        await dispatch(
          update({
            error: errors
          })
        );

        await dispatch(push('/login'));
      }
    } catch (e) {
      // no op
    } finally {
      dispatch(complete());
    }
  };
};

export const Actions = {
  signIn,
  update,
  updateUser
};

export const meQuery = gql`
  query me {
    me {
      type
      permissions
      partner {
        id
        logo
        name
        phone
        email
        description
        code
        account {
          currency
        }
      }
    }
  }
`;
