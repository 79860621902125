import merge from './merge';
import isEmpty from './isEmpty';
import cast from '@/libs/cast';
import * as number from '@/libs/number';
// TODO: FIX type errors
// @ts-nocheck
export function prepare<T>(size: number, defaultValue: T): Array<T> {
  const result = [];
  for (let i = 0; i < size; i++) {
    result.push(defaultValue);
  }

  return result;
}

type DeepArray<T> = Array<T | DeepArray<T>>;

export function flatten<T>(array: DeepArray<T>): Array<T> {
  return array.reduce<Array<T>>((acc, b) => {
    return [...acc, ...(Array.isArray(b) ? flatten(b) : [b])];
  }, []);
}

export function compact<T>(array: Array<T>): Array<T> {
  return array.filter(value => !isEmpty(value));
}

export function sum(array: Array<number>): number {
  return array.reduce((result, item) => result + item, 0);
}

export function findIndex<T>(array: Array<T>, item: T, options: { compare?: (item1: T, item2: T) => boolean } = {}): number {
  for (let i = 0; i < array.length; i++) {
    if ((options.compare || compare)(array[i], item)) {
      return i;
    }
  }

  return -1;
}

export function same<T>(
  array1: Array<T>,
  array2: Array<T>,
  options: {
    ignoreOrder?: boolean;
    compare?: (item1: T, item2: T) => boolean;
  } = {}
) {
  if ((!array1 && array2) || (array1 && !array2)) {
    return false;
  }

  if (array1 === array2) {
    return true;
  }

  if (array1.length !== array2.length) {
    return false;
  }

  const compareMethod = options.compare || compare;

  for (let i = 0; i < array1.length; i++) {
    const index = findIndex(array2, array1[i], { compare: compareMethod });

    if (index === -1) {
      return false;
    }

    if (!options.ignoreOrder && index !== 0) {
      return false;
    }

    array2 = [...array2];
    array2.splice(index, 1);
  }

  return true;
}

export function unique<T>(array: Array<T>): Array<T> {
  // @ts-ignore
  return [...new Set(array)];
}

export function immutableUpdate<T>(
  array: Array<T>,
  value: Partial<T>,
  options?: {
    identityField?: string;
    merge?: boolean;
    locate?: (item: T) => boolean;
  }
): Array<T> {
  const { identityField = 'id', merge: optionMerge = true, locate } = options || {};

  // @ts-ignore
  const index = array.findIndex(item => (locate ? locate(item) : item[identityField] === value[identityField]));

  if (index === -1) {
    return array;
  }

  return Object.assign([...array], { [index]: optionMerge ? merge(array[index], value, { deep: false }) : value });
}

type CompareOptions = {
  deep?: boolean;
  number?: {
    round: boolean;
    roundOptions?: number.RoundOptions;
  };
};

export function compare<T>(item1: T, item2: T, options: CompareOptions = {}): boolean {
  const { deep = true } = options;

  if ((!item1 && item2) || (item1 && !item2)) {
    return false;
  }

  if (typeof item1 != typeof item2) {
    return false;
  }

  if (options.number) {
    const { round = false, roundOptions = {} } = options.number;
    if (round && typeof item1 === 'number') {
      item1 = cast(number.round(item1, roundOptions));
    }
    if (round && typeof item2 === 'number') {
      item2 = cast(number.round(item2, roundOptions));
    }
  }

  if (item1 === item2) {
    return true;
  }

  if (!deep) {
    return false;
  }

  if (Array.isArray(item1) && Array.isArray(item2)) {
    return same(item1, item2, {
      compare: (item3, item4) => compare(item3, item4, options)
    });
  }

  if (typeof item1 != 'object') {
    return false;
  }

  for (const k of compact([...Object.keys(item1), ...Object.keys(item2)])) {
    if (k === '__typename') continue;

    // @ts-ignore
    const p1 = item1[k];
    // @ts-ignore
    const p2 = item2[k];

    let result = false;

    if (typeof p1 === 'object') {
      result = compare(p1, p2, options);
    } else {
      result = compare(p1, p2, options);
    }

    if (!result) {
      return false;
    }
  }

  return true;
}
