import styled from 'styled-components';
import { colors } from '@/defaultStyles';

export const FormLabel = styled.label`
  margin-bottom: 0 !important;

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;

  color: ${colors.BATTLESHIP_GREY};

  text-transform: uppercase;
  white-space: nowrap;
`;
