import { i18n } from '@/core';
import { required } from '@/libs/validators';
import { FormInput } from '@components/forms/controls';
import { makeFormik } from '@components/forms/FormikForm';
import { useGettext } from '@cranium/i18n';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { Button, CardSubtitle, CardTitle, Col, Form, Row } from 'reactstrap';

type FormInitialProps = {
  unit: string;
  quantity: string;
  bufferTime: string;
};

type Props = FormikProps<FormInitialProps>;

export const deliveryGeofenceFormInitialProps: FormInitialProps = {
  unit: '',
  quantity: '',
  bufferTime: ''
};

const DeliveryGeofenceForm: React.FC<Props> = ({ handleSubmit, isSubmitting, isValid, dirty }) => {
  const { gettext } = useGettext();
  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col md={4}>
          <Field name={'unit'} id={'unit'} placeholder={gettext('Unit')} component={FormInput} validate={required} />
        </Col>
        <Col md={4}>
          <Field name={'quantity'} id={'quantity'} placeholder={gettext('Quantity')} component={FormInput} validate={required} />
        </Col>
      </Row>
      <Row form>
        <Col>
          <CardTitle>{i18n.t('cards.settings.deliveryGeofence.subtitle')}</CardTitle>
          <CardSubtitle style={{ whiteSpace: 'pre-line' }}>{i18n.t('cards.settings.deliveryGeofence.description')}</CardSubtitle>
        </Col>
      </Row>
      <Row form className="mt-4">
        <Col md={4}>
          <Field
            name={'bufferTime'}
            id={'bufferTime'}
            placeholder={gettext('Buffer time')}
            component={FormInput}
            validate={required}
          />
        </Col>
      </Row>
      <div className="float-left">
        <Button type="submit" size="lg" disabled={isSubmitting || !isValid || !dirty} className="btn-success mt-3">
          {i18n.t('labels.save')}
        </Button>
      </div>
    </Form>
  );
};

export default makeFormik<FormInitialProps>(DeliveryGeofenceForm);
