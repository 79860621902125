import cx from 'classnames';
import React from 'react';
import { PricesCells } from './PricesCells';
import styles from './PricesFormRow.module.scss';
import { get } from 'lodash';
import { useFormikContext } from 'formik';
import type { PricesFormInitialProps } from '../types';

export const PricesFormRow: React.FC<PricesFormRowComponentProps> = props => {
  const { namePrefix } = props;
  const { values } = useFormikContext<PricesFormInitialProps>();
  return (
    <tr className={cx(styles.mainRow)}>
      <td>
        {/* name */}
        <span className={styles.productName}>{`${get(values, namePrefix).name}`}</span>
      </td>
      <PricesCells namePrefix={namePrefix} />
    </tr>
  );
};

/* types */
type PricesFormRowComponentProps = {
  namePrefix: string;
};
