import gql from 'graphql-tag';

export const contractorsDataTable = gql`
  query contractors($limit: Int, $search: String, $skip: Int) {
    contractors(limit: $limit, search: $search, skip: $skip) {
      count
      values {
        id
        info {
          additional
          avatar
          country {
            code
            id
            name
          }
          name
          type
        }
        companyInfo {
          address
          logo
          name
        }
        owner {
          id
          identities {
            id
            provider
            uid
          }
        }
      }
    }
  }
`;
