import gql from 'graphql-tag';

export const fees = gql`
  query fees($contractor: IdInput, $input: OrderPreparatoryConfigInput) {
    orderPreparatoryConfig(contractor: $contractor, input: $input) {
      fees(filter: true, withPossibles: true) {
        fee {
          id
          name
          type
        }
        price {
          id
          value
          content
          currency
        }
      }
    }
  }
`;
