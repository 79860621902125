import React, { useCallback } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';

import { useAuthorization } from '@/core/gql/client';
import { useMePartnerLogo } from '@/core/gql/helpers/hooks';
import { ChangeLogoForm } from '@components/forms/settings';
import { changeLogoFormInitialProps } from '@components/forms/settings/ChangeLogoForm';
import { hideModal, showLoaderModal, showModal } from '@components/modals';
import { SVGBrokreteLogo } from '@components/elements/icons';
import { isPartner } from '@/libs/user';
import { useApolloClient } from '@apollo/client';
import { useGettext } from '@cranium/i18n';

type HeaderLogoProps = {
  className?: string;
  minimized?: boolean;
};

const HeaderLogo: React.FC<HeaderLogoProps> = ({ className, minimized }) => {
  const {
    authorization: { user }
  } = useAuthorization();

  /* fetch partner logo */
  const { logo: partnerLogo, refetch: refetchPartnerLogo } = useMePartnerLogo();
  /* show change logo form */
  const showLogoFormModal = useChangePartnerLogo();
  const isPartnerUser = !!(user && isPartner(user?.type));

  return (
    <Root className={className} isPartner={isPartnerUser}>
      {partnerLogo && (
        <LogoContainer minimized={!!minimized}>
          {partnerLogo === 'default' ? (
            <div
              onClick={() => {
                user?.partner?.id && isPartnerUser && showLogoFormModal(user.partner.id, undefined, refetchPartnerLogo);
              }}
            >
              <SVGBrokreteLogo />
            </div>
          ) : (
            <img
              src={partnerLogo}
              alt=""
              onClick={() => {
                user?.partner?.id && isPartnerUser && showLogoFormModal(user.partner.id, partnerLogo, refetchPartnerLogo);
              }}
            />
          )}
        </LogoContainer>
      )}
    </Root>
  );
};

const Root = styled.div<{ isPartner: boolean }>`
  user-select: none;
  cursor: ${({ isPartner }) => (isPartner ? 'pointer' : 'none')};
  width: inherit;
`;
const LogoContainer = styled.div<{ minimized: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  padding: ${props => (props.minimized ? '20px 5px' : '20px')};
  * {
    max-height: 60px;
    max-width: ${props => (props.minimized ? '40px' : '200px')};
  }
`;

export default HeaderLogo;

/* hooks */
const useChangePartnerLogo = () => {
  const { gettext } = useGettext();
  const client = useApolloClient();
  return useCallback(
    async (partnerId: string, logo?: string, refetch?: () => void) => {
      const { value } = await showModal({
        titleText: gettext('Logo'),
        html: (
          <div className="text-left">
            <ChangeLogoForm
              initialValues={{ ...changeLogoFormInitialProps, image: logo || '' }}
              onSubmit={value => {
                hideModal({ value });
              }}
            />
          </div>
        )
      });

      if (value) {
        const { files = {} } = value;
        const { image } = files;
        showLoaderModal();
        const { data } = await client.mutate<{ partnerUpdate: { success: boolean } }>({
          mutation: LOGO_MUTATION,
          variables: {
            input: {
              id: partnerId,
              logo: image
            }
          }
        });

        if (data) {
          const {
            partnerUpdate: { success }
          } = data;

          if (success && refetch) {
            refetch();
          }
        }

        hideModal();
      }
    },
    [client]
  );
};

/* gql */
const LOGO_MUTATION = gql`
  mutation partnerUpdate($input: PartnerUpdateInput!) {
    partnerUpdate(input: $input) {
      success
      partner {
        logo
      }
    }
  }
`;
