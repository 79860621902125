// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductTypePricesForm-module__wrapper--oDw9h{position:relative;overflow-x:auto;width:100%}.ProductTypePricesForm-module__wrapper--oDw9h .ProductTypePricesForm-module__fieldControls--chbq2{min-width:67px}.ProductTypePricesForm-module__wrapper--oDw9h table{width:100%;max-width:1020px;border-spacing:0}.ProductTypePricesForm-module__wrapper--oDw9h table th,.ProductTypePricesForm-module__wrapper--oDw9h table td{padding:.5rem .25rem;text-align:center;vertical-align:center;min-width:100px}.ProductTypePricesForm-module__wrapper--oDw9h table th:first-child,.ProductTypePricesForm-module__wrapper--oDw9h table td:first-child{position:-webkit-sticky;position:sticky}.ProductTypePricesForm-module__wrapper--oDw9h table input{padding-right:.25rem !important}.ProductTypePricesForm-module__wrapper--oDw9h table input::-webkit-input-placeholder{text-align:end !important}.ProductTypePricesForm-module__wrapper--oDw9h table input::placeholder{text-align:end !important}", "",{"version":3,"sources":["webpack://./src/view/components/forms/settings/ProductTypePricesForm/ProductTypePricesForm.module.scss"],"names":[],"mappings":"AAAA,8CACE,iBAAA,CACA,eAAA,CACA,UAAA,CAEA,kGACE,cAAA,CAGF,oDACE,UAAA,CACA,gBAAA,CACA,gBAAA,CACA,8GAEE,oBAAA,CACA,iBAAA,CACA,qBAAA,CACA,eAAA,CAEA,sIACE,uBAAA,CAAA,eAAA,CAIJ,0DAIE,+BAAA,CAHA,qFACE,yBAAA,CADF,uEACE,yBAAA","sourcesContent":[".wrapper {\n  position: relative;\n  overflow-x: auto;\n  width: 100%;\n\n  & .fieldControls {\n    min-width: 67px;\n  }\n\n  table {\n    width: 100%;\n    max-width: 1020px;\n    border-spacing: 0;\n    th,\n    td {\n      padding: 0.5rem 0.25rem;\n      text-align: center;\n      vertical-align: center;\n      min-width: 100px;\n\n      &:first-child {\n        position: sticky;\n      }\n    }\n\n    input {\n      &::placeholder {\n        text-align: end !important;\n      }\n      padding-right: 0.25rem !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ProductTypePricesForm-module__wrapper--oDw9h",
	"fieldControls": "ProductTypePricesForm-module__fieldControls--chbq2"
};
export default ___CSS_LOADER_EXPORT___;
