import { useCallback, useRef, useState } from 'react';
import { Fee, OrderPreparatoryConfigInput, Price } from '@/interfaces/brokrete';
import { OverrideFields } from '@/shared/utils/types';
import { useIsMounted } from '@/view/hooks/useIsMounted';
import { Statuses } from './types';

import {
  useOrderFeeOrderRequest,
  OrderFeeOrderQuery,
  useOrderPreparatoryConfigTotalPriceRequest,
  OrderPreparatoryConfigTotalPriceQuery,
  useOrderPreparatoryConfigDefaultFeePriceRequest,
  OrderPreparatoryConfigDefaultFeePriceQuery
} from './queries/useOrderFeeQueries.generated';

export type OrderPreparatoryConfigDefaultFeePrice = OverrideFields<
  OrderPreparatoryConfigDefaultFeePriceQuery['orderPreparatoryConfig']['fees'][number],
  {
    price: OverrideFields<
      OrderPreparatoryConfigDefaultFeePriceQuery['orderPreparatoryConfig']['fees'][number]['price'],
      {
        id: string;
      }
    >;
  }
>;
export type OrderFeePanelFeePrice = { fee: Fee; price: Price; value: number };
export type OrderFeePrice = OrderPreparatoryConfigDefaultFeePrice & { status: Statuses; value: number };
export type OrderFeePanelDefaultFeePrice = any;

interface State {
  loading: boolean;
  feePrices: OrderFeePrice[];
  totalPrice?: { value: number };
}

export const useOrderFee = (orderId: string) => {
  const isMounted = useIsMounted();

  const fetchOrder = useOrderFeeOrderRequest();
  const fetchOrderTotalPrice = useOrderPreparatoryConfigTotalPriceRequest();
  const fetchOrderDefaultFeePrices = useOrderPreparatoryConfigDefaultFeePriceRequest();

  const propsRef = useRef<{ orderId: string; order?: OrderFeeOrderQuery['order'] }>({ orderId });
  const [state, setState] = useState<State>({ loading: false, feePrices: [] });
  Object.assign(propsRef.current, { orderId });

  const getOrderData = useCallback(async () => {
    const { orderId, order } = propsRef.current;

    if (orderId === order?.id) return order;

    const { data } = await fetchOrder({ id: orderId });
    propsRef.current.order = data?.order;

    return data?.order;
  }, [orderId]);

  const getOrderFeePrices = async () => {
    const order = await getOrderData();

    if (!order) {
      return [];
    }

    return order.prices
      .filter(v => !!v.fee)
      .map(({ value, price, fee }) => ({
        value,
        price,
        fee
      })) as OrderFeePanelFeePrice[];
  };

  const getDefaultFeePrices = async (input: OrderPreparatoryConfigInput) => {
    const order = await getOrderData();
    const { contractor: contractorInput, ...restInput } = input;

    let totalPrice = undefined;
    let defaultFees: Array<OrderFeePanelDefaultFeePrice> = [];

    if (order) {
      const { contractor, partner, currency } = order;
      const { data } = await fetchOrderDefaultFeePrices({
        order: { id: orderId },
        input: restInput,
        contractor: contractorInput ?? contractor,
        partner,
        currency
      });

      const fees = data?.orderPreparatoryConfig.fees ?? [];

      if (fees.length) {
        const feeInput = fees.filter(v => !!v.price.id).map(v => ({ id: v.fee.id, price: { id: v.price.id as string } }));

        const totalPriceData = await fetchTotalPrice({ ...input, fees: feeInput });

        if (totalPriceData) {
          totalPrice = { value: totalPriceData.value };

          defaultFees = fees
            .filter(v => !!v.fee)
            .map(item => {
              const fee = totalPriceData.components.find(v => v.fee?.id === item.fee.id);
              return { ...item, value: fee?.value ?? Number.NaN };
            });
        }
      }
    }

    return { totalPrice, feePrices: defaultFees };
  };

  const fetchTotalPrice = async (
    input: OrderPreparatoryConfigInput
  ): Promise<OrderPreparatoryConfigTotalPriceQuery['orderPreparatoryConfig']['totalPrice'] | undefined> => {
    const order = await getOrderData();
    const { contractor: contractorInput, ...restInput } = input;

    if (order) {
      const { contractor, partner, currency } = order;

      if (order) {
        const { data } = await fetchOrderTotalPrice({
          order: { id: orderId },
          input: restInput,
          contractor: contractorInput ?? contractor,
          partner,
          currency
        });

        return data?.orderPreparatoryConfig.totalPrice;
      }
    }

    return undefined;
  };

  const fetch = useCallback(async (input: OrderPreparatoryConfigInput) => {
    setState(v => ({ ...v, loading: true }));

    const orderFeePrices = await getOrderFeePrices();
    const defaultFeePrices = await getDefaultFeePrices(input);

    let newState: State = { loading: false, feePrices: [], totalPrice: defaultFeePrices.totalPrice };

    const oldFeePrices: OrderFeePrice[] = orderFeePrices
      .filter(({ fee }) => !defaultFeePrices.feePrices.some(v => v.fee.id === fee.id))
      .map(v => ({ ...v, status: defaultFeePrices.feePrices.length ? Statuses.old : Statuses.changed }));

    const newFeePrices: OrderFeePrice[] = defaultFeePrices.feePrices.map(item => ({
      ...item,
      status: orderFeePrices.some(v => v.fee.id === item.fee.id) ? Statuses.changed : Statuses.new
    })) as OrderFeePrice[];

    newState.feePrices = oldFeePrices.concat(newFeePrices);

    if (isMounted.current) {
      setState(newState);
    }

    return newState;
  }, []);

  return { fetch, ...state, fetchTotalPrice };
};
