import gql from 'graphql-tag';

export const supplierInfo = gql`
  query Supplier($id: ID!) {
    supplier(id: $id) {
      id
      name
      plants {
        values {
          id
          name
          address {
            display
            city
          }
          deliveryRadius
          city {
            name
            country {
              name
              currency
            }
          }
        }
      }
      categories {
        values {
          id
          name
          image
          industry
        }
      }
    }
  }
`;
