import { useRef } from 'react';
import type { OrderFeePanelInstance } from './types';

export const useOrderFeePanel = () => {
  const instanceRef = useRef<OrderFeePanelInstance>({
    getCurrentPrice: () => Promise.resolve(undefined),
    getFee: () => [],
    getFeePrices: () => [],
    setChecked: () => {},
    requireActionConfirmation: () => false,
    confirmAction: async () => Promise.resolve()
  });
  return instanceRef.current;
};
