import { FC } from 'react';
import { SVGProps } from './types';
export const SVGBrokreteLogo: FC<SVGProps> = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="255" height="24" viewBox="0 0 255 24" {...props}>
      <g fill="#2C2F35" fillRule="nonzero">
        <path d="M28.498 17.225c0 4.197-3.564 6.053-8.317 6.053H0V.8h19.597c5.826 0 8.229 2.338 8.229 5.41 0 2.69-1.602 5.38-6.63 5.573 5.539.223 7.302 2.473 7.302 5.441zm-9.127-9.284c0-.865-.836-1.525-3.265-1.525H7.813v3.089h8.293c2.37 0 3.265-.635 3.265-1.564zm.45 8.037c0-1.024-.93-1.664-3.427-1.664h-8.58v3.425h8.582c2.336 0 3.424-.704 3.424-1.761zM61.476 23.287H50.749l-7.461-6.82h-3.49v6.82h-7.814V.801H49.95c6.979 0 10.407 3.68 10.407 7.685 0 3.937-2.754 6.653-8.228 7.107l9.348 7.694zM39.797 6.563v4.197H48.7c2.21 0 3.203-.963 3.203-2.082 0-1.12-.993-2.115-3.203-2.115h-8.902zM93.552 11.943c0 6.948-6.178 11.976-15.72 11.976-9.544 0-15.627-5.028-15.627-11.976C62.205 4.995 68.257 0 77.831 0s15.721 4.99 15.721 11.943zm-22.925-.03c0 2.943 2.24 5.464 7.204 5.464 4.964 0 7.129-2.53 7.129-5.465 0-3.01-2.21-5.349-7.129-5.349s-7.204 2.338-7.204 5.35zM108.147 15.498l-3.68 3.037v4.752h-7.814V.801h7.814v9.505L115.447.8h10.6l-12.263 10.13 14.02 12.356h-11.3zM160.185 23.126h-10.72l-7.459-6.82h-3.5v6.82h-7.813V.649h17.964c6.98 0 10.406 3.68 10.406 7.684 0 3.938-2.754 6.654-8.229 7.108l9.35 7.685zm-21.679-16.71v4.19h8.902c2.21 0 3.203-.951 3.203-2.082 0-1.132-.994-2.108-3.203-2.108h-8.902zM193.426 6.575V.649h30.416v5.926h-11.323v16.55h-7.813V6.576zM190.655 6.38h-19.96v2.816h14.214v5.092h-14.212v3.201h19.96v5.637h-27.773V.649h27.771zM254.84 6.38h-19.96v2.816h14.214v5.092h-14.215v3.201h19.96v5.637h-27.773V.649h27.774z"></path>
      </g>
    </svg>
  );
};
