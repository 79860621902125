import React from 'react';
import { getIn, useFormikContext } from 'formik';
import MaskedInput from '@components/elements/MaskedInput';
import FormattedInput from '@components/elements/FormattedInput';
import styled from 'styled-components';
import { FormLabel } from '@components/forms/controls/FormLabel';

type InputProps = {
  [key: string]: any;
  id?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  formGroupClassNames?: string;
  formText?: string;
  mask?: string | string[];
  numeric?: boolean;
  prefix?: string;
  width?: string;
  extractValue?: (value: any) => string | number;
};

const FormInput: React.FC<InputProps> = (props): JSX.Element => {
  const {
    field,
    mask,
    label,
    numeric,
    prefix,
    placeholder,
    form: { errors: formErrors, touched: formTouched },
    formText,
    formGroupClassNames,
    extractValue,
    readOnly,
    className,
    width,
    ...restProps
  } = props;

  const { name, value, onChange } = field;
  const { setFieldValue } = useFormikContext();

  const { id, disabled } = restProps;

  const error = getIn(formErrors, name);
  const touched = getIn(formTouched, name);

  const invalid: boolean = !!(error && touched);

  const inputValue = extractValue ? extractValue(value) : typeof value === 'number' ? value : value || '';

  const shouldDisplayLabel = label && !restProps.hidden;
  const isStandardInput = !numeric && (!mask || mask === '');

  return (
    <FormGroup width={width} className={formGroupClassNames}>
      {shouldDisplayLabel && <FormLabel htmlFor={id}>{label}</FormLabel>}

      {isStandardInput && (
        <Input
          invalid={invalid}
          placeholder={placeholder}
          {...field}
          {...restProps}
          className={className}
          value={inputValue}
          readOnly={readOnly}
          disabled={disabled}
        />
      )}

      {numeric && (
        <FormattedInput
          className={className}
          value={inputValue}
          name={name}
          prefix={prefix}
          onChange={value => setFieldValue(name, value)}
          isNumericString
          disabled={disabled}
          readOnly={readOnly}
        />
      )}

      {mask && (
        <MaskedInput
          value={inputValue}
          mask={mask}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}

      {formText && <FormText>{formText}</FormText>}
    </FormGroup>
  );
};

const FormGroup = styled.div<{ width?: string }>`
  font-family: 'Catamaran', sans-serif;
  color: #72747e;
  align-items: flex-start;
  ${props => props.width && `width: ${props.width};`}
`;

const Input = styled.input`
  font-size: 14px;
  border: none;
  color: #282a31;
  align-items: flex-start;
  padding: 0px 14px 0px 14px;
  height: 38px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px #dcdcdf solid;
  border-radius: 4px;

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px rgb(0, 106, 255);
  }

  &:disabled {
    background-color: #efefef;
    color: #999999;
  }

  transition: border 0.1s;
`;

const FormText = styled.span`
  font-size: 10px;
  font-weight: normal;
  float: left;
  padding-top: 3px;
`;

export default FormInput;
