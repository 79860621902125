import React from 'react';
import ReactSelect, { Theme, Props } from 'react-select';
import { StylesConfig } from 'react-select/src/styles';
import { OptionType } from '@components/filters/FilterInputAsyncSelect';

export { ReactSelect };

export const stylesObject: any = {
  ['[type=text]:focus']: { boxShadow: 'none' } //eslint-disable-line no-useless-computed-key
};

const basePadding = { padding: '4px 3px' };
const baseFonts = { fontSize: '14px' };
export const defaultStyles: StylesConfig<OptionType, boolean> = {
  menuPortal: base => ({ ...base, zIndex: 2147483647 }),
  placeholder: styles => ({ ...styles, ...baseFonts, ...basePadding }),
  singleValue: styles => ({ ...styles, ...baseFonts, ...basePadding }),
  option: styles => ({ ...styles, ...baseFonts, padding: '5px 10px' }),
  input: base => ({
    ...base,
    ...baseFonts,
    padding: '0 4px',
    ...stylesObject
  }),
  indicatorSeparator: base => ({ ...base, display: 'none' })
};
export const getDefaultTheme = (theme: Theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 40,
    baseUnit: 2
  },
  colors: {
    ...theme.colors,
    primary25: '#f6f6f6',
    primary: '#1fb841'
  }
});

const Select: React.FunctionComponent<Props> = ({ value = null, ...restProps }) => {
  return (
    <ReactSelect
      isSearchable
      styles={defaultStyles}
      theme={getDefaultTheme}
      menuPortalTarget={document.body}
      value={value}
      defaultValue={value}
      {...restProps}
    />
  );
};

export default Select;
