import { PropsWithChildren, FC } from 'react';
import { PermissionsGateProps, useHasPermissions } from './Access';

export const PermissionsGate: FC<PropsWithChildren<PermissionsGateProps>> = ({
  permissions,
  operator,
  fallback = null,
  children
}) => {
  return useHasPermissions(permissions, operator) ? <>{children}</> : <>{fallback}</>;
};
