import React from 'react';
import { useField, FieldConfig } from 'formik';

import FormGroup from './FormGroup';
import InputError from './InputError';
import TextArea from './TextArea';
import Label from './Label';

interface Props extends FieldConfig {
  label: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: string) => void;
}

const FormikTextAreaField: React.FunctionComponent<Props> = ({ label, placeholder, disabled, onChange, ...props }) => {
  const [field, meta] = useField(props);
  const error = meta.touched && !!meta.error;
  return (
    <FormGroup>
      <Label>{label}</Label>
      <TextArea
        {...field}
        disabled={disabled}
        error={error}
        placeholder={placeholder}
        onChange={e => {
          field.onChange(e);
          onChange && onChange(e, props.name);
        }}
      />
      <InputError visible={error}>{meta.error}</InputError>
    </FormGroup>
  );
};

export default FormikTextAreaField;
