import React, { useCallback } from 'react';
import styled from 'styled-components';
import SortableList from './SortableList';
import './SortableHelper.scss';
import { ProductTypeImageResource } from '@/interfaces/brokrete';

const SortableGrid: React.FunctionComponent<Props> = ({ photos, move, ...arrayHelpers }) => {
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      move(oldIndex, newIndex);
    },
    [move]
  );
  return (
    <TypeList>
      <StyledSortableList
        axis="xy"
        photos={photos}
        onSortEnd={onSortEnd}
        transitionDuration={500}
        helperClass="create-product_SortableHelper"
        {...arrayHelpers}
      />
    </TypeList>
  );
};

const TypeList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledSortableList = styled(SortableList)`
  position: relative;
  z-index: 0;
  border-radius: 3px;
  outline: none;
  height: auto;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  padding: 0;
`;

type Props = {
  photos: Array<ProductTypeImageResource>;
  [key: string]: any;
};

export default SortableGrid;
