import gql from 'graphql-tag';
import { DeliveryMethod, Order } from '@/interfaces/brokrete';
import { Plant } from '@/gql/types.generated';

export type OrderWithConfigResponse = {
  order: Order;
  orderPreparatoryConfig: {
    trucksConfig: {
      deliveryMethod: DeliveryMethod;
      count: {
        max: number;
      };
      quantity: {
        max: number;
        min: number;
        step: number;
      };
    };
    trucksConfigs: {
      deliveryMethod: DeliveryMethod;
      count: {
        max: number;
      };
      quantity: {
        max: number;
        min: number;
        step: number;
      };
    }[];
  };
};
export type UpdateOrderMutationResponse = { orderUpdate: { success: boolean; order: Order } };

export const orderWithConfigQuery = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      status
      partner {
        id
      }
      supplier {
        id
      }
      plant {
        id
        name
      }
      deliveryMethod {
        id
        name
        caption
        description
      }
      timeBetweenTrucks
      trucks
      units
      trucksDrivers {
        id
        truckId
        driver {
          id
          info {
            name
          }
        }
        truck {
          id
          name
          type
        }
        plant {
          id
          name
        }
      }
      product {
        id
        industry
        permissions {
          trucks
        }
      }
      prices {
        id
        value
        quantity
        anchorPrice {
          id
        }
        productDecorate {
          id
          name
        }
        fee {
          id
          name
        }
        option {
          id
          name
        }
        price {
          id
          value
          content
        }
        productType {
          id
          name
        }
      }
    }
    orderPreparatoryConfig(order: { id: $id }) {
      trucksConfig {
        deliveryMethod {
          id
          name
          caption
          description
        }
        count {
          max
        }
        quantity {
          min
          max
          step
        }
      }
      trucksConfigs {
        deliveryMethod {
          name
          caption
          description
        }
        count {
          max
        }
        quantity {
          min
          max
          step
        }
      }
    }
  }
`;

export const driversQuery = gql`
  query drivers($search: String, $orderTruck: IdInput, $plant: IdInput) {
    drivers(search: $search, orderTruck: $orderTruck, plant: $plant) {
      values {
        id
        info {
          name
        }
        trucks {
          id
          name
        }
      }
    }
  }
`;

export const trucksQuery = gql`
  query trucks($search: String, $plant: IdInput) {
    trucks(search: $search, plant: $plant) {
      values {
        id
        name
        type
        driver {
          id
          info {
            name
          }
        }
      }
    }
  }
`;

export const plantsQuery = gql`
  query plants($partner: IdInput, $name: String) {
    plants(partner: $partner, name: $name) {
      values {
        id
        name
      }
    }
  }
`;

export const updateOrderMutation = gql`
  mutation orderUpdate($order: OrderUpdateInput!, $plant: IdInput, $quiet: Boolean, $askToConfirm: Boolean) {
    orderUpdate(order: $order, plant: $plant, quiet: $quiet, askToConfirm: $askToConfirm) {
      order {
        id
        deliveryMethod {
          name
          caption
          description
        }
        timeBetweenTrucks
        trucks
        trucksDrivers {
          id
          truckId
          quantity
          driver {
            id
            info {
              name
            }
          }
          truck {
            id
            name
            type
          }
          plant {
            id
            name
          }
        }
      }
      success
    }
  }
`;
